import { css } from '@ptt-eia-web/helpers'
import {
  IProjectListParams,
  ISettingResponse,
  useGetProjectTypesQRY,
  useGetServiceTypesQRY,
} from '@ptt-eia-web/services'
import { Typography, DatePicker, Button, Select, Card, Row, Col, Flex } from 'antd'
import dayjs, { Dayjs } from 'dayjs'
import { useCallback, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

import { colors } from '../../app/theme'

const parseToSearchParams = (params: IProjectListParams) => {
  return {
    serviceTypeId: params.serviceTypeId?.toString() ?? '',
    projectTypeId: params.projectTypeId?.toString() ?? '',
    year: params.year?.toString() ?? '',
    currentPage: params.currentPage?.toString() ?? '1',
  }
}

const parseToApiParams = (params: URLSearchParams): IProjectListParams => {
  const getIntParam = (paramName: string): number | null => {
    const paramValue = params.get(paramName)
    return paramValue ? parseInt(paramValue, 10) : null
  }

  return {
    projectTypeId: getIntParam('projectTypeId'),
    serviceTypeId: getIntParam('serviceTypeId'),
    year: getIntParam('year'),
  }
}

const getOptions = <T extends ISettingResponse>(list: T[]) => {
  return list.map((item) => ({
    label: item.name,
    value: item.id,
  }))
}

const getYearValue = (year: Dayjs | null) => {
  return year ? Number(year.format('YYYY')) : null
}

const defaultFilter = {
  projectTypeId: null,
  serviceTypeId: null,
  year: null,
}

export const FilterNav: React.FC = () => {
  const { data: projectTypeList = [] } = useGetProjectTypesQRY()
  const { data: serviceTypeList = [] } = useGetServiceTypesQRY()

  const [searchParams, setSearchParams] = useSearchParams()
  const [filter, setFilter] = useState<IProjectListParams>(parseToApiParams(searchParams))

  const handleChangeYearPicker = useCallback(
    (year: Dayjs | null) => {
      setFilter({ ...filter, year: getYearValue(year) })
    },
    [filter],
  )

  const handleChangeProjectType = useCallback(
    (value: number) => {
      setFilter({ ...filter, projectTypeId: value })
    },
    [filter],
  )

  const handleChangeServiceType = useCallback(
    (value: number) => {
      setFilter({ ...filter, serviceTypeId: value })
    },
    [filter],
  )

  const handleClearFilter = useCallback(() => {
    setSearchParams(parseToSearchParams(defaultFilter))
    setFilter(defaultFilter)
  }, [setSearchParams])

  const handleSummitSearch = useCallback(() => {
    setSearchParams(parseToSearchParams(filter))
  }, [filter, setSearchParams])

  return (
    <Card>
      <Typography css={css({ fontWeight: 'bold', marginBottom: 6 })}>FILTER</Typography>
      <Row gutter={[16, 16]}>
        <Col xs={24} md={6}>
          <Select
            placeholder="ประเภท Service"
            value={filter.serviceTypeId}
            css={css`
              width: 100%;
            `}
            onSelect={handleChangeServiceType}
            options={getOptions(serviceTypeList)}
          />
        </Col>
        <Col xs={24} md={6}>
          <Select
            placeholder="ประเภทโครงการ"
            value={filter.projectTypeId}
            css={css`
              width: 100%;
            `}
            onSelect={handleChangeProjectType}
            options={getOptions(projectTypeList)}
          />
        </Col>
        <Col xs={24} md={6}>
          <DatePicker
            css={css`
              width: 100%;
            `}
            onChange={(value) => handleChangeYearPicker(value)}
            picker="year"
            placeholder="Year"
            value={filter.year ? dayjs(filter.year.toString()) : null}
            format="YYYY"
          />
        </Col>
        <Col xs={24} md={6}>
          <Flex gap={16}>
            <Button
              onClick={handleSummitSearch}
              css={css`
                width: 100%;
                text-align: center;
              `}
              type="primary"
            >
              Search
            </Button>

            <Button
              onClick={handleClearFilter}
              css={css`
                width: 100%;
                background-color: #ccecf8;
                color: ${colors.primary};
              `}
              type="primary"
            >
              Clear
            </Button>
          </Flex>
        </Col>
      </Row>
    </Card>
  )
}
