export enum MenuCodeEnum {
  Home = 110000,
  ServiceRequest = 120000,
  ServiceRequestDashboard = 121000,
  ServiceRequestTask = 122000,
  ProjectDashboardMain = 130000,
  ProjectDashboard = 131000,
  ProjectStatusUpdate = 132000,
  PrNews = 140000,
  AnnualReport = 150000,
  AboutUs = 160000,
  AdminUserManagement = 210000,
  AdminHomeContent = 220000,
  AdminAnnualReport = 230000,
  AdminAboutUs = 240000,
  AdminSettings = 250000,
}
