/* eslint-disable @typescript-eslint/no-explicit-any */
import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse, InternalAxiosRequestConfig } from 'axios'
import dayjs from 'dayjs'
import { get, set } from 'lodash'
import qs from 'qs'

export enum ContentType {
  X_WWW_FORM_URLENCODED = 'application/x-www-form-urlencoded',
  JSON = 'application/json',
  FORM_DATA = 'multipart/form-data',
}

export const getContentTypeHeader = (contentType: ContentType) => {
  return { 'Content-Type': contentType }
}

export class BaseHttpClient {
  private readonly ax: AxiosInstance
  private readonly config: AxiosRequestConfig

  constructor(config: AxiosRequestConfig) {
    this.config = config
    this.ax = axios.create(config)
    this.ax.interceptors.request.use(async (request) => {
      set(request, 'headers.Content-Type', get(request, 'headers.Content-Type', ContentType.JSON))
      request.data = this.deepLoop(request.data, this.normalizeRequestData)
      if (request.headers?.['Content-Type'] === ContentType.FORM_DATA) {
        this.parseDataToFormData(request)
      } else if (request.headers?.['Content-Type'] === ContentType.X_WWW_FORM_URLENCODED) {
        this.parseDataToString(request)
      }
      return this.onRequest(request)
    })
    this.ax.interceptors.response.use(
      async (response) => {
        response.data = this.deepLoop(response.data, this.normalizeRequestData)
        return this.onResponse(response)
      },
      async (error: AxiosError) => {
        if (error.response?.data && error.response.data instanceof Blob) {
          let errorString = await error.response.data.text()
          try {
            errorString = JSON.parse(errorString)
          } catch {
            // do nothing
          }
          error.response.data = errorString
        }
        return this.onError(error)
      },
    )
  }

  private parseDataToString(request: AxiosRequestConfig) {
    if (request.data) {
      request.data = qs.stringify(request.data)
    }
  }

  private parseDataToFormData(request: AxiosRequestConfig) {
    if (request.data && !(request.data instanceof FormData)) {
      const formData = new FormData()
      Object.entries(request.data).forEach(([key, value]: any[]) => {
        if (value !== undefined) {
          if (value instanceof Array) {
            value.forEach((val) => {
              formData.append(`${key}`, val)
            })
          } else {
            formData.append(key, value)
          }
        }
      })
      request.data = formData
    }
  }

  private deepLoop(data: any, func: (d: any) => any): any {
    if (data instanceof Blob) {
      return func(data)
    }
    if (dayjs.isDayjs(data)) {
      return func(data)
    }
    if (data instanceof Date) {
      return func(data)
    }
    if (data instanceof Array) {
      return data.map((d) => this.deepLoop(d, func))
    }
    if (data instanceof Object) {
      const formatData: any = {}
      Object.keys(data).forEach((key) => {
        formatData[key] = this.deepLoop(data[key], func)
      })
      return formatData
    }
    return func(data)
  }

  private resolveFullUrl(path: string) {
    if (!path.startsWith('/')) {
      throw new Error('Invalid path')
    }
    try {
      const urlObj = new URL(`${this.config.baseURL}${path}`)
      return urlObj.href
    } catch (error) {
      throw new Error('Invalid path')
    }
  }

  protected async onRequest(request: InternalAxiosRequestConfig<any>): Promise<InternalAxiosRequestConfig<any>> {
    return request
  }

  protected async onResponse(response: AxiosResponse<any>): Promise<AxiosResponse<any>> {
    return response
  }
  protected onError(error: AxiosError): any {
    return Promise.reject(error)
  }
  protected normalizeRequestData(value: any) {
    return value
  }
  protected normalizeResponseData(value: any) {
    return value
  }

  get<T>(url: string, config?: AxiosRequestConfig<T>): Promise<AxiosResponse<T, any>> {
    return this.ax.get<T>(this.resolveFullUrl(url), config)
  }

  post<T>(url: string, data?: any, config?: AxiosRequestConfig<T>): Promise<AxiosResponse<T, any>> {
    return this.ax.post<T>(this.resolveFullUrl(url), data, config)
  }

  put<T>(url: string, data?: any, config?: AxiosRequestConfig): Promise<AxiosResponse<T, any>> {
    return this.ax.put<T>(this.resolveFullUrl(url), data, config)
  }

  patch<T>(url: string, data?: any, config?: AxiosRequestConfig): Promise<AxiosResponse<T, any>> {
    return this.ax.patch<T>(this.resolveFullUrl(url), data, config)
  }

  delete<T>(url: string, config?: AxiosRequestConfig<any>): Promise<AxiosResponse<T, any>> {
    return this.ax.delete<T>(this.resolveFullUrl(url), config)
  }

  getBlob<T extends Blob>(url: string, config?: AxiosRequestConfig<Text>): Promise<AxiosResponse<T, any>> {
    return this.ax.get<T>(this.resolveFullUrl(url), {
      responseType: 'blob',
      ...config,
    })
  }

  postGetFile<T extends Blob>(
    url: string,
    data?: any,
    config?: AxiosRequestConfig & { contentType?: ContentType },
  ): Promise<AxiosResponse<T, any>> {
    return this.ax.post<T>(this.resolveFullUrl(url), data, {
      responseType: 'blob',
      ...config,
    })
  }
}
