import { css } from '@ptt-eia-web/helpers'
import { useGetServiceTypesQRY, useGetProjectTypesQRY, useGetMdSubServiceQRY } from '@ptt-eia-web/services'
import { Col, Input, Row, Select } from 'antd'
import { useWatch } from 'antd/es/form/Form'
import FormItem from 'antd/es/form/FormItem'
import { Dayjs } from 'dayjs'
import { useMemo } from 'react'

import { Txt, TxtProps } from '../../../components/Txt'
import { IBaseFormInfoTypeProps, NEW_REQ_FORM_KEY, requireRule } from '../helper'

import { SelectApproverFormItem } from './SelectApproverFormItem'

type IFormInfoType2Props = IBaseFormInfoTypeProps

const TxtField = (props: TxtProps & { value?: string }) => {
  const { value, ...restProps } = props
  return <Txt {...restProps}>{value}</Txt>
}

export type RangeValue = [Dayjs, Dayjs] | null
export const FormInfoType2 = (props: IFormInfoType2Props) => {
  const { editable = true } = props

  const subServiceId = useWatch(NEW_REQ_FORM_KEY.SUB_SERVICE_ID, {
    preserve: true,
  })

  const { data: serviceTypes } = useGetServiceTypesQRY()
  const serviceTypeOptions = useMemo(() => {
    return serviceTypes
      ?.filter((st) => !!st.active)
      .map((st) => ({
        label: st.name,
        value: st.id,
      }))
  }, [serviceTypes])

  const { data: mdSubService } = useGetMdSubServiceQRY()
  const subServiceOptions = useMemo(() => {
    return mdSubService
      ?.filter((md) => !!md.active)
      .map((md) => ({
        label: md.name,
        value: md.id,
      }))
  }, [mdSubService])

  const { data: projectTypes } = useGetProjectTypesQRY()
  const projectTypeOptions = useMemo(() => {
    return projectTypes
      ?.filter((pj) => !!pj.active)
      .map((pj) => ({
        label: pj.name,
        value: pj.id,
      }))
  }, [projectTypes])

  return (
    <>
      <Row gutter={[50, 0]}>
        <FormItem name={NEW_REQ_FORM_KEY.REQ_UNIT_CODE} hidden />
        <FormItem name={NEW_REQ_FORM_KEY.LIST_TB_SERVICE_REQ_TEAM} hidden />
        <FormItem name={NEW_REQ_FORM_KEY.STATUS_ID} hidden />

        <Col sm={24} md={12} lg={8}>
          <FormItem name={NEW_REQ_FORM_KEY.REQ_USER_NAME} labelCol={{ span: 24 }} label="ผู้ส่งคำขอ">
            <TxtField ag="med16" />
          </FormItem>
        </Col>
        <Col sm={24} md={12} lg={8}>
          <FormItem name={NEW_REQ_FORM_KEY.REQ_DATE} labelCol={{ span: 24 }} label="วันเวลาที่ส่งคำขอ">
            <TxtField ag="med16" />
          </FormItem>
        </Col>
        <Col sm={24} md={12} lg={8}>
          <FormItem name={NEW_REQ_FORM_KEY.REQ_UNIT_NAME} labelCol={{ span: 24 }} label="หน่วยงานที่ขอ">
            <TxtField ag="med16" />
          </FormItem>
        </Col>

        <Col sm={24} md={12} lg={8}>
          <FormItem name={NEW_REQ_FORM_KEY.SERVICE_TYPE_ID} labelCol={{ span: 24 }} label="ประเภท Service">
            <Select disabled options={serviceTypeOptions} placeholder="ประเภท Service" />
          </FormItem>
        </Col>
        {subServiceId && (
          <Col sm={24} md={12} lg={8}>
            <FormItem name={NEW_REQ_FORM_KEY.SUB_SERVICE_ID} labelCol={{ span: 24 }} label="ประเภท Sub Service">
              <Select disabled options={subServiceOptions} placeholder="ประเภท Sub Service" />
            </FormItem>
          </Col>
        )}
      </Row>

      <Row gutter={[50, 0]}>
        <Col sm={24} md={12} lg={8}>
          <FormItem name={NEW_REQ_FORM_KEY.PROJECT_TYPE_ID} labelCol={{ span: 24 }} label="ประเภทโครงการ">
            <Select disabled options={projectTypeOptions} placeholder="ประเภทโครงการ" />
          </FormItem>
        </Col>

        <Col sm={24} md={12} lg={8}>
          <FormItem
            name={NEW_REQ_FORM_KEY.PROJECT_NAME}
            labelCol={{ span: 24 }}
            label="ชื่อโครงการ"
            required
            rules={[requireRule('ชื่อโครงการ')]}
          >
            <Input disabled={!editable} maxLength={250} placeholder="ชื่อโครงการ" />
          </FormItem>
        </Col>
        <Col sm={24} md={12} lg={8}>
          <FormItem name={NEW_REQ_FORM_KEY.PROJECT_ABBR} labelCol={{ span: 24 }} label="ชื่อย่อโครงการ">
            <Input disabled={!editable} maxLength={50} placeholder="ชื่อย่อโครงการ" />
          </FormItem>
        </Col>
      </Row>

      <Row gutter={[50, 0]}>
        <Col md={24} lg={16}>
          <Row gutter={[50, 0]}>
            <Col sm={24} md={12}>
              <SelectApproverFormItem editable={editable} />
            </Col>
            <Col sm={24} md={12}>
              <FormItem
                name={NEW_REQ_FORM_KEY.TEL}
                labelCol={{ span: 24 }}
                label="เบอร์โทรติดต่อผู้กรอกข้อมูล"
                required
                rules={[requireRule('เบอร์โทรติดต่อผู้กรอกข้อมูล')]}
              >
                <Input disabled={!editable} placeholder="เบอร์โทรติดต่อผู้กรอกข้อมูล" />
              </FormItem>
            </Col>
          </Row>
        </Col>
      </Row>

      <Row gutter={[50, 0]}>
        <Col sm={24} lg={12}>
          <FormItem
            name={NEW_REQ_FORM_KEY.PRJ_LOCATION}
            labelCol={{ span: 24 }}
            label="ที่ตั้งโครงการ"
            required
            rules={[requireRule('ที่ตั้งโครงการ')]}
          >
            <Input.TextArea
              css={css`
                min-height: 240px !important;
                max-height: 240px !important;
              `}
              autoSize={{ minRows: 9, maxRows: 9 }}
              disabled={!editable}
              placeholder="ที่ตั้งโครงการ"
              maxLength={1500}
              showCount
            />
          </FormItem>
        </Col>
        <Col sm={24} lg={12}>
          <FormItem
            name={NEW_REQ_FORM_KEY.PRJ_DETAIL}
            labelCol={{ span: 24 }}
            label="รายละเอียดโครงการเบื้องต้น"
            required
            rules={[requireRule('รายละเอียดโครงการเบื้องต้น')]}
          >
            <Input.TextArea
              css={css`
                min-height: 240px !important;
                max-height: 240px !important;
              `}
              autoSize={{ minRows: 9, maxRows: 9 }}
              disabled={!editable}
              placeholder="รายละเอียดโครงการเบื้องต้น"
              maxLength={1500}
              showCount
            />
          </FormItem>
        </Col>
      </Row>
    </>
  )
}
