import qs from 'qs'

type ParamType = Record<string, unknown> | undefined
type Option<T extends ParamType, V extends ParamType> = {
  param?: T
  query?: V
}

type GenerateParam = {
  param?: ParamType
  query?: ParamType
}

export const generatePath =
  <TT extends GenerateParam>(url: string) =>
  (option?: Option<TT['param'], TT['query']>) => {
    const { param, query } = option || {}
    let newQuery = ''
    if (query) {
      newQuery = `?${qs.stringify(query)}`
    }

    let newUrl = url
    if (param) {
      const urls = url.split('/')
      newUrl = urls
        .map((u) => {
          if (/:.+/.test(u)) {
            return param[u.slice(1)]
          }
          return u
        })
        .join('/')
    }

    return `${newUrl}${newQuery}`
  }
