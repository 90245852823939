import { AppModal } from '@ptt-eia-web/components'
import { getColumnNumber, css } from '@ptt-eia-web/helpers'
import { IActivityLogResponse, useGetRequestStatusUpdateIdQRY } from '@ptt-eia-web/services'
import { Card } from 'antd'
import Table, { ColumnType } from 'antd/es/table'
import { useCallback, useMemo } from 'react'
import { useParams } from 'react-router-dom'

import { StatusBadge } from '@frontend/components/StatusBadge'
import { Txt } from '@frontend/components/Txt'

interface IActivityLogsModalProps {
  isOpen: boolean
  onClose: () => void
}

export const ActivityLogsModal = ({ isOpen, onClose }: IActivityLogsModalProps) => {
  const { id = 'N/A' } = useParams()
  const { data: activityLogs = [], isLoading } = useGetRequestStatusUpdateIdQRY(id)

  const columns = useMemo(
    (): ColumnType<IActivityLogResponse>[] => [
      {
        title: 'NO.',
        width: 50,
        render: (value, record, index) => <Txt ag="light16">{getColumnNumber(activityLogs, record)}</Txt>,
      },
      {
        title: 'STEP',
        align: 'center',

        width: 200,
        render: (_, record) => <StatusBadge status={record.statusDisplay} color={record.mdreqStatusColor} />,
      },
      { title: 'เหตุผล ', width: 200, dataIndex: 'reason' },
      { title: 'BY', width: 200, dataIndex: 'createByDisplay' },
      { title: 'MODIFY DATE', width: 200, dataIndex: 'createDateDisplay' },
    ],
    [activityLogs],
  )

  const handleClickCancel = useCallback(() => {
    onClose()
  }, [onClose])

  return (
    <AppModal
      width={1000}
      css={css`
        .ant-modal-body {
          padding: 32px 32px 0px 32px;
        }
      `}
      title="Activity Logs"
      open={isOpen}
      onCancel={handleClickCancel}
      cancelText="Close"
      footer={(_, { OkBtn, CancelBtn }) => <CancelBtn />}
    >
      <Card
        css={css`
          .ant-card-body {
            padding: 16px 16px 64px 16px;
          }
        `}
      >
        <Table loading={isLoading} rowKey="id" columns={columns} dataSource={activityLogs} pagination={false} />
      </Card>
    </AppModal>
  )
}
