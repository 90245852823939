import { IProjectDashboardDtOutDto } from '@ptt-eia-web/services'
import dayjs from 'dayjs'
import { sortBy } from 'lodash'

export const sortProjectDashboardHistory = (projectDashboardDtOutDtos?: IProjectDashboardDtOutDto[]) => {
  if (!projectDashboardDtOutDtos) {
    return []
  }
  return sortBy(projectDashboardDtOutDtos, (item) => dayjs(item.createDate, 'DD/MM/YYYY').unix())
}
