import { AnyObject } from 'antd/es/_util/type'
import { v4 as uuidV4 } from 'uuid'

export const uuid = () => uuidV4()

export const getAllSearchParams = (searchParams: URLSearchParams) => {
  const result: AnyObject = {}
  searchParams.forEach((v, key) => {
    result[key] = v
  })
  return result
}

export const sanitizeHtml = (html: string) => {
  // Remove script tags and content
  html = html.replace(/<script\b[^<]*(?:(?!<\/script>)<[^<]*)*<\/script>/gi, '')
  // Remove onclick attributes
  html = html.replace(/\son\w+="[^"]*"/g, '')
  // Encode potentially dangerous characters
  html = html.replace(/</g, '&lt;').replace(/>/g, '&gt;').replace(/"/g, '&quot;').replace(/'/g, '&#39;')
  // Further sanitizations can be added here based on your content's needs
  return html
}
