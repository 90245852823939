import { css } from '@ptt-eia-web/helpers'

interface IIconButtonCssProps {
  color: string
  hoverColor: string
}

export const IconButtonCss = ({ color, hoverColor }: IIconButtonCssProps) => css`
  cursor: pointer;
  color: ${color};
  transition: 0.3s;

  &:hover {
    color: ${hoverColor};
  }
`
