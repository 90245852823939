import { css, getColumnNumber } from '@ptt-eia-web/helpers'
import { IProjectListResponse, IServiceRequestRequestListResponse } from '@ptt-eia-web/services'
import { Card, Flex } from 'antd'
import Table, { ColumnType } from 'antd/es/table'
import { useCallback, useMemo } from 'react'
import { GoEye } from 'react-icons/go'
import { Link, useSearchParams } from 'react-router-dom'

import { StatusBadge } from '@frontend/components/StatusBadge'

import { colors } from '../../app/theme'
import { IconButtonCss } from '../../assets/css/IconButtonCss'
import { Txt } from '../../components/Txt'

interface IRequestListProps {
  dataSource: IServiceRequestRequestListResponse[]
  isLoading: boolean
}

export const RequestList = (props: IRequestListProps) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const currentPageParam = searchParams.get('currentPage') ?? '1'
  const { dataSource, isLoading } = props

  const handlePageChange = useCallback(
    (page: number, pageSize: number) => {
      const params = Object.fromEntries([...searchParams])
      setSearchParams({
        ...params,
        currentPage: page.toString(),
      })
    },
    [searchParams, setSearchParams],
  )

  const columns: ColumnType<IServiceRequestRequestListResponse>[] = useMemo(
    (): ColumnType<IProjectListResponse>[] => [
      {
        title: 'NO.',
        width: 70,
        ellipsis: true,
        align: 'center',
        render: (value, record, index) => <Txt ag="light16">{getColumnNumber(dataSource, record)}</Txt>,
      },
      { title: 'ชื่อโครงการ', width: 150, ellipsis: true, dataIndex: 'projectName' },
      {
        title: 'ประเภท Service',
        width: 200,
        align: 'center',
        ellipsis: true,
        dataIndex: 'mdserviceTypeDisplay',
      },
      { title: 'ประเภทโครงการ', align: 'center', ellipsis: true, width: 200, dataIndex: 'mdprojectTypeDisplay' },
      {
        title: 'STATUS',
        align: 'center',
        width: 200,
        render: (_, record) => <StatusBadge color={record.mdreqStatusColor} status={record.mdreqStatusDisplay} />,
      },
      { title: 'CREATE DATE', ellipsis: true, width: 100, dataIndex: 'createDateDisplay' },
      {
        title: 'VIEW',
        ellipsis: true,
        align: 'center',
        width: 70,
        render: (value: string, record: IProjectListResponse) => {
          return (
            <Link to={`/service-request/request-tracking/${encodeURIComponent(record.id.toString())}`}>
              <GoEye size={16} css={IconButtonCss({ color: colors.gray, hoverColor: colors.primary })} />
            </Link>
          )
        },
      },
    ],
    [dataSource],
  )

  return (
    <Card>
      <Flex
        gap={8}
        css={css`
          margin-bottom: 32px;
        `}
      >
        <Txt ag="med24" color="primary">
          Request
        </Txt>
        <Txt ag="med24" color="darkBlue">
          List
        </Txt>
      </Flex>
      <Table
        rowKey="id"
        columns={columns}
        dataSource={dataSource}
        loading={isLoading}
        pagination={{
          current: Number(currentPageParam),
          defaultCurrent: 1,
          defaultPageSize: 5,
          showSizeChanger: false,
          onChange: handlePageChange,
        }}
      />
    </Card>
  )
}
