import { IUpdateRequestStatusParams, usePostUpdateRequestStatusMTT } from '@ptt-eia-web/services'
import { useCallback } from 'react'

import { AddReasonBeforeSubmitModal } from '@frontend/modules/service-request-tracking/AddReasonBeforeSubmitModal'

export interface IRejectModalProps {
  requestId?: string
  isOpen: boolean
  onClose: () => void
  mdReqStatusId?: number
}

export const RejectModal = ({ isOpen, onClose, requestId = 'N/A', mdReqStatusId }: IRejectModalProps) => {
  const { mutateAsync: updateRequestStatus } = usePostUpdateRequestStatusMTT(requestId)

  const handleCloseModal = useCallback(() => {
    onClose()
  }, [onClose])

  const handleSubmit = useCallback(
    async (reason: string) => {
      if (mdReqStatusId) {
        const params: IUpdateRequestStatusParams = {
          mdreqStatusId: mdReqStatusId,
          tbserviceReqId: Number(requestId),
          reason,
        }
        await updateRequestStatus(params)
      } else {
        throw new Error('Cannot find next status')
      }
    },
    [mdReqStatusId, requestId, updateRequestStatus],
  )

  return (
    <AddReasonBeforeSubmitModal
      title="กรุณาระบุเหตุผลการ Reject"
      isOpen={isOpen}
      onClose={handleCloseModal}
      onSubmit={handleSubmit}
    />
  )
}
