import { DataType } from '../project-dashboard-table/FullTable'
const WITHOUT_LTA = 'Without LTA'
export const renderColSpanEIAActiveSwitch = (
  colSpan: number,
  isColumnStartSpan: boolean,
  activeSwitch: number | undefined,
) => {
  if (activeSwitch === 0 && isColumnStartSpan) {
    return colSpan
  } else if (activeSwitch === 1) {
    return 1
  }
  return 0
}

export const renderColSpanMonitoringActiveWithoutLTA = (record: DataType, colSpan: number) => {
  if (record.monitorActive === 0) {
    return 0
  }
  if (record.lta === WITHOUT_LTA) {
    return colSpan
  }
  return 1
}

export const renderColSpanMonitoringActive = (monitorActive: number | undefined) => {
  if (monitorActive === 0) {
    return 0
  }
  return 1
}
