import { MenuCodeEnum, PermissionActionEnum } from '@ptt-eia-web/constants'
import { css } from '@ptt-eia-web/helpers'
import { IMDDashboardStatusResponse, useGetMasterDashboardStatusQRY, usePermission } from '@ptt-eia-web/services'
import { Col, Form, Input, Row, Select, Tooltip } from 'antd'
import { useWatch } from 'antd/es/form/Form'
import React, { useEffect, useState } from 'react'

import { permissionConfigs } from '@frontend/app/permission-configs'
interface IProjectStatusChoice {
  value: number
  label: string
}
const updateStatusOptions = (
  dataList: IMDDashboardStatusResponse[],
  setOptionsFn: React.Dispatch<React.SetStateAction<IProjectStatusChoice[]>>,
) => {
  const newOptions = dataList.map((data) => ({
    value: data.id,
    label: data.status,
  }))
  setOptionsFn(newOptions)
}
export const UpdateHistoryForm = () => {
  const { can } = usePermission(permissionConfigs)
  const formProjectName = useWatch('projectName')
  const { data: dashboardStatus } = useGetMasterDashboardStatusQRY()
  const canEdit = can(MenuCodeEnum.ProjectStatusUpdate, PermissionActionEnum.Edit)
  const [dashboardStatusOptions, setDashboardStatusOptions] = useState<IProjectStatusChoice[]>([])
  useEffect(() => {
    if (dashboardStatus) updateStatusOptions(dashboardStatus, setDashboardStatusOptions)
  }, [dashboardStatus])
  return (
    <div>
      <Row
        css={css`
          padding: 12px 70px 20px 70px;
        `}
        gutter={50}
      >
        <Col xs={24} md={24} lg={8} xl={8}>
          <Tooltip title={formProjectName}>
            <Form.Item
              css={css`
                width: 100%;
                .ant-form-item-label label {
                  height: 20px;
                }
              `}
              name="projectName"
              label="ชื่อโครงการ"
            >
              <Input disabled={!canEdit} />
            </Form.Item>
          </Tooltip>
          <Form.Item
            css={css`
              width: 100%;
              .ant-form-item-label label {
                height: 20px;
              }
            `}
            name="formType"
            label="ชื่อแบบฟอร์ม"
          >
            <Input disabled />
          </Form.Item>
        </Col>
        <Col xs={24} md={24} lg={8} xl={8}>
          <Form.Item
            css={css`
              width: 100%;
              .ant-form-item-label label {
                height: 20px;
              }
            `}
            name="projectType"
            label="ประเภทโครงการ"
          >
            <Input disabled />
          </Form.Item>
          <Form.Item
            css={css`
              width: 100%;
              .ant-form-item-label label {
                height: 20px;
              }
            `}
            name="projectStatus"
            label="Project Status"
          >
            <Select disabled={!canEdit} placeholder="สถานะโครงการ" options={dashboardStatusOptions} />
          </Form.Item>
        </Col>
        <Col xs={24} md={24} lg={8} xl={8}>
          <Form.Item
            css={css`
              width: 100%;
              .ant-form-item-label label {
                height: 20px;
              }
            `}
            name="reportType"
            label="ประเภทรายงาน"
          >
            <Input disabled />
          </Form.Item>
        </Col>
      </Row>
    </div>
  )
}
