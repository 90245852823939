import { MenuCodeEnum, PermissionActionEnum } from '@ptt-eia-web/constants'
import { css } from '@ptt-eia-web/helpers'
import { INotificationResponse, useGetProjectDashboardWithIdQRY, usePermission } from '@ptt-eia-web/services'
import { notification } from 'antd'
import { useForm } from 'antd/es/form/Form'
import React, { useCallback, useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

import { permissionConfigs } from '@frontend/app/permission-configs'
import { paths } from '@frontend/app/route/path-config'

import { ISearchUserFormValue, SearchUserModal } from '../../../../components/SearchUserModal'

import { BackButton } from './BackButton'
import { DataType } from './EmployeeTable'
import { Header } from './Header'
import { ModalEmail } from './ModalNotificationEmail'
import { UpdateHistoryCard } from './UpdateHistoryCard'

export const UpdateHistoryDashboard = () => {
  const [isOpenEmailNotificationModal, setIsOpenEmailNotificationModal] = useState(false)
  const [isOpenAddEmailNotificationModal, setIsOpenAddEmailNotificationModal] = useState(false)
  const [searchForm] = useForm()
  const { can } = usePermission(permissionConfigs)
  const canEdit = can(MenuCodeEnum.ProjectStatusUpdate, PermissionActionEnum.Edit)
  const [tableData, setTableData] = useState<DataType[]>([])
  const [searchParams] = useSearchParams()

  const { data: resultProject } = useGetProjectDashboardWithIdQRY({ id: Number(searchParams.get('id')) })

  const generateData = (dtItem: INotificationResponse, index: number) => {
    return {
      no: index,
      employeeCode: dtItem.empCode,
      fullName: dtItem.fullName,
      email: dtItem.email,
    }
  }
  useEffect(() => {
    searchForm.setFieldValue('search', undefined)
    const newList: DataType[] = []
    resultProject?.projectDashboardNotiOutDtos?.forEach((item, index) => {
      newList.push(generateData(item, index + 1))
    })
    setTableData(newList)
  }, [searchForm, resultProject])
  const handleCloseNotificationModal = useCallback(() => {
    const newList: DataType[] = []
    resultProject?.projectDashboardNotiOutDtos?.forEach((item, index) => {
      newList.push(generateData(item, index + 1))
    })
    setTableData(newList)
    setIsOpenEmailNotificationModal(false)
  }, [resultProject?.projectDashboardNotiOutDtos])

  const handleClickReportEmailNotification = useCallback(() => {
    setIsOpenEmailNotificationModal(true)
  }, [])
  const handleCloseAddEmailNotificationModal = useCallback(() => {
    setIsOpenAddEmailNotificationModal(false)
  }, [])

  const handleClickAddEmailNotification = useCallback(() => {
    setIsOpenAddEmailNotificationModal(true)
  }, [])

  const isNotAddEmailNotiAccount = (requests: ISearchUserFormValue) => {
    if (
      requests.departmentNameTH !== undefined &&
      requests.positionNameTH !== undefined &&
      requests.emailAddress !== undefined &&
      requests.employeeNameTH !== undefined &&
      requests.employeeId !== undefined &&
      requests.iname !== undefined
    ) {
      return false
    }
    return true
  }

  const handleModalAddEmailNotification = useCallback(
    (val: ISearchUserFormValue) => {
      const isDuplicate = tableData.some((item) => item.employeeCode === val.employeeId)
      let isNotAddAccount = true
      isNotAddAccount = isNotAddEmailNotiAccount(val)
      if (isNotAddAccount) {
        setIsOpenAddEmailNotificationModal(false)
      } else if (!isDuplicate) {
        const newDataType = {
          no: tableData.length + 1,
          employeeCode: val.employeeId,
          fullName: val.employeeNameTH,
          email: val.emailAddress,
        }
        setTableData([...tableData, newDataType])
      } else {
        notification.error({ message: 'มีรายชื่อผู้รับอีเมลนี้อยู่แล้ว' })
      }
      setIsOpenAddEmailNotificationModal(false)
    },
    [tableData],
  )
  const handlerClickDelete = useCallback(
    (ids: string) => {
      const deleteEmployee = tableData?.filter((item) => item.employeeCode !== ids)
      setTableData(deleteEmployee)
    },
    [tableData],
  )

  return (
    <>
      <Header onClickReportEmail={handleClickReportEmailNotification} />
      <UpdateHistoryCard />
      {isOpenEmailNotificationModal && (
        <ModalEmail
          tableData={tableData}
          isModalOpen={isOpenEmailNotificationModal}
          onClose={handleCloseNotificationModal}
          onDelete={handlerClickDelete}
          onClickAddEmail={handleClickAddEmailNotification}
          canEdit={canEdit}
        />
      )}
      <SearchUserModal
        zIndex={1004}
        open={isOpenAddEmailNotificationModal}
        onCancel={handleCloseAddEmailNotificationModal}
        onOk={handleModalAddEmailNotification}
        title="เพิ่มผู้รับอีเมลแจ้งเตือน"
        width={800}
      />
      <div
        css={css`
          margin: 44px;
        `}
      >
        <BackButton paths={paths.projectStatusUpdate()} />
      </div>
    </>
  )
}
