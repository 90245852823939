import { useMsal } from '@azure/msal-react'
import { css } from '@ptt-eia-web/helpers'
import { azureLogout } from '@ptt-eia-web/services'
import { Button } from 'antd'
import { useCallback, useState } from 'react'

export const PageAccountNotFound = () => {
  const { instance } = useMsal()
  const [isLoading, setIsLoading] = useState(false)

  const handleLogout = useCallback(() => {
    setIsLoading(true)
    azureLogout(instance)
  }, [instance])

  return (
    <div
      css={css`
        height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      `}
    >
      <h1>ไม่พบผู้ใช้ในระบบ</h1>
      <div>
        กรุณา{' '}
        <Button type="primary" onClick={handleLogout} loading={isLoading}>
          Logout
        </Button>{' '}
        เพื่อเข้าสู่ระบบด้วยบัญชีอื่น
      </div>
    </div>
  )
}
