import { Select, SelectProps } from 'antd'
import { DefaultOptionType } from 'antd/es/select'
import { debounce } from 'lodash'
import { useCallback, useEffect, useMemo, useState } from 'react'

interface IAsyncSelectProps extends Omit<SelectProps, 'onChange'> {
  fetchFn: (searchValue: string) => DefaultOptionType[] | Promise<DefaultOptionType[]>
  onChange?: (value?: string) => void
}

export const AsyncSelect = (props: IAsyncSelectProps) => {
  const { fetchFn, onChange, value: valueProps, ...restProps } = props
  const [options, setOptions] = useState<DefaultOptionType[]>([])
  const [loading, setLoading] = useState(false)

  const fetchDebounce = useMemo(() => {
    return debounce(async (value: string) => {
      if (!value) {
        setOptions([])
        return
      }
      try {
        setLoading(true)
        const data = await fetchFn(value)
        setOptions(data ?? [])
      } catch (error) {
        setOptions([])
      } finally {
        setLoading(true)
      }
    }, 200)
  }, [fetchFn])

  const handleSelect = useCallback(
    (val: string) => {
      onChange?.(val)
    },
    [onChange],
  )

  const handleClear = useCallback(() => {
    onChange?.(undefined)
  }, [onChange])

  useEffect(() => {
    if (!valueProps) {
      setOptions([])
    }
  }, [valueProps])

  return (
    <Select
      {...restProps}
      value={valueProps}
      showSearch
      allowClear
      filterOption={false}
      onClear={handleClear}
      onSelect={handleSelect}
      onSearch={fetchDebounce}
      options={options}
      loading={loading}
    />
  )
}
