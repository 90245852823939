import { AnyObject } from 'antd/es/_util/type'
import { AnchorHTMLAttributes, useEffect } from 'react'

interface ILinkProps extends AnchorHTMLAttributes<HTMLAnchorElement> {
  id: string
  url: string
  target: string
  children: React.ReactNode
}
export const SafeLink = ({ id, url, target, children, ...restProps }: ILinkProps) => {
  useEffect(() => {
    const linkElement = document.getElementById(id) as AnyObject
    if (linkElement) {
      linkElement.href = encodeURI(url)
    }
  }, [id, url])
  return (
    <a id={id} target={target} rel="noopener noreferrer" {...restProps}>
      {children}
    </a>
  )
}
