import { MenuCodeEnum } from '@ptt-eia-web/constants'
import { css, keyframes } from '@ptt-eia-web/helpers'
import { useGetRoleMenuQRY } from '@ptt-eia-web/services'
import React, { useCallback } from 'react'
import { Link } from 'react-router-dom'

import AboutUsImg from '../../assets/svgs/about-us-icon.svg'
import AnnualReportImg from '../../assets/svgs/annual-report-icon.svg'
import PrNewImg from '../../assets/svgs/pr-news-icon.svg'
import ProjectDashboardImg from '../../assets/svgs/project-dashboard-icon.svg'
import ServiceRequestImg from '../../assets/svgs/service-request-icon.svg'
interface FeatureMenuProps {
  color: string
  left: string
  top: string
  icon: string
  text?: string
  href: string
  animationDelay?: number
}

const fadeIn = keyframes`
  0% {
    opacity: 0;
    transform: scale(0);
  }
  100% {
    opacity: 0.87;
    transform: scale(1);
  }
`

const FeatureMenu: React.FC<FeatureMenuProps> = ({ color, left, top, icon, text, href, animationDelay }) => {
  if (!text) {
    return null
  }
  return (
    <div
      css={css`
        animation: ${fadeIn} 0.5s linear 1 forwards;
        animation-delay: ${animationDelay}s;
        opacity: 0;
      `}
    >
      <Link
        to={href}
        css={css`
          transition: all 0.3s !important;
          position: absolute;
          left: ${left}px;
          top: ${top}px;
          width: 140px;
          height: 145px;
          color: white;
          background-color: ${color};
          cursor: pointer;
          &:hover {
            transform: translateY(-5px) !important;
            color: white;
            box-shadow: 0px 7px 20px rgb(230 252 255 / 75%);
          }
          border-top-left-radius: 60px;
          border-bottom-right-radius: 60px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          text-align: center;
          font-weight: 500;
          padding: 54px 8px 54px 8px;
        `}
      >
        <img
          css={css`
            opacity: 1;
            background-size: cover;
          `}
          src={icon}
          alt=""
        />
        <p
          css={css`
            margin-top: 16px;
            font-size: 18px;
          `}
        >
          {text}
        </p>
      </Link>
    </div>
  )
}

export const Menu = () => {
  const { data: roleMenuList = [] } = useGetRoleMenuQRY()
  const getMenuLabel = useCallback(
    (menuCode: MenuCodeEnum) => {
      return roleMenuList.find((r) => r.menuCode === menuCode)?.menuNameTh
    },
    [roleMenuList],
  )

  return (
    <div
      css={css`
        height: 450px;
        width: 450px;
        margin-top: 32px;
      `}
    >
      <div
        css={css`
          position: relative;
          width: 100%;
          height: 100%;
        `}
      >
        <FeatureMenu
          animationDelay={1.5}
          color="#4CCEB9"
          left="0"
          top="120"
          icon={AboutUsImg}
          text={getMenuLabel(MenuCodeEnum.AboutUs)}
          href="/about-us"
        />
        <FeatureMenu
          animationDelay={0.2}
          color="#4AACA1"
          left="160"
          top="0"
          icon={ProjectDashboardImg}
          text={getMenuLabel(MenuCodeEnum.ProjectDashboard)}
          href="/project-dashboard"
        />
        <FeatureMenu
          animationDelay={0.6}
          color="#00B814"
          left="305"
          top="105"
          icon={AnnualReportImg}
          text={getMenuLabel(MenuCodeEnum.AnnualReport)}
          href="/annual-reports"
        />
        <FeatureMenu
          animationDelay={1.2}
          color="#8FD073"
          left="100"
          top="250"
          icon={ServiceRequestImg}
          text={getMenuLabel(MenuCodeEnum.ServiceRequest)}
          href="/service-request"
        />
        <FeatureMenu
          animationDelay={0.9}
          color="#DAD68A"
          left="264"
          top="270"
          icon={PrNewImg}
          text={getMenuLabel(MenuCodeEnum.PrNews)}
          href="/pr-news"
        />
      </div>
    </div>
  )
}
