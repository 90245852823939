export const transmissionReportData = [
  {
    id: 1,
    description: 'EIA Screening & Scoping',
  },
  {
    id: 2,
    description: 'Environmental Baseline Study',
  },
  {
    id: 3,
    description: 'Impact Assessment',
  },
  {
    id: 4,
    description: 'Draft EIA Report and Mitigation & Monitoring Measures',
  },
  {
    id: 5,
    description: 'Final EIA Report Submission',
  },
  {
    id: 6,
    description: 'Expert Panel Consderation',
  },
  {
    id: 7,
    description: 'EIA Approval',
  },
]

export const plantReportData = [
  {
    id: 1,
    description: 'EIA Screening & Scoping',
  },
  {
    id: 2,
    description: 'Environmental Baseline Study',
  },
  {
    id: 3,
    description: 'Impact Assessment',
  },
  {
    id: 4,
    description: 'Draft EIA Report and Mitigation & Monitoring Measures',
  },
  {
    id: 5,
    description: 'Final EIA Report Submission',
  },
  {
    id: 6,
    description: 'Expert Panel Consderation',
  },
  {
    id: 7,
    description: 'EIA Approval',
  },
]

export const tsoReportData = [
  {
    id: 1,
    description: 'Screening & Scoping',
  },
  {
    id: 2,
    description: 'Environmental Baseline Study',
  },
  {
    id: 3,
    description: 'Impact Assessment',
  },
  {
    id: 4,
    description: 'Draft Report and Mitigation & Monitoring Measures',
  },
  {
    id: 5,
    description: 'Final Report Submission',
  },
  {
    id: 6,
    description: 'Expert Panel Consideration',
  },
  {
    id: 7,
    description: 'Approval',
  },
]

export const distributionReportData = [
  {
    id: 1,
    description: 'TOR & Procurement Process',
  },
  {
    id: 2,
    description: 'Environmental Baseline Study (EIA)/Environmental Study (ER)',
  },
  {
    id: 3,
    description: 'Impact Assessment (EIA)/Impact Review (ER)',
  },
  {
    id: 4,
    description: 'Draft Report and Mitigation & Monitoring Measures',
  },
  {
    id: 5,
    description: 'Final Report Submission to ONEP/DOEB',
  },
  {
    id: 6,
    description: 'ONEP/DOEB Expert Panel Review & Consideration',
  },
  {
    id: 7,
    description: 'EIA/ER Approval',
  },
]

export const businessReportData = [
  {
    id: 1,
    description: 'Screening & Scoping',
  },
  {
    id: 2,
    description: 'Environmental Baseline Study',
  },
  {
    id: 3,
    description: 'Impact Assessment',
  },
  {
    id: 4,
    description: 'Draft Report and Mitigation & Monitoring Measures',
  },
  {
    id: 5,
    description: 'Final Report Submission',
  },
  {
    id: 6,
    description: 'Expert Panel Consideration',
  },
  {
    id: 7,
    description: 'Approval',
  },
]

export const otherReportData = [
  {
    id: 1,
    description: 'Screening & Scoping',
  },
  {
    id: 2,
    description: 'Environmental Baseline Study',
  },
  {
    id: 3,
    description: 'Impact Assessment',
  },
  {
    id: 4,
    description: 'Draft Report and Mitigation & Monitoring Measures',
  },
  {
    id: 5,
    description: 'Final Report Submission',
  },
  {
    id: 6,
    description: 'Expert Panel Consideration',
  },
  {
    id: 7,
    description: 'Approval',
  },
]
