import styled from '@emotion/styled'
import { css } from '@ptt-eia-web/helpers'
import { ISettingResponse, useGetProjectTypesQRY, useGetServiceTypesQRY } from '@ptt-eia-web/services'
import { Typography, Flex, DatePicker, Button } from 'antd'
import Select, { LabeledValue } from 'antd/es/select'
import dayjs, { Dayjs } from 'dayjs'
import { useCallback, useMemo, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

import { colors } from '../../app/theme'

const CURRENT_YEAR = dayjs().year()

interface IOption {
  value: number
  label: string
}

const FilterLayoutStyle = styled.div`
  height: 150px;
  border-radius: 10px;
  background-color: white;
  padding: 28px 30px;
  font-weight: 500;
  border-width: 0.5px;
  border-style: solid;
  border-color: ${colors.line};
  box-shadow: 0.5px 0.5px 0.5px ${colors.line};
`
const mapOptions = (dataList: ISettingResponse[]): IOption[] => {
  return dataList
    .filter((item) => item.active === 1)
    .map((data) => ({
      value: data.id,
      label: data.name,
    }))
}

export const FilterNav = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const yearParam = searchParams.get('year')
  const projectTypeId = searchParams.get('projectTypeId')
  const serviceTypeId = searchParams.get('serviceTypeId')

  const [chooseServiceType, setChooseServiceType] = useState<string>(serviceTypeId !== null ? serviceTypeId : '')
  const [chooseProjectType, setChooseProjectType] = useState<string>(projectTypeId !== null ? projectTypeId : '')
  const [chooseYear, setChooseYear] = useState<string>(yearParam !== null ? yearParam : CURRENT_YEAR.toString())

  const { data: projectTypeList = [] } = useGetProjectTypesQRY()
  const { data: serviceTypeList = [] } = useGetServiceTypesQRY()

  const projectTypeOptions = useMemo(() => {
    return mapOptions(projectTypeList)
  }, [projectTypeList])

  const serviceTypeOptions = useMemo(() => {
    return mapOptions(serviceTypeList)
  }, [serviceTypeList])

  const handleChangeServiceType = useCallback((label: LabeledValue) => {
    setChooseServiceType(label?.toString())
  }, [])

  const handleChangeYearPicker = useCallback((year: Dayjs | null) => {
    setChooseYear(year?.format('YYYY') ?? CURRENT_YEAR.toString())
  }, [])

  const handleChangeProjectType = useCallback((label: LabeledValue) => {
    setChooseProjectType(label?.toString())
  }, [])

  const handleSummitSearch = useCallback(() => {
    const params = Object.fromEntries([...searchParams])
    setSearchParams({
      ...params,
      serviceTypeId: chooseServiceType ?? '',
      projectTypeId: chooseProjectType ?? '',
      year: chooseYear ?? '',
      currentPage: '1',
    })
  }, [chooseProjectType, chooseServiceType, chooseYear, searchParams, setSearchParams])

  const handleClearFilter = useCallback(() => {
    const params = new URLSearchParams(searchParams.toString())
    params.delete('serviceTypeId')
    params.delete('projectTypeId')
    params.delete('year')
    params.delete('currentPage')
    setSearchParams(params)
    setChooseServiceType('0')
    setChooseProjectType('0')
    setChooseYear(CURRENT_YEAR.toString())
  }, [searchParams, setSearchParams])

  return (
    <FilterLayoutStyle>
      <Typography css={css({ fontWeight: 'bold', marginBottom: 6 })}>FILTER</Typography>
      <Flex gap={16} wrap="wrap">
        <div
          css={css`
            flex: 1;
          `}
        >
          <Select
            placeholder="ประเภท Service"
            value={serviceTypeOptions.find((item) => item.value === Number(chooseServiceType))}
            css={css`
              width: 100%;
            `}
            onSelect={handleChangeServiceType}
            options={serviceTypeOptions}
          />
        </div>
        <div
          css={css`
            flex: 1;
          `}
        >
          <Select
            placeholder="ประเภทโครงการ"
            value={projectTypeOptions.find((item) => item.value === Number(chooseProjectType))}
            css={css`
              width: 100%;
            `}
            onSelect={handleChangeProjectType}
            options={projectTypeOptions}
          />
        </div>
        <div
          css={css`
            flex: 1;
          `}
        >
          <DatePicker
            css={css`
              width: 100%;
            `}
            onChange={(value) => handleChangeYearPicker(value)}
            picker="year"
            placeholder="Year"
            value={chooseYear ? dayjs(chooseYear) : null}
            format="YYYY"
          />
        </div>
        <Flex
          gap={16}
          css={css`
            flex: 1;
          `}
        >
          <div
            css={css`
              flex: 0.5;
            `}
          >
            <Button
              onClick={handleSummitSearch}
              css={css`
                width: 100%;
                text-align: center;
                background-color: #006b93;
                color: ${colors.white};
              `}
              type="primary"
            >
              Search
            </Button>
          </div>
          <div
            css={css`
              flex: 0.5;
            `}
          >
            <Button
              onClick={handleClearFilter}
              css={css`
                width: 100%;
                background-color: #ccecf8;
                color: ${colors.primary};
              `}
              type="primary"
            >
              Clear
            </Button>
          </div>
        </Flex>
      </Flex>
    </FilterLayoutStyle>
  )
}
