import {
  QueryKey,
  UndefinedInitialDataOptions,
  UseMutationOptions,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query'
import { message } from 'antd'

import { pttClient } from '../ptt-client'
import { IResponseWithData } from '../services-types'

import {
  GetProjectTypeParams,
  IDashboardSetting,
  IGetReportTypeParams,
  IGetServiceTypeParams,
  IProjectTypeParams,
  IReportTypeParams,
  IServiceTypeParams,
} from './setting.params'
import {
  IDashboardSettingResponse,
  IProjectTypeDetailResponse,
  IReportTypeDetailResponse,
  ISettingResponse,
} from './setting.response'

const PROJECT_TYPE_URL = '/Setting/ProjectType'
const PROJECT_TYPE_ID_URL = '/Setting/ProjectType/Id'
export const useGetDashboardSettingQRY = (
  options?: UndefinedInitialDataOptions<IDashboardSettingResponse, Error, IDashboardSettingResponse, QueryKey>,
) => {
  return useQuery<IDashboardSettingResponse>({
    queryKey: ['dashboardSetting'],
    queryFn: async (): Promise<IDashboardSettingResponse> => {
      const res = await pttClient.get<IResponseWithData<IDashboardSettingResponse[]>>('/Setting/DashboardSetting')
      return res.data && res.data.data[0]
    },
    ...options,
  })
}

export const usePostDashboardSettingMTT = (
  options?: UseMutationOptions<IDashboardSettingResponse, Error, IDashboardSetting, unknown>,
) => {
  const queryClient = useQueryClient()
  return useMutation<IDashboardSettingResponse, Error, IDashboardSetting>({
    mutationFn: async (body: IDashboardSetting) => {
      const res = await pttClient.post<IResponseWithData<IDashboardSettingResponse>>('/Setting/DashboardSetting', {
        ...body,
      })

      return res.data.data
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['dashboardSetting'] })
      message.success('บันทึกการตั้งค่า Dashboard สำเร็จ')
    },
    ...options,
  })
}

export const useGetProjectTypesQRY = (
  params?: GetProjectTypeParams,
  options?: UndefinedInitialDataOptions<ISettingResponse[], Error, ISettingResponse[], QueryKey>,
) => {
  return useQuery<ISettingResponse[]>({
    queryKey: [PROJECT_TYPE_URL, params],
    queryFn: async (): Promise<ISettingResponse[]> => {
      const res = await pttClient.get<IResponseWithData<ISettingResponse[]>>(PROJECT_TYPE_URL, {
        params,
      })
      return res.data.data
    },
    ...options,
  })
}

export const usePostProjectTypeMTT = (
  options?: UseMutationOptions<ISettingResponse, Error, IProjectTypeParams, unknown>,
) => {
  const queryClient = useQueryClient()
  return useMutation<ISettingResponse, Error, IProjectTypeParams>({
    mutationFn: async (body: IProjectTypeParams) => {
      const res = await pttClient.post<IResponseWithData<ISettingResponse>>(PROJECT_TYPE_URL, {
        ...body,
      })

      return res.data.data
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [PROJECT_TYPE_URL] })
      message.success('เพิ่มข้อมูลประเภทโครงการสำเร็จ')
    },
    ...options,
  })
}

export const useGetProjectTypeDetailQRY = (
  id?: number,
  options?: UndefinedInitialDataOptions<IProjectTypeDetailResponse, Error, IProjectTypeDetailResponse, QueryKey>,
) => {
  return useQuery<IProjectTypeDetailResponse>({
    queryKey: [PROJECT_TYPE_URL, { id }],
    queryFn: async (): Promise<IProjectTypeDetailResponse> => {
      const res = await pttClient.get<IResponseWithData<IProjectTypeDetailResponse>>(PROJECT_TYPE_ID_URL, {
        params: { id },
      })
      return res.data.data
    },
    ...options,
  })
}

export const usePutProjectTypeMTT = (
  id?: number,
  options?: UseMutationOptions<ISettingResponse, Error, IProjectTypeParams, unknown>,
) => {
  const queryClient = useQueryClient()
  return useMutation<ISettingResponse, Error, IProjectTypeParams>({
    mutationFn: async (body: IProjectTypeParams) => {
      const res = await pttClient.put<IResponseWithData<ISettingResponse>>(PROJECT_TYPE_URL, {
        ...body,
      })

      return res.data.data
    },
    onSuccess: (values) => {
      queryClient.invalidateQueries({ queryKey: [PROJECT_TYPE_URL] })
      queryClient.invalidateQueries({ queryKey: [PROJECT_TYPE_URL] })
      message.success('แก้ไขข้อมูลโครงการสำเร็จ')
    },
    ...options,
  })
}

export const useDeleteProjectTypeMTT = (options?: UseMutationOptions<ISettingResponse, Error, number, unknown>) => {
  const queryClient = useQueryClient()
  return useMutation<ISettingResponse, Error, number>({
    mutationFn: async (id: number) => {
      const res = await pttClient.delete<IResponseWithData<ISettingResponse>>(PROJECT_TYPE_URL, { data: { id } })

      return res.data.data
    },
    onSuccess: (values) => {
      queryClient.invalidateQueries({ queryKey: [PROJECT_TYPE_URL] })
      message.success('ลบข้อมูลโครงการสำเร็จ')
    },
    ...options,
  })
}

const REPORT_TYPE_URL = '/Setting/ReportType'
const REPORT_TYPE_ID_URL = '/Setting/ReportType/Id'

export const useGetReportTypesQRY = (
  params?: IGetReportTypeParams,
  options?: UndefinedInitialDataOptions<IReportTypeDetailResponse[], Error, IReportTypeDetailResponse[], QueryKey>,
) => {
  return useQuery<IReportTypeDetailResponse[]>({
    queryKey: [REPORT_TYPE_URL, params],
    queryFn: async (): Promise<IReportTypeDetailResponse[]> => {
      const res = await pttClient.get<IResponseWithData<IReportTypeDetailResponse[]>>(REPORT_TYPE_URL, {
        params,
      })
      return res.data.data
    },
    ...options,
  })
}

export const usePostReportTypeMTT = (
  options?: UseMutationOptions<IReportTypeDetailResponse, Error, IReportTypeParams, unknown>,
) => {
  const queryClient = useQueryClient()
  return useMutation<IReportTypeDetailResponse, Error, IReportTypeParams>({
    mutationFn: async (body: IReportTypeParams) => {
      const res = await pttClient.post<IResponseWithData<IReportTypeDetailResponse>>(REPORT_TYPE_URL, {
        ...body,
      })

      return res.data.data
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [REPORT_TYPE_URL] })
      message.success('เพิ่มข้อมูลประเภทรายงานสำเร็จ')
    },
    ...options,
  })
}

export const useGetReportTypeDetailQRY = (
  id?: number,
  options?: UndefinedInitialDataOptions<IReportTypeDetailResponse, Error, IReportTypeDetailResponse, QueryKey>,
) => {
  return useQuery<IReportTypeDetailResponse>({
    queryKey: [REPORT_TYPE_ID_URL, { id }],
    queryFn: async (): Promise<IReportTypeDetailResponse> => {
      const res = await pttClient.get<IResponseWithData<IReportTypeDetailResponse>>(REPORT_TYPE_ID_URL, {
        params: { id },
      })
      return res.data.data
    },
    ...options,
  })
}

export const usePutReportTypeMTT = (
  options?: UseMutationOptions<IReportTypeDetailResponse, Error, IReportTypeParams, unknown>,
) => {
  const queryClient = useQueryClient()
  return useMutation<IReportTypeDetailResponse, Error, IReportTypeParams>({
    mutationFn: async (body: IReportTypeParams) => {
      const res = await pttClient.put<IResponseWithData<IReportTypeDetailResponse>>(REPORT_TYPE_URL, {
        ...body,
      })

      return res.data.data
    },
    onSuccess: async (values) => {
      await queryClient.invalidateQueries({ queryKey: [REPORT_TYPE_ID_URL] })
      await queryClient.invalidateQueries({ queryKey: [REPORT_TYPE_URL] })

      message.success('แก้ไขข้อมูลประเภทรายงานสำเร็จ')
    },
    ...options,
  })
}

export const useDeleteReportTypeMTT = (
  options?: UseMutationOptions<IReportTypeDetailResponse, Error, number, unknown>,
) => {
  const queryClient = useQueryClient()
  return useMutation<IReportTypeDetailResponse, Error, number>({
    mutationFn: async (id: number) => {
      const res = await pttClient.delete<IResponseWithData<IReportTypeDetailResponse>>(REPORT_TYPE_URL, {
        data: { id },
      })

      return res.data.data
    },
    onSuccess: (values) => {
      queryClient.invalidateQueries({ queryKey: [REPORT_TYPE_URL] })
      message.success('ลบข้อมูลประเภทรายงานสำเร็จ')
    },
    ...options,
  })
}

const SERVICE_TYPE_URL = '/Setting/ServiceType'
const SERVICE_TYPE_ID_URL = `${SERVICE_TYPE_URL}/Id`

export const useGetServiceTypesQRY = (
  params?: IGetServiceTypeParams,
  options?: UndefinedInitialDataOptions<ISettingResponse[], Error, ISettingResponse[], QueryKey>,
) => {
  return useQuery<ISettingResponse[]>({
    queryKey: [SERVICE_TYPE_URL, params],
    queryFn: async (): Promise<ISettingResponse[]> => {
      const res = await pttClient.get<IResponseWithData<ISettingResponse[]>>(SERVICE_TYPE_URL, { params })
      return res.data.data
    },
    ...options,
  })
}

export const usePostServiceTypeMTT = (
  options?: UseMutationOptions<ISettingResponse, Error, IServiceTypeParams, unknown>,
) => {
  const queryClient = useQueryClient()
  return useMutation<ISettingResponse, Error, IServiceTypeParams>({
    mutationFn: async (body: IServiceTypeParams) => {
      const res = await pttClient.post<IResponseWithData<ISettingResponse>>(SERVICE_TYPE_URL, {
        ...body,
      })

      return res.data.data
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [SERVICE_TYPE_URL] })
      message.success('เพิ่มข้อมูลประเภทบริการสำเร็จ')
    },
    ...options,
  })
}

export const useGetServiceTypeQRY = (
  id?: number,
  options?: UndefinedInitialDataOptions<ISettingResponse, Error, ISettingResponse, QueryKey>,
) => {
  return useQuery<ISettingResponse>({
    queryKey: [SERVICE_TYPE_URL, { id }],
    queryFn: async (): Promise<ISettingResponse> => {
      const res = await pttClient.get<IResponseWithData<ISettingResponse>>(SERVICE_TYPE_ID_URL, {
        params: { id },
      })
      return res.data.data
    },
    ...options,
  })
}

export const usePutServiceTypeMTT = (
  id?: number,
  options?: UseMutationOptions<ISettingResponse, Error, IServiceTypeParams, unknown>,
) => {
  const queryClient = useQueryClient()
  return useMutation<ISettingResponse, Error, IServiceTypeParams>({
    mutationFn: async (body: IServiceTypeParams) => {
      const res = await pttClient.put<IResponseWithData<ISettingResponse>>(SERVICE_TYPE_URL, {
        ...body,
      })

      return res.data.data
    },
    onSuccess: (values) => {
      queryClient.invalidateQueries({ queryKey: [SERVICE_TYPE_URL] })
      queryClient.invalidateQueries({ queryKey: [SERVICE_TYPE_ID_URL] })
      message.success('แก้ไขข้อมูลประเภทบริการสำเร็จ')
    },
    ...options,
  })
}

export const useDeleteServiceTypeMTT = (options?: UseMutationOptions<ISettingResponse, Error, number, unknown>) => {
  const queryClient = useQueryClient()
  return useMutation<ISettingResponse, Error, number>({
    mutationFn: async (id: number) => {
      const res = await pttClient.delete<IResponseWithData<ISettingResponse>>(SERVICE_TYPE_URL, { data: { id } })

      return res.data.data
    },
    onSuccess: (values) => {
      queryClient.invalidateQueries({ queryKey: [SERVICE_TYPE_URL] })
      message.success('ลบข้อมูลประเภทบริการสำเร็จ')
    },
    ...options,
  })
}
