import { css, downloadFile, getFullUrl } from '@ptt-eia-web/helpers'
import { IAboutUsPEMDProfileResponse } from '@ptt-eia-web/services'
import { useState } from 'react'
import { BsDownload } from 'react-icons/bs'
import { GoFile } from 'react-icons/go'

import { colors } from '@frontend/app/theme'
import bgPemdProfileImage from '@frontend/assets/about-us/bg-pemd-profile.png'
import { HeaderTitle } from '@frontend/components/HeaderTitle'
import { Txt } from '@frontend/components/Txt'

interface IPEMDProfileItemProps {
  profile: IAboutUsPEMDProfileResponse
}
const PEMDProfileItem = ({ profile }: IPEMDProfileItemProps) => {
  const { description, pdfFile, pdfPath, modifyDate } = profile
  const [isDownloading, setIsDownloading] = useState(false)
  const pdfUrl = getFullUrl(pdfPath)
  const handleDownload = async () => {
    setIsDownloading(true)
    await downloadFile(pdfUrl, pdfFile)
    setIsDownloading(false)
  }

  return (
    <div
      css={css`
        display: flex;
        justify-content: start;
      `}
    >
      <GoFile size={40} color={colors.primary} />
      <div
        css={css`
          margin-left: 8px;
          max-width: 200px;
          text-align: left;
        `}
      >
        <div>
          <Txt ag="med14" color="labelText" ellipsis>
            {description}
          </Txt>
        </div>
        <div>
          <Txt ag="med10" color="labelText" ellipsis>
            {modifyDate}
          </Txt>
        </div>
      </div>
      <div
        css={css`
          display: flex;
          align-items: center;
          margin-left: 8px;
          svg {
            cursor: pointer;
          }
        `}
      >
        <BsDownload size={20} color={isDownloading ? colors.disableGray : colors.labelText} onClick={handleDownload} />
      </div>
    </div>
  )
}

interface ISectionPEMDProfile {
  profiles: IAboutUsPEMDProfileResponse[]
}
export const SectionPMEDProfile = ({ profiles = [] }: ISectionPEMDProfile) => {
  return (
    <div>
      <div
        css={css`
          padding: 40px 0;
          background: url(${bgPemdProfileImage});
          background-size: cover;
          text-align: center;
        `}
      >
        <HeaderTitle titleFirst="PEMD" titleSecond="Profile" />
        <div
          css={css`
            display: grid;
            grid-template-columns: repeat(auto-fit, 300px);
            justify-content: center;
            column-gap: 24px;
            row-gap: 40px;
            margin: 0 auto;
            padding: 80px 136px;
          `}
        >
          {profiles.map((profile) => (
            <PEMDProfileItem key={profile.id} profile={profile} />
          ))}
        </div>
      </div>
    </div>
  )
}
