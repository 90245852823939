import { AppModal } from '@ptt-eia-web/components'
import { css } from '@ptt-eia-web/helpers'
import { Form, Input } from 'antd'
import { useWatch } from 'antd/es/form/Form'
import { useCallback, useState } from 'react'

import { Txt } from '@frontend/components/Txt'

interface IAddReasonBeforeSubmitModalProps {
  title: string
  isOpen: boolean
  onClose: () => void
  onSubmit: (reason: string) => Promise<void>
}

export const AddReasonBeforeSubmitModal = ({ title, isOpen, onClose, onSubmit }: IAddReasonBeforeSubmitModalProps) => {
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)
  const reason: string = useWatch('reason', form)

  const handleCloseModal = useCallback(() => {
    onClose()
    form.resetFields()
  }, [form, onClose])

  const handleSubmit = useCallback(async () => {
    await form.validateFields().then(async () => {
      setLoading(true)
      await onSubmit(reason)
      setLoading(false)
      handleCloseModal()
    })
  }, [form, handleCloseModal, onSubmit, reason])

  return (
    <AppModal
      width={800}
      css={css`
        .ant-modal-body {
          padding: 48px 80px;
        }
      `}
      title={
        <Txt ag="med18" color="white">
          {title}
        </Txt>
      }
      centered
      open={isOpen}
      okText="Confirm"
      cancelText="Cancel"
      okButtonProps={{ loading }}
      onOk={handleSubmit}
      onCancel={handleCloseModal}
    >
      <Form layout="vertical" form={form}>
        <Form.Item
          css={css`
            label {
              font-size: 16px !important;
            }
          `}
          name="reason"
          label="เหตุผล"
          rules={[{ required: true, message: 'กรุณาระบุเหตุผล' }]}
        >
          <Input.TextArea
            css={css`
              min-height: 230px !important;
              max-height: 230px !important;
              border-radius: 8px;
            `}
            autoSize={{ minRows: 9, maxRows: 9 }}
          />
        </Form.Item>
      </Form>
    </AppModal>
  )
}
