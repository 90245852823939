import { MenuCodeEnum, PermissionActionEnum } from '@ptt-eia-web/constants'
import { css } from '@ptt-eia-web/helpers'
import { useGetProjectDashboardWithIdQRY, usePermission } from '@ptt-eia-web/services'
import { Flex } from 'antd'
import { last } from 'lodash'
import React, { useCallback } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'

import { permissionConfigs } from '@frontend/app/permission-configs'
import { paths } from '@frontend/app/route/path-config'
import { checkValue } from '@frontend/components/CheckValue'

import { colors } from '../../../../app/theme'
import { Txt } from '../../../../components/Txt'
import { sortProjectDashboardHistory } from '../../project-dashboard-helper'
import { INewReqModalProject } from '../project-list/ModalAddNewProject'

import { AddButton } from './AddButton'
import { UpdateHistoryTable } from './UpdateHistoryTable'

export const UpdateHistoryCard = () => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const projectDashBoardId = parseInt(checkValue(searchParams.get('id')))
  const { data: resultProject } = useGetProjectDashboardWithIdQRY({ id: projectDashBoardId })
  const latestHistoryId = last(sortProjectDashboardHistory(resultProject?.projectDashboardDtOutDtos))?.id
  const { can } = usePermission(permissionConfigs)
  const canEdit = can(MenuCodeEnum.ProjectStatusUpdate, PermissionActionEnum.Edit)
  const handleClickOkNewProjectModal = useCallback(() => {
    const value: INewReqModalProject = {
      id: searchParams.get('id')?.toString() as unknown as number,
      projectTypeValue: searchParams.get('projectType')?.toString(),
      reportTypeValue: searchParams.get('reportType')?.toString(),
      formTypeValue: searchParams.get('formType')?.toString(),
      projectTypeId: searchParams.get('projectTypeId') as unknown as number,
      reportTypeId: searchParams.get('reportTypeId') as unknown as number,
      formTypeId: searchParams.get('formTypeId') as unknown as number,
      flowsType: 'add',
      projectStatus: searchParams.get('projectStatus') as unknown as string,
      projectName: searchParams.get('projectName') as unknown as string,
      projectDashboardId: searchParams.get('id') as unknown as number,
      latestHistoryId,
    }
    navigate(paths.projectStatusUpdateNewProject({ query: value }))
  }, [latestHistoryId, navigate, searchParams])

  return (
    <div
      css={css`
        min-height: 480px;
        background-color: white;
        margin: 30px 0px;
        padding: 32px 8px;
        border-radius: 15px;
        border: 1px solid ${colors.line};
        box-shadow: 0px 2px 6px #0000000a;
      `}
    >
      <Flex
        css={css`
          padding: 0px 24px 32px 24px;
        `}
        justify="space-between"
        align="center"
      >
        <Flex gap={10}>
          <Txt
            ag="med30"
            css={css`
              color: ${colors.primary};
            `}
          >
            Update
          </Txt>
          <Txt ag="med30">History</Txt>
        </Flex>
        {canEdit && <AddButton onAddClick={handleClickOkNewProjectModal} />}
      </Flex>
      <div
        css={css`
          padding: 0px 24px 54px 24px;
        `}
      >
        <UpdateHistoryTable />
      </div>
    </div>
  )
}
