import {
  QueryKey,
  UndefinedInitialDataOptions,
  UseMutationOptions,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query'
import { message } from 'antd'

import { pttClient } from '../ptt-client'
import { IResponseWithData } from '../services-types'

import {
  IProjectListParams,
  IServiceRequestParam,
  INewServiceRequestParams,
  IProjectTeamParams,
  IDeleteProjectTeamParams,
  IUpdateRequestStatusParams,
  IGetMdSubServiceParams,
} from './service-request.params'
import {
  IMdSubServiceResponse,
  IRequestTrackingResponse,
  IProjectListResponse,
  ITeamRoleResponse,
  IServiceRequestDashboardResponse,
  IServiceRequestRequestListResponse,
  IServiceRequestExportRequestListResponse,
  IActivityLogResponse,
} from './service-request.response'

const SERVICE_REQUEST_URL = '/ServiceRequest'
const SERVICE_REQUEST_REQUEST_LIST = '/ServiceRequest/RequestList'
const SERVICE_REQUEST_EXPORT_REQUEST_LIST = '/ServiceRequest/ExportRequestList'
const SERVICE_REQUEST_PROJECT_LIST = '/ServiceRequest/ProjectList'
const REQUEST_TRACKING_ID_URL = '/ServiceRequest/RequesTracking/Id'
const TEAM_ROLE_URL = '/ServiceRequest/TeamRole'
const PROJECT_TEAM_URL = '/ServiceRequest/ProjectTeam'
const REQUEST_STATUS_UPDATE_URL = '/ServiceRequest/ReqStatusUpdate'
const REQUEST_STATUS_UPDATE_ID_URL = `${REQUEST_STATUS_UPDATE_URL}/Id`
const NEW_REQUEST_ID_URL = `${SERVICE_REQUEST_URL}/NewRequest/Id`
const PROJECT_DASHBOARD_URL = '/ProjectDashboard'
const EXPORT_REQUEST_TRACKING_URL = `${SERVICE_REQUEST_URL}/ExportRequestTracking`

export const useGetMdSubServiceQRY = (
  params?: IGetMdSubServiceParams,
  options?: UndefinedInitialDataOptions<IMdSubServiceResponse[]>,
) => {
  return useQuery({
    queryKey: [SERVICE_REQUEST_URL, params],
    queryFn: async () => {
      const res = await pttClient.get<IResponseWithData<IMdSubServiceResponse[]>>(
        `${SERVICE_REQUEST_URL}/MdsubService`,
        { params },
      )
      return res.data.data
    },
    ...options,
  })
}

const SERVICE_REQUEST_REQUEST_DASHBOARD_URL = '/ServiceRequest/RequestDashboard'
export const useGetRequestDashboardChartQRY = (
  params?: IServiceRequestParam,
  options?: UndefinedInitialDataOptions<
    IServiceRequestDashboardResponse,
    Error,
    IServiceRequestDashboardResponse,
    QueryKey
  >,
) => {
  return useQuery<IServiceRequestDashboardResponse>({
    queryKey: [SERVICE_REQUEST_REQUEST_DASHBOARD_URL, { params }],
    queryFn: async (): Promise<IServiceRequestDashboardResponse> => {
      const res = await pttClient.get<IResponseWithData<IServiceRequestDashboardResponse>>(
        SERVICE_REQUEST_REQUEST_DASHBOARD_URL,
        {
          params,
        },
      )
      return res.data.data
    },
    ...options,
  })
}

export const useGetServiceRequestRequestList = (
  params?: IServiceRequestParam,
  options?: UndefinedInitialDataOptions<
    IServiceRequestRequestListResponse[],
    Error,
    IServiceRequestRequestListResponse[],
    QueryKey
  >,
) => {
  return useQuery({
    queryKey: [SERVICE_REQUEST_REQUEST_LIST, { params }],
    queryFn: async () => {
      const res = await pttClient.get<IResponseWithData<IServiceRequestRequestListResponse[]>>(
        `${SERVICE_REQUEST_REQUEST_LIST}`,
        {
          params,
        },
      )
      return res.data.data
    },
    ...options,
  })
}

export const useServiceRequestExportRequestListMTT = () => {
  return useMutation({
    mutationFn: async (params: IServiceRequestParam) => {
      const { data } = await pttClient.get<IResponseWithData<IServiceRequestExportRequestListResponse>>(
        SERVICE_REQUEST_EXPORT_REQUEST_LIST,
        {
          params,
        },
      )
      return data
    },
  })
}

export const useGetServiceRequestProjectList = (
  params: IProjectListParams,
  options?: UndefinedInitialDataOptions<IProjectListResponse[]>,
) => {
  return useQuery({
    queryKey: [SERVICE_REQUEST_PROJECT_LIST, { params }],
    queryFn: async () => {
      const res = await pttClient.get<IResponseWithData<IProjectListResponse[]>>(`${SERVICE_REQUEST_PROJECT_LIST}`, {
        params,
      })
      return res.data.data
    },
    ...options,
  })
}

export const useGetRequestTrackingDetailQRY = (
  id: string,
  options?: UndefinedInitialDataOptions<IRequestTrackingResponse>,
) => {
  return useQuery({
    queryKey: [REQUEST_TRACKING_ID_URL, { id }],
    queryFn: async () => {
      const res = await pttClient.get<IResponseWithData<IRequestTrackingResponse>>(`${REQUEST_TRACKING_ID_URL}`, {
        params: { id },
      })
      return res.data.data
    },
    ...options,
  })
}

export const useGetTeamRoleQRY = (options?: UndefinedInitialDataOptions<ITeamRoleResponse[]>) => {
  return useQuery({
    queryKey: [TEAM_ROLE_URL],
    queryFn: async () => {
      const res = await pttClient.get<IResponseWithData<ITeamRoleResponse[]>>(TEAM_ROLE_URL)
      return res.data.data
    },
    ...options,
  })
}

export const usePostNewRequestMTT = (
  options?: UseMutationOptions<{ tbserviceReqOutDto: { id: string } }, Error, INewServiceRequestParams>,
) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: async (params) => {
      const res = await pttClient.post<IResponseWithData<{ tbserviceReqOutDto: { id: string } }>>(
        `${SERVICE_REQUEST_URL}/NewRequest`,
        params,
      )
      return res.data.data
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [SERVICE_REQUEST_REQUEST_LIST] })
    },
    ...options,
  })
}

export const usePostProjectTeamMTT = (
  serviceReqId: string,
  options?: UseMutationOptions<void, Error, IProjectTeamParams>,
) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: async (params) => {
      await pttClient.post(PROJECT_TEAM_URL, params)
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: [REQUEST_TRACKING_ID_URL, { id: serviceReqId }] })
      await queryClient.invalidateQueries({ queryKey: [NEW_REQUEST_ID_URL, { id: serviceReqId }] })
      message.success('เพิ่ม Member สำเร็จ')
    },
    ...options,
  })
}

export const usePutProjectTeamMTT = (
  serviceReqId: string,
  options?: UseMutationOptions<void, Error, IProjectTeamParams>,
) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: async (params) => {
      await pttClient.put(PROJECT_TEAM_URL, params)
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: [REQUEST_TRACKING_ID_URL, { id: serviceReqId }] })
      await queryClient.invalidateQueries({ queryKey: [NEW_REQUEST_ID_URL, { id: serviceReqId }] })
    },
    ...options,
  })
}

export const useDeleteProjectTeamMTT = (
  serviceReqId: string,
  options?: UseMutationOptions<void, Error, IDeleteProjectTeamParams>,
) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: async (params) => {
      await pttClient.delete(PROJECT_TEAM_URL, { data: { ...params } })
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: [REQUEST_TRACKING_ID_URL, { id: serviceReqId }] })
      await queryClient.invalidateQueries({ queryKey: [NEW_REQUEST_ID_URL, { id: serviceReqId }] })
    },
    ...options,
  })
}

export const usePostUpdateRequestStatusMTT = (
  serviceReqId?: string,
  options?: UseMutationOptions<void, Error, IUpdateRequestStatusParams>,
) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: async (params) => {
      await pttClient.post(REQUEST_STATUS_UPDATE_URL, params)
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: [SERVICE_REQUEST_PROJECT_LIST] })
      await queryClient.invalidateQueries({ queryKey: [SERVICE_REQUEST_REQUEST_LIST] })
      await queryClient.invalidateQueries({ queryKey: [NEW_REQUEST_ID_URL, { id: serviceReqId }] })
      await queryClient.invalidateQueries({ queryKey: [REQUEST_TRACKING_ID_URL, { id: serviceReqId }] })
      await queryClient.invalidateQueries({ queryKey: [REQUEST_STATUS_UPDATE_ID_URL, { id: serviceReqId }] })
    },
    ...options,
  })
}

export const useGetRequestStatusUpdateIdQRY = (
  id?: string,
  options?: UndefinedInitialDataOptions<IActivityLogResponse[], Error, IActivityLogResponse[], QueryKey>,
) => {
  return useQuery({
    queryKey: [REQUEST_STATUS_UPDATE_ID_URL, { id }],
    queryFn: async () => {
      const res = await pttClient.get<IResponseWithData<IActivityLogResponse[]>>(`${REQUEST_STATUS_UPDATE_ID_URL}`, {
        params: { id },
      })
      return res.data.data
    },
    ...options,
  })
}

export const useGetNewRequestByIdQRY = (
  id: string,
  options?: UndefinedInitialDataOptions<IRequestTrackingResponse, Error, IRequestTrackingResponse, QueryKey>,
) => {
  return useQuery({
    queryKey: [NEW_REQUEST_ID_URL, { id }],
    queryFn: async () => {
      const res = await pttClient.get<IResponseWithData<IRequestTrackingResponse>>(`${NEW_REQUEST_ID_URL}`, {
        params: { id },
      })
      return res.data.data
    },
    ...options,
  })
}

export const usePutNewRequestMTT = (
  id: string,
  options?: UseMutationOptions<void, Error, INewServiceRequestParams>,
) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: async (params) => {
      await pttClient.put(`${SERVICE_REQUEST_URL}/NewRequest`, params)
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: [PROJECT_DASHBOARD_URL] })
      await queryClient.invalidateQueries({ queryKey: [REQUEST_TRACKING_ID_URL, { id }] })
      await queryClient.invalidateQueries({ queryKey: [REQUEST_STATUS_UPDATE_ID_URL, { id }] })
      await queryClient.invalidateQueries({ queryKey: [SERVICE_REQUEST_REQUEST_LIST] })
      await queryClient.invalidateQueries({ queryKey: [SERVICE_REQUEST_REQUEST_LIST] })
    },
    ...options,
  })
}

export const useGetExportRequestTrackingMTT = (
  options?: UseMutationOptions<IServiceRequestExportRequestListResponse, Error, { id: number }>,
) => {
  return useMutation<IServiceRequestExportRequestListResponse, Error, { id: number }>({
    mutationFn: async (params) => {
      const res = await pttClient.get<IResponseWithData<IServiceRequestExportRequestListResponse>>(
        EXPORT_REQUEST_TRACKING_URL,
        { params },
      )
      return res.data.data
    },
    ...options,
  })
}

export const useDeleteRequestTrackingMTT = (options?: UseMutationOptions<void, null, { id: number }>) => {
  const queryClient = useQueryClient()
  return useMutation<void, null, { id: number }>({
    mutationFn: async (params) => {
      await pttClient.delete(`${SERVICE_REQUEST_URL}/NewRequest`, {
        data: { ...params },
      })
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: [PROJECT_DASHBOARD_URL] })
      await queryClient.invalidateQueries({ queryKey: [SERVICE_REQUEST_REQUEST_LIST] })
    },
    ...options,
  })
}
