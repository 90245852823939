import { MenuCodeEnum, PermissionActionEnum } from '@ptt-eia-web/constants'
import { css } from '@ptt-eia-web/helpers'
import { usePermission } from '@ptt-eia-web/services'
import { Col, Form, Row, Switch } from 'antd'
import { useWatch } from 'antd/es/form/Form'
import TextArea from 'antd/es/input/TextArea'
import React, { useEffect, useMemo, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

import { permissionConfigs } from '@frontend/app/permission-configs'
import { Txt } from '@frontend/components/Txt'

import { colors } from '../../../../../../app/theme'
import TransmissionMonitoring from '../../../../../../assets/project-dashboard/monitoring-table.png'

export const EnvComplianceAndMonitoring = () => {
  const formEiaSwitch = useWatch('monitorActive')
  const [searchParams] = useSearchParams()
  const { can } = usePermission(permissionConfigs)
  const canEdit = can(MenuCodeEnum.ProjectStatusUpdate, PermissionActionEnum.Edit)
  const [toggleEIA, setToggleEIA] = useState<boolean>(false)
  useEffect(() => {
    if (searchParams.get('flowsType')?.toString() === 'new') {
      setToggleEIA(true)
    }
  }, [searchParams])

  useEffect(() => {
    if (formEiaSwitch !== undefined) {
      setToggleEIA(formEiaSwitch)
    }
  }, [formEiaSwitch, toggleEIA])
  const isDisableToggle = useMemo(() => {
    if (!canEdit) {
      return true
    }
    if (!toggleEIA) {
      return true
    }
  }, [toggleEIA, canEdit])

  const isDisables = useMemo(() => {
    if (!canEdit) {
      return true
    }
  }, [canEdit])

  return (
    <div
      css={css`
        padding: 60px 70px 0px 70px;
      `}
    >
      <Txt
        ag="med24"
        css={css`
          color: ${colors.primary};
          margin-right: 8px;
        `}
      >
        Environmental Compliance
      </Txt>
      <Txt ag="med24">and Monitoring</Txt>
      <Row
        gutter={[60, 16]}
        css={css`
          @media screen and (min-width: 717px) {
            margin: 46px 0px 16px 0px;
          }
        `}
      >
        <Col lg={8} md={24} xs={24}>
          <Col span={24}>
            <Row gutter={[0, 16]}>
              <Col span={8}>
                <Form.Item>
                  <Txt ag="reg14">Monitoring</Txt>
                </Form.Item>
              </Col>
              <Col span={14}>
                <Form.Item name="monitorActive">
                  <Switch disabled={canEdit ? false : isDisables} defaultChecked />
                </Form.Item>
              </Col>
            </Row>
          </Col>
          <Col
            css={css`
              padding-top: 16px;
            `}
            span={24}
          >
            <Form.Item
              css={css`
                .ant-form-item-label label {
                  height: 20px;
                }
                .ant-input {
                  height: auto;
                }
              `}
              rules={[{ required: isDisableToggle, message: 'กรุณากรอกข้อความ' }]}
              name="monitorReason"
              label="ข้อความกรณี Monitoring Inactive"
            >
              <TextArea
                disabled={!isDisableToggle}
                placeholder={isDisableToggle ? 'กรอกข้อความ' : ''}
                rows={6}
                maxLength={1500}
                showCount
              />
            </Form.Item>
          </Col>
        </Col>

        <Col xs={24} md={24} lg={16} xl={16}>
          <div
            css={css`
              height: 100%;
              padding: 12px;
              box-shadow: 0px 2px 6px #0000000a;
              border: 1px solid #dddddd;
              border-radius: 10px;
            `}
          >
            <img
              css={css`
                width: 100%;
                height: auto;
              `}
              src={TransmissionMonitoring}
              alt="Transmission Monitoring"
            />
          </div>
        </Col>
      </Row>
    </div>
  )
}
