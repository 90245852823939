import { ContentType, getContentTypeHeader, isBase64Data, uuid } from '@ptt-eia-web/helpers'
import { useMutation } from '@tanstack/react-query'

import { pttClient } from '../ptt-client'
import { IResponseWithData } from '../services-types'

import { IFetchFilePathParams, IUploadAttachmentParam } from './attachment-params'
import { IUploadAttachmentResponse } from './attachment-response'

export const ATTACHMENT_UPLOAD_TIMEOUT = 90 * 1000

const ATTACHMENT_UPLOAD_PATH = '/UploadFile'

export const useUploadAttachmentMTT = () => {
  return useMutation({
    mutationFn: async (param: IUploadAttachmentParam) => {
      const res = await pttClient.post<IResponseWithData<IUploadAttachmentResponse>>(ATTACHMENT_UPLOAD_PATH, param, {
        headers: getContentTypeHeader(ContentType.FORM_DATA),
        timeout: ATTACHMENT_UPLOAD_TIMEOUT,
      })
      return res.data.data
    },
  })
}

export const useBase64FetchFilePath = () => {
  const { mutateAsync: uploadAttachment } = useUploadAttachmentMTT()
  const fetchFilePath = async ({ base64Data, fileName = uuid(), uploadPath }: IFetchFilePathParams) => {
    if (!isBase64Data(base64Data)) {
      return base64Data
    }
    const fileResponse = await fetch(base64Data)
    const fileBlob = await fileResponse.blob()
    const fileExt = RegExp(/data:\w+\/(\w+)/).exec(base64Data)?.[1]
    const fileFullName = fileName.endsWith(`.${fileExt}`) ? fileName : `${fileName}.${fileExt}`
    const fileUpload = new File([fileBlob], fileFullName)
    const { filePath } = await uploadAttachment({ filePath: uploadPath, fileUpload })
    return filePath
  }
  return { fetchFilePath }
}
