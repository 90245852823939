import { css } from '@ptt-eia-web/helpers'
import { Typography } from 'antd'
import { TextProps } from 'antd/es/typography/Text'
import { PropsWithChildren, useMemo } from 'react'

import { colors } from '../app/theme'

export type TxtProps = {
  ag:
    | 'med30'
    | 'med28'
    | 'med24'
    | 'med20'
    | 'med18'
    | 'med16'
    | 'med14'
    | 'med12'
    | 'med10'
    | 'med8'
    | 'light16'
    | 'light12'
    | 'light10'
    | 'reg18'
    | 'reg14'
    | 'reg12'
    | 'reg10'
  color?: keyof typeof colors
  css?: string
  showTitle?: boolean
} & TextProps

export const fontStyle: { [p in TxtProps['ag']]: string } = {
  med30: css`
    font-family: Prompt;
    font-weight: 500;
    font-size: 30px;
    line-height: 46px;
  `,
  med28: css`
    font-family: Prompt;
    font-weight: 500;
    font-size: 28px;
  `,
  med24: css`
    font-family: Prompt;
    font-weight: 500;
    font-size: 24px;
  `,
  med20: css`
    font-family: Prompt;
    font-weight: 500;
    font-size: 20px;
  `,
  med18: css`
    font-family: Prompt;
    font-weight: 500;
    font-size: 18px;
  `,
  med16: css`
    font-family: Prompt;
    font-weight: 500;
    font-size: 16px;
  `,
  med14: css`
    font-family: Prompt;
    font-weight: 500;
    font-size: 14px;
  `,
  med12: css`
    font-family: Prompt;
    font-weight: 500;
    font-size: 12px;
  `,
  med10: css`
    font-family: Prompt;
    font-weight: 500;
    font-size: 10px;
  `,
  med8: css`
    font-family: Prompt;
    font-weight: 500;
    font-size: 8px;
  `,
  light16: css`
    font-family: Prompt;
    font-weight: 300;
    font-size: 16px;
  `,
  light12: css`
    font-family: Prompt;
    font-weight: 300;
    font-size: 12px;
  `,
  light10: css`
    font-family: Prompt;
    font-weight: 300;
    font-size: 10px;
  `,
  reg18: css`
    font-family: Prompt;
    font-weight: 400;
    font-size: 18px;
  `,
  reg14: css`
    font-family: Prompt;
    font-weight: 400;
    font-size: 14px;
  `,
  reg12: css`
    font-family: Prompt;
    font-weight: 400;
    font-size: 12px;
  `,
  reg10: css`
    font-family: Prompt;
    font-weight: 400;
    font-size: 10px;
  `,
}

export const Txt = (props: PropsWithChildren<TxtProps>) => {
  const { ag = 'med14', color, showTitle, title, ...restProps } = props
  const colorValue = color ? colors[color] : undefined

  const _title = useMemo(() => {
    if (props.title) return props.title

    if (showTitle && typeof props.children === 'string') return props.children
  }, [props.children, props.title, showTitle])

  return (
    <Typography.Text
      css={css([
        css`
          font-family: Prompt;
        `,
        fontStyle[ag],
        {
          color: colorValue,
        },
      ])}
      title={_title}
      {...restProps}
    />
  )
}
