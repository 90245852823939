import styled from '@emotion/styled'
import { MenuCodeEnum, PermissionActionEnum } from '@ptt-eia-web/constants'
import { css, getCSPNonce, numberFormat, secureRandomInt } from '@ptt-eia-web/helpers'
import { ProjectDashboardLTAInDtos, ProjectDashboardSHEInDtos, usePermission } from '@ptt-eia-web/services'
import { Button, ConfigProvider, Form, FormInstance, Input, InputRef, Table, Tooltip } from 'antd'
import { useWatch } from 'antd/es/form/Form'
import dayjs from 'dayjs'
// Import proper type for rules
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

import { permissionConfigs } from '@frontend/app/permission-configs'
import { colors } from '@frontend/app/theme'

import IconTrash from '../../../../../assets/svgs/icon-actions-trash.svg?react'

const TrashIcon = styled(IconTrash)`
  text-align: center;
  align-self: center;
  width: 21px;
  height: 20px;
  #Line_53 {
    stroke-width: 1.2;
    stroke: #ff0000;
  }
  #Line_54 {
    stroke-width: 1.2;
    stroke: #ff0000;
  }
  #Rectangle_15 {
    stroke-width: 1.2;
    stroke: #ff0000;
  }
  #Path_41 {
    stroke-width: 1.2;
    stroke: #ff0000;
  }
`
interface Item {
  key: number
  Phase: string
  CurentYearMan: number
  TotalMan: number
  TargetMan: number
}

export const LtaEditableContext = React.createContext<FormInstance<Item> | null>(null)

interface IEditableRowProps {
  index: number
}

const EditableRow: React.FC<IEditableRowProps> = ({ index, ...props }) => {
  const [form] = Form.useForm()
  return (
    <Form form={form} component={false}>
      <LtaEditableContext.Provider value={form}>
        <tr {...props} />
      </LtaEditableContext.Provider>
    </Form>
  )
}

interface IEditableCellProps {
  title: React.ReactNode
  editable: boolean
  children: React.ReactNode
  dataIndex: keyof Item
  record: Item
  handleSave: (record: Item) => void
}

type EditableTableProps = Parameters<typeof Table>[0]

type ColumnTypes = Exclude<EditableTableProps['columns'], undefined>

interface IWithoutLTATableProps {
  onProjectDashboardLTAInDtos: (arg0: ProjectDashboardLTAInDtos[]) => void
  onValidateInputPhaseLTASuccess: (arg0: boolean) => void
  dataSourceLTA: ProjectDashboardLTAInDtos[]
  dataSourceSHE: ProjectDashboardSHEInDtos[]
}

export const WithoutLTATable = (props: IWithoutLTATableProps) => {
  const { onProjectDashboardLTAInDtos } = props
  const [dataSource, setDataSource] = React.useState<ProjectDashboardLTAInDtos[]>(props.dataSourceLTA ?? [])
  useEffect(() => {
    setDataSource(props.dataSourceLTA)
  }, [props.dataSourceLTA])
  const { can } = usePermission(permissionConfigs)
  const canEdit = can(MenuCodeEnum.ProjectStatusUpdate, PermissionActionEnum.Edit)
  const [toggleEIA, setToggleEIA] = useState<boolean>(false)
  const formEiaSwitch = useWatch('monitorActive')
  const [searchParams] = useSearchParams()

  useEffect(() => {
    if (searchParams.get('flowsType')?.toString() === 'new') {
      setToggleEIA(true)
    }
  }, [searchParams])

  useEffect(() => {
    if (formEiaSwitch !== undefined) {
      setToggleEIA(formEiaSwitch)
    }
  }, [formEiaSwitch, toggleEIA])
  const isDisableToggle = useMemo(() => {
    if (!canEdit) {
      return true
    }
    if (!toggleEIA) {
      return true
    } else if (!toggleEIA === false && props.dataSourceSHE.length === 0) {
      return true
    }
  }, [toggleEIA, canEdit, props.dataSourceSHE])
  const handleAdd = () => {
    const randomId = secureRandomInt({ min: 100_000, max: 999_999 })
    const newData: ProjectDashboardLTAInDtos = {
      Id: randomId + 1,
      Phase: '',
      CurentYearMan: 0,
      TotalMan: 0,
      TargetMan: 0,
      IsNewData: true,
    }
    setDataSource([...dataSource, newData])
  }
  const handleDelete = (key: React.Key) => {
    const newData = dataSource.filter((item) => item.Id !== key)
    onProjectDashboardLTAInDtos(newData)
    setDataSource(newData)
  }
  const handleValidateDataIndex = (dataIndex: keyof Item) => {
    return dataIndex === 'TotalMan' || dataIndex === 'TargetMan' || dataIndex === 'CurentYearMan'
  }

  const handleSetUpInputRef = (editing: boolean, inputRef: React.RefObject<InputRef>) => {
    if (editing && inputRef.current) {
      inputRef.current.focus()
    }
  }

  const handleRulePhaseInput = (dataIndex: keyof Item) => {
    return [
      { required: true, message: 'กรุณากรอกข้อมูล' },
      () => ({
        validator(_: unknown, value: string) {
          if (dataIndex === 'Phase') {
            const isMatchSHEPhase = props.dataSourceSHE.some((item) => value === item.Phase)
            if (value && !isMatchSHEPhase) {
              props.onValidateInputPhaseLTASuccess(false)
              return Promise.reject(new Error('Phase ไม่ถูกต้อง'))
            }
          }
          return Promise.resolve()
        },
      }),
    ]
  }

  const handleKeyDown = (isIntegerField: boolean, e: React.KeyboardEvent) => {
    if (
      isIntegerField &&
      !/^\d$/.test(e.key) && // Allow digits
      e.key !== 'Backspace' && // Allow backspace
      e.key !== 'Delete' && // Allow delete
      e.key !== 'ArrowLeft' && // Allow arrow left
      e.key !== 'ArrowRight' && // Allow arrow right
      e.key !== 'Home' && // Allow home
      e.key !== 'End' && // Allow end
      e.key !== 'Tab' // Allow tab
    ) {
      e.preventDefault()
    }
  }
  const inputTable = (
    dataIndex: keyof Item,
    inputRef: React.RefObject<InputRef>,
    isNumber: boolean,
    save: () => void,
  ) => {
    return (
      <Form.Item
        css={css`
          margin: -8px 0px !important;
        `}
        rules={handleRulePhaseInput(dataIndex)}
        name={dataIndex}
      >
        {isNumber ? (
          <Input
            css={css`
              text-align: center;
            `}
            defaultValue={0}
            size="small"
            ref={inputRef}
            onPressEnter={save}
            onBlur={save}
            onKeyDown={(e) => handleKeyDown(isNumber, e)}
          />
        ) : (
          <Input
            css={css`
              text-align: center;
            `}
            size="small"
            ref={inputRef}
            onPressEnter={save}
            onBlur={save}
          />
        )}
      </Form.Item>
    )
  }

  const EditableCell: React.FC<IEditableCellProps> = ({
    title,
    editable,
    children,
    dataIndex,
    record,
    handleSave,
    ...restProps
  }) => {
    const [editing, setEditing] = useState(false)
    const inputRef = useRef<InputRef>(null)
    const form = useContext(LtaEditableContext)
    useEffect(() => {
      handleSetUpInputRef(editing, inputRef)
    }, [editing])

    const toggleEdit = () => {
      setEditing(!editing)
      if (form) {
        form.setFieldsValue({ [dataIndex]: record[dataIndex] || '' })
      }
    }

    const save = async () => {
      let values
      try {
        if (form) {
          values = await form.validateFields()
        }
        // Ensure values is not undefined (use an empty object if it is)
        const filteredValues = Object.fromEntries(
          Object.entries(values || {}).filter(([_, value]) => value !== undefined && value !== null),
        )
        toggleEdit()
        props.onValidateInputPhaseLTASuccess(true)
        handleSave({ ...record, ...filteredValues })
      } catch (errInfo) {
        console.log('Save failed:', errInfo)
      }
    }
    const isIntegerField = ['TotalMan', 'TargetMan', 'CurentYearMan'].includes(dataIndex)
    let childNode = children
    if (editable) {
      childNode = editing ? (
        inputTable(dataIndex, inputRef, isIntegerField, save)
      ) : (
        <div
          css={css`
            padding: 5px 12px;
            height: 32px;
            text-align: center;
            cursor: pointer;
            &:hover {
              padding: 4px 11px;
              border: 1px solid ${colors.line};
              border-radius: 2px;
            }
          `}
          onClick={toggleEdit}
        >
          {handleValidateDataIndex(dataIndex) ? numberFormat(record[dataIndex]) : childNode}
        </div>
      )
    }

    return <td {...restProps}>{childNode}</td>
  }

  const defaultColumns: (ColumnTypes[number] & { editable?: boolean; dataIndex: string })[] = [
    {
      title: 'PHASE',
      align: 'center',
      dataIndex: 'Phase',
      key: 'phase',
      width: '16.6%',
      editable: !isDisableToggle,
      render: (text) => (
        <Tooltip title={text} trigger="hover">
          <div
            css={css`
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 1;
              overflow: hidden;
              text-overflow: ellipsis;
            `}
          >
            {text}
          </div>
        </Tooltip>
      ),
    },
    {
      title: `TOTAL MAN HOUR ${dayjs().year()}`,
      dataIndex: 'CurentYearMan',
      align: 'center',
      key: 'curentYearMan',
      width: '16.6%',
      editable: !isDisableToggle,
    },
    {
      title: 'TOTAL MANHOUR',
      align: 'center',
      dataIndex: 'TotalMan',
      width: '16.6%',
      key: 'totalMan',
      editable: !isDisableToggle,
    },
    {
      title: 'TARGET MANHOUR',
      align: 'center',
      dataIndex: 'TargetMan',
      key: 'targetMan',
      width: '16.6%',
      editable: !isDisableToggle,
    },
    {
      title: 'DELETE',
      dataIndex: 'delete',
      align: 'center',
      width: '16.6%',
      key: 'delete',
      render: (_, record) => (
        <div
          css={css`
            display: flex;
            justify-content: center;
            align-items: center;
          `}
        >
          <ConfigProvider
            csp={{ nonce: getCSPNonce() }}
            theme={{
              token: {
                colorPrimary: `${colors.white}`,
                borderRadius: 0,
                colorBgContainer: `transparent`,
              },
            }}
          >
            <Button
              disabled={isDisableToggle}
              onClick={() => handleDelete(record.Id)}
              css={css`
                cursor: pointer;
                background-color: transparent !important;
                border: 0 solid;
                &:hover {
                  cursor: ${isDisableToggle ? 'not-allowed' : 'pointer'};
                }
              `}
            >
              <TrashIcon />
            </Button>
          </ConfigProvider>
        </div>
      ),
    },
  ]

  const handleIntegerOutput = (row: ProjectDashboardLTAInDtos) => {
    const currentValue =
      typeof row.CurentYearMan === 'number' ? row.CurentYearMan : parseInt(row.CurentYearMan || '0', 10)
    row.CurentYearMan = isNaN(currentValue) ? 0 : currentValue
    const totalCurrent = typeof row.TotalMan === 'number' ? row.TotalMan : parseInt(row.TotalMan || '0', 10)
    row.TotalMan = isNaN(totalCurrent) ? 0 : totalCurrent
    const targetCurrent = typeof row.TargetMan === 'number' ? row.TargetMan : parseInt(row.TargetMan || '0', 10)
    row.TargetMan = isNaN(targetCurrent) ? 0 : targetCurrent
  }

  const handleSave = (row: ProjectDashboardLTAInDtos) => {
    handleIntegerOutput(row)
    const newData = [...dataSource]
    const index = newData.findIndex((item) => row.Id === item.Id)
    const item = newData[index]
    newData.splice(index, 1, {
      ...item,
      ...row,
    })
    onProjectDashboardLTAInDtos(newData)
    setDataSource(newData)
  }
  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  }

  const columns = defaultColumns.map((col) => {
    if (!col.editable) {
      return col
    }
    return {
      ...col,
      onCell: (record: ProjectDashboardLTAInDtos) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave,
      }),
    }
  })

  return (
    <div>
      <Table
        bordered
        dataSource={dataSource}
        components={components}
        rowClassName={() => 'editable-row'}
        pagination={dataSource.length > 4 ? { defaultCurrent: 1, defaultPageSize: 5 } : false}
        columns={columns as ColumnTypes}
      />
      <Button
        disabled={isDisableToggle}
        css={css`
          font-size: 16px;
          margin-top: 24px;
        `}
        onClick={handleAdd}
        type="primary"
      >
        + Add Row
      </Button>
    </div>
  )
}
