import styled from '@emotion/styled'
import { MenuCodeEnum, PermissionActionEnum } from '@ptt-eia-web/constants'
import { css } from '@ptt-eia-web/helpers'
import { usePermission } from '@ptt-eia-web/services'
import { Table } from 'antd'
import React, { useEffect } from 'react'

import { permissionConfigs } from '@frontend/app/permission-configs'

import IconTrash from '../../../../assets/svgs/icon-actions-trash.svg?react'

import type { ColumnsType } from 'antd/es/table'

const TrashIcon = styled(IconTrash)`
  text-align: center;
  align-self: center;
  width: 18px;
  height: 18px;
  #Line_53 {
    stroke-width: 1.2;
    stroke: #ff0000;
  }
  #Line_54 {
    stroke-width: 1.2;
    stroke: #ff0000;
  }
  #Rectangle_15 {
    stroke-width: 1.2;
    stroke: #ff0000;
  }
  #Path_41 {
    stroke-width: 1.2;
    stroke: #ff0000;
  }
`

export interface DataType {
  no: number
  employeeCode: string
  fullName: string
  email: string
}

interface IModalEmployeeProps {
  tableData: DataType[]
  isPending: boolean
  onDelete: (id: string) => void
}

export const EmployeeTable = ({ tableData, onDelete, isPending }: IModalEmployeeProps) => {
  const [dataList, setDataList] = React.useState<DataType[]>([])
  const { can } = usePermission(permissionConfigs)
  const canEdit = can(MenuCodeEnum.ProjectStatusUpdate, PermissionActionEnum.Edit)
  useEffect(() => {
    setDataList(tableData)
  }, [tableData])

  let columns: ColumnsType<DataType> = [
    {
      title: 'NO.',
      align: 'center',
      dataIndex: 'no',
      key: 'no',
      width: 80,
    },
    {
      title: 'NAME',
      align: 'center',
      width: 200,
      dataIndex: 'fullName',
      key: 'fullName',
    },
    {
      title: 'EMAIl',
      align: 'center',
      width: 150,
      dataIndex: 'email',
      key: 'email',
    },
  ]
  if (canEdit) {
    columns = [
      ...columns,
      {
        title: 'DELETE',
        align: 'center',
        width: 80,
        dataIndex: 'delete',
        key: 'delete',
        render: (_, record) => (
          <div
            css={css`
              display: flex;
              justify-content: center;
              align-items: center;
            `}
          >
            <button
              onClick={() => onDelete(record.employeeCode)}
              css={css`
                cursor: pointer;
                background-color: transparent;
                border: 0 solid;
              `}
            >
              <TrashIcon />
            </button>
          </div>
        ),
      },
    ]
  }

  return (
    <Table
      bordered
      loading={isPending}
      columns={columns}
      dataSource={dataList}
      pagination={{ defaultCurrent: 1, defaultPageSize: 5 }}
    />
  )
}
