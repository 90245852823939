import styled from '@emotion/styled'
import { MenuCodeEnum, PermissionActionEnum } from '@ptt-eia-web/constants'
import { usePermission } from '@ptt-eia-web/services'
import React, { useEffect } from 'react'
import { Navigate, useSearchParams } from 'react-router-dom'

import { permissionConfigs } from '@frontend/app/permission-configs'

import { SwitchGraphToggle } from './SwitchGraphToggle'
import { ReportDashboardChart } from './project-dashboard-table/ReportDashboardChart'
import { PageSummaryGraphProjectDashboard } from './summary-graph-project-dashboard/PageSummaryGraphProjectDashboard'

const SpaceStyle = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
`

export const PageProjectDashBoard: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams({ tab: 'table' })
  const { can } = usePermission(permissionConfigs)
  const canView = can(MenuCodeEnum.ProjectDashboard, PermissionActionEnum.View)

  useEffect(() => {
    setSearchParams(searchParams)
  }, [searchParams, setSearchParams])

  if (!canView) return <Navigate to="/404" />
  return (
    <SpaceStyle>
      {searchParams.get('tab') === 'table' || !searchParams.get('tab') ? (
        <PageSummaryGraphProjectDashboard />
      ) : (
        <ReportDashboardChart />
      )}
      <SwitchGraphToggle />
    </SpaceStyle>
  )
}
