import { useMsal } from '@azure/msal-react'
import styled from '@emotion/styled'
import { SafeLinkTo } from '@ptt-eia-web/components'
import { css, getIsAdmin, getSelectedKeys, mapMenuItems } from '@ptt-eia-web/helpers'
import { azureLogout, useGetMeSuspenseQRY, useGetRoleMenuQRY } from '@ptt-eia-web/services'
import { Dropdown, Layout, Menu, MenuProps } from 'antd'
import { useMemo } from 'react'
import { useLocation } from 'react-router-dom'

import logoSvg from '../../assets/svgs/ptt-logo.svg'
import { colors, themeConfig } from '../theme'

import { MENU_MAPPINGS } from './menu-mappings'

const VerticalLine = styled('div')`
  height: 30px;
  margin: 0 12px;
  width: 1px;
  background: ${colors.gray};
`
const LogoText = styled('div')<{ color?: string }>`
  font-size: 12px;
  line-height: 12px;
  color: ${({ color }) => color ?? 'inherit'};
`
const ProfileText = styled('span')<{ color?: string }>`
  font-size: 12px;
  line-height: 12px;
  color: ${({ color }) => color ?? 'inherit'};
`

export const Navbar = () => {
  const { data: user } = useGetMeSuspenseQRY()
  const { data: roleMenuList = [] } = useGetRoleMenuQRY()
  const { instance } = useMsal()
  const location = useLocation()

  const menuItems = useMemo(() => {
    const renderLabel = (label: string, path?: string) =>
      path ? (
        <SafeLinkTo id={`menu_item_${label}`} path={path}>
          {label}
        </SafeLinkTo>
      ) : (
        label
      )
    return mapMenuItems({ menuMappings: MENU_MAPPINGS, roleMenuList, renderLabel })
  }, [roleMenuList])

  const selectedKeys = useMemo(() => {
    return getSelectedKeys(menuItems, location.pathname)
  }, [location.pathname, menuItems])

  const profileMenuitems: MenuProps['items'] = useMemo(() => {
    const isAdmin = getIsAdmin(roleMenuList)
    let items: MenuProps['items'] = [
      {
        key: '1',
        label: 'Logout',
        onClick: () => azureLogout(instance),
      },
    ]
    if (isAdmin) {
      items = [
        {
          key: '2',
          label: <a href="/backend">Admin</a>,
        },
        ...items,
      ]
    }
    return items
  }, [instance, roleMenuList])

  return (
    <Layout.Header
      css={css`
        display: flex;
        align-items: center;
        justify-content: center;
        background: white;
        height: 120px;
        min-width: 670px;
      `}
    >
      <div
        css={css`
          max-width: ${themeConfig.maxWidth};
          flex: 1;
          display: flex;
          align-items: center;
          justify-content: center;
        `}
      >
        <img
          css={css`
            width: 120px;
          `}
          src={logoSvg}
          alt="logo"
        />
        <VerticalLine />
        <div
          css={css`
            min-width: 127px;
            margin-right: auto;
          `}
        >
          <LogoText color={colors.primary}>Project</LogoText>
          <LogoText color={colors.secondary}>Environmental</LogoText>
          <LogoText>Management Services</LogoText>
        </div>
        <Menu
          css={css`
            max-width: calc(100% - 478px);
            flex: 1;
            margin-top: 12px;
            justify-content: flex-end;
            .ant-menu-title-content a {
              color: inherit;
            }
          `}
          theme="light"
          mode="horizontal"
          selectedKeys={selectedKeys}
          items={menuItems}
        />
        <div
          css={css`
            display: flex;
            min-width: 200px;
            justify-content: center;
            align-items: center;
          `}
        >
          <Dropdown menu={{ items: profileMenuitems }}>
            <div
              css={css`
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-content: center;
                margin-top: 12px;
                cursor: pointer;
              `}
            >
              {user?.code && <ProfileText>{`${user?.code}`}</ProfileText>}
              <ProfileText color={colors.secondary}>{`${user?.fname} ${user?.lname}`}</ProfileText>
            </div>
          </Dropdown>
        </div>
      </div>
    </Layout.Header>
  )
}
