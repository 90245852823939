import { RcFile } from 'antd/es/upload'

import { getEnv } from './env-helper'

function convertBase64ToBlob(base64Data: string, contentType: string): Blob {
  // Split the base64 string to get MIME type and raw base64 data
  const parts = base64Data.split(';base64,')
  const base64 = parts.length === 2 ? parts[1] : base64Data
  const raw = window.atob(base64)
  const rawLength = raw.length
  const array = new Uint8Array(new ArrayBuffer(rawLength))
  for (let i = 0; i < rawLength; i++) {
    array[i] = raw.charCodeAt(i)
  }
  return new Blob([array], { type: contentType })
}

export const convertFileToBase64 = (file: RcFile): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result as string)
    reader.onerror = (error) => reject(error)
  })

export const isBase64Data = (dataUri: string) => {
  const regex = /^data:([a-zA-Z0-9]+\/[a-zA-Z0-9-.+]+)?;base64,[a-zA-Z0-9+/]+={0,2}$/
  return regex.test(dataUri)
}

export const isBase64DataOptional = (data?: string) => {
  return data && isBase64Data(data)
}

export const isPathOnly = (path: string) => {
  return path.startsWith('/')
}

export const isFullUrl = (path: string) => {
  return path.startsWith('http')
}

export const getFullUrl = (path: string) => {
  if (!isPathOnly(path)) {
    throw new Error('invalid path, it must be path-only string')
  }
  return `${getEnv('STORAGE_BASE_URL')}${path}`
}

// 1
export const downloadFile = (url: string, fileName: string) => {
  return new Promise<void>((resolve) => {
    if (isBase64Data(url)) {
      const mimeType = url.match(/data:([^;]+);base64,/)?.[1]
      if (!mimeType) {
        throw new Error('MIME type could not be extracted from base64Data')
      }
      const blob = convertBase64ToBlob(url, mimeType)
      const a = document.createElement('a')
      a.href = URL.createObjectURL(blob)
      a.setAttribute('download', fileName)
      a.click()
      resolve()
      return
    }
    let sanitizedUrl
    try {
      const urlObj = new URL(`${url}`)
      sanitizedUrl = urlObj.href
    } catch (error) {
      throw new Error('Invalid path')
    }
    fetch(sanitizedUrl).then(async (t) => {
      const b = await t.blob()
      const a = document.createElement('a')
      a.href = URL.createObjectURL(b)
      a.setAttribute('download', fileName)
      a.click()
      resolve()
    })
  })
}

export const openFile = (url: string) => {
  window.open(url, '_blank')?.focus()
}

export const openBase64File = async (base64Data: string) => {
  const mimeType = base64Data.match(/data:([^;]+);base64,/)?.[1]
  if (!mimeType) {
    throw new Error('MIME type could not be extracted from base64Data')
  }
  const blob = convertBase64ToBlob(base64Data, mimeType)
  openFile(URL.createObjectURL(blob))
}

export const downloadExcelBase64 = async (base64Data: string, fileName: string) => {
  const excelBase64 = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${base64Data}`
  downloadFile(excelBase64, fileName)
}

export const calculateFileSizeMB = (base64: string) => {
  const stringLength = base64.length - 'data:image/png;base64,'.length
  const sizeInBytes = 4 * Math.ceil(stringLength / 3) * 0.5624896334383812
  return sizeInBytes / 1000 / 1024
}
