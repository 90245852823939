import { MenuCodeEnum, PermissionActionEnum } from '@ptt-eia-web/constants'
import { css } from '@ptt-eia-web/helpers'
import {
  IMasterReportStatusResponse,
  useGetMasterReportStatusQRY,
  useGetReportTypeDetailQRY,
  usePermission,
} from '@ptt-eia-web/services'
import { Col, Flex, Form, InputNumber, Radio, Row, Space } from 'antd'
import { useWatch } from 'antd/es/form/Form'
import TextArea from 'antd/es/input/TextArea'
import React, { useEffect, useMemo, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

import { permissionConfigs } from '@frontend/app/permission-configs'
import { Txt } from '@frontend/components/Txt'
import { ColorIndicator } from '@frontend/modules/project-dashboard/summary-graph-project-dashboard/Indicator'

import { colors } from '../../../../../../app/theme'
import RemarkTablePicture from '../../../../../../assets/project-dashboard/remark-table.png'
import { progressColor } from '../../../../project-dashboard-table/ReportStatus'
export const SecondForm = () => {
  const { data: reportStatusData } = useGetMasterReportStatusQRY()
  const [searchParams] = useSearchParams()
  const { data: reportTypeList } = useGetReportTypeDetailQRY(searchParams.get('reportTypeId') as unknown as number)
  const formUpdateBy = useWatch('updateBy')
  const formUpdateDate = useWatch('updateDate')
  const formActual = useWatch('actual')
  const formPlan = useWatch('plan')
  const { can } = usePermission(permissionConfigs)
  const canEdit = can(MenuCodeEnum.ProjectStatusUpdate, PermissionActionEnum.Edit)
  const [reportStatusFrom, setReportStatusFrom] = useState(searchParams.get('formTypeId'))
  const isDisables = useMemo(() => {
    if (!canEdit) {
      return true
    }
  }, [canEdit])

  useEffect(() => {
    setReportStatusFrom(searchParams.get('projectTypeId'))
  }, [searchParams])
  const calculateColor = (plan: number, actual: number) => {
    let total
    if (plan !== null && actual !== null) {
      total = plan - actual
    } else {
      return 'none'
    }
    if (total > 5) {
      return '#ff5050'
    } else if (total >= 1 && total <= 5) {
      return '#ffd966'
    } else if (total === 0) {
      return '#92d050'
    } else if (total < 0) {
      return '#00b050'
    } else {
      return 'none'
    }
  }
  const colorInputNumberActual = useMemo(() => calculateColor(formPlan, formActual), [formPlan, formActual])
  const getStatusText = (item: IMasterReportStatusResponse) => {
    switch (reportStatusFrom) {
      case '1':
        return item.statusForm1
      case '2':
        return item.statusForm2
      default:
        return item.statusForm3
    }
  }
  return (
    <>
      <div
        css={css`
          padding: 60px 70px 20px 70px;
          background-color: ${colors.lightGray};
        `}
      >
        <Row gutter={[0, 16]}>
          <Col span={6}>
            <Txt
              ag="med24"
              css={css`
                color: ${colors.primary};
              `}
            >
              {reportTypeList?.eiaEr}
            </Txt>
          </Col>
          <Col xl={18} lg={18}>
            <div
              css={css`
                height: auto;
                padding: 8px;
                box-shadow: 0px 2px 6px #0000000a;
                border: 1px solid #dddddd;
                border-radius: 10px;
              `}
            >
              <img
                css={css`
                  width: 100%;
                  height: auto;
                `}
                src={RemarkTablePicture}
                alt="RemarkTablePicture"
              />
            </div>
          </Col>
        </Row>
        <Row
          css={css`
            margin-top: 40px;
          `}
          gutter={[60, 40]}
        >
          <Col xs={24} md={24} lg={10} xl={10}>
            <Form.Item
              rules={[{ required: !isDisables, message: 'กรุณาเลือก' }]}
              name="eiaReportStatus"
              label="Report Status"
            >
              <Radio.Group disabled={isDisables}>
                <Space direction="vertical">
                  {reportStatusData?.map((item) => (
                    <Radio key={item.id} value={item.id}>
                      {getStatusText(item)}
                    </Radio>
                  ))}
                </Space>
              </Radio.Group>
            </Form.Item>
          </Col>
          <Col xs={24} md={24} lg={14} xl={14}>
            <Txt ag="reg14">Progress (%)</Txt>
            <Row
              css={css`
                margin-top: 10px;
              `}
              gutter={60}
            >
              <Col xs={24} md={24} lg={24} xl={24}>
                <Row gutter={40}>
                  <Col xs={24} md={24} lg={24} xl={12}>
                    <Row gutter={40}>
                      <Col xs={24} md={24} lg={12} xl={12}>
                        <Form.Item
                          css={css`
                            .ant-form-item-label label {
                              height: 20px;
                            }
                          `}
                          rules={[{ required: !isDisables, message: 'กรุณาระบุค่า Plan' }]}
                          name="plan"
                          label="Plan"
                        >
                          <InputNumber
                            css={css`
                              width: 100%;
                            `}
                            disabled={isDisables}
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24} md={24} sm={24} lg={12} xl={12}>
                        <Form.Item
                          css={css`
                            .ant-form-item-label label {
                              height: 20px;
                            }
                          `}
                          rules={[{ required: !isDisables, message: 'กรุณาระบุค่า actual' }]}
                          name="actual"
                          label="Actual"
                        >
                          <InputNumber
                            css={css`
                              width: 100%;
                              background-color: ${colorInputNumberActual};
                              &:hover {
                                background-color: ${colorInputNumberActual};
                              }
                            `}
                            disabled={isDisables}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={24} md={24} lg={24} xl={12}>
                    <Row>
                      <Col span={24}>
                        <div
                          css={css`
                            display: grid;
                            justify-content: center;
                            align-items: center;
                            margin-top: 28px;
                            grid-template-columns: repeat(2, 1fr);
                            @media screen and (max-width: 1196px) {
                              margin-top: 0px;
                              margin-bottom: 24px;
                            }
                          `}
                        >
                          {progressColor.map((items) => {
                            return (
                              <span
                                key={items.id}
                                css={css`
                                  display: flex;
                                  align-items: center;
                                  justify-content: flex-start;
                                  text-align: center;
                                  gap: 10px;
                                  font-size: 14px;
                                `}
                              >
                                <ColorIndicator color={items.color} /> {items.description}
                              </span>
                            )
                          })}
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
              <Col span={24}>
                <Form.Item
                  css={css`
                    .ant-form-item-label label {
                      height: 20px;
                    }
                    .ant-input {
                      height: 150px;
                    }
                  `}
                  rules={[{ required: !isDisables, message: 'กรุณาระบุ Highlight Activities' }]}
                  name="highlightActivity"
                  label="Highlight Activities"
                >
                  <TextArea disabled={isDisables} placeholder="กรุณากรอกข้อความ" maxLength={1500} showCount />
                </Form.Item>
                <Form.Item
                  css={css`
                    .ant-form-item-label label {
                      height: 20px;
                    }
                    .ant-input {
                      height: 150px;
                    }
                  `}
                  rules={[{ required: !isDisables, message: 'กรุณาระบุ way forward' }]}
                  name="wayForward"
                  label="Way Forward"
                >
                  <TextArea disabled={isDisables} placeholder="กรุณากรอกข้อความ" maxLength={1500} showCount />
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
      <Flex
        css={css`
          padding: 50px 70px 0px 70px;
        `}
        gap={100}
      >
        <Form.Item name="updateBy" label="Update By">
          <Txt
            ag="med16"
            css={css`
              color: ${colors.primary};
            `}
          >
            {formUpdateBy ? formUpdateBy : '-'}
          </Txt>
        </Form.Item>
        <Form.Item name="updateDate" label="Update Date">
          <Txt ag="med16">{formUpdateDate ? formUpdateDate : '-'}</Txt>
        </Form.Item>
      </Flex>
    </>
  )
}
