import { MenuCodeEnum, PermissionActionEnum } from '@ptt-eia-web/constants'
import { css } from '@ptt-eia-web/helpers'
import {
  IAttachmentUploadFilePath,
  IUploadAttachmentParam,
  IUploadAttachmentResponse,
  useGetMeSuspenseQRY,
  useGetPTTRelationPeopleQTY,
  usePermission,
  usePostNewRequestMTT,
  useRelationHeadQTY,
  useUploadAttachmentMTT,
} from '@ptt-eia-web/services'
import { Button, Card, Flex, Form, notification } from 'antd'
import { useForm } from 'antd/es/form/Form'
import dayjs from 'dayjs'
import { first } from 'lodash'
import { useCallback, useEffect, useMemo } from 'react'
import { FaChevronLeft } from 'react-icons/fa'
import { Navigate, useNavigate, useSearchParams } from 'react-router-dom'

import { permissionConfigs } from '@frontend/app/permission-configs'
import { paths } from '@frontend/app/route/path-config'
import { HeaderTitle } from '@frontend/components/HeaderTitle'

import { LayoutStyle } from '../LayoutStyle'
import { IAttachType, IFormValueType, NEW_REQ_FORM_KEY, createInitFormValue } from '../helper'

import { Attachment } from './Attachment'
import { FormInfoDetail } from './FormInfoDetail'

const useGetServiceParams = (key: string): string | null => {
  const [searchParams] = useSearchParams()
  return searchParams.get(key)
}

const uploadAndNormalizeAttachData = async (
  uploadApi: (variables: IUploadAttachmentParam) => Promise<IUploadAttachmentResponse>,
  filePath: IAttachmentUploadFilePath,
  attachFiles?: IAttachType[],
) => {
  const data: IAttachType[] = []

  for (const v of attachFiles ?? []) {
    const { newFile, ...rest } = v
    const newData = { ...rest }
    if (newFile) {
      const { filePath: newPath } = await uploadApi({
        filePath,
        fileUpload: newFile,
      })
      newData.path = newPath
      v.path = newPath
      v.newFile = undefined
    }
    data.push(newData)
  }

  return data
}

export const PageServiceRequestNew = () => {
  const navigate = useNavigate()
  const [form] = useForm()
  const serviceTypeId = useGetServiceParams('serviceType')
  const subServiceTypeId = useGetServiceParams('subServiceType')
  const reportTypeId = useGetServiceParams('reportType')
  const projectTypeId = useGetServiceParams('projectType')

  const { data: currentUser, isLoading: isGetMeLoading } = useGetMeSuspenseQRY()
  const {
    data: relationPeople,
    isLoading: isRelationPeopleLoading,
    isError: isRelationPeopleError,
  } = useGetPTTRelationPeopleQTY()
  const { data: relationHead, isLoading: isRelationHeadLoading, isError: isRelationHeadError } = useRelationHeadQTY()
  const { mutateAsync: uploadFile, isPending: isUploadFilePending } = useUploadAttachmentMTT()
  const { mutateAsync: createRequest, isPending: isCreateRequestPending } = usePostNewRequestMTT()

  const { can } = usePermission(permissionConfigs)
  const canAdd = can(MenuCodeEnum.ServiceRequestDashboard, PermissionActionEnum.Add)

  const isLoading = useMemo(
    () =>
      isGetMeLoading ||
      isUploadFilePending ||
      isCreateRequestPending ||
      isRelationPeopleLoading ||
      isRelationHeadLoading ||
      isRelationPeopleError ||
      isRelationHeadError,
    [
      isCreateRequestPending,
      isGetMeLoading,
      isRelationHeadError,
      isRelationHeadLoading,
      isRelationPeopleError,
      isRelationPeopleLoading,
      isUploadFilePending,
    ],
  )

  useEffect(() => {
    const approver = first(relationHead)
    const newRelationPeople = relationPeople?.map((v) => {
      if (v.mdteamRole === 2) {
        return approver
      }
      return v
    })
    form.setFieldsValue(
      createInitFormValue(
        [NEW_REQ_FORM_KEY.REQ_USER_NAME, currentUser?.fullName],
        [NEW_REQ_FORM_KEY.REQ_DATE, dayjs().format('DD/MM/YYYY HH:mm')],
        [NEW_REQ_FORM_KEY.REQ_UNIT_NAME, currentUser?.unitName],
        [NEW_REQ_FORM_KEY.REQ_UNIT_CODE, currentUser?.unitCode],
        [NEW_REQ_FORM_KEY.APPROVER, approver?.empCode],
        [NEW_REQ_FORM_KEY.LIST_TB_SERVICE_REQ_TEAM, newRelationPeople],
        [NEW_REQ_FORM_KEY.SERVICE_TYPE_ID, serviceTypeId && Number(serviceTypeId)],
        [NEW_REQ_FORM_KEY.SUB_SERVICE_ID, subServiceTypeId && Number(subServiceTypeId)],
        [NEW_REQ_FORM_KEY.REPORT_TYPE_ID, reportTypeId && Number(reportTypeId)],
        [NEW_REQ_FORM_KEY.PROJECT_TYPE_ID, projectTypeId && Number(projectTypeId)],
      ),
    )
  }, [
    currentUser?.fullName,
    currentUser?.unitCode,
    currentUser?.unitName,
    form,
    projectTypeId,
    relationHead,
    relationPeople,
    reportTypeId,
    serviceTypeId,
    subServiceTypeId,
  ])

  const handleSaveDraftClick = useCallback(() => {
    form.setFieldValue(NEW_REQ_FORM_KEY.STATUS_ID, 99)
    form.submit()
  }, [form])

  const handleSaveClick = useCallback(() => {
    form.setFieldValue(NEW_REQ_FORM_KEY.STATUS_ID, 1)
    form.submit()
  }, [form])

  const handleSubmit = useCallback(
    async (value: IFormValueType) => {
      const { tempDateRange, tempReqDate, tempReqUserName, tempApproveName, tempOwnerName, ...submitValue } = value

      try {
        submitValue.listTbattachPipeLineInDto = await uploadAndNormalizeAttachData(
          uploadFile,
          'PipeLine',
          value.listTbattachPipeLineInDto,
        )
        submitValue.listTbattachConnectPointInDto = await uploadAndNormalizeAttachData(
          uploadFile,
          'ConnectPoint',
          value.listTbattachConnectPointInDto,
        )
        submitValue.listTbattachConstructInDto = await uploadAndNormalizeAttachData(
          uploadFile,
          'Construct',
          value.listTbattachConstructInDto,
        )
        submitValue.listTbattachOthersInDto = await uploadAndNormalizeAttachData(
          uploadFile,
          'Others',
          value.listTbattachOthersInDto,
        )
        const res = await createRequest(submitValue)
        notification.success({ message: 'Success' })

        // 99: save draft
        if (submitValue.tbserviceReqInDto.mdreqStatusId === 99) {
          navigate(
            paths.serviceRequestTracking({
              param: { id: res.tbserviceReqOutDto.id },
            }),
          )
        }
        // 1: save
        if (submitValue.tbserviceReqInDto.mdreqStatusId === 1) {
          navigate(-1)
        }
      } catch {
        // Do not thing
      }
    },
    [createRequest, navigate, uploadFile],
  )

  const handleGoBackClick = useCallback(() => {
    navigate(-1)
  }, [navigate])

  if (!canAdd) return <Navigate to="/404" />
  return (
    <LayoutStyle>
      <HeaderTitle titleFirst="New" titleSecond="Request" />
      <Form<IFormValueType> form={form} preserve scrollToFirstError onFinish={handleSubmit}>
        <Card loading={isLoading}>
          <FormInfoDetail serviceTypeId={Number(serviceTypeId)} subServiceTypeId={Number(subServiceTypeId)} />
        </Card>

        <Attachment serviceTypeId={Number(serviceTypeId)} subServiceTypeId={Number(subServiceTypeId)} />

        <Flex
          css={css`
            margin-top: 50px;
          `}
          justify="center"
          align="center"
          gap={15}
        >
          <Button
            css={css`
              background-color: #006b93;
              min-width: 100px;
            `}
            type="primary"
            loading={isLoading}
            onClick={handleSaveDraftClick}
          >
            Save Draft
          </Button>
          <Button
            css={css`
              min-width: 100px;
            `}
            type="primary"
            loading={isLoading}
            onClick={handleSaveClick}
          >
            Submit
          </Button>
          <Button
            icon={<FaChevronLeft />}
            css={css`
              min-width: 100px;
            `}
            onClick={handleGoBackClick}
            loading={isLoading}
          >
            Back
          </Button>
        </Flex>
      </Form>
    </LayoutStyle>
  )
}
