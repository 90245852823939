import { css, keyframes } from '@ptt-eia-web/helpers'
import { Tooltip } from 'antd'
import React, { useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'

import { colors } from '../../app/theme'

type DashBoardType = 'table' | 'chart'

const animationPath1 = keyframes`
  30% {
    transform: scaleY(1.8);
  }
  60% {
    transform: scaleY(1);
  }
`
const animationPath2 = keyframes`
  10% {
    transform: scaleY(1);
  }
  40% {
    transform: scaleY(1.5);
  }
  70% {
    transform: scaleY(1);
  }
`
const animationPath3 = keyframes`
  20% {
    transform: scaleY(1);
  }
  50% {
    transform: scaleY(1.2);
  }
  80% {
    transform: scaleY(1);
  }
`

const slide = keyframes`
  0% { transform: translate(14px, 0px) }
  25%{ transform: translate(0px, 0px)}
  50%{
    transform: translate(0px, 14px)
  }
  75%{
    transform: translate(14px, 14px) 
  }
  100% { transform: translate(14px, 0px) }
`

export const SwitchGraphToggle: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams({ tab: 'table' })
  const buttonStyle = css`
    width: 65px;
    height: 70px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    border-width: 0px;
    z-index: 1;
    box-shadow: 0px 5px 15px #acb2c1;
    cursor: pointer;
  `

  useEffect(() => {
    if (!searchParams.get('tab')) {
      setSearchParams({ tab: 'table' })
    }
  }, [searchParams, setSearchParams])

  const handleToggler = (tabName: DashBoardType) => {
    setSearchParams({ tab: tabName })
  }

  return (
    <div
      data-testid="project-dashboard-switch-graph"
      css={css`
        position: fixed;
        left: 0;
        top: 220px;
        width: 85px;
      `}
    >
      <Tooltip
        color="#002F41"
        title={<div css={css({ textAlign: 'center', marginTop: 6 })}>Dashboard</div>}
        placement="right"
      >
        <button
          onClick={() => handleToggler('table')}
          css={buttonStyle}
          style={{
            backgroundColor:
              searchParams.get('tab') === 'table' || !searchParams.get('tab') ? colors.secondary : colors.white,
          }}
        >
          <svg
            css={css`
              .path-1,
              .path-2,
              .path-3 {
                transform-origin: bottom;
                transform: scaleY(1);
              }

              .path-1 {
                animation: ${animationPath1} 1000ms cubic-bezier(0.24, 0.88, 0.55, 0.95);
                transform: scaleY(1.2);
                animation-iteration-count: infinite;
              }
              .path-2 {
                animation: ${animationPath2} 1000ms cubic-bezier(0.24, 0.88, 0.55, 0.95);
                animation-iteration-count: infinite;
              }
              .path-3 {
                animation: ${animationPath3} 1000ms cubic-bezier(0.24, 0.88, 0.55, 0.95);
                animation-iteration-count: infinite;
              }
            `}
            width="24px"
            height="24px"
            viewBox="0 0 24 24"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
              <g
                id="Icons-/-AJAX-Loader"
                fill={searchParams.get('tab') === 'table' ? 'white' : '#A7B0BA'}
                fill-rule="nonzero"
              >
                <path
                  className="path-1"
                  d="M4,18 L6,18 C6.55228475,18 7,18.4477153 7,19 L7,23 C7,23.5522847 6.55228475,24 6,24 L4,24 C3.44771525,24 3,23.5522847 3,23 L3,19 C3,18.4477153 3.44771525,18 4,18 Z"
                  id="Path"
                ></path>
                <path
                  className="path-2"
                  d="M11,12 L13,12 C13.5522847,12 14,12.4477153 14,13 L14,23 C14,23.5522847 13.5522847,24 13,24 L11,24 C10.4477153,24 10,23.5522847 10,23 L10,13 C10,12.4477153 10.4477153,12 11,12 Z"
                  id="Path"
                ></path>
                <path
                  className="path-3"
                  d="M17,23 L17,6 C17,5.44771525 17.4477153,5 18,5 L20,5 C20.5522847,5 21,5.44771525 21,6 L21,23 C21,23.5522847 20.5522847,24 20,24 L18,24 C17.4477153,24 17,23.5522847 17,23 Z"
                  id="Path"
                ></path>
              </g>
            </g>
          </svg>
        </button>
      </Tooltip>
      <Tooltip
        color="#002F41"
        title={<div css={css({ textAlign: 'center', marginTop: 6 })}>Data Table</div>}
        placement="right"
      >
        <button
          onClick={() => handleToggler('chart')}
          css={buttonStyle}
          style={{ backgroundColor: searchParams.get('tab') === 'chart' ? colors.secondary : colors.white }}
        >
          <div
            css={css`
              position: relative;
              width: 24px;
              height: 24px;
              margin: auto;
            `}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill={searchParams.get('tab') === 'chart' ? 'white' : '#A7B0BA'}
              viewBox="0 0 16 16"
            >
              <path d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2zm15 2h-4v3h4zm0 4h-4v3h4zm0 4h-4v3h3a1 1 0 0 0 1-1zm-5 3v-3H6v3zm-5 0v-3H1v2a1 1 0 0 0 1 1zm-4-4h4V8H1zm0-4h4V4H1zm5-3v3h4V4zm4 4H6v3h4z" />
            </svg>
            <svg
              css={css`
                position: absolute;
                left: 0;
                width: 16px;
                animation: ${slide} 3s infinite alternate;
              `}
              xmlns="http://www.w3.org/2000/svg"
              fill={searchParams.get('tab') === 'chart' ? 'white' : '#A7B0BA'}
              viewBox="0 0 16 16"
            >
              <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0" />
            </svg>
          </div>
        </button>
      </Tooltip>
    </div>
  )
}
