import { AnyObject } from 'antd/es/_util/type'
import { HTMLAttributes, useEffect } from 'react'

interface IImageProps extends HTMLAttributes<HTMLImageElement> {
  id: string
  alt: string
  url: string
  width?: number
  height?: number
}
export const SafeImage = ({ alt, id, url, ...restProps }: IImageProps) => {
  useEffect(() => {
    const imgElement = document.getElementById(id) as AnyObject
    if (imgElement) {
      imgElement.src = encodeURI(url)
    }
  }, [id, url])
  return <img id={id} alt={alt} {...restProps} />
}
