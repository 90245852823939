import { MenuCodeEnum, PermissionActionEnum } from '@ptt-eia-web/constants'
import { css, getCSPNonce } from '@ptt-eia-web/helpers'
import { usePermission } from '@ptt-eia-web/services'
import { Button, ConfigProvider } from 'antd'
import React from 'react'

import { permissionConfigs } from '@frontend/app/permission-configs'

import { colors } from '../../../app/theme'
import { Title } from '../Title'

interface IHeaderProps {
  onClickNewProject: () => void
}
export const Header = ({ onClickNewProject }: IHeaderProps) => {
  const { can } = usePermission(permissionConfigs)
  const canAdd = can(MenuCodeEnum.ProjectStatusUpdate, PermissionActionEnum.Add)
  return (
    <div
      css={css`
        position: relative;
      `}
    >
      <Title titleFirst="Project" titleSecond="Status Update" />
      {canAdd && (
        <ConfigProvider
          csp={{ nonce: getCSPNonce() }}
          theme={{
            token: {
              colorPrimary: `${colors.white}`,
              colorBorder: `#2ebca2`,
              colorBgContainer: `#2ebca2`,
            },
          }}
        >
          <Button
            onClick={onClickNewProject}
            css={css`
              width: 150px;
              position: absolute;
              right: 0px;
              top: 18px;
              font-size: 54px;
              color: ${colors.white};
              font-weight: 900;
              background-color: #2ebca2;
              border-color: #2ebca2;
            `}
          >
            New Project
          </Button>
        </ConfigProvider>
      )}
    </div>
  )
}
