import { css, getCSPNonce } from '@ptt-eia-web/helpers'
import { Button, ConfigProvider } from 'antd'
import { IoIosArrowBack } from 'react-icons/io'

import { colors } from '@frontend/app/theme'
export interface IPrevArrowButtonProps {
  onClick?: () => void
}

export const PrevArrowButton = (props: IPrevArrowButtonProps) => {
  return (
    <ConfigProvider
      csp={{ nonce: getCSPNonce() }}
      theme={{
        token: {
          colorPrimary: `${colors.secondary}`,
          borderRadius: 2,
        },
      }}
    >
      <Button
        onClick={props.onClick}
        shape="circle"
        css={css`
          position: absolute;
          left: -50px;
          top: 50%;
          margin-top: -30px;
          height: 50px;
          width: 50px;
          box-shadow: 10px 10px 50px #00000027;
          border-radius: 100%;
          border: 2px solid ${colors.secondary};
        `}
      >
        <div
          data-testid="icon-prev-arrow"
          css={css`
            border-radius: 100%;
            width: 100%;
            height: 100%;
            position: absolute;
            background-color: #f7fbed;
            display: flex;
            align-items: center;
            justify-content: center;
            color: ${colors.secondary};
            &:hover {
              color: ${colors.white};
              background-color: ${colors.secondary};
            }
          `}
        >
          <IoIosArrowBack size={30} />
        </div>
      </Button>
    </ConfigProvider>
  )
}
