import { css } from '@ptt-eia-web/helpers'
import { Col, Row } from 'antd'
import React from 'react'

import { colors } from '../../app/theme'
import { Txt } from '../../components/Txt'

interface ITitleProps {
  titleFirst?: string
  titleSecond?: string
}

export const Title: React.FC<ITitleProps> = (props) => {
  const { titleFirst, titleSecond } = props
  if (!titleFirst || !titleSecond) {
    return null
  }
  return (
    <Row
      css={css`
        display: flex;
        flex: 1;
        justify-content: center;
        align-items: center;
        padding: 18px 0px 26px 0px;
      `}
    >
      <Col
        css={css`
          display: flex;
          gap: 8px;
        `}
      >
        <Txt
          ag="med30"
          css={css`
            color: ${colors.primary};
          `}
        >
          {titleFirst}
        </Txt>
        <Txt ag="med30">{titleSecond}</Txt>
      </Col>
    </Row>
  )
}
