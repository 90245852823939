import { RightOutlined } from '@ant-design/icons'
import styled from '@emotion/styled'
import { SafeImage } from '@ptt-eia-web/components'
import { css, getFullUrl } from '@ptt-eia-web/helpers'
import { IPRNewsResponse } from '@ptt-eia-web/services'
import { Button, Flex } from 'antd'
import dayjs from 'dayjs'
import 'dayjs/locale/th'
import buddhistEra from 'dayjs/plugin/buddhistEra'
import { Link } from 'react-router-dom'

import { colors } from '../../app/theme'
import { Txt } from '../../components/Txt'

dayjs.extend(buddhistEra)

interface IPrNewsCardProps {
  prNews: IPRNewsResponse
}

const LayoutWrapTextStyle = styled.div`
  margin-top: 16px;
  overflow: hidden;
  line-height: 1.5em;
  max-height: 3em;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  font-size: 18px;
  align-self: baseline;
`

const CoverImageContent = styled.div`
  display: flex;
  justify-content: center;
  align-self: center;
  width: 420px;
  height: 250px;
  padding: 0px 8px;
  transition: all 0.3s;
  :hover {
    transform: scale(1.15);
  }
`

export const dateShortTH = (date: string) => {
  if (!dayjs(date).isValid()) {
    return ''
  }
  dayjs.locale('th')
  return dayjs(date).format('DD MMM BB')
}

export const PrNewsCard = (props: IPrNewsCardProps) => {
  const { prNews } = props
  return (
    <Flex
      vertical
      css={css`
        margin-top: 24px;
        align-content: center;
        align-items: center;
      `}
    >
      <Flex
        vertical
        align="center"
        css={css`
          width: 420px;
        `}
      >
        <CoverImageContent>
          <SafeImage
            id={`pr_news_cover_image_${prNews.id}`}
            url={getFullUrl(prNews.filePath)}
            alt={prNews.fileName}
            css={css`
              object-fit: cover;
              width: 100%;
              max-width: 100%;
            `}
          />
        </CoverImageContent>
      </Flex>
      <Flex
        vertical
        align="left"
        css={css`
          padding: 0px 16px;
          width: 420px;
          margin-top: 8px;
        `}
      >
        <LayoutWrapTextStyle> {prNews.title}</LayoutWrapTextStyle>
        <Txt
          ag="med14"
          color="primary"
          css={css`
            margin-top: 8px;
          `}
        >
          {dateShortTH(prNews.startDate)}
        </Txt>
        <Link to={`/pr-news/${encodeURIComponent(prNews.id.toString())}`}>
          <div
            css={css`
              position: relative;
              margin-top: 16px;
              :hover {
                .secondary-button {
                  transform: translate3d(0, 0, 0);
                }
              }
            `}
          >
            <Flex
              css={css`
                overflow: hidden;
                height: 36px;
                position: absolute;
                top: 0px;
                border-radius: 36px;
              `}
            >
              <Flex
                className="secondary-button"
                css={css`
                  border-radius: 36px;
                  background: ${colors.secondary};
                  padding: 0 16px 0 ${16 + 30}px;
                  transform: translate3d(-100%, 0, 0);
                  transition: transform 175ms ease;
                `}
                align="center"
              >
                <Txt css={css``} ag="light12" color="white">
                  Read more
                </Txt>
              </Flex>
            </Flex>

            <Button
              shape="circle"
              css={css`
                border: unset;
                box-shadow: 1px 0 0 0 ${colors.white};
                background-color: ${colors.secondary};
                cursor: pointer;
                min-width: 36px !important;
                height: 36px !important;
                :hover {
                  border-color: ${colors.white} !important;
                }
              `}
            >
              <RightOutlined
                css={css`
                  color: ${colors.pageText};
                `}
              />
            </Button>
          </div>
        </Link>
      </Flex>
    </Flex>
  )
}
