import { css } from '@ptt-eia-web/helpers'
import { IProjectDashboardDtOutDto, IProjectDashboardOutDto } from '@ptt-eia-web/services'
import { Table } from 'antd'
import React, { useEffect, useMemo } from 'react'

import { SPAN_HEADER_CLASS, colors } from '../../../app/theme'

import { CellEiaHighlightActivities } from './CellEiaHighlightActivities'

import type { ColumnsType } from 'antd/es/table'

interface DataType {
  key: string | undefined
  mddashboardStatusId: number | undefined
  plan: number | undefined
  actual: number | undefined
  project: string | undefined
  highlightActivity: string | undefined
  wayForward: string | undefined
  planActualColors: string | undefined
}

const columnTitleStyles = css`
  font-weight: 900;
  font-size: 16px;
  text-align: center;
`
const columnTitleWhiteTextStyles = css`
  font-weight: 900;
  font-size: 16px;
  text-align: center;
  align-self: center;
  color: ${colors.white};
`

const generateData = (_: string, subItem: IProjectDashboardOutDto, dtItem: IProjectDashboardDtOutDto) => {
  return {
    key: String(subItem.id),
    mddashboardStatusId: dtItem.mdreportStatusId,
    project: subItem.projectName,
    plan: dtItem.progressPlan,
    actual: dtItem.progressActual,
    highlightActivity: dtItem.highlightActivity,
    wayForward: dtItem.wayForward,
    planActualColors: dtItem.progressActualColor,
  }
}

const renderSubHeaderRow = (text: string, record: DataType) => (
  <div
    css={css`
      height: 100%;
      align-self: center;
      text-align: ${isNaN(Number(record.key)) ? 'left' : 'center'};
      font-size: ${isNaN(Number(record.key)) ? '16px' : '14px'};
      font-weight: ${isNaN(Number(record.key)) ? 'bold' : 'normal'};
    `}
  >
    {text}
  </div>
)

export interface IRemarkTableDataProps {
  dataSource: IProjectDashboardOutDto[] | undefined
  typeTable: string
}

const spanCol = (record: DataType) => {
  return isNaN(Number(record.key)) ? 0 : 1
}

export const RemarkTableData = (props: IRemarkTableDataProps) => {
  const [dataList, setDataList] = React.useState<DataType[]>([])

  const generateColumn = (title: string, dataIndex: string, width: number) => {
    return {
      title: () => <p css={columnTitleStyles}>{title}</p>,
      dataIndex,
      width,
      onHeaderCell: () => ({
        className: SPAN_HEADER_CLASS,
      }),
      onCell: (record: DataType) => ({
        colSpan: spanCol(record),
        style: {
          backgroundColor: record.mddashboardStatusId === parseInt(title) ? '#315496' : 'transparent',
        },
      }),
      render: (_: number) => '',
    }
  }

  const columns: ColumnsType<DataType> = useMemo(() => {
    const distributionColumns = Array.from({ length: 7 }, (_, index) =>
      generateColumn((index + 1).toString(), 'mddashboardStatusId', 5),
    )

    return [
      {
        title: () => <p css={columnTitleStyles}>NO.</p>,
        dataIndex: 'key',
        width: 6,
        onCell: (record) => ({ colSpan: isNaN(Number(record.key)) ? 12 : 1 }),
        render: renderSubHeaderRow,
        onHeaderCell: () => ({
          className: SPAN_HEADER_CLASS,
        }),
      },
      {
        title: () => <p css={columnTitleStyles}>PROJECT</p>,
        dataIndex: 'project',
        width: 50,
        onCell: (record) => ({ colSpan: spanCol(record) }),
        render: (text) => (
          <p
            css={css`
              white-space: pre-wrap;
              font-size: 14px;
              align-self: center;
            `}
          >
            {text}
          </p>
        ),
        onHeaderCell: () => ({
          className: SPAN_HEADER_CLASS,
        }),
      },
      {
        title: () => <p css={columnTitleWhiteTextStyles}>EIA REPORT STATUS</p>,
        onHeaderCell: () => ({ style: { background: '#006B93', height: '20px', className: SPAN_HEADER_CLASS } }),
        onCell: (record) => ({ colSpan: spanCol(record) }),
        children: distributionColumns,
      },
      {
        title: () => <p css={columnTitleWhiteTextStyles}>PROGRESS(%)</p>,
        onHeaderCell: () => ({ style: { background: colors.primary, height: 26, className: SPAN_HEADER_CLASS } }),
        onCell: (record) => ({ colSpan: spanCol(record) }),
        children: [
          {
            title: () => <p css={columnTitleStyles}>PLAN</p>,
            onHeaderCell: () => ({
              style: { height: 26, className: SPAN_HEADER_CLASS },
            }),
            onCell: (record) => ({ colSpan: spanCol(record) }),
            width: 15,
            dataIndex: 'plan',
            render: (value, record) => (
              <p
                css={css`
                  text-align: center;
                `}
              >
                {value}
              </p>
            ),
          },
          {
            title: () => <p css={columnTitleStyles}>ACTUAL</p>,
            onHeaderCell: () => ({
              style: { height: 26, className: SPAN_HEADER_CLASS },
            }),
            width: 15,
            dataIndex: 'actual',
            onCell: (record) => ({
              colSpan: spanCol(record),
              style: { backgroundColor: record.planActualColors !== '' ? record.planActualColors : 'transparent' },
            }),
            render: (value, record) => (
              <p
                css={css`
                  color: ${record.planActualColors !== '' ? '#393a2c' : ''};
                  text-align: center;
                `}
              >
                {value}
              </p>
            ),
          },
        ],
      },
      {
        title: () => <p css={columnTitleStyles}>REMARK</p>,
        width: 50,
        onHeaderCell: () => ({
          className: SPAN_HEADER_CLASS,
        }),
        onCell: (record) => ({ colSpan: spanCol(record) }),
        render: (_, record) => (
          <CellEiaHighlightActivities highlightActivity={record.highlightActivity} wayForward={record.wayForward} />
        ),
      },
    ]
  }, [])

  useEffect(() => {
    const newList: DataType[] = []
    let rowNum = 0
    const sortedDataSource = props.dataSource?.sort((a, b) => {
      return a.mdreportTypeEIA_ER.localeCompare(b.mdreportTypeEIA_ER)
    })
    sortedDataSource?.forEach((item) => {
      item.projectDashboardDtOutDtos?.forEach((dtItem) => {
        rowNum = rowNum + 1
        newList.push({ ...generateData(props.typeTable, item, dtItem), key: rowNum.toString() })
      })
    })
    setDataList(newList)
  }, [props])

  return (
    <Table
      css={css`
        .ant-table-tbody tr.sub-header-row {
          color: ${colors.black};
          background-color: ${colors.subHeaderBg} !important;
        }
        .ant-table-tbody {
          tr:nth-of-type(even) {
            background-color: white;
            .ant-table-cell-fix-right {
              background-color: white;
            }
          }
        }
      `}
      columns={columns}
      pagination={false}
      virtual
      bordered
      dataSource={dataList}
      rowKey="key"
      size="small"
      rowClassName={(record) => (isNaN(Number(record.key)) ? 'sub-header-row' : '')}
    />
  )
}
