import { css } from '@ptt-eia-web/helpers'
import React from 'react'

import { colors } from '@frontend/app/theme'

import { FormInput } from '../FormInput'
import { SecondForm } from '../SecondForm'

import { EnvComplianceAndMonitoring } from './EnvComplianceAndMonitoring'

export const DistributionPipeline = () => {
  return (
    <div
      css={css`
        height: 100%;
        padding-bottom: 60px;
        background-color: white;
        border-radius: 15px;
        border: 1px solid ${colors.line};
        box-shadow: 0px 2px 6px #0000000a;
      `}
    >
      <FormInput />
      <SecondForm />
      <EnvComplianceAndMonitoring />
    </div>
  )
}
