import styled from '@emotion/styled'
import { SafeImage } from '@ptt-eia-web/components'
import { css, getFullUrl, mediaQuery, secureRandomInt } from '@ptt-eia-web/helpers'
import { IStringOrNull } from '@ptt-eia-web/services'
import { Flex } from 'antd'

import { colors } from '@frontend/app/theme'
import { Txt } from '@frontend/components/Txt'

const MissionWrapper = styled.div`
  height: 100%;
  min-height: 260px;
  background-color: ${colors.primary};
  padding: 0 40px;
  @media ${mediaQuery.xlGte} {
    padding: 0 80px;
  }
`
const MissionContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  min-height: 260px;
  max-width: 1600px;
  margin: 0 auto;
  padding-top: 140px;
`
const MissionImageContainer = styled.div`
  padding: 40px;
  img {
    max-width: 680px;
    border-radius: 4px;
    box-shadow: 0 1px 1px hsl(0deg 0% 0% / 0.075), 0 2px 2px hsl(0deg 0% 0% / 0.075), 0 4px 4px hsl(0deg 0% 0% / 0.075),
      0 8px 8px hsl(0deg 0% 0% / 0.075), 0 16px 16px hsl(0deg 0% 0% / 0.075);
  }
`
interface ISectionMissionProps {
  text: string
  imageUrl: IStringOrNull
}
export const SectionMission = ({ text, imageUrl }: ISectionMissionProps) => {
  return (
    <MissionWrapper>
      <MissionContent>
        <Flex>
          <div
            css={css`
              min-width: 250px;
            `}
          >
            <Txt ag="med30" color="darkBlue">
              Mission
            </Txt>
          </div>
          <div>
            {text.split('\n').map((item) => (
              <div key={secureRandomInt({ min: 100, max: 999 })}>
                <Txt ag="med24" color="white">
                  {item}
                </Txt>
              </div>
            ))}
          </div>
        </Flex>
        <MissionImageContainer>
          {imageUrl ? <SafeImage id="section_mission" alt="mission" url={getFullUrl(imageUrl)} /> : null}
        </MissionImageContainer>
      </MissionContent>
    </MissionWrapper>
  )
}
