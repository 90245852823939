import styled from '@emotion/styled'
import { MenuCodeEnum, PermissionActionEnum } from '@ptt-eia-web/constants'
import { usePermission } from '@ptt-eia-web/services'
import React, { useEffect } from 'react'
import { Navigate, useSearchParams } from 'react-router-dom'

import { permissionConfigs } from '@frontend/app/permission-configs'

import BgPieChartDashboard from '../../../assets/project-dashboard/bg-pie-dashboard.jpeg'

import { ProjectListCard } from './project-list/ProjectListCard'
import { UpdateHistoryDashboard } from './update-history/UpdateHistoryDashboard'

const LayoutStyle = styled.div`
  width: 100%;
  height: 100%;
  padding: 10px 64px;
  background-image: url(${BgPieChartDashboard});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
`
export const PageProjectUpdateStatus: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const { can } = usePermission(permissionConfigs)
  const canView = can(MenuCodeEnum.ProjectStatusUpdate, PermissionActionEnum.View)

  useEffect(() => {
    setSearchParams(searchParams)
  }, [searchParams, setSearchParams])

  if (!canView) return <Navigate to="/404" />
  return <LayoutStyle>{searchParams.get('id') === null ? <ProjectListCard /> : <UpdateHistoryDashboard />}</LayoutStyle>
}
