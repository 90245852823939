import { AppModal } from '@ptt-eia-web/components'
import { css } from '@ptt-eia-web/helpers'
import { IProjectTeamResponse, IUpdateRequestStatusParams, usePostUpdateRequestStatusMTT } from '@ptt-eia-web/services'
import { Button, Card, Flex, Space } from 'antd'
import { useCallback } from 'react'

import { colors } from '../../../app/theme'
import { Txt } from '../../../components/Txt'

import { ApproveModalPageList } from './ApproveModalPageList'

export interface IAssignTeamModalProps {
  requestId?: string
  projectTeams?: IProjectTeamResponse[]
  isOpen: boolean
  onClose: () => void
  onClickAdd: () => void
  mdRole5Added: boolean
}

export const AssignTeamModal = ({
  projectTeams = [],
  isOpen,
  onClose,
  onClickAdd,
  requestId = 'N/A',
  mdRole5Added,
}: IAssignTeamModalProps) => {
  const { mutateAsync: updateRequestStatus, isPending } = usePostUpdateRequestStatusMTT(requestId)

  const handleCloseModal = useCallback(() => {
    onClose()
  }, [onClose])

  const handleSubmit = useCallback(async () => {
    const params: IUpdateRequestStatusParams = {
      mdreqStatusId: 8,
      tbserviceReqId: Number(requestId),
    }
    await updateRequestStatus(params)
    handleCloseModal()
  }, [handleCloseModal, requestId, updateRequestStatus])

  return (
    <AppModal
      width={1200}
      css={css`
        .ant-modal-body {
          padding: 12px 36px;
        }
        .ant-modal-footer {
          .ant-btn-primary {
            background-color: ${colors.secondary};
          }
        }
      `}
      title={
        <Txt ag="med18" color="white">
          Approve/Assign
        </Txt>
      }
      centered
      open={isOpen}
      cancelText="Close"
      onCancel={handleCloseModal}
      footer={(_, { OkBtn }) => {
        return (
          <Flex gap={12}>
            <Button
              css={css`
                width: 100px;
              `}
              type="primary"
              onClick={handleSubmit}
              disabled={!mdRole5Added}
              loading={isPending}
            >
              Approve
            </Button>
            <Button
              css={css`
                width: 100px;
              `}
              type="default"
              onClick={handleCloseModal}
            >
              Close
            </Button>
          </Flex>
        )
      }}
    >
      <Card>
        <Space
          css={css`
            width: 100%;
          `}
          size={16}
          direction="vertical"
        >
          <ApproveModalPageList
            requestId={requestId}
            onClickAdd={onClickAdd}
            projectTeams={projectTeams}
            canDeleteRoleIds={[5]}
          />
        </Space>
      </Card>
    </AppModal>
  )
}
