import { useGetEmployeeDataNoJobGroupQRY } from '@ptt-eia-web/services'
import { Input } from 'antd'
import { useCallback, useState } from 'react'

import { ISearchUserFormValue, SearchUserModal } from '../../../components/SearchUserModal'

interface ISelectOwnerInputProps {
  editable?: boolean
  value?: string
  onChange?: (value: string) => void
  placeholder?: string
}

export const SelectOwnerInput = (props: ISelectOwnerInputProps) => {
  const { editable = true, value, placeholder, onChange } = props
  const [open, setOpen] = useState(false)
  const { data: employeeData } = useGetEmployeeDataNoJobGroupQRY({ EmployeeID: value, Limit: 1 }, { enabled: !!value })

  const handleInputClick = useCallback(() => {
    setOpen(true)
  }, [])
  const handleModalCancel = useCallback(() => {
    setOpen(false)
  }, [])
  const handleModalOk = useCallback(
    (val: ISearchUserFormValue) => {
      onChange?.(val.employeeId)
      setOpen(false)
    },
    [onChange],
  )

  return (
    <>
      <Input
        readOnly
        disabled={!editable}
        onClick={handleInputClick}
        value={employeeData?.[0].employeeNameTH}
        placeholder={placeholder}
      />

      <SearchUserModal
        open={open}
        onCancel={handleModalCancel}
        onOk={handleModalOk}
        title="ผู้รับผิดชอบโครงการ"
        width={800}
      />
    </>
  )
}
