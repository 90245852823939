import { IUpdateRequestStatusParams, usePostUpdateRequestStatusMTT } from '@ptt-eia-web/services'
import { useCallback } from 'react'
import { useParams } from 'react-router-dom'

import { AddReasonBeforeSubmitModal } from '@frontend/modules/service-request-tracking/AddReasonBeforeSubmitModal'

interface IConfirmRecallModalProps {
  isOpen: boolean
  onClose: () => void
}

export const ConfirmRecallModal = ({ isOpen, onClose }: IConfirmRecallModalProps) => {
  const { id = 'N/A' } = useParams()
  const { mutateAsync: updateRequestStatus } = usePostUpdateRequestStatusMTT(id)

  const handleCloseModal = useCallback(() => {
    onClose()
  }, [onClose])

  const handleSubmit = useCallback(
    async (reason: string) => {
      const params: IUpdateRequestStatusParams = {
        mdreqStatusId: 13,
        tbserviceReqId: Number(id),
        reason,
      }
      await updateRequestStatus(params)
    },
    [id, updateRequestStatus],
  )

  return (
    <AddReasonBeforeSubmitModal
      title="กรุณาระบุเหตุผลการยืนยัน Recall"
      isOpen={isOpen}
      onClose={handleCloseModal}
      onSubmit={handleSubmit}
    />
  )
}
