import styled from '@emotion/styled'
import { css } from '@ptt-eia-web/helpers'
import { Layout } from 'antd'
import { PropsWithChildren } from 'react'

import { themeConfig } from '../theme'

import { Footer } from './Footer'
import { HeaderBottomLine } from './HeaderBottomLine'
import { Navbar } from './Navbar'

const Content = styled.div`
  margin-top: 125px;
  display: flex;
  flex-flow: column;
`

export const MainLayout = (props: PropsWithChildren) => {
  const { children } = props
  return (
    <Layout data-testid="app-layout">
      <div
        css={css`
          position: fixed;
          right: 0;
          left: 0;
          z-index: 1000;
        `}
      >
        <Navbar />
        <HeaderBottomLine />
      </div>
      <Content>
        <div
          css={css`
            min-height: calc(100vh - ${themeConfig.headerHeight + themeConfig.footerHeight}px);
          `}
        >
          {children}
        </div>
        <Footer />
      </Content>
    </Layout>
  )
}
