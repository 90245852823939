import { css, getColumnNumber } from '@ptt-eia-web/helpers'
import { IProjectTeamResponse } from '@ptt-eia-web/services'
import { Button, Card, Flex } from 'antd'
import Table, { ColumnType } from 'antd/es/table'
import { useCallback, useMemo, useState } from 'react'
import { GoEye, GoPlus } from 'react-icons/go'

import { colors } from '../../../app/theme'
import { IconButtonCss } from '../../../assets/css/IconButtonCss'
import { Txt } from '../../../components/Txt'

import { AddMemberModal } from './AddMemberModal'
import { EditMemberModal } from './EditMemberModal'

const canEditTeam = (mdTeamRoleId?: number) => {
  return mdTeamRoleId === undefined ? false : ![1, 6, 7].includes(mdTeamRoleId)
}

interface IProjectTeamTableProps {
  data: IProjectTeamResponse[]
  canManageTeams: boolean
  canSaveChange?: boolean
  isRequester?: boolean
}

export const ProjectTeamTable = ({
  data,
  canManageTeams,
  canSaveChange = false,
  isRequester = false,
}: IProjectTeamTableProps) => {
  const [isAddModalOpen, setIsAddModalOpen] = useState(false)
  const [isEditModalOpen, setIsEditModalOpen] = useState(false)

  const [selectedProjectTeam, setSelectedProjectTeam] = useState<IProjectTeamResponse | undefined>(undefined)

  const projectTeamEmployeeCodes = useMemo(
    () => data.filter((item) => item.mdteamRole !== 1).map((item) => item.empCode),
    [data],
  )

  const handleClickOpenAddModal = useCallback(() => {
    setIsAddModalOpen(true)
  }, [])

  const handleCloseAddModal = useCallback(() => {
    setIsAddModalOpen(false)
  }, [])

  const handleClickOpenEditModal = useCallback((record: IProjectTeamResponse) => {
    setSelectedProjectTeam(record)
    setIsEditModalOpen(true)
  }, [])

  const handleCloseEditModal = useCallback(() => {
    setSelectedProjectTeam(undefined)
    setIsEditModalOpen(false)
  }, [])

  const columns = useMemo(() => {
    let cols: ColumnType<IProjectTeamResponse>[] = [
      {
        title: 'No.',
        width: 50,
        render: (value, record, index) => <Txt ag="light16">{getColumnNumber(data, record)}</Txt>,
      },
      { title: 'ROLE', width: 200, dataIndex: 'mdteamRoleDisplay' },
      { title: 'NAME', width: 200, dataIndex: 'fullname' },
      { title: 'POSITION', width: 200, dataIndex: 'posName' },
      { title: 'MODIFY BY', width: 200, dataIndex: 'modifyByDisplay' },
      { title: 'MODIFY DATE', width: 200, dataIndex: 'modifyDateDisplay' },
    ]
    if (canManageTeams || canSaveChange)
      cols = [
        ...cols,
        {
          title: 'View',
          width: 50,
          render: (value, record) => {
            return (
              <GoEye
                onClick={() => handleClickOpenEditModal(record)}
                size={16}
                css={IconButtonCss({ color: colors.gray, hoverColor: colors.primary })}
              />
            )
          },
        },
      ]
    return cols
  }, [canManageTeams, canSaveChange, data, handleClickOpenEditModal])

  const existedTeamRole = useMemo(() => data.map((team) => team.mdteamRole), [data])

  const addAcceptedIds = useMemo(() => {
    let ids: number[] = [2, 3]
    if (canSaveChange) ids = [3, 4, 5]
    if (selectedProjectTeam?.mdteamRole) ids = [...ids, selectedProjectTeam?.mdteamRole]
    return ids.filter((id) => id === 5 || !existedTeamRole.includes(id))
  }, [canSaveChange, existedTeamRole, selectedProjectTeam?.mdteamRole])

  const editAcceptedIds = useMemo(() => {
    let ids: number[] = [2, 3].filter((id) => !existedTeamRole.includes(id))
    if (selectedProjectTeam?.mdteamRole) ids = [...ids, selectedProjectTeam?.mdteamRole]
    return ids
  }, [existedTeamRole, selectedProjectTeam?.mdteamRole])

  const isShowAddMember = useMemo(
    () => addAcceptedIds.length > 0 && (canSaveChange || canManageTeams),
    [addAcceptedIds.length, canManageTeams, canSaveChange],
  )

  return (
    <Card data-testid="card-project-team-component">
      <Flex
        css={css`
          margin-bottom: 44px;
        `}
        justify="space-between"
      >
        <Flex gap={8}>
          <Txt ag="med18" color="primary">
            Project
          </Txt>
          <Txt ag="med18" color="darkBlue">
            Team
          </Txt>
        </Flex>
        {isShowAddMember && (
          <Button data-testid="btn-add-member" onClick={handleClickOpenAddModal} type="primary" icon={<GoPlus />}>
            Add Member
          </Button>
        )}
      </Flex>
      <Table rowKey="id" columns={columns} dataSource={data} />
      <AddMemberModal
        title="+ Add Project Team"
        isOpen={isAddModalOpen}
        onClose={handleCloseAddModal}
        projectTeamEmployeeCodes={projectTeamEmployeeCodes}
        acceptedIds={addAcceptedIds}
      />
      <EditMemberModal
        isOpen={isEditModalOpen}
        onClose={handleCloseEditModal}
        projectTeam={selectedProjectTeam}
        editable={canManageTeams && canEditTeam(selectedProjectTeam?.mdteamRole) && !isRequester}
        acceptedIds={editAcceptedIds}
        canSaveChange={canSaveChange}
      />
    </Card>
  )
}
