import { MenuCodeEnum } from './menu-constant'

export enum PermissionIdEnum {
  ServiceRequest = 1,
  ProjectData = 2,
  AnnualReport = 3,
}

export enum PermissionActionEnum {
  View,
  Add,
  Edit,
  EditAll,
  Delete,
}

export enum RoleIdEnum {
  SystemAdmin = 1,
  PowerUser = 2,
  GuestUser = 3,
}

export interface IRolePermission {
  roleId: RoleIdEnum
  permissionId?: PermissionIdEnum
}

export interface IPermissionConfig {
  menuCode: MenuCodeEnum
  actions: {
    [key in PermissionActionEnum]?: IRolePermission[]
  }
}
