import styled from '@emotion/styled'

import { themeConfig } from '../../app/theme'
import BgPieChartDashboard from '../../assets/project-dashboard/bg-pie-dashboard.jpeg'

export const LayoutStyle = styled.div`
  width: 100%;
  min-height: calc(100vh - ${themeConfig.headerHeight + themeConfig.footerHeight}px);
  background-image: url(${BgPieChartDashboard});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding: 0 64px 30px;
  position: relative;
`
