import { css, keyframes } from '@ptt-eia-web/helpers'

import pttLogo from './ptt-logo.svg'

const logoAnimation = keyframes`
  0%, 50%, 100% {
    opacity: 0.25;
  }
  25%, 75% {
    opacity: 1;
  }
`

export const LoadingPage = () => {
  return (
    <div
      css={css`
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100vh;
        flex-direction: column;
      `}
    >
      <img
        css={css`
          animation: ${logoAnimation} 2.5s linear infinite;
        `}
        width={150}
        src={pttLogo}
        alt="loading-logo"
      />
    </div>
  )
}
