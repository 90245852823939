import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'

import { ATTACHMENT_UPLOAD_TIMEOUT } from '../attachment/attachment-service'
import { pttClient } from '../ptt-client'
import { IResponseWithData } from '../services-types'

import {
  ICreateAboutUsPEMDParams,
  ICreateAboutUsTeamParams,
  IUpdateAboutUsPEMDParams,
  IUpdateAboutUsParams,
  IUpdateAboutUsTeamSeqParams,
} from './about-us-params'
import { IAboutUsPEMDProfileResponse, IAboutUsResponse, IAboutUsTeamResponse } from './about-us-response'

const ABOUT_US_URL = '/AboutUs/AboutUs'

const ABOUT_US_TEAM_LIST_URL = '/AboutUs/AboutUsTeamList'
const ABOUT_US_TEAM_URL = '/AboutUs/AboutUsTeam'

const ABOUT_US_PEMD_LIST_URL = '/AboutUs/AboutUsPEMDList'
const ABOUT_US_PEMD_URL = '/AboutUs/AboutUsPEMD'

export const useGetAboutUsQRY = () => {
  return useQuery({
    queryKey: [ABOUT_US_URL],
    queryFn: async () => {
      const { data } = await pttClient.get<IResponseWithData<IAboutUsResponse>>(ABOUT_US_URL)
      return data.data
    },
  })
}
export const useUpdateAboutUsMTT = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: async (params: IUpdateAboutUsParams) => {
      const { data } = await pttClient.put<IResponseWithData<IAboutUsResponse[]>>(ABOUT_US_URL, params)
      return data
    },
    onSuccess: (data) => {
      queryClient.setQueryData<IAboutUsResponse[]>([ABOUT_US_URL], data.data)
    },
  })
}

export const useGetAboutUsTeamListQRY = () => {
  return useQuery({
    queryKey: [ABOUT_US_TEAM_LIST_URL],
    queryFn: async () => {
      const { data } = await pttClient.get<IResponseWithData<IAboutUsTeamResponse[]>>(ABOUT_US_TEAM_LIST_URL)
      return data.data
    },
  })
}

export const useUpdateAboutUsTeamSeqMTT = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: async (params: IUpdateAboutUsTeamSeqParams) => {
      const { data } = await pttClient.put<IResponseWithData<IAboutUsTeamResponse[]>>(
        ABOUT_US_TEAM_LIST_URL,
        {},
        { params },
      )
      return data
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: [ABOUT_US_TEAM_LIST_URL] })
    },
  })
}

export const useDeleteAboutUsTeamMTT = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: async (id: number) => {
      const { data } = await pttClient.delete<IResponseWithData<IAboutUsTeamResponse[]>>(ABOUT_US_TEAM_URL, {
        params: { id },
      })
      return data
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [ABOUT_US_TEAM_LIST_URL] })
    },
  })
}

export const useCreateAboutUsTeamMTT = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: async (params: ICreateAboutUsTeamParams) => {
      const { data } = await pttClient.post<IResponseWithData<IAboutUsTeamResponse[]>>(ABOUT_US_TEAM_URL, params)
      return data
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [ABOUT_US_TEAM_LIST_URL] })
    },
  })
}

export const useGetAboutUsPEMDListQRY = () => {
  return useQuery({
    queryKey: [ABOUT_US_PEMD_LIST_URL],
    queryFn: async () => {
      const { data } = await pttClient.get<IResponseWithData<IAboutUsPEMDProfileResponse[]>>(ABOUT_US_PEMD_LIST_URL)
      return data.data
    },
  })
}

export const useCreateAboutUsPEMDProfileMTT = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: async (params: ICreateAboutUsPEMDParams) => {
      const { data } = await pttClient.post<IResponseWithData<IAboutUsPEMDProfileResponse[]>>(
        ABOUT_US_PEMD_URL,
        params,
        { timeout: ATTACHMENT_UPLOAD_TIMEOUT },
      )
      return data
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [ABOUT_US_PEMD_LIST_URL] })
    },
  })
}

export const useUpdateAboutUsPEMDProfileMTT = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: async (params: IUpdateAboutUsPEMDParams) => {
      const { data } = await pttClient.put<IResponseWithData<IAboutUsPEMDProfileResponse[]>>(
        ABOUT_US_PEMD_URL,
        params,
        { timeout: ATTACHMENT_UPLOAD_TIMEOUT },
      )
      return data
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [ABOUT_US_PEMD_LIST_URL] })
    },
  })
}
export const useDeleteAboutUsPEMDProfileMTT = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: async (id: number) => {
      const { data } = await pttClient.delete<IResponseWithData<IAboutUsPEMDProfileResponse[]>>(ABOUT_US_PEMD_URL, {
        params: { id },
      })
      return data
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [ABOUT_US_PEMD_LIST_URL] })
    },
  })
}
