import { MenuCodeEnum } from '@ptt-eia-web/constants'
import { pathToRegexp } from 'path-to-regexp'
import { arrayToTree } from 'performant-array-to-tree'
export interface IMenuItem {
  key: string
  label: string
  path?: string
  nested?: string[]
  children?: IMenuItem[]
}

export interface IMenuMapping {
  menuCode: number
  menuPath?: string
  menuNestedPaths?: string[]
  menuIcon?: React.ReactNode
}

export function removeEmptyChildren(item: IMenuItem) {
  if (!item.children) {
    return item
  }
  if (item.children.length === 0) {
    delete item.children
    return item
  }
  item.children = item.children.map((childrenItem: IMenuItem) => removeEmptyChildren(childrenItem))
  return item
}

interface IRoleMenu {
  menuCode: number
  menuNameTh: string
  menuNameEn: string
  parent: number | null
  roleId: number
}

interface ICreateMenuConfigsArgs {
  menuMappings: IMenuMapping[]
  roleMenuList: IRoleMenu[]
  renderLabel?: (label: string, path?: string) => React.ReactNode
}

export const mapMenuItems = ({ menuMappings, roleMenuList, renderLabel }: ICreateMenuConfigsArgs): IMenuItem[] => {
  const mappingCodes = menuMappings.map((r) => r.menuCode)
  const filteredRoleMenuList = roleMenuList.filter((m) => mappingCodes.includes(m.menuCode)).sort((m) => m.menuCode)
  const menuConfigs = filteredRoleMenuList.map((roleMenu) => {
    const menuMapping = menuMappings.find((menu) => menu.menuCode === roleMenu.menuCode)
    const path = menuMapping?.menuPath
    const nested = menuMapping?.menuNestedPaths
    const labelText = roleMenu.menuNameTh
    return {
      key: roleMenu.menuCode.toString(),
      label: renderLabel ? renderLabel(labelText, path) : labelText,
      parent: roleMenu.parent?.toString(),
      path,
      nested,
      icon: menuMapping?.menuIcon,
    }
  })
  const menuItemTree = arrayToTree(menuConfigs, {
    id: 'key',
    parentId: 'parent',
    childrenField: 'children',
    dataField: null,
  }) as IMenuItem[]
  return menuItemTree.map((item) => removeEmptyChildren(item))
}

const matchPath = (configPath: string, actualPath: string): boolean => {
  const regex = pathToRegexp(configPath)
  return regex.test(actualPath)
}

const comparePath = (menuItem: IMenuItem, pathname: string) => {
  if (!menuItem.path) return false
  return matchPath(menuItem.path, pathname) || menuItem.nested?.some((path) => matchPath(path, pathname))
}

export const getSelectedKeys = (menuItems: IMenuItem[], pathname: string) => {
  let keys: string[] = []
  for (const item of menuItems) {
    const { key, children = [] } = item
    if (comparePath(item, pathname)) {
      keys = [...keys, key]
    }
    if (children.some((i) => comparePath(i, pathname))) {
      keys = [...keys, key]
    }
    for (const childrenItem of children) {
      if (comparePath(childrenItem, pathname)) {
        keys = [...keys, childrenItem.key]
      }
    }
  }
  return keys
}

export const getIsAdmin = (roleMenuList: IRoleMenu[]) => {
  return roleMenuList.some((m) =>
    [
      MenuCodeEnum.AdminUserManagement,
      MenuCodeEnum.AdminHomeContent,
      MenuCodeEnum.AdminAnnualReport,
      MenuCodeEnum.AdminAboutUs,
      MenuCodeEnum.AdminSettings,
    ].includes(m.menuCode),
  )
}
