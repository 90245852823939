import { AppModal } from '@ptt-eia-web/components'
import { css } from '@ptt-eia-web/helpers'
import {
  GetProjectTypeParams,
  IGetReportTypeParams,
  useGetMasterDashboardFormQRY,
  useGetProjectTypesQRY,
  useGetReportTypesQRY,
} from '@ptt-eia-web/services'
import { Form, Select } from 'antd'
import { useForm, useWatch } from 'antd/es/form/Form'
import React, { useEffect, useState } from 'react'
type RawValue = string | number
interface IModalAddNewProjectProps {
  isModalOpen: boolean
  onClose: () => void
  onClickOk: (arg0: INewReqModalProject) => void
}

interface IOption {
  value: number
  label: string
}

export interface LabeledValue {
  key?: string
  value: RawValue
  label: React.ReactNode
}

export type INewReqModalProject = {
  id?: number
  projectTypeValue?: string
  reportTypeValue?: string
  projectName?: string
  formTypeValue?: string
  projectTypeId?: number
  reportTypeId?: number
  formTypeId?: number
  flowsType?: string
  projectDashboardId?: number
  isDraft?: number
  projectStatus?: string
  latestHistoryId?: number
}

const updateOptions = <T extends { id: unknown; name: string }>(
  dataList: T[],
  setOptionsFn: React.Dispatch<React.SetStateAction<IOption[]>>,
) => {
  const newOptions = dataList.map((data) => ({
    value: data.id as number,
    label: data.name,
  }))
  setOptionsFn(newOptions)
}

export const ModalAddNewProject = ({ isModalOpen, onClickOk, onClose }: IModalAddNewProjectProps) => {
  const projectTypeParams: GetProjectTypeParams = { active: 1 }
  const reportTypeParams: IGetReportTypeParams = { active: 1 }
  const { data: projectTypeList } = useGetProjectTypesQRY(projectTypeParams)
  const { data: reportTypeList } = useGetReportTypesQRY(reportTypeParams)
  const { data: dashboardFormList } = useGetMasterDashboardFormQRY()
  const [optionsProjectType, setOptionsProjectType] = useState<IOption[]>([])
  const [optionsReportType, setOptionsReportType] = useState<IOption[]>([])
  const [optionsFormType, setOptionsFormType] = useState<IOption[]>([])
  const [form] = useForm()
  const chooseProjectType = useWatch('projectType', form)
  const chooseReportType = useWatch('reportType', form)
  const chooseFormType = useWatch('formType', form)

  useEffect(() => {
    if (projectTypeList) updateOptions(projectTypeList, setOptionsProjectType)
    if (reportTypeList) updateOptions(reportTypeList, setOptionsReportType)
    if (dashboardFormList) updateOptions(dashboardFormList, setOptionsFormType)
  }, [projectTypeList, reportTypeList, form, dashboardFormList])
  const handlerSetValue = () => {
    let formTypeValues = chooseFormType
    if (chooseFormType === null || chooseFormType === undefined) {
      formTypeValues = projectTypeList?.find((item) => {
        return item.id === chooseProjectType
      })?.mddashboardFormId
    }
    onClickOk({
      projectTypeValue: chooseProjectType
        ? optionsProjectType.find((option) => option.value === chooseProjectType)?.label || ''
        : '',
      reportTypeValue: chooseReportType
        ? optionsReportType.find((option) => option.value === chooseReportType)?.label || ''
        : '',
      formTypeValue: formTypeValues
        ? optionsFormType.find((option) => option.value === formTypeValues)?.label || ''
        : '',
      projectTypeId: chooseProjectType,
      reportTypeId: chooseReportType ?? '',
      formTypeId: formTypeValues,
      flowsType: 'new',
    })
  }
  return (
    <AppModal
      width={800}
      title="New Project"
      onOk={() => {
        form
          .validateFields()
          .then(() => {
            handlerSetValue()
            form.resetFields()
          })
          .catch((errorInfo) => {
            console.log('Validation failed:', errorInfo)
          })
      }}
      open={isModalOpen}
      okText="Confirm"
      onCancel={onClose}
      okButtonProps={{
        htmlType: 'submit',
        type: 'primary',
      }}
    >
      <Form
        form={form}
        css={css`
          padding: 30px 80px;
        `}
        layout="vertical"
      >
        <Form.Item
          css={css`
            flex: 1;
          `}
          name="reportType"
          label="ประเภทรายงาน"
          rules={[{ required: true, message: 'กรุณากรอกประเภทรายงาน' }]}
        >
          <Select
            placeholder="ประเภทรายงาน"
            css={css`
              width: 100%;
            `}
            options={optionsReportType}
          />
        </Form.Item>
        <Form.Item
          css={css`
            flex: 1;
          `}
          name="projectType"
          label="ประเภทโครงการ"
          rules={[{ required: true, message: 'กรุณากรอกประเภทโครงการ' }]}
        >
          <Select
            placeholder="ประเภทโครงการ"
            css={css`
              width: 100%;
            `}
            options={optionsProjectType}
          />
        </Form.Item>

        {chooseProjectType === 5 && (
          <Form.Item
            css={css`
              flex: 1;
            `}
            name="formType"
            label="แบบฟอร์ม"
            rules={[{ required: true, message: 'กรุณากรอกแบบฟอร์ม' }]}
          >
            <Select
              placeholder="แบบฟอร์ม"
              css={css`
                width: 100%;
              `}
              options={optionsFormType}
            />
          </Form.Item>
        )}
      </Form>
    </AppModal>
  )
}
