import styled from '@emotion/styled'
import { css, getFullUrl, mediaQuery, secureRandomInt } from '@ptt-eia-web/helpers'
import { IStringOrNull } from '@ptt-eia-web/services'
import { Flex } from 'antd'

import { colors } from '@frontend/app/theme'
import leafsImg from '@frontend/assets/about-us/vision-leafs.png'
import { HeaderTitle } from '@frontend/components/HeaderTitle'
import { Txt } from '@frontend/components/Txt'

const VisionWrapper = styled.div`
  background-color: ${colors.lightBlueBg};
  padding: 0 40px;
  @media ${mediaQuery.xlGte} {
    padding: 0 40px 0 80px;
  }
`
interface IVisionImageGroup {
  imgSrc: string
}
const VisionImageGroup = ({ imgSrc }: IVisionImageGroup) => {
  return (
    <div
      css={css`
        position: relative;
        width: 740px;
        height: 380px;
        @media ${mediaQuery.lgLte} {
          scale: 0.85;
        }
      `}
    >
      <img
        src={leafsImg}
        alt="leafs"
        css={css`
          position: absolute;
          right: 0;
          width: 600px;
        `}
      />
      <img
        src={imgSrc}
        alt="vision"
        css={css`
          border-radius: 4px;
          box-shadow: 0 1px 1px hsl(0deg 0% 0% / 0.075), 0 2px 2px hsl(0deg 0% 0% / 0.075),
            0 4px 4px hsl(0deg 0% 0% / 0.075), 0 8px 8px hsl(0deg 0% 0% / 0.075), 0 16px 16px hsl(0deg 0% 0% / 0.075);
          position: absolute;
          top: 70px;
          right: 60px;
          width: 680px;
          height: 400px;
          object-fit: cover;
        `}
      />
    </div>
  )
}

interface ISectionVisionProps {
  text: string
  imageUrl: IStringOrNull
}
export const SectionVision = ({ text, imageUrl }: ISectionVisionProps) => {
  return (
    <VisionWrapper>
      <HeaderTitle titleFirst="About" titleSecond="Us" />
      <Flex
        justify="space-between"
        css={css`
          @media ${mediaQuery.lgLte} {
            flex-wrap: wrap;
          }
        `}
      >
        <Flex vertical justify="center">
          <div>
            <Txt ag="med30" color="darkBlue">
              Vision
            </Txt>
          </div>
          <div
            css={css`
              margin-top: 36px;
              width: 100%;
              max-width: 800px;
              padding-right: 80px;
              padding-bottom: 40px;
            `}
          >
            {text.split('\n').map((item) => (
              <div key={secureRandomInt({ min: 100, max: 999 })}>
                <Txt ag="med24" color="primary">
                  {item}
                </Txt>
              </div>
            ))}
          </div>
        </Flex>
        <Flex
          vertical
          justify="start"
          css={css`
            padding: 40px 0;
            @media ${mediaQuery.lgLte} {
              padding: 0;
            }
          `}
        >
          {imageUrl && <VisionImageGroup imgSrc={getFullUrl(imageUrl)} />}
        </Flex>
      </Flex>
    </VisionWrapper>
  )
}
