import styled from '@emotion/styled'
import { css, keyframes } from '@ptt-eia-web/helpers'
import { IProjectDashboardGroupByProjectType } from '@ptt-eia-web/services'
import { Col, Tooltip } from 'antd'
import React from 'react'
import { useSearchParams } from 'react-router-dom'

import { colors } from '../../../app/theme'

const GRAPH_COLORS = [
  colors.bobblePrimary,
  colors.bobbleSecondary,
  colors.bobbleTertiary,
  colors.bobbleQuaternary,
  colors.bobbleQuinary,
  colors.bobbleSextary,
]

const Bobble = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
  gap: 4px;
`
const BobbleContent = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: ${colors.white};
  height: 100%;
  width: 100%;
  border-radius: 60px;
  box-shadow: 2px 5px 10px #00000029 inset;
`

const BobbleValue = styled.span<{ color: string }>`
  color: ${({ color }) => color};
  font-size: 30px;
  font-weight: 500;
  margin-bottom: 5px;
`

const BobbleButtonStyle = styled.button<{ color: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  cursor: pointer;
  border-radius: 100px;
  border-width: 0px;
  width: 120px;
  height: 120px;
  box-shadow: 0px 3px 6px #00000029;
  background-color: ${({ color }) => color};
  padding: 10px;
`
const ColorIndicator = styled.div<{ color: string }>`
  width: 15px;
  height: 15px;
  border-radius: 100%;
  background-color: ${({ color }) => color};
`

const DividerCol = styled(Col)`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 6px;
`

const DividerItem = styled.div`
  border-radius: 30px;
  background-color: #707070;
  width: 2px;
  height: 8px;
`

const balloon = keyframes`
  0%   {transform: translateY(0)}
  25%  {transform: translateY(-15px)}
  75%  {transform: translateY(15px)}
  100% {transform: translateY(0)}
`

interface IBobbleButtonProps {
  value: IProjectDashboardGroupByProjectType
  animationDuration: number
}

export const BobbleButton: React.FC<IBobbleButtonProps> = (props) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const getColorOfGraph = (idx: number | undefined) => {
    if (idx === undefined) {
      return colors.bobblePrimary
    } else {
      return GRAPH_COLORS[idx - 1] || GRAPH_COLORS[GRAPH_COLORS.length - 1]
    }
  }
  const scrollToTop = () => {
    // Scroll to the top of the document
    window.scrollTo({
      top: 0,
      behavior: 'smooth', // Add smooth scrolling behavior
    })
  }
  const handleClickBobble = (args: string) => {
    const paramReportTypeId = searchParams.get('reportTypeId')
    const paramYear = searchParams.get('year')
    if (paramReportTypeId) {
      searchParams.delete('reportTypeId')
    }
    if (paramYear) {
      searchParams.delete('year')
    }
    const params = Object.fromEntries([...searchParams])
    setSearchParams({
      ...params,
      projectTypeId: args,
      tab: 'chart',
    })
    scrollToTop()
  }

  const getNumberOfDividers = (index: number | undefined) => {
    switch (index) {
      case 1:
        return 12
      case 5:
        return 5
      case 6:
        return 5
      default:
        return 8
    }
  }

  const renderDivider = (item: IProjectDashboardGroupByProjectType | undefined) => {
    const numberOfDividers = getNumberOfDividers(item?.mdProjectTypeId)
    return (
      <DividerCol>
        <ColorIndicator color={getColorOfGraph(item?.mdProjectTypeId)} />
        {Array.from({ length: numberOfDividers }, (_, index) => (
          <DividerItem key={index} />
        ))}
      </DividerCol>
    )
  }

  const getMarginTopBobble = (index: number | undefined) => {
    switch (index) {
      case 1:
        return 10
      case 2:
        return 70
      case 3:
        return 30
      case 5:
        return 50
      case 6:
        return 95
      default:
        return 0
    }
  }
  const renderBobbleButton = (item: IProjectDashboardGroupByProjectType | undefined) => (
    <Tooltip
      color="#002F41"
      title={
        <Col>
          <div
            css={css`
              display: flex;
              align-items: center;
              justify-content: center;
              flex-direction: column;
              font-weight: 500;
            `}
          >
            <span>คลิกเพื่อเข้าสู่</span>
            <span>WEEKLY DASHBOARD</span>
          </div>
        </Col>
      }
    >
      <BobbleButtonStyle color={getColorOfGraph(item?.mdProjectTypeId)}>
        <BobbleContent>
          <div
            css={css`
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
            `}
          >
            <BobbleValue color={getColorOfGraph(item?.mdProjectTypeId)}> {item?.projectPercent}%</BobbleValue>
            <span
              css={css`
                color: ${getColorOfGraph(item?.mdProjectTypeId)};
                font-size: 12px;
                line-height: 14px;
              `}
            >
              {item?.mdProjectTypeName}
            </span>
            <span
              css={css`
                color: ${getColorOfGraph(item?.mdProjectTypeId)};
                line-height: 16px;
              `}
            >
              {item?.projectCount}
            </span>
          </div>
        </BobbleContent>
      </BobbleButtonStyle>
    </Tooltip>
  )
  return (
    <Bobble
      className="bobble"
      onClick={() => handleClickBobble(props.value.mdProjectTypeId.toString())}
      style={{ marginTop: getMarginTopBobble(props.value.mdProjectTypeId) }}
      css={css`
        animation: ${balloon} ${props.animationDuration}s linear infinite;
      `}
    >
      {renderBobbleButton(props.value)}
      <div
        css={css`
          display: flex;
          flex-direction: column;
          gap: 4px;
        `}
      >
        {renderDivider(props.value)}
      </div>
    </Bobble>
  )
}
