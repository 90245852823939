import styled from '@emotion/styled'
import { SafeImage } from '@ptt-eia-web/components'
import { css, downloadFile, getFullUrl } from '@ptt-eia-web/helpers'
import { IAnnualReportResponse, IAnnualReportSearchParam, useGetAnnualReportListQRY } from '@ptt-eia-web/services'
import { Button, Col, Flex, Row } from 'antd'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { FaRegFilePdf } from 'react-icons/fa'

import FrameAnnualReport from '../../assets/annual-report/annual-report-frame.png'
import BgHeaderAnnualReport from '../../assets/annual-report/header-bg-report.png'

import { AnnualReportTitle } from './AnnualReportTitle'

const HeaderStyle = styled.div`
  width: 100%;
  min-height: 552px;
  background-image: url(${BgHeaderAnnualReport});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding: 0 64px;
`
const FrameImageContent = styled.div`
  display: flex;
  justify-content: center;
  align-self: center;
  background-image: url(${FrameAnnualReport});
  background-position: center;
  background-repeat: no-repeat;
  width: 600px;
  height: 500px;
  margin-top: 24px;
`
const PDFImageContent = styled.div`
  display: flex;
  justify-content: center;
  align-self: center;
  margin: 70px 0 70px 0;
  width: 300px;
  height: 400px;
`

export const HeaderAnnualReport = () => {
  const [annualReportCurrentData, setAnnualReportCurrentData] = useState<IAnnualReportResponse>()
  const filters = useMemo((): IAnnualReportSearchParam => {
    const MDDocTypeID = '1'
    const IsFront = '1'
    return { MDDocTypeID, IsFront }
  }, [])
  const { data: annualReports = [], isFetched } = useGetAnnualReportListQRY(filters)
  const handleClickDownload = useCallback((pdfPath: string, fileName: string) => {
    const pdfUrl = getFullUrl(pdfPath)
    downloadFile(pdfUrl, fileName)
  }, [])

  useEffect(() => {
    if (isFetched && annualReports !== null) {
      annualReports.length > 0 && setAnnualReportCurrentData(annualReports[0])
    }
  }, [annualReports, isFetched])

  return (
    <HeaderStyle>
      <Row align="middle" gutter={[16, 16]}>
        <Col
          xs={24}
          sm={24}
          md={12}
          xl={15}
          css={css`
            display: flex;
            align-self: center;
            justify-content: space-around;
          `}
        >
          <FrameImageContent>
            {annualReportCurrentData !== undefined && (
              <PDFImageContent>
                <SafeImage
                  id={`annual_report_cover_image_${annualReportCurrentData.id}`}
                  url={getFullUrl(annualReportCurrentData.picturePath)}
                  alt={annualReportCurrentData.pdfFile}
                  width={300}
                  height={400}
                  css={css`
                    object-fit: cover;
                    width: 100%;
                    max-width: 100%;
                    max-height: 100%;
                    transition: all 0.3s;
                    :hover {
                      scale: 1.15;
                    }
                  `}
                />
              </PDFImageContent>
            )}
          </FrameImageContent>
        </Col>
        <Col xs={24} sm={24} md={12} xl={9}>
          <Flex
            vertical
            justify="center"
            align="start"
            css={css`
              width: 60%;
            `}
          >
            {annualReportCurrentData !== undefined && <AnnualReportTitle annualReport={annualReportCurrentData} />}
            {annualReportCurrentData !== undefined && (
              <Button
                type="primary"
                icon={<FaRegFilePdf size="21px" />}
                onClick={() => {
                  handleClickDownload(annualReportCurrentData.pdfPath, annualReportCurrentData.pdfFile)
                }}
              >
                PDF
              </Button>
            )}
          </Flex>
        </Col>
      </Row>
    </HeaderStyle>
  )
}
