import {
  IProjectDashboardOutDto,
  IProjectDashboardReportParam,
  IProjectDashboardReportResponse,
  useGetProjectDashboardReportQRY,
  useGetProjectTypesQRY,
  GetProjectTypeParams,
  ISettingResponse,
} from '@ptt-eia-web/services'
import React, { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

import { GotoTopDown } from '@frontend/components/GotoTopDown'
import { parseSearchParams } from '@frontend/components/PasreSearchParams'

import { BDTable } from './BDTable'
import { DistributionPipeLineCard } from './DistributionPipeLineCard'
import { OtherTable } from './OtherTable'
import { PlantTable } from './PlantTable'
import { TSOTable } from './TSOTable'
import { TransmissionTable } from './TransmissionTable'

export const ChartDashboardCard = () => {
  const [searchParams] = useSearchParams()
  const projectTypeParams: GetProjectTypeParams = { active: 1 }
  const { data: projectTypeList } = useGetProjectTypesQRY(projectTypeParams)
  const [paramSearch, setParamSearch] = useState<IProjectDashboardReportParam>(parseSearchParams(searchParams))
  useEffect(() => {
    setParamSearch(parseSearchParams(searchParams))
  }, [searchParams])
  const { data: dataSourceTables } = useGetProjectDashboardReportQRY(paramSearch)

  const [dataSourceTablesByType, setDataSourceTablesByType] = useState<Record<string, IProjectDashboardOutDto[]>>({})

  const [reportStatusForm, setReportStatusForm] = useState<string>('')

  useEffect(() => {
    if (dataSourceTables) {
      const master: ISettingResponse[] = projectTypeList || []
      let otherProjectName = 'Other'
      master.forEach((item) => {
        if (item.id === 6) {
          otherProjectName = item.name
        }
      })
      const newData: Record<string, IProjectDashboardOutDto[]> = {}

      dataSourceTables.forEach((parentItem: IProjectDashboardReportResponse) => {
        newData[parentItem.mdProjectTypeName] = parentItem.projectDashboardOutDtos

        if (parentItem.mdProjectTypeName === otherProjectName && parentItem.projectDashboardOutDtos.length > 0) {
          setReportStatusForm(parentItem.projectDashboardOutDtos[0].mddashboardFormId.toString())
        }
      })

      setDataSourceTablesByType(newData)
    }
  }, [dataSourceTables, projectTypeList])

  const renderTableWithMasterProjectType = (master: ISettingResponse[]) => {
    return master?.map((item) => {
      switch (item.id) {
        case 1:
          return <TransmissionTable key={item.id} dataSourceTables={dataSourceTablesByType[item.name]} />
        case 2:
          return <DistributionPipeLineCard key={item.id} dataSourceTables={dataSourceTablesByType[item.name]} />
        case 3:
          return <PlantTable key={item.id} dataSourceTables={dataSourceTablesByType[item.name]} />
        case 4:
          return <TSOTable key={item.id} dataSourceTables={dataSourceTablesByType[item.name]} />
        case 5:
          return <BDTable key={item.id} dataSourceTables={dataSourceTablesByType[item.name]} />
        case 6:
          return (
            <OtherTable
              key={item.id}
              dataSourceTables={dataSourceTablesByType[item.name]}
              reportStatusFrom={reportStatusForm}
            />
          )
        default:
          return null
      }
    })
  }

  return (
    <div>
      {renderTableWithMasterProjectType(projectTypeList || [])}
      <GotoTopDown />
    </div>
  )
}
