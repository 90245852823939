import { getColumnNumber, css } from '@ptt-eia-web/helpers'
import { IProjectListParams, IProjectListResponse, useGetServiceRequestProjectList } from '@ptt-eia-web/services'
import { Card, Flex, Table } from 'antd'
import { ColumnType } from 'antd/es/table'
import { isEmpty } from 'lodash'
import { useCallback, useMemo } from 'react'
import { GoEye } from 'react-icons/go'
import { Link, useSearchParams } from 'react-router-dom'

import { StatusBadge } from '@frontend/components/StatusBadge'

import { colors } from '../../app/theme'
import { IconButtonCss } from '../../assets/css/IconButtonCss'
import { Txt } from '../../components/Txt'

const getParam = (param: string | null) => {
  return isEmpty(param) ? null : Number(param)
}

export const ProjectList = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const filters = useMemo((): IProjectListParams => {
    const serviceTypeId = getParam(searchParams.get('serviceTypeId'))
    const projectTypeId = getParam(searchParams.get('projectTypeId'))
    const year = getParam(searchParams.get('year'))

    return { projectTypeId, serviceTypeId, year }
  }, [searchParams])

  const currentPageParam = searchParams.get('currentPage') ?? '1'

  const handlePageChange = useCallback(
    (page: number, pageSize: number) => {
      const params = Object.fromEntries([...searchParams])
      setSearchParams({
        ...params,
        currentPage: page.toString(),
      })
    },
    [searchParams, setSearchParams],
  )

  const { data: projectList = [] } = useGetServiceRequestProjectList(filters)

  const columns: ColumnType<IProjectListResponse>[] = useMemo(
    (): ColumnType<IProjectListResponse>[] => [
      {
        title: 'NO.',
        width: 70,
        ellipsis: true,
        align: 'center',
        render: (value, record, index) => <Txt ag="light16">{getColumnNumber(projectList, record)}</Txt>,
      },
      { title: 'ชื่อโครงการ', width: 150, ellipsis: true, dataIndex: 'projectName' },
      {
        title: 'ประเภท Service',
        width: 200,
        align: 'center',
        ellipsis: true,
        dataIndex: 'mdserviceTypeDisplay',
      },
      { title: 'ประเภทโครงการ', align: 'center', ellipsis: true, width: 150, dataIndex: 'mdprojectTypeDisplay' },
      {
        title: 'STATUS',
        align: 'center',

        width: 200,
        render: (_, record) => <StatusBadge color={record.mdreqStatusColor} status={record.mdreqStatusDisplay} />,
      },
      { title: 'CREATE DATE', ellipsis: true, width: 100, dataIndex: 'createDateDisplay' },
      {
        title: 'VIEW',
        ellipsis: true,
        align: 'center',
        width: 70,
        render: (value: string, record: IProjectListResponse) => {
          return (
            <Link to={`/service-request/task/${encodeURIComponent(record.id.toString())}/view`}>
              <GoEye size={16} css={IconButtonCss({ color: colors.gray, hoverColor: colors.primary })} />
            </Link>
          )
        },
      },
    ],
    [projectList],
  )

  return (
    <Card>
      <Flex
        gap={8}
        css={css`
          margin-bottom: 32px;
        `}
      >
        <Txt ag="med24" color="primary">
          Project
        </Txt>
        <Txt ag="med24" color="darkBlue">
          List
        </Txt>
      </Flex>
      <Table
        rowKey="id"
        columns={columns}
        dataSource={projectList}
        pagination={{
          current: Number(currentPageParam),
          defaultCurrent: 1,
          defaultPageSize: 5,
          showSizeChanger: false,
          onChange: handlePageChange,
        }}
      />
    </Card>
  )
}
