import styled from '@emotion/styled'
import { css } from '@ptt-eia-web/helpers'

import { colors } from '../theme'

const HorizontalLine = styled.div<{ width: string; background: string }>`
  width: ${({ width }) => width};
  background: ${({ background }) => background};
  height: 5px;
`

export const HeaderBottomLine = () => {
  return (
    <div
      css={css`
        display: flex;
        width: 100%;
      `}
    >
      <HorizontalLine width="30%" background={colors.secondary} />
      <HorizontalLine width="70%" background={colors.primary} />
    </div>
  )
}
