import {
  QueryKey,
  UndefinedInitialDataOptions,
  UseMutationOptions,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query'
import { message } from 'antd'

import { pttClient } from '../ptt-client'
import { IResponseWithData } from '../services-types'

import { IPRNewsFormSaveParams, IPrNewsGetDetailParam } from './pr-news-param'
import { IPRNewsResponse } from './pr-news.response'

const PR_NEWS_BASE_URL_PATH = '/HomeContent/PrNews'
export const useGetPRNewsQRY = (
  options?: UndefinedInitialDataOptions<IPRNewsResponse[], Error, IPRNewsResponse[], QueryKey>,
) => {
  return useQuery<IPRNewsResponse[]>({
    queryKey: [PR_NEWS_BASE_URL_PATH],
    queryFn: async (): Promise<IPRNewsResponse[]> => {
      const res = await pttClient.get<IResponseWithData<IPRNewsResponse[]>>(PR_NEWS_BASE_URL_PATH)
      return res.data.data
    },
    ...options,
  })
}

const PR_NEWS_GET_DETAIL_PATH = '/HomeContent/PrNews/Id'
export const useGetPRNewsDetailQRY = (
  params: IPrNewsGetDetailParam,
  options?: UndefinedInitialDataOptions<IPRNewsResponse, Error, IPRNewsResponse, QueryKey>,
) => {
  return useQuery<IPRNewsResponse>({
    queryKey: [PR_NEWS_GET_DETAIL_PATH, { params }],
    queryFn: async (): Promise<IPRNewsResponse> => {
      const res = await pttClient.get<IResponseWithData<IPRNewsResponse>>(PR_NEWS_GET_DETAIL_PATH, {
        params,
      })
      return { ...res.data.data, detail: encodeURIComponent(res.data.data.detail) }
    },
    ...options,
  })
}

export const usePostPRNewsMTT = (
  options?: UseMutationOptions<IPRNewsResponse, Error, Omit<IPRNewsFormSaveParams, 'id'>, unknown>,
) => {
  const queryClient = useQueryClient()
  return useMutation<IPRNewsResponse, Error, Omit<IPRNewsFormSaveParams, 'id'>>({
    mutationFn: async (body: Omit<IPRNewsFormSaveParams, 'id'>) => {
      const res = await pttClient.post<IResponseWithData<IPRNewsResponse>>(PR_NEWS_BASE_URL_PATH, {
        ...body,
      })

      return res.data.data
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [PR_NEWS_BASE_URL_PATH] })
      message.success('เพิ่มข้อมูลข่าวประชาสัมพันธ์สำเร็จ')
    },
    ...options,
  })
}

export const usePutPRNewsMTT = (
  params?: IPrNewsGetDetailParam,
  options?: UseMutationOptions<IPRNewsResponse, Error, IPRNewsFormSaveParams, unknown>,
) => {
  const queryClient = useQueryClient()
  return useMutation<IPRNewsResponse, Error, IPRNewsFormSaveParams>({
    mutationFn: async (body: IPRNewsFormSaveParams) => {
      const res = await pttClient.put<IResponseWithData<IPRNewsResponse>>(PR_NEWS_BASE_URL_PATH, {
        ...body,
      })

      return res.data.data
    },
    onSuccess: (values) => {
      queryClient.invalidateQueries({ queryKey: [PR_NEWS_BASE_URL_PATH] })
      queryClient.invalidateQueries({ queryKey: [PR_NEWS_GET_DETAIL_PATH, { params }] })
      message.success('แก้ไขข้อมูลข่าวประชาสัมพันธ์สำเร็จ')
    },
    ...options,
  })
}

export const useDeletePRNewsMTT = (options?: UseMutationOptions<IPRNewsResponse, Error, number, unknown>) => {
  const queryClient = useQueryClient()
  return useMutation<IPRNewsResponse, Error, number>({
    mutationFn: async (id: number) => {
      const res = await pttClient.delete<IResponseWithData<IPRNewsResponse>>(PR_NEWS_BASE_URL_PATH, {
        data: { id },
      })

      return res.data.data
    },
    onSuccess: (values) => {
      queryClient.invalidateQueries({ queryKey: [PR_NEWS_BASE_URL_PATH] })
      message.success('ลบข้อมูลข่าวประชาสัมพันธ์สำเร็จ')
    },
    ...options,
  })
}
