import { azureRedirectUri } from '@ptt-eia-web/auth'
import { generatePath } from '@ptt-eia-web/helpers'

import { INewReqModalProject } from '@frontend/modules/project-dashboard/project-update-status/project-list/ModalAddNewProject'

import { INewReqModalFormValue } from '../../modules/service-request/NewRequestButton'

export const paths = {
  login: generatePath('/login'),
  loginCallback: generatePath(azureRedirectUri),
  loginDebug: generatePath('/login-debug'),
  accountNotFound: generatePath('/account-not-found'),
  home: generatePath('/home'),
  projectDashboard: generatePath('/project-dashboard'),
  projectStatusUpdate: generatePath('/project-dashboard/project-status-update'),
  projectStatusUpdateNewProject: generatePath<{ query: INewReqModalProject }>(
    '/project-dashboard/project-status-update/new-project',
  ),
  serviceRequest: generatePath('/service-request'),
  serviceRequestNew: generatePath<{ query: INewReqModalFormValue }>('/service-request/new'),
  serviceRequestTracking: generatePath<{ param: { id: string } }>('/service-request/request-tracking/:id'),
  serviceRequestTask: generatePath('/service-request/task'),
  serviceRequestTaskDetail: generatePath<{ param: { id: string } }>('/service-request/task/:id/view'),
  prNews: generatePath('/pr-news'),
  prNewsDetail: generatePath<{ param: { prNewsId: string } }>('/pr-news/:prNewsId'),
  annualReport: generatePath('/annual-reports'),
  aboutUs: generatePath('/about-us'),
}

// ==== example

// ==create route
// export const cars = generatePath('/cars')
// export const carsDetail = generatePath<{ param: { id: string }; query: { sort: string } }>('/cars/:id')

// ==usage
// cars() // => '/cars'
// carsDetail() // => '/cars/:id
// carsDetail({ param: { id: '1' } }) // => '/cars/1'
// carsDetail({ query: { sort: '2' } }) // => '/cars/:id?sort=2'
// carsDetail({ param: { id: '1' }, query: { sort: '2' } }) // => '/cars/1'?sort=2
