import styled from '@emotion/styled'
import { Chart, TooltipItem } from 'chart.js'
import { Doughnut } from 'react-chartjs-2'

const DonutChartLayoutStyle = styled.div`
  height: 250px;
`

interface ICustomOptions {
  cutout?: string
  responsive?: boolean
  maintainAspectRatio?: boolean
  plugins?: {
    [key: string]: unknown // Allow for additional plugins that might have different structures
  }
}

interface IDataSet {
  labels: string[]
  datasets: [
    {
      group: string
      label: string
      data: number[]
      backgroundColor: string[]
      hoverOffset: number
      borderWidth: number
    },
  ]
}

const wrapText = (
  context: CanvasRenderingContext2D,
  text: string,
  x: number,
  y: number,
  maxWidthText: number,
  lineHeightText: number,
) => {
  const characters = Array.from(text)
  let line = ''
  let newY = y

  while (characters.length > 0) {
    line += characters.shift()
    const metrics = context.measureText(line)
    const testWidth = metrics.width

    if (testWidth > maxWidthText && line.length > 0) {
      characters.unshift(line[line.length - 1])
      line = line.substring(0, line.length - 1)

      context.fillText(line, x, newY)
      line = ''
      newY += lineHeightText
    }
  }

  if (line.length > 0) {
    context.fillText(line, x, newY)
  }
}

const donutLabelPlugin = {
  id: 'DonutChartRequestStatus',
  beforeDatasetsDraw(chart: Chart) {
    const { ctx, data } = chart
    if (ctx && data.datasets.length > 0) {
      const datasetMeta = chart.getDatasetMeta(0)
      // Defaulting to first element for simplicity; this could be generalized based on needs.
      const element = datasetMeta.data[0]

      if (!element) return

      const xCoor = element.x
      const yCoor = element.y

      ctx.save()
      ctx.font = 'normal 28px Prompt'
      ctx.fillStyle = 'rgba(0, 0, 0, 1)'
      ctx.textAlign = 'center'
      ctx.textBaseline = 'middle'

      const maxWidth = 87
      const lineHeight = 40

      if (data.datasets[0].label) {
        wrapText(ctx, data.datasets[0].label, xCoor, yCoor, maxWidth, lineHeight)
      }

      ctx.restore()
    }
  },
}
interface IDonutChartRequestStatusProps {
  value: IDataSet
}

export const DonutChartRequestStatus = (props: IDonutChartRequestStatusProps) => {
  const options: ICustomOptions = {
    cutout: '75%',
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
        position: 'bottom',
      },
      tooltip: {
        enabled: true,
        filter: function (tooltipItem: TooltipItem<'doughnut'>) {
          return tooltipItem.dataset.data[0] !== -1
        },
        callbacks: {
          label: function (tooltipItems: TooltipItem<'doughnut'>) {
            const total = tooltipItems.dataset.data.reduce(function (previousValue: number, currentValue: number) {
              return previousValue + currentValue
            })
            const currentValue = tooltipItems.dataset.data[tooltipItems.dataIndex]
            const percentData = Math.floor((currentValue / total) * 100 + 0.5)
            return `${percentData} %, ${currentValue} Requests`
          },
        },
      },
      title: {
        display: false,
      },
    },
  }

  return (
    <DonutChartLayoutStyle>
      <Doughnut data={props.value} options={options} plugins={[donutLabelPlugin]} />
    </DonutChartLayoutStyle>
  )
}
