import styled from '@emotion/styled'
import { css } from '@ptt-eia-web/helpers'
import {
  IProjectDashboardDtOutDto,
  IProjectDashboardOutDto,
  useGetProjectDashboardWithIdQRY,
} from '@ptt-eia-web/services'
import { Table, Tooltip } from 'antd'
import React, { useCallback, useEffect } from 'react'
import { FiEye } from 'react-icons/fi'
import { useNavigate, useSearchParams } from 'react-router-dom'

import { paths } from '@frontend/app/route/path-config'
import { checkValue } from '@frontend/components/CheckValue'

import { colors } from '../../../../app/theme'
import CheckSvg from '../../../../assets/svgs/check-icon.svg?react'
import CloseSvg from '../../../../assets/svgs/close-icon.svg?react'
import { Txt } from '../../../../components/Txt'
import { INewReqModalProject } from '../project-list/ModalAddNewProject'

import type { ColumnsType } from 'antd/es/table'
interface DataType {
  key: number | undefined
  no: number | undefined
  plan: string | null
  actual: string | null
  eiaStatus: number | null
  eiaHighlight: string | null
  eiaCompliance: string | null
  submission: string | null
  activity: string | null
  modifyDate: string | null
  modifyBy: string | null
  projectName: string
  reportType: string
  projectType: string
  projectTypeId: number
  formTypeId: number
  formType: string
  isDraft: number
}
const columnTitleStyles = css`
  text-align: center;
`
const columnTextStyles = css`
  font-size: 14px;
  text-align: center;
`

const CloseIcon = styled(CloseSvg)`
  text-align: center;
  align-self: center;
  width: 31px;
  height: 30px;
`
const CheckIcon = styled(CheckSvg)`
  text-align: center;
  align-self: center;
  width: 31px;
  height: 30px;
  #Ellipse_1973 {
    fill: #2ebca2;
  }
  #Ellipse_1974 {
    fill: #2ebca2;
  }
  #Path_16406 {
    fill: #2ebca2;
  }
  #Path_16407 {
    fill: ${colors.white};
  }
  #Path_16408 {
    fill: #2ebca2;
  }
`

const generateData = (prItem: IProjectDashboardOutDto, dtItem: IProjectDashboardDtOutDto, index: number) => {
  return {
    key: dtItem.id,
    no: index,
    plan: dtItem.progressPlan?.toString() ?? '', // convert to string
    actual: dtItem.progressActual?.toString() || '', // convert to string
    eiaStatus: dtItem.mdreportStatusId ?? null,
    eiaHighlight: dtItem.highlightActivity ?? '',
    eiaCompliance: dtItem.eiacompliance ?? '',
    submission: dtItem.sixMonth ?? '',
    activity: dtItem.complianceHighlight ?? '',
    modifyDate: dtItem.modifyDate ?? null,
    modifyBy: dtItem.modifyByDisplay ?? null,
    projectName: prItem.projectName ?? '',
    reportType: prItem.mdreportTypeName ?? '',
    projectType: prItem.mdprojectTypeName ?? '',
    formType: prItem.mddashboardFormName ?? '',
    formTypeId: prItem.mddashboardFormId ?? 0,
    projectTypeId: prItem.mdprojectTypeId ?? 0,
    isDraft: dtItem.isDraft,
  }
}

export const UpdateHistoryTable = () => {
  const [searchParams] = useSearchParams()
  const id = parseInt(checkValue(searchParams.get('id')))
  const { data: resultProject, isPending } = useGetProjectDashboardWithIdQRY({ id })
  const [dataList, setDataList] = React.useState<DataType[]>([])

  useEffect(() => {
    const newList: DataType[] = []
    resultProject?.projectDashboardDtOutDtos?.forEach((item, index) => {
      newList.push(generateData(resultProject as IProjectDashboardOutDto, item, index + 1))
    })
    setDataList(newList)
  }, [resultProject])

  const navigate = useNavigate()
  const handleClickOkNewProjectModal = useCallback(
    (record: DataType) => {
      const value: INewReqModalProject = {
        flowsType: 'view',
        id: record.key,
        projectDashboardId: record.key,
        projectName: searchParams.get('projectName') ?? '',
        projectStatus: searchParams.get('projectStatus') ?? '',
        projectTypeValue: record.projectType.toString(),
        reportTypeValue: record.reportType.toString(),
        reportTypeId: searchParams.get('reportTypeId') as unknown as number,
        formTypeValue: record.formType.toString(),
        projectTypeId: record.projectTypeId,
        formTypeId: record.formTypeId,
        isDraft: record.isDraft,
      }
      navigate(paths.projectStatusUpdateNewProject({ query: value }))
    },
    [navigate, searchParams],
  )
  const columns: ColumnsType<DataType> = [
    {
      title: 'NO.',
      align: 'center',
      dataIndex: 'no',
      key: 'no',
      width: 10,
      render: (text) => <div css={columnTitleStyles}>{text}</div>,
    },
    {
      title: 'PLAN',
      align: 'center',
      dataIndex: 'plan',
      key: 'plan',
      width: 10,
      render: (text) => <div css={columnTextStyles}>{text}</div>,
    },
    {
      title: 'ACTUAL',
      align: 'center',
      dataIndex: 'actual',
      key: 'actual',
      width: 10,
      render: (text) => <div css={columnTextStyles}>{text}</div>,
    },
    {
      title: 'REPORT STATUS',
      align: 'center',
      dataIndex: 'eiaStatus',
      key: 'eiaStatus',
      width: 10,
      render: (text) => <div css={columnTextStyles}>{text}</div>,
    },

    {
      title: (
        <div css={columnTitleStyles}>
          <Txt ag="reg14">EIA HIGHLIGHT ACTIVITIES</Txt>
        </div>
      ),
      key: 'eiaHighlight',
      dataIndex: 'eiaHighlight',
      width: 300,
      render: (text) => (
        <Tooltip title={text} trigger="hover">
          <div
            css={css`
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 2;
              overflow: hidden;
              text-overflow: ellipsis;
            `}
          >
            {text}
          </div>
        </Tooltip>
      ),
    },
    {
      title: <Txt ag="reg14">EIA COMPLIANCE</Txt>,
      align: 'center',
      key: 'eiaCompliance',
      dataIndex: 'eiaCompliance',
      width: 150,
      render: (_, record) => {
        if (record.eiaCompliance) {
          return <span>{record.eiaCompliance === '0' ? <CheckIcon alt="check" /> : <CloseIcon alt="close" />}</span>
        }
      },
    },
    {
      title: (
        <div css={columnTitleStyles}>
          <Txt ag="reg14">SIX MONTH REPORT SUBMISSION</Txt>
        </div>
      ),
      key: 'submission',
      dataIndex: 'submission',
      width: 200,
      render: (text) => (
        <div
          css={css`
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            overflow: hidden;
            text-overflow: ellipsis;
          `}
        >
          {text}
        </div>
      ),
    },
    {
      title: (
        <div css={columnTitleStyles}>
          <Txt ag="reg14">EIA COMPLIANCE HIGHLIGHT ACTIVITIES</Txt>
        </div>
      ),
      key: 'activity',
      dataIndex: 'activity',
      width: 250,
      render: (text) => (
        <div
          css={css`
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 5;
            overflow: hidden;
            text-overflow: ellipsis;
          `}
        >
          {text}
        </div>
      ),
    },
    {
      title: 'MODIFY DATE',
      align: 'center',
      key: 'modifyDate',
      dataIndex: 'modifyDate',
      width: 100,
      render: (text) => <div css={columnTextStyles}>{text}</div>,
    },
    {
      title: (
        <div css={columnTitleStyles}>
          <Txt ag="reg14">MODIFY BY</Txt>
        </div>
      ),
      key: 'modifyBy',
      dataIndex: 'modifyBy',
      width: 100,
    },
    {
      title: 'VIEW',
      align: 'center',
      dataIndex: 'view',
      key: 'view',
      width: 10,
      render: (_, record) => (
        <div
          css={css`
            text-align: center;
          `}
        >
          <button
            onClick={() => handleClickOkNewProjectModal(record)}
            css={css`
              justify-content: center;
              background-color: transparent;
              border: 0 solid;
              cursor: pointer;
            `}
          >
            <FiEye
              css={css`
                font-size: 18px;
                color: ${record.isDraft === 0 ? colors.secondary : colors.gray};

                &:hover {
                  color: ${record.isDraft === 0 ? colors.secondary : colors.primary};
                }
              `}
            />
          </button>
        </div>
      ),
    },
  ]
  return (
    <Table
      bordered
      loading={isPending}
      pagination={{ defaultCurrent: 1, defaultPageSize: 5 }}
      columns={columns}
      dataSource={dataList}
    />
  )
}
