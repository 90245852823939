import {
  QueryCache,
  QueryKey,
  UndefinedInitialDataOptions,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query'

import { pttClient } from '../ptt-client'
import { IResponseWithData } from '../services-types'

import {
  IProjectDashboardExportParam,
  IProjectDashboardListParam,
  IProjectDashboardParam,
  IProjectDashboardSaveEmployeesParam,
  IProjectDashboardWithId,
} from './project-dashboard-param'
import {
  IProjectDashboardExportResponse,
  IProjectDashboardListResponse,
  IProjectDashboardOutDto,
  IProjectDashboardReportResponse,
  IProjectDashboardResponse,
  IProjectDashboardWithIDResponse,
} from './project-dashboard-response'

const PROJECT_DASHBOARD_URL = '/ProjectDashboard'
const PROJECT_DASHBOARD_CHART_URL = `${PROJECT_DASHBOARD_URL}/ProjectDashboardChart`
const PROJECT_DASHBOARD_REPORT_URL = `${PROJECT_DASHBOARD_URL}/ProjectDashboardReport`
const PROJECT_DASHBOARD_EXPORT_URL = `${PROJECT_DASHBOARD_URL}/ProjectDashboardExport`
const PROJECT_DASHBOARD_LIST_URL = `${PROJECT_DASHBOARD_URL}/ProjectDashboardList`
const PROJECT_DASHBOARD_WITH_ID_URL = `${PROJECT_DASHBOARD_URL}/ProjectDashboard`
const PROJECT_DASHBOARD_PUT_URL = `${PROJECT_DASHBOARD_URL}/ProjectDashboard`
const PROJECT_DASHBOARD_DT_WITH_ID_URL = `${PROJECT_DASHBOARD_URL}/ProjectDashboardDt`

export const useGetProjectDashboardChartQRY = (
  params?: IProjectDashboardParam,
  options?: UndefinedInitialDataOptions<IProjectDashboardResponse, Error, IProjectDashboardResponse, QueryKey>,
) => {
  const queryCache = new QueryCache()
  queryCache.find({ queryKey: [PROJECT_DASHBOARD_CHART_URL, { params }] })
  queryCache.clear()
  return useQuery<IProjectDashboardResponse>({
    queryKey: [PROJECT_DASHBOARD_CHART_URL, { params }],
    queryFn: async (): Promise<IProjectDashboardResponse> => {
      const res = await pttClient.get<IResponseWithData<IProjectDashboardResponse>>(PROJECT_DASHBOARD_CHART_URL, {
        params,
      })
      return res.data.data
    },
    ...options,
  })
}

export const useGetProjectDashboardReportQRY = (
  params?: IProjectDashboardParam,
  options?: UndefinedInitialDataOptions<
    IProjectDashboardReportResponse[],
    Error,
    IProjectDashboardReportResponse[],
    QueryKey
  >,
) => {
  return useQuery<IProjectDashboardReportResponse[]>({
    queryKey: [PROJECT_DASHBOARD_REPORT_URL, { params }],
    queryFn: async (): Promise<IProjectDashboardReportResponse[]> => {
      const res = await pttClient.get<IResponseWithData<IProjectDashboardReportResponse[]>>(
        PROJECT_DASHBOARD_REPORT_URL,
        {
          params,
        },
      )
      return res.data.data
    },
    ...options,
  })
}

export const useGetProjectDashboardListQRY = (params: IProjectDashboardListParam) => {
  return useQuery<IProjectDashboardListResponse[], Error>({
    queryKey: [PROJECT_DASHBOARD_LIST_URL, { params }],
    queryFn: async (): Promise<IProjectDashboardListResponse[]> => {
      const res = await pttClient.get<IResponseWithData<IProjectDashboardListResponse[]>>(PROJECT_DASHBOARD_LIST_URL, {
        params,
      })
      return res.data.data
    },
  })
}

export const useProjectDashboardExportMTT = () => {
  return useMutation({
    mutationFn: async (params: IProjectDashboardExportParam) => {
      const { data } = await pttClient.get<IResponseWithData<IProjectDashboardExportResponse>>(
        PROJECT_DASHBOARD_EXPORT_URL,
        {
          params,
        },
      )
      return data
    },
  })
}

export const usePutProjectDashboardMTT = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: async (params: IProjectDashboardSaveEmployeesParam) => {
      const { data } = await pttClient.put<IResponseWithData<IProjectDashboardOutDto>>(
        PROJECT_DASHBOARD_PUT_URL,
        params,
      )
      return data
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [PROJECT_DASHBOARD_LIST_URL] })
      queryClient.invalidateQueries({ queryKey: [PROJECT_DASHBOARD_WITH_ID_URL] })
      queryClient.invalidateQueries({ queryKey: [PROJECT_DASHBOARD_REPORT_URL] })
      // message.success('เพิ่มข้อมูลประเภทโครงการสำเร็จ')
    },
  })
}

export const usePostProjectDashboardMTT = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: async (params: IProjectDashboardSaveEmployeesParam) => {
      const { data } = await pttClient.post<IResponseWithData<IProjectDashboardOutDto>>(
        PROJECT_DASHBOARD_PUT_URL,
        params,
      )
      return data
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [PROJECT_DASHBOARD_PUT_URL] })
      queryClient.invalidateQueries({ queryKey: [PROJECT_DASHBOARD_LIST_URL] })
      // message.success('เพิ่มข้อมูลประเภทโครงการสำเร็จ')
    },
  })
}

export const usePostProjectDashboardDtMTT = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: async (params: IProjectDashboardSaveEmployeesParam) => {
      const { data } = await pttClient.post<IResponseWithData<IProjectDashboardOutDto>>(
        PROJECT_DASHBOARD_DT_WITH_ID_URL,
        params,
      )
      return data
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [PROJECT_DASHBOARD_WITH_ID_URL] })
      queryClient.invalidateQueries({ queryKey: [PROJECT_DASHBOARD_DT_WITH_ID_URL] })
    },
  })
}

export const usePutProjectDashboardDtMTT = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: async (params: IProjectDashboardSaveEmployeesParam) => {
      const { data } = await pttClient.put<IResponseWithData<IProjectDashboardOutDto>>(
        PROJECT_DASHBOARD_DT_WITH_ID_URL,
        params,
      )
      return data
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [PROJECT_DASHBOARD_WITH_ID_URL] })
      queryClient.invalidateQueries({ queryKey: [PROJECT_DASHBOARD_DT_WITH_ID_URL] })
    },
  })
}

export const useGetProjectDashboardWithIdQRY = (params?: IProjectDashboardWithId) => {
  const queryCache = new QueryCache()
  queryCache.find({ queryKey: [PROJECT_DASHBOARD_WITH_ID_URL, { params }] })
  queryCache.clear()
  return useQuery<IProjectDashboardWithIDResponse>({
    queryKey: [PROJECT_DASHBOARD_WITH_ID_URL, { params }],
    queryFn: async (): Promise<IProjectDashboardWithIDResponse> => {
      const res = await pttClient.get<IResponseWithData<IProjectDashboardWithIDResponse>>(
        PROJECT_DASHBOARD_WITH_ID_URL,
        {
          params,
        },
      )
      return res.data.data
    },
  })
}

export const useGetProjectDashboardDtWithIdQRY = (params?: IProjectDashboardWithId) => {
  const queryCache = new QueryCache()
  queryCache.find({ queryKey: [PROJECT_DASHBOARD_WITH_ID_URL, { params }] })
  queryCache.clear()
  return useQuery<IProjectDashboardWithIDResponse>({
    queryKey: [PROJECT_DASHBOARD_DT_WITH_ID_URL, { params }],
    queryFn: async (): Promise<IProjectDashboardWithIDResponse> => {
      const res = await pttClient.get<IResponseWithData<IProjectDashboardWithIDResponse>>(
        PROJECT_DASHBOARD_DT_WITH_ID_URL,
        {
          params,
        },
      )
      return res.data.data
    },
    enabled: !!params?.id,
  })
}
