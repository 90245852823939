import { css, keyframes } from '@ptt-eia-web/helpers'
import { Col, Row } from 'antd'

import { colors } from '@frontend/app/theme'

import HomeBg2 from '../../assets/home/home-bg-2.jpeg'
import HomeBg1 from '../../assets/svgs/home-bg-1.svg'

import { Menu } from './Menu'

const textDown = keyframes`
  from {
    transform: translate3d(0, -150%, 0);
  }
  to {
    transform: translate3d(0, 0, 0);
  }
`
const textUp = keyframes`
  from {
    transform: translate3d(0, 100%, 0);
  }
  to {
    transform: translate3d(0, 0, 0);
  }
`

export const BannerHome: React.FC = () => {
  return (
    <Row
      css={css`
        height: 800px;
        overflow: hidden;
        position: relative;
        z-index: 10;
      `}
    >
      <Col span={24}>
        <div
          css={css`
            background-image: url(${HomeBg2});
            background-size: cover;
            background-position: center 35%;
            background-repeat: no-repeat;
            width: 100%;
            height: 100%;
            z-index: -1;
            position: absolute;
            animation-name: zoomIn;
            animation-duration: 5s;
            animation-timing-function: ease-in-out;
            animation-fill-mode: revert;

            @keyframes zoomIn {
              from {
                transform: scale(1.2);
              }
              to {
                transform: scale(1);
              }
            }
          `}
        />
        <div
          data-testid="fe-banner-home-content"
          css={css`
            background-image: url(${HomeBg1});
            background-size: cover;
            background-color: transparent;
            background-repeat: no-repeat;

            top: 0;
            z-index: 2;
            height: 8vw;
          `}
        />
        <div
          css={css`
            padding: 0px 54px;
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            @media screen and (max-width: 990px) {
              justify-content: center;
            }
          `}
        >
          <div
            css={css`
              width: 430px;
            `}
          >
            <div
              css={css`
                font-size: 27px;
                color: white;
                animation: ${textDown} 0.4s linear 1 forwards;
              `}
            >
              PROJECT
            </div>
            <div
              css={css`
                position: relative;
                height: 50px;
                margin-top: 12px;
                margin-left: -1px;
              `}
            >
              <div
                css={css`
                  position: absolute;
                  left: calc(50% - 43px);
                  z-index: 1;
                  height: 50px;
                `}
              >
                <svg
                  id="uuid-ca8e3320-9f72-4da3-9a0c-e3edc3f23f67"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 96.68 97.87"
                  width="50px"
                >
                  <path
                    d="M46.21,0h50.47v51.66c0,25.51-20.71,46.21-46.21,46.21H0v-51.66C0,20.71,20.71,0,46.21,0Z"
                    fill="#78b943"
                    stroke-width="0"
                  />
                </svg>
              </div>
              <div
                css={css`
                  position: absolute;
                  font-size: 48px;
                  color: white;
                  z-index: 2;
                  line-height: 60px;
                  animation: ${textUp} 0.8s linear 1 forwards;
                `}
              >
                ENVIRONMENTAL
              </div>
            </div>
            <div
              css={css`
                margin-top: 22px;
                animation: ${textUp} 0.8s linear 1 forwards;
              `}
            >
              <div
                css={css`
                  font-size: 32px;
                  color: white;
                  width: fit-content;
                  background-color: ${colors.secondary};
                  padding: 4px 10px;
                `}
              >
                MANAGEMENT SERVICES
              </div>
            </div>
          </div>

          <Menu />
        </div>
      </Col>
    </Row>
  )
}
