import styled from '@emotion/styled'
import { MenuCodeEnum, PermissionActionEnum } from '@ptt-eia-web/constants'
import { useGetAboutUsQRY, usePermission } from '@ptt-eia-web/services'
import { useMemo } from 'react'
import { Navigate } from 'react-router-dom'

import { permissionConfigs } from '@frontend/app/permission-configs'

import { SectionMission } from './SectionMission'
import { SectionPMEDProfile } from './SectionPEMDProfile'
import { SectionServices } from './SectionServices'
import { SectionTeamInfo } from './SectionTeamInfo'
import { SectionVision } from './SectionVision'

const LayoutAboutUs = styled.div`
  width: 100%;
`

export const PageAboutUs = () => {
  const { data } = useGetAboutUsQRY()
  const { can } = usePermission(permissionConfigs)
  const canView = can(MenuCodeEnum.AboutUs, PermissionActionEnum.View)

  const activePemdProfiles = useMemo(() => {
    return data?.pemdProfiles?.filter((item) => item.active === 1) ?? []
  }, [data?.pemdProfiles])

  if (!data) return null
  if (!canView) return <Navigate to="/404" />

  return (
    <LayoutAboutUs>
      <SectionVision text={data.vision} imageUrl={data.visionPicturePath} />
      <SectionMission text={data.mission} imageUrl={data.missionPicturePath} />
      <SectionTeamInfo teamInfos={data.teamInfos} />
      <SectionServices text={data.service} imageUrl={data.servicePicturePath} />
      <SectionPMEDProfile profiles={activePemdProfiles} />
    </LayoutAboutUs>
  )
}
