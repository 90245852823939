import { css } from '@ptt-eia-web/helpers'
import { ProjectDashboardLTAInDtos, ProjectDashboardSHEInDtos } from '@ptt-eia-web/services'
import React, { useEffect, useState } from 'react'

import { colors } from '../../../../../../app/theme'
import { EIAPerformance } from '../EIAPerformance'
import { FormInput } from '../FormInput'
import { SHEPerformance } from '../SHEPerformance'
import { SecondForm } from '../SecondForm'
import { WithoutLTA } from '../WithoutLTA'

import { EnvComplianceAndMonitoring } from './EnvComplianceAndMonitoring'

interface TransPipelineAndPlantProps {
  onProjectDashboardSHEInDtos: (arg0: ProjectDashboardSHEInDtos[]) => void
  onProjectDashboardLTAInDtos: (arg0: ProjectDashboardLTAInDtos[]) => void
  onValidateInputPhaseLTASuccess: (arg0: boolean) => void
  dataSourceSHE: ProjectDashboardSHEInDtos[]
  dataSourceLTA: ProjectDashboardLTAInDtos[]
}

export const TransPipelineAndPlant = (props: TransPipelineAndPlantProps) => {
  const [sheDataSource, setSheDataSource] = useState<ProjectDashboardSHEInDtos[]>([])
  useEffect(() => {
    setSheDataSource(props.dataSourceSHE)
  }, [props.dataSourceSHE])

  return (
    <div
      css={css`
        height: 100%;
        padding-bottom: 60px;
        background-color: white;
        border-radius: 15px;
        border: 1px solid ${colors.line};
        box-shadow: 0px 2px 6px #0000000a;
      `}
    >
      <FormInput />
      <SecondForm />
      <EnvComplianceAndMonitoring />
      <SHEPerformance
        dataSourceLTA={props.dataSourceLTA}
        dataSource={props.dataSourceSHE}
        onProjectDashboardSHEInDtos={(newDataSHE: ProjectDashboardSHEInDtos[]) =>
          props.onProjectDashboardSHEInDtos(newDataSHE)
        }
      />
      <WithoutLTA
        onValidateInputPhaseLTASuccess={(isSuccess: boolean) => props.onValidateInputPhaseLTASuccess(isSuccess)}
        dataSourceLTA={props.dataSourceLTA}
        onProjectDashboardLTAInDtos={(newDataLTA: ProjectDashboardLTAInDtos[]) =>
          props.onProjectDashboardLTAInDtos(newDataLTA)
        }
        dataSourceSHE={sheDataSource}
      />
      <EIAPerformance />
    </div>
  )
}
