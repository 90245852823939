import { css, downloadExcelBase64 } from '@ptt-eia-web/helpers'
import { IServiceRequestParam, useServiceRequestExportRequestListMTT } from '@ptt-eia-web/services'
import { Button } from 'antd'
import dayjs from 'dayjs'
import { isEmpty } from 'lodash'
import { useCallback, useEffect, useMemo } from 'react'
import { useSearchParams } from 'react-router-dom'
const CURRENT_YEAR = dayjs().year()
const getParam = (param: string | null) => {
  return isEmpty(param) ? null : Number(param)
}

export const ExportRequestListButton = () => {
  const [searchParams] = useSearchParams()
  const filters = useMemo((): IServiceRequestParam => {
    const serviceTypeId = getParam(searchParams.get('serviceTypeId'))
    const projectTypeId = getParam(searchParams.get('projectTypeId'))
    const year = getParam(searchParams.get('year')) ?? CURRENT_YEAR

    return { projectTypeId, serviceTypeId, year }
  }, [searchParams])

  const { mutateAsync: exportRequestList, isPending, data: response } = useServiceRequestExportRequestListMTT()

  const handleSubmit = useCallback(async () => {
    await exportRequestList(filters)
  }, [exportRequestList, filters])

  useEffect(() => {
    if (response?.data?.base64String) {
      downloadExcelBase64(response?.data?.base64String, response?.data?.fileName)
    }
  }, [response])

  return (
    <Button
      loading={isPending}
      onClick={() => handleSubmit()}
      type="primary"
      css={css`
        width: 150px;
        position: absolute;
        right: 0px;
        top: 18px;
      `}
    >
      Export
    </Button>
  )
}
