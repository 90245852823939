import { MenuCodeEnum, PermissionActionEnum } from '@ptt-eia-web/constants'
import { css, getCSPNonce } from '@ptt-eia-web/helpers'
import { useGetMasterDashboardStatusQRY, usePermission, usePutProjectDashboardMTT } from '@ptt-eia-web/services'
import { Button, ConfigProvider, Flex, Form, notification } from 'antd'
import { useForm } from 'antd/es/form/Form'
import React, { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

import { permissionConfigs } from '@frontend/app/permission-configs'

import { colors } from '../../../../app/theme'
import { Title } from '../../Title'

import { UpdateHistoryForm } from './UpdateHistoryForm'

interface IHeaderProps {
  onClickReportEmail: () => void
}

interface IEvent {
  projectName: string
  projectStatus: string
}
export const Header = ({ onClickReportEmail }: IHeaderProps) => {
  const [form] = useForm()
  const [searchParams, setSearchParams] = useSearchParams()
  const [isChange, setIsChange] = useState(false)
  const { mutateAsync: updateProjectDashboard } = usePutProjectDashboardMTT()
  const [currentProjectName, setCurrentProjectName] = useState(searchParams.get('projectName'))
  const [currentProjectStatus, setCurrentProjectStatus] = useState(searchParams.get('projectStatusId'))
  const { can } = usePermission(permissionConfigs)
  const canEdit = can(MenuCodeEnum.ProjectStatusUpdate, PermissionActionEnum.Edit)
  const { data: dashboardStatus } = useGetMasterDashboardStatusQRY()
  useEffect(() => {
    form.setFieldsValue({
      projectName: searchParams.get('projectName'),
      projectType: searchParams.get('projectType'),
      reportType: searchParams.get('reportType'),
      formType: searchParams.get('formType'),
      projectStatus: searchParams.get('projectStatus'),
      projectStatusId: searchParams.get('projectStatusId'),
    })
  }, [form, searchParams])
  const handleOnChange = (event: IEvent) => {
    if (event.projectName) {
      setCurrentProjectName(event.projectName)
    } else {
      setCurrentProjectStatus(event.projectStatus)
    }
    setIsChange(true)
  }

  const handleSubmitEdit = async () => {
    const params = Object.fromEntries([...searchParams])
    const status = dashboardStatus?.find((item) => {
      return item.id === Number(currentProjectStatus)
    })
    setSearchParams({
      ...params,
      projectName: currentProjectName ?? '',
      projectStatus: status?.status ?? '',
      projectStatusId: String(status?.id) ?? '',
    })
    await updateProjectDashboard({
      Id: Number(searchParams.get('id')),
      ProjectName: currentProjectName,
      MdprojectTypeId: Number(searchParams.get('projectTypeId')),
      MdreportTypeId: Number(searchParams.get('reportTypeId')),
      MddashboardFormId: Number(searchParams.get('formTypeId')),
      MddashboardStatusId: status?.id,
    })
    notification.success({ message: 'Success' })
    form.setFieldsValue({ projectName: currentProjectName, projectStatus: status?.id })
    setIsChange(false)
  }

  return (
    <div>
      <Title titleFirst="Project" titleSecond="Status Update" />

      <Form form={form} layout="vertical" onValuesChange={(e) => handleOnChange(e)}>
        <div
          css={css`
            border-radius: 10px;
            background-color: white;
            padding: 40px 30px;
            font-weight: 500;
            border-width: 0.5px;
            border-style: solid;
            border-color: ${colors.line};
            box-shadow: 0.5px 0.5px 0.5px ${colors.line};
          `}
        >
          <UpdateHistoryForm />
          <Flex justify="end" gap={20}>
            <ConfigProvider
              csp={{ nonce: getCSPNonce() }}
              theme={{
                token: {
                  colorPrimary: `${colors.white}`,
                  colorBorder: `${colors.white}`,
                  colorBgContainer: `#00338d`,
                },
              }}
            >
              <Button
                onClick={onClickReportEmail}
                css={css`
                  background-color: #00338d;
                  color: ${colors.white};
                  border-color: #00338d;
                `}
              >
                รายชื่อผู้รับอีเมลแจ้งเตือน
              </Button>
              {canEdit && (
                <Form.Item name="submitEdit">
                  <Button
                    onClick={handleSubmitEdit}
                    disabled={!isChange}
                    css={css`
                      background-color: ${isChange ? '#00338d' : colors.gray};
                      color: ${colors.white};
                      border-color: ${isChange ? '#00338d' : colors.gray};
                    `}
                    htmlType="submit"
                  >
                    บันทึกแก้ไข
                  </Button>
                </Form.Item>
              )}
            </ConfigProvider>
          </Flex>
        </div>
      </Form>
    </div>
  )
}
