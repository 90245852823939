import styled from '@emotion/styled'
import { css } from '@ptt-eia-web/helpers'
import { IProjectDashboardResponse, useGetReportTypesQRY } from '@ptt-eia-web/services'
import { Col, Row } from 'antd'
import React from 'react'

import { Title } from '../Title'

import { ChartReport } from './ChartReport'
const HeaderStyle = styled.div`
  background-color: transparent;
  height: 100%;
`

export const ReportStatus: React.FC<{ dashboardChartData?: IProjectDashboardResponse }> = ({ dashboardChartData }) => {
  const { data: reportStatusData } = useGetReportTypesQRY()

  const checkSplit = (input: string) => {
    if (typeof input === 'string') {
      return input.split('#')
    }
    // Return null or some default value if input is not a string
    return null
  }
  return (
    <HeaderStyle>
      <Title titleFirst="REPORT" titleSecond="STATUS" />
      <Row justify="center">
        <Col>
          <div
            css={css`
              display: grid;
              grid-template-columns: repeat(5, 1fr);
              gap: 25px;
              padding: 0px 16px;
            `}
          >
            {reportStatusData?.map((item) => {
              // Check if the item color contains '#', if so, split it.
              const colorCodes = checkSplit(item.color)

              // Use the appropriate color code after checking and splitting
              const backgroundColor = colorCodes?.length ? colorCodes[1] ?? colorCodes[0] : 'dddddd'
              const backgroundColorCode = `#${backgroundColor}`
              if (item.active !== 1) {
                return null
              }
              return (
                <div
                  css={css`
                    display: flex;
                    column-gap: 10px;
                  `}
                  key={item.id}
                >
                  <div
                    css={css`
                      width: 15px;
                      height: 15px;
                      border-radius: 50%;
                      background-color: ${backgroundColorCode};
                    `}
                  />
                  {item.name}
                </div>
              )
            })}
          </div>
        </Col>
      </Row>
      <ChartReport />
    </HeaderStyle>
  )
}
