import styled from '@emotion/styled'
import { MenuCodeEnum, PermissionActionEnum } from '@ptt-eia-web/constants'
import {
  IProjectDashboardDtInDto,
  IProjectDashboardOutDto,
  IProjectDashboardSaveEmployeesParam,
  ProjectDashboardLTAInDtos,
  ProjectDashboardSHEInDtos,
  useGetMasterDashboardFormQRY,
  useGetProjectDashboardDtWithIdQRY,
  useGetProjectTypesQRY,
  useGetReportTypesQRY,
  usePermission,
  usePostProjectDashboardDtMTT,
  usePostProjectDashboardMTT,
  usePutProjectDashboardDtMTT,
} from '@ptt-eia-web/services'
import { Form, notification } from 'antd'
import { useForm } from 'antd/es/form/Form'
import React, { useCallback, useEffect, useState } from 'react'
import { Navigate, useNavigate, useSearchParams } from 'react-router-dom'

import { permissionConfigs } from '@frontend/app/permission-configs'
import { checkValue } from '@frontend/components/CheckValue'

import BgPieChartDashboard from '../../../../../assets/project-dashboard/bg-pie-dashboard.jpeg'
import { Title } from '../../../Title'

import { DistributionPipeline } from './DistributionPipeline/DistributionPipeline'
import { TransPipelineAndPlant } from './TransPipeline/TransPipelineAndPlant'
import { TsoAndOther } from './TsoAndOther/TsoAndOther'
import { WrapButton } from './WrapButton'
const LayoutStyle = styled.div`
  width: 100%;
  height: 100%;
  padding: 10px 64px;
  background-image: url(${BgPieChartDashboard});
  background-size: cover;
  background-position: top center;
  background-repeat: no-repeat;
`

interface IFormValues {
  projectType: string
  reportType: string
  formType: string
  projectName: string
  projectStatus: string
  eiaActive: number | boolean
  eiaActiveReason: string
  monitorReason: string
  eiaReportStatus: string
  plan: number
  actual: number
  highlightActivity: string
  wayForward: string
  eiaCompliance: number
  sixMonthReportSubmission: string
  eiaComplianceHighlightActivities: string
  monitorActive: number | boolean
  submitButton: number
}

export const PageNewProject = () => {
  const [projectDashboardSHEInDtos, setProjectDashboardSHEInDtos] = useState<ProjectDashboardSHEInDtos[]>([])
  const [projectDashboardLTAInDtos, setProjectDashboardLTAInDtos] = useState<ProjectDashboardLTAInDtos[]>([])
  const [isValidateInputPhaseLTASuccess, setIsValidateInputPhaseLTASuccess] = useState<boolean>(true)
  const navigate = useNavigate()
  const handleChangeProjectDashboardSHEInDtos = useCallback(
    (data: ProjectDashboardSHEInDtos[]) => {
      setProjectDashboardSHEInDtos(data)
    },
    [setProjectDashboardSHEInDtos],
  )
  const handleChangeProjectDashboardLTAInDtos = useCallback(
    (data: ProjectDashboardLTAInDtos[]) => {
      setProjectDashboardLTAInDtos(data)
    },
    [setProjectDashboardLTAInDtos],
  )
  const [searchParams] = useSearchParams()
  const [form] = useForm()
  const { data: reportTypeList } = useGetReportTypesQRY()
  const { data: projectTypeList } = useGetProjectTypesQRY()
  const { data: formList } = useGetMasterDashboardFormQRY()
  const latestHistoryId = parseInt(checkValue(searchParams.get('latestHistoryId')))
  const idForGetData = parseInt(checkValue(searchParams.get('id') ?? '0'))
  const { data: resultProject } = useGetProjectDashboardDtWithIdQRY({
    id: latestHistoryId ? latestHistoryId : idForGetData,
  })

  const flowType = checkValue(searchParams.get('flowsType'))

  const { can } = usePermission(permissionConfigs)
  const canView = can(MenuCodeEnum.ProjectStatusUpdate, PermissionActionEnum.View)
  useEffect(() => {
    form.setFieldsValue({ formType: searchParams.get('formTypeId') })
  }, [form, searchParams])

  useEffect(() => {
    if (flowType === 'view' || (latestHistoryId != null && flowType !== 'new')) {
      form.setFieldsValue({
        projectName: searchParams.get('projectName'),
        projectType: resultProject?.mdprojectTypeName,
        reportType: resultProject?.mdreportTypeName,
        formType: resultProject?.mddashboardFormName,
        projectStatus: searchParams.get('projectStatus'),
        eiaActive: resultProject?.projectDashboardDtOutDtos[0].eiaactive,
        eiaActiveReason: resultProject?.projectDashboardDtOutDtos[0].eiaactiveReason,
        eiaReportStatus: resultProject?.projectDashboardDtOutDtos[0].mdreportStatusId,
        plan: resultProject?.projectDashboardDtOutDtos[0].progressPlan,
        actual: resultProject?.projectDashboardDtOutDtos[0].progressActual,
        highlightActivity: resultProject?.projectDashboardDtOutDtos[0].highlightActivity,
        wayForward: resultProject?.projectDashboardDtOutDtos[0].wayForward,
        monitorActive: resultProject?.projectDashboardDtOutDtos[0].monitorActive,
        monitorReason: resultProject?.projectDashboardDtOutDtos[0].monitorReason,
        eiaCompliance: resultProject?.projectDashboardDtOutDtos[0].eiacompliance ?? '0',
        sixMonthReportSubmission: resultProject?.projectDashboardDtOutDtos[0].sixMonth,
        eiaComplianceHighlightActivities: resultProject?.projectDashboardDtOutDtos[0].complianceHighlight,
        updateBy: resultProject?.projectDashboardDtOutDtos[0].modifyByDisplay,
        updateDate: resultProject?.projectDashboardDtOutDtos[0].modifyDate,
      })

      const sheOutDtos = resultProject?.projectDashboardDtOutDtos[0]?.projectDashboardSHEOutDtos

      const sheInDtos =
        sheOutDtos?.map((outDto: IProjectDashboardOutDto) => {
          const sheInDto: ProjectDashboardSHEInDtos = {
            Id: outDto.id,
            Tbdashboardid: outDto.tbdashboardDtid,
            Phase: outDto.phase,
            PhaseNote: outDto.phaseNote,
            CurentYearMan: outDto.curentYearMan,
            TotalMan: outDto.totalMan,
            TargetMan: outDto.targetMan,
            Lta: outDto.lta,
            Trir: outDto.trir,
          }

          return sheInDto
        }) ?? []
      setProjectDashboardSHEInDtos(sheInDtos)

      const ltaOutDtos = resultProject?.projectDashboardDtOutDtos[0]?.projectDashboardLTAOutDtos

      const ltaInDtos =
        ltaOutDtos?.map((outDto: IProjectDashboardOutDto) => {
          const ltaOutDto: ProjectDashboardLTAInDtos = {
            Id: outDto.id,
            Tbdashboardid: outDto.tbdashboardDtid,
            Phase: outDto.phase,
            CurentYearMan: outDto.curentYearMan,
            TotalMan: outDto.totalMan,
            TargetMan: outDto.targetMan,
          }

          return ltaOutDto
        }) ?? []
      setProjectDashboardLTAInDtos(ltaInDtos)
    } else {
      form.setFieldsValue({
        projectType: searchParams.get('projectTypeValue'),
        reportType: searchParams.get('reportTypeValue'),
        formType: searchParams.get('formTypeValue'),
        projectName: searchParams.get('projectName'),
        projectStatus: searchParams.get('projectStatus'),
      })
    }
  }, [reportTypeList, projectTypeList, formList, latestHistoryId, searchParams, flowType, resultProject, form])

  const { mutateAsync: createNewProjectList } = usePostProjectDashboardMTT()
  const { mutateAsync: addHistoryList } = usePostProjectDashboardDtMTT()
  const { mutateAsync: updateHistoryList } = usePutProjectDashboardDtMTT()
  const prepareTableFormOne = useCallback(
    (tableObject?: ProjectDashboardLTAInDtos[] | ProjectDashboardSHEInDtos[]) => {
      return tableObject?.map((item) => {
        if (item.IsNewData || flowType === 'add') {
          return { ...item, Id: 0 }
        }
        return item
      })
    },
    [flowType],
  )
  const onFinish = useCallback(
    async (values: IFormValues) => {
      let parentId = 0
      let isAddHistory = false
      if (flowType === 'view') {
        parentId = parseInt(checkValue(searchParams.get('projectDashboardId')))
      } else if (flowType === 'add') {
        parentId = parseInt(checkValue(searchParams.get('id')))
        isAddHistory = true
      }
      const dtInDtoList: IProjectDashboardDtInDto = {
        Id: isAddHistory ? 0 : parentId,
        TbdashboardId: parseInt(checkValue(searchParams.get('id'))),
        Eiaactive: values.eiaActive === true || values.eiaActive === 1 || values.eiaActive === undefined ? 1 : 0,
        EiaactiveReason: values.eiaActiveReason,
        MdreportStatusId: parseInt(values.eiaReportStatus),
        ProgressPlan: values.plan,
        ProgressActual: values.actual,
        HighlightActivity: values.highlightActivity,
        WayForward: values.wayForward,
        MonitorActive:
          values.monitorActive === true || values.monitorActive === 1 || values.monitorActive === undefined ? 1 : 0,
        MonitorReason: values.monitorReason,
        Eiacompliance: values.eiaCompliance === undefined || values.eiaCompliance?.toString() === '0' ? '0' : '1',
        SixMonth: values.sixMonthReportSubmission,
        ComplianceHighlight: values.eiaComplianceHighlightActivities,
        IsDraft: values.submitButton,
        ProjectDashboardLTAInDtos: prepareTableFormOne(projectDashboardLTAInDtos),
        ProjectDashboardSHEInDtos: prepareTableFormOne(projectDashboardSHEInDtos),
      }

      const dataCreate: IProjectDashboardSaveEmployeesParam = {
        Id: 0,
        ProjectName: values.projectName,
        MdprojectTypeId: parseInt(checkValue(searchParams.get('projectTypeId'))),
        MdreportTypeId: parseInt(checkValue(searchParams.get('reportTypeId'))),
        MddashboardFormId: parseInt(checkValue(searchParams.get('formTypeId'))),
        MddashboardStatusId: parseInt(values.projectStatus),
        ProjectDashboardDtInDto: dtInDtoList,
      }
      const dataUpdate: IProjectDashboardSaveEmployeesParam = {
        Id: parentId,
        ProjectDashboardDtInDto: dtInDtoList,
      }
      if (flowType === 'add') {
        await addHistoryList(dataUpdate)
      } else if (flowType === 'view') {
        await updateHistoryList(dataUpdate)
      } else {
        await createNewProjectList(dataCreate)
      }
      notification.success({ message: 'Success' })
      navigate(-1)
    },
    [
      navigate,
      createNewProjectList,
      addHistoryList,
      updateHistoryList,
      searchParams,
      flowType,
      projectDashboardLTAInDtos,
      projectDashboardSHEInDtos,
      prepareTableFormOne,
    ],
  )
  let projectComponent = null

  if (parseInt(searchParams.get('formTypeId') ?? '0') === 1) {
    projectComponent = (
      <TransPipelineAndPlant
        onValidateInputPhaseLTASuccess={(isSuccess: boolean) => setIsValidateInputPhaseLTASuccess(isSuccess)}
        dataSourceSHE={projectDashboardSHEInDtos}
        onProjectDashboardSHEInDtos={(newDataSHE: ProjectDashboardSHEInDtos[]) =>
          handleChangeProjectDashboardSHEInDtos(newDataSHE)
        }
        dataSourceLTA={projectDashboardLTAInDtos}
        onProjectDashboardLTAInDtos={(newDataLTA: ProjectDashboardLTAInDtos[]) =>
          handleChangeProjectDashboardLTAInDtos(newDataLTA)
        }
      />
    )
  } else if (parseInt(searchParams.get('formTypeId') ?? '0') === 2) {
    projectComponent = <DistributionPipeline />
  } else {
    projectComponent = <TsoAndOther />
  }

  if (!canView) return <Navigate to="/404" />
  return (
    <LayoutStyle>
      <Title titleFirst="Project Status Update >" titleSecond="New Project" />
      <Form name="dynamic_form_nest_item" onFinish={onFinish} form={form} layout="vertical" scrollToFirstError>
        {projectComponent}
        <WrapButton
          sourceLTA={projectDashboardLTAInDtos}
          sourceSHE={projectDashboardSHEInDtos}
          isInputPhaseLTASuccess={isValidateInputPhaseLTASuccess}
        />
      </Form>
    </LayoutStyle>
  )
}
