import { css, downloadExcelBase64 } from '@ptt-eia-web/helpers'
import { IProjectDashboardExportParam, useProjectDashboardExportMTT } from '@ptt-eia-web/services'
import { Button } from 'antd'
import React, { useCallback, useEffect } from 'react'

interface IExportButtonProps {
  projectId: number | undefined
  reportId: number | undefined
  year: number | undefined
}

export const ExportButton = (props: IExportButtonProps) => {
  const { mutateAsync: exportTable, isPending, data: response } = useProjectDashboardExportMTT()
  const handleSubmit = useCallback(async () => {
    const values: IProjectDashboardExportParam = {
      MdprojectTypeId: props.projectId,
      MdreportTypeId: !isNaN(props.reportId ?? 0) ? props.reportId : undefined,
      Year: !isNaN(props.year ?? 0) ? props.year : undefined,
    }
    await exportTable(values)
  }, [exportTable, props])
  useEffect(() => {
    if (response?.data?.base64String) {
      downloadExcelBase64(response?.data?.base64String, response?.data?.fileName)
    }
  }, [response])

  return (
    <Button
      loading={isPending}
      onClick={() => handleSubmit()}
      type="primary"
      css={css`
        width: 120px;
      `}
    >
      Export
    </Button>
  )
}
