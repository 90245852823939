import { SafeImage } from '@ptt-eia-web/components'
import { css, mediaQuery, keyframes, getFullUrl } from '@ptt-eia-web/helpers'
import { IAboutUsTeamResponse } from '@ptt-eia-web/services'
import { Collapse, CollapseProps } from 'antd'
import { AnyObject } from 'antd/es/_util/type'
import { isEmpty, sortBy } from 'lodash'
import { useMemo } from 'react'
import { IoChevronDownOutline, IoChevronUpOutline } from 'react-icons/io5'

import { colors } from '@frontend/app/theme'
import bgTeamContentImage from '@frontend/assets/about-us/bg-team-content.jpg'
import bgTeamHeaderImage from '@frontend/assets/about-us/bg-team-header.jpg'
import defaultAvatarImage from '@frontend/assets/images/default-avatar.jpg'
import { HeaderTitle } from '@frontend/components/HeaderTitle'
import { Txt } from '@frontend/components/Txt'

const slideUp = keyframes`
  from {
    opacity:1;
    transform: translate3d(0, 100%, 0);
  }
  to {
    opacity:1;
    transform: translate3d(0, 0, 0);
  }
`
const TeamInfoHeader = () => {
  return (
    <div
      css={css`
        height: 500px;
        .header-title {
          position: absolute;
          top: 80px;
          left: 50%;
          transform: translate(-50%, -50%);
        }
        @media ${mediaQuery.xxlGte} {
          height: calc(500px + 15vw);
        }
      `}
    >
      <img
        src={bgTeamHeaderImage}
        alt="team-info-bg"
        css={css`
          width: 100%;
          height: 500px;
          object-fit: cover;
          @media ${mediaQuery.xxlGte} {
            height: calc(500px + 15vw);
          }
        `}
      />
      <HeaderTitle titleFirst="Team" titleSecond="Info" />
    </div>
  )
}

function handleAvatarImageError(e: AnyObject) {
  e.target.src = defaultAvatarImage
}

interface ITeamInfoItemProps {
  teamInfo: IAboutUsTeamResponse
  animationDelay: number
}

const TeamInfoItem = ({ teamInfo, animationDelay }: ITeamInfoItemProps) => {
  const avatarUrl = teamInfo.picturePath ? getFullUrl(teamInfo.picturePath) : defaultAvatarImage
  return (
    <div
      id="test_render_img"
      css={css`
        text-align: center;
        opacity: 0;
        animation: ${slideUp} 0.3s linear ${animationDelay}s forwards;
      `}
    >
      <SafeImage
        id={`team_image_${teamInfo.id}`}
        alt="team-info"
        url={avatarUrl}
        onError={handleAvatarImageError}
        css={css`
          width: 100px;
          height: 100px;
          object-fit: cover;
          border-radius: 50%;
          border: 2px solid ${colors.secondary};
        `}
      />
      <div
        css={css`
          margin-top: 8px;
        `}
      >
        <Txt ag="med14" color="black">
          {teamInfo.fullName}
        </Txt>
      </div>
      <div>
        <Txt ag="reg10" color="labelText">
          {isEmpty(teamInfo.posName) ? teamInfo.unitName : teamInfo.posName}
        </Txt>
      </div>
    </div>
  )
}

interface ITeamInfoContentProps {
  teamInfos: IAboutUsTeamResponse[]
}
const TeamInfoContent = ({ teamInfos }: ITeamInfoContentProps) => {
  const sortedTeams = useMemo(() => sortBy(teamInfos, (t) => t.recNo), [teamInfos])
  return (
    <div
      css={css`
        display: grid;
        grid-template-columns: repeat(auto-fit, 165px);
        justify-content: center;
        column-gap: 24px;
        row-gap: 36px;
        padding: 56px 136px;
        margin: 0 auto;
      `}
    >
      {sortedTeams.map((teamInfo, index) => (
        <TeamInfoItem key={teamInfo.id} teamInfo={teamInfo} animationDelay={(index + 1) * 0.2} />
      ))}
    </div>
  )
}

interface ISectionTeamInfoProps {
  teamInfos?: IAboutUsTeamResponse[]
}

export const SectionTeamInfo = ({ teamInfos = [] }: ISectionTeamInfoProps) => {
  const memoizedItems = useMemo(() => {
    const items: CollapseProps['items'] = [
      {
        key: '1',
        label: <TeamInfoHeader />,
        children: <TeamInfoContent teamInfos={teamInfos} />,
        forceRender: true,
      },
    ]
    return items
  }, [teamInfos])

  return (
    <Collapse
      expandIcon={({ isActive }) =>
        isActive ? (
          <IoChevronUpOutline size={48} color={colors.primary} />
        ) : (
          <IoChevronDownOutline size={48} color={colors.primary} />
        )
      }
      items={memoizedItems}
      css={css`
        border: 0;
        border-radius: 0;
        .ant-collapse-content {
          .ant-collapse-content-box {
            background: url(${bgTeamContentImage});
            background-size: cover;
            background-position: center;
            padding: 0;
          }
        }
        .ant-collapse-item.ant-collapse-item-active {
          border-radius: 0;
          border: 0;
        }
        .ant-collapse-item .ant-collapse-header {
          cursor: pointer;
          padding: 0;
          .ant-collapse-expand-icon {
            position: absolute;
            bottom: 24px;
            left: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
        }
      `}
    />
  )
}
