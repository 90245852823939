import { Interpolation, Theme } from '@emotion/react'
import styled from '@emotion/styled'
import { css, getEnv } from '@ptt-eia-web/helpers'
import { IOtherLinkResponse, useGetOtherLink } from '@ptt-eia-web/services'
import { Row, Col, Tooltip } from 'antd'
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import Slider from 'react-slick'

import { NextArrowButton } from '@frontend/modules/home-content/NextArrowButton'
import { PrevArrowButton } from '@frontend/modules/home-content/PrevArrowButton'

import BgOtherLink from '../../../src/assets/home/bg-ohter-link.jpeg'
import ImageRightButton from '../../../src/assets/svgs/other-banner2.svg'
import { colors } from '../../app/theme'
import DefaultBGOtherLink from '../../assets/home/logo-PEMD.png'

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

const OtherLinksWrapper = styled(Row)`
  overflow: hidden;
  position: relative;
  z-index: 10;
  height: 350px;
  display: flex;
  flex-direction: column;
`

const BackgroundCol = styled(Col)`
  background-image: url(${BgOtherLink});
  background-size: cover;
  width: 100%;
  height: 100%;
  background-color: transparent;
  background-repeat: no-repeat;
`

const ImageBottomRight = styled.img`
  position: absolute;
  bottom: 0;
  right: 0;
  height: 20%;
`

const LogoCol = styled(Link)`
  gap: 10px;
  color: ${colors.black};
  &:hover {
    color: ${colors.black};
  }
`

const LogoWrap = styled.div`
  width: 162px;
  height: 162px;
  overflow: hidden;
  border-radius: 26px;
  border: 2px solid ${colors.secondary};
  transition: transform 0.5s ease;
  &:hover {
    transform: scale(1.04);
  }
`

const getItemImageSrc = (item: IOtherLinkResponse) => {
  return item.filePath ? encodeURI(`${getEnv('STORAGE_BASE_URL')}${item.filePath}`) : DefaultBGOtherLink
}

const breakpoint = 1055
export const OtherLinks = () => {
  const { data: otherLinks } = useGetOtherLink()
  const [swipeEnabled, setSwipeEnabled] = useState(window.innerWidth >= breakpoint)
  const [activeDot, setActiveDot] = useState<number>(0)

  const handleDotClick = (index: number) => {
    setActiveDot(index)
  }
  const handleAfterChange = (currentSlide: number) => {
    setActiveDot(currentSlide)
  }

  const NextArrow = (
    props: JSX.IntrinsicAttributes & { css?: Interpolation<Theme> } & {
      className: string
      onClick: () => void
    },
  ) => <NextArrowButton {...props} />

  const PrevArrow = (
    props: JSX.IntrinsicAttributes & { css?: Interpolation<Theme> } & {
      className: string
      onClick: () => void
    },
  ) => <PrevArrowButton {...props} />

  const settingsSlide = {
    dots: !swipeEnabled && (otherLinks?.length ?? 0) > 3,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: swipeEnabled,
    swipe: !swipeEnabled,
    customPaging: (i: number) => (
      <div
        onMouseDown={() => handleDotClick(i)}
        css={css`
          width: 10px;
          height: 10px;
          border: 1px solid ${colors.secondary};
          background-color: ${i === activeDot ? colors.secondary : colors.white};
          border-radius: 50%;
          margin-right: 5px;
          margin-top: 8px;
          cursor: pointer;
          &:hover {
            background-color: ${colors.secondary};
          }
          &:active {
            background-color: ${colors.secondary};
          }
        `}
      />
    ),
    nextArrow:
      (otherLinks?.length ?? 0) > 3 ? (
        <NextArrow
          className={''}
          onClick={function (): void {
            throw new Error('Function not implemented.')
          }}
        />
      ) : undefined,
    prevArrow:
      (otherLinks?.length ?? 0) > 3 ? (
        <PrevArrow
          className={''}
          onClick={function (): void {
            throw new Error('Function not implemented.')
          }}
        />
      ) : undefined,
    afterChange: handleAfterChange,
  }
  useEffect(() => {
    const handleResize = () => {
      setSwipeEnabled(window.innerWidth >= breakpoint)
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])
  return (
    <OtherLinksWrapper>
      <BackgroundCol>
        <ImageBottomRight src={ImageRightButton} alt="bg-swiper-content" />
        <Col>
          <div
            css={css`
              display: flex;
              justify-content: center;
              align-items: center;
              gap: 8px;
            `}
          >
            <p
              css={css`
                color: ${colors.secondary};
                font-size: 30px;
              `}
            >
              Other
            </p>
            <p
              css={css`
                font-size: 30px;
              `}
            >
              Link
            </p>
          </div>
        </Col>
        {otherLinks && (
          <div
            data-testid={`fe-item-link`}
            css={css`
              margin: 0 auto;
              max-width: 855px;
              height: 100px;
            `}
          >
            {otherLinks.length > 2 ? (
              <Slider {...settingsSlide}>
                {otherLinks.map((item: IOtherLinkResponse) => (
                  <div
                    key={item.id}
                    css={css`
                      padding-top: 4px;
                      display: flex !important;
                      text-align: center;
                      justify-content: center;
                      align-items: center;
                      flex-direction: column;
                    `}
                  >
                    <LogoCol to={item.link} target="_blank">
                      <LogoWrap
                        css={css`
                          background-image: url(${getItemImageSrc(item)});
                          background-size: cover;
                          background-position: center;
                          background-repeat: no-repeat;
                        `}
                      />
                      <Tooltip title={item.description} trigger="hover">
                        <div
                          css={css`
                            padding: 12px;
                            white-space: nowrap;
                            max-width: 162px;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            font-size: 20px;
                          `}
                        >
                          {item.description}
                        </div>
                      </Tooltip>
                    </LogoCol>
                  </div>
                ))}
              </Slider>
            ) : (
              <div
                css={css`
                  display: flex;
                  justify-content: space-around;
                  align-items: center;
                `}
              >
                {otherLinks.map((item: IOtherLinkResponse) => (
                  <div
                    key={item.id}
                    css={css`
                      padding-top: 4px;
                      display: flex !important;
                      text-align: center;
                      justify-content: center;
                      align-items: center;
                      flex-direction: column;
                    `}
                  >
                    <LogoCol to={item.link} target="_blank">
                      <LogoWrap
                        css={css`
                          background-image: url(${getItemImageSrc(item)});
                          background-size: cover;
                          background-position: center;
                          background-repeat: no-repeat;
                        `}
                      />
                      <Tooltip title={item.description} trigger="hover">
                        <div
                          css={css`
                            padding: 12px;
                            white-space: nowrap;
                            max-width: 162px;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            font-size: 20px;
                          `}
                        >
                          {item.description}
                        </div>
                      </Tooltip>
                    </LogoCol>
                  </div>
                ))}
              </div>
            )}
          </div>
        )}
      </BackgroundCol>
    </OtherLinksWrapper>
  )
}
