import { AppModal } from '@ptt-eia-web/components'
import { css } from '@ptt-eia-web/helpers'
import {
  IProjectDashboardNotiOutDtos,
  IProjectDashboardSaveEmployeesParam,
  IProjectResponse,
  useGetProjectDashboardWithIdQRY,
  usePutProjectDashboardMTT,
} from '@ptt-eia-web/services'
import React, { useCallback } from 'react'
import { useSearchParams } from 'react-router-dom'

import { ISearchUserFormValue } from '../../../../components/SearchUserModal'

import { EmployeeCard } from './EmployeeCard'
import { DataType } from './EmployeeTable'

interface IModalEmailProps {
  isModalOpen: boolean
  onClose: () => void
  onClickAddEmail: () => void
  searchUserValue?: ISearchUserFormValue
  canEdit: boolean
  tableData?: DataType[]
  onDelete: (id: string) => void
}

const parseRequest = (
  newList: IProjectDashboardNotiOutDtos[],
  resultProject: IProjectResponse | undefined,
): IProjectDashboardSaveEmployeesParam => {
  return {
    Id: resultProject?.id ?? null,
    ProjectName: resultProject?.projectName ?? '',
    MddashboardFormId: resultProject?.mddashboardFormId ?? null,
    MdprojectTypeId: resultProject?.mdprojectTypeId ?? null,
    MdreportTypeId: resultProject?.mdreportTypeId ?? null,
    MddashboardStatusId: resultProject?.mddashboardStatusId ?? null,
    ProjectDashboardNotiInDtos: newList,
  }
}

export const ModalEmail = ({
  isModalOpen,
  onClose,
  onClickAddEmail,
  onDelete,
  canEdit,
  tableData,
}: IModalEmailProps) => {
  const [searchParams] = useSearchParams()

  const { data: resultProject, isPending } = useGetProjectDashboardWithIdQRY({ id: Number(searchParams.get('id')) })

  const { mutateAsync: updateProjectDashboard } = usePutProjectDashboardMTT()

  const handleClickSave = useCallback(async () => {
    const newList: IProjectDashboardNotiOutDtos[] = []
    tableData?.forEach((item) => {
      newList.push({
        id: 0,
        tbdashboardId: Number(searchParams.get('id')) ?? null,
        empCode: item?.employeeCode ?? null,
        fullName: item?.fullName ?? '',
        email: item?.email ?? '',
      })
    })
    await updateProjectDashboard(parseRequest(newList, resultProject))
    onClose()
  }, [searchParams, updateProjectDashboard, tableData, resultProject, onClose])

  return (
    <AppModal
      width={800}
      title="ผู้รับอีเมลแจ้งเตือน"
      onOk={handleClickSave}
      open={isModalOpen}
      okText="Save"
      onCancel={onClose}
      css={css`
        .ant-modal-footer {
          button.ant-btn-primary {
            display: ${canEdit ? 'inline-flex' : 'none'};
          }
        }
      `}
    >
      <div
        css={css`
          padding: 10px 44px 0px 44px;
        `}
      >
        <EmployeeCard
          tableData={tableData ?? []}
          onDelete={onDelete}
          isPending={isPending}
          onClickAddEmail={onClickAddEmail}
        />
      </div>
    </AppModal>
  )
}
