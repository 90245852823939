import { MenuCodeEnum, PermissionActionEnum } from '@ptt-eia-web/constants'
import { css } from '@ptt-eia-web/helpers'
import { IRequestTrackingResponse, useGetRequestStatusUpdateIdQRY, usePermission } from '@ptt-eia-web/services'
import { Button, Card, Flex, Form } from 'antd'
import dayjs from 'dayjs'
import { isEmpty } from 'lodash'
import { useEffect, useMemo } from 'react'

import { permissionConfigs } from '@frontend/app/permission-configs'
import { colors } from '@frontend/app/theme'

import { NEW_REQ_FORM_KEY, createInitFormValue } from '../service-request/helper'
import { Attachment } from '../service-request/new/Attachment'
import { FormInfoDetail } from '../service-request/new/FormInfoDetail'

import { RequestProgress } from './RequestProgress'
import { RequestTrackingTopField, TOP_FIELD_FORM_KEY } from './RequestTrackingTopField'
import { getUserInfoByTeamRole } from './request-helper'

const attachmentValues = (requestTracking?: IRequestTrackingResponse) => {
  const values: [key: string[], value: unknown][] = []
  if (!isEmpty(requestTracking?.listTbattachConnectPointOutDto))
    values.push([NEW_REQ_FORM_KEY.LIST_TB_ATTACH_CONNECT_POINT, requestTracking?.listTbattachConnectPointOutDto])
  if (!isEmpty(requestTracking?.listTbattachConstructOutDto))
    values.push([NEW_REQ_FORM_KEY.LIST_TB_ATTACH_CONSTRUCT, requestTracking?.listTbattachConstructOutDto])
  if (!isEmpty(requestTracking?.listTbattachOthersOutDto))
    values.push([NEW_REQ_FORM_KEY.LIST_TB_ATTACH_OTHER, requestTracking?.listTbattachOthersOutDto])
  if (!isEmpty(requestTracking?.listTbattachPipeLineOutDto))
    values.push([NEW_REQ_FORM_KEY.LIST_TB_ATTACH_PIPE_LINE, requestTracking?.listTbattachPipeLineOutDto])
  return values
}

const getNumberValue = (id: number | string | undefined) => {
  return id && Number(id)
}

interface IRequestTrackingFormProps {
  requestTracking?: IRequestTrackingResponse
  isRequestLoading: boolean
  isNotSubmitted: boolean

  onOpenActivityLogs: () => void
}

export const RequestTrackingForm = ({
  requestTracking,
  isRequestLoading,
  onOpenActivityLogs,
  isNotSubmitted,
}: IRequestTrackingFormProps) => {
  const form = Form.useFormInstance()
  const { data: reqStatus = [] } = useGetRequestStatusUpdateIdQRY(requestTracking?.tbserviceReqOutDto?.id?.toString())

  const { can, currentUser } = usePermission(permissionConfigs)
  const canEditAll = can(MenuCodeEnum.ServiceRequestDashboard, PermissionActionEnum.EditAll)

  const isPageLoading = useMemo(() => isRequestLoading, [isRequestLoading])

  const serviceRequest = useMemo(() => requestTracking?.tbserviceReqOutDto, [requestTracking?.tbserviceReqOutDto])
  const teams = useMemo(() => requestTracking?.listTbserviceReqTeamOutDto || [], [requestTracking])

  const serviceType = useMemo(
    () => ({
      id: serviceRequest?.mdserviceTypeId,
      name: serviceRequest?.mdserviceTypeDisplay,
    }),
    [serviceRequest?.mdserviceTypeDisplay, serviceRequest?.mdserviceTypeId],
  )

  const subServiceType = useMemo(
    () => ({
      id: serviceRequest?.mdsubServiceId,
      name: serviceRequest?.mdsubServiceIdDisplay,
    }),
    [serviceRequest?.mdsubServiceId, serviceRequest?.mdsubServiceIdDisplay],
  )

  const isCancel = useMemo(() => serviceRequest?.mdreqStatusId === 9, [serviceRequest])
  const isEditable = useMemo(() => {
    return isNotSubmitted && (canEditAll || currentUser?.id === serviceRequest?.createBy)
  }, [canEditAll, currentUser?.id, isNotSubmitted, serviceRequest?.createBy])

  const canSaveChange = useMemo(() => serviceRequest?.saveChangesBtn, [serviceRequest?.saveChangesBtn])

  useEffect(() => {
    const approver = requestTracking?.listTbserviceReqTeamOutDto.find((team) => team.mdteamRole === 2)
    form.setFieldsValue(
      createInitFormValue(
        [
          NEW_REQ_FORM_KEY.REQ_USER_NAME,
          getUserInfoByTeamRole(requestTracking?.listTbserviceReqTeamOutDto, 1)?.fullName,
        ],
        [NEW_REQ_FORM_KEY.REQ_DATE, dayjs(serviceRequest?.createDate).format('DD/MM/YYYY HH:mm')],
        [NEW_REQ_FORM_KEY.REQ_UNIT_NAME, serviceRequest?.reqUnitName],
        [NEW_REQ_FORM_KEY.PROJECT_NAME, serviceRequest?.projectName],
        [NEW_REQ_FORM_KEY.PROJECT_ABBR, serviceRequest?.projectAbbr],
        [NEW_REQ_FORM_KEY.PRJ_LOCATION, serviceRequest?.prjlocation],
        [NEW_REQ_FORM_KEY.PRJ_DETAIL, serviceRequest?.prjdetail],
        [NEW_REQ_FORM_KEY.OWNER, serviceRequest?.owner],
        [NEW_REQ_FORM_KEY.TEL, serviceRequest?.tel],
        [NEW_REQ_FORM_KEY.BUDGET, serviceRequest?.budget],
        [NEW_REQ_FORM_KEY.COST_CENTER, serviceRequest?.costCenter],
        [NEW_REQ_FORM_KEY.IO, serviceRequest?.io],

        [NEW_REQ_FORM_KEY.PLAN_START, serviceRequest?.planStart ? dayjs(serviceRequest?.planStart) : undefined],
        [NEW_REQ_FORM_KEY.PLAN_END, serviceRequest?.planEnd ? dayjs(serviceRequest?.planEnd) : undefined],

        [NEW_REQ_FORM_KEY.START_POINT, serviceRequest?.startPoint],
        [NEW_REQ_FORM_KEY.END_POINT, serviceRequest?.endPoint],
        [NEW_REQ_FORM_KEY.PIPE_SIZE, serviceRequest?.pipeSize],
        [NEW_REQ_FORM_KEY.PIPE_DISTANCE, serviceRequest?.pipeDistance],
        [NEW_REQ_FORM_KEY.PIPE_DESIGN_PRESSURE, serviceRequest?.pipeDesignPressure],
        [NEW_REQ_FORM_KEY.PIPE_MAX_PRESSURE, serviceRequest?.pipeMaxPressure],
        [NEW_REQ_FORM_KEY.REQ_UNIT_CODE, serviceRequest?.reqUnitCode],
        [NEW_REQ_FORM_KEY.REQ_UNIT_NAME, serviceRequest?.reqUnitName],

        ...attachmentValues(requestTracking),

        [NEW_REQ_FORM_KEY.APPROVER, approver?.empCode],
        [NEW_REQ_FORM_KEY.LIST_TB_SERVICE_REQ_TEAM, requestTracking?.listTbserviceReqTeamOutDto],
        [NEW_REQ_FORM_KEY.SERVICE_TYPE_ID, getNumberValue(serviceType.id)],
        [NEW_REQ_FORM_KEY.SUB_SERVICE_ID, getNumberValue(subServiceType.id)],
        [NEW_REQ_FORM_KEY.REPORT_TYPE_ID, getNumberValue(serviceRequest?.mdreportTypeId)],
        [NEW_REQ_FORM_KEY.PROJECT_TYPE_ID, getNumberValue(requestTracking?.tbserviceReqOutDto.mdprojectTypeId)],

        [TOP_FIELD_FORM_KEY.TEMP_REQUEST_STATUS_NAME, serviceRequest?.mdreqStatusDisplay],
        [TOP_FIELD_FORM_KEY.REQUEST_NUMBER, serviceRequest?.reqNo],
        [NEW_REQ_FORM_KEY.STATUS_ID, serviceRequest?.mdreqStatusId],
      ),
    )
  }, [
    form,
    reqStatus,
    requestTracking,
    requestTracking?.listTbserviceReqTeamOutDto,
    requestTracking?.tbserviceReqOutDto.mdreportTypeId,
    serviceRequest,
    serviceType.id,
    subServiceType.id,
    teams,
  ])

  return (
    <>
      <Card
        css={css`
          padding: 64px;
        `}
        loading={isPageLoading}
      >
        <Flex
          css={css`
            margin-bottom: 32px;
          `}
          gap={64}
          vertical
        >
          <Flex justify="center">
            <RequestProgress serviceRequest={serviceRequest} teams={requestTracking?.listTbserviceReqTeamOutDto} />
          </Flex>
          <Flex>
            <Button
              css={css`
                &:hover {
                  background-color: #00ffd0 !important;
                }
                background-color: ${colors.bobbleSecondary};
              `}
              type="primary"
              onClick={onOpenActivityLogs}
            >
              Activity Logs
            </Button>
          </Flex>
        </Flex>
        <RequestTrackingTopField isCancel={isCancel} />
        <FormInfoDetail
          editable={isEditable}
          serviceTypeId={serviceRequest?.mdserviceTypeId}
          subServiceTypeId={serviceRequest?.mdsubServiceId}
        />
      </Card>
      <Attachment
        editable={isEditable || canSaveChange}
        serviceTypeId={serviceRequest?.mdserviceTypeId}
        subServiceTypeId={serviceRequest?.mdsubServiceId}
      />
    </>
  )
}
