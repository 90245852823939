import { PropsWithChildren, createContext, useContext, useMemo } from 'react'

import { useGetPublicConfigSuspenseQRY } from './config-service'

interface IConfigValue {
  exampleKey: string
}

const AppConfigContext = createContext<IConfigValue | null>(null)

// unused this config for now, It has already moved to env-config.js
export const AppConfigProvider = ({ children }: PropsWithChildren) => {
  const { data: config } = useGetPublicConfigSuspenseQRY()
  const { tinyKey } = config
  const configValue = useMemo(
    () => ({
      exampleKey: tinyKey,
    }),
    [tinyKey],
  )
  return <AppConfigContext.Provider value={configValue}>{children}</AppConfigContext.Provider>
}

export function useAppConfig() {
  const context = useContext(AppConfigContext)
  if (!context) {
    throw new Error('LoginContext Hook cannot be used outside of context')
  }
  return context
}
