import { css } from '@ptt-eia-web/helpers'
import { TooltipItem, Chart, ChartDataset } from 'chart.js'
import React from 'react'
import { Doughnut } from 'react-chartjs-2'

import 'chart.js/auto'
import { colors } from '../../../app/theme'

interface CustomDataset extends ChartDataset<'doughnut'> {
  countProject: number
  group: string
  label: string
  data: number[]
  backgroundColor: string[]
  hoverOffset: number
  borderWidth: number
}

interface IDataSet {
  labels: string[]
  datasets: CustomDataset[]
}

interface DonutChartProps {
  value: IDataSet
}

// Define interfaces for the structure of plugins options if needed
interface LegendOptions {
  display: boolean
}

// Update TooltipOptions to match Chart.js expected types
type TextAlign = 'center' | 'left' | 'right'
interface TooltipCallbacks {
  label?: (context: TooltipItem<'doughnut'>) => string
}

interface TooltipOptions {
  enabled: boolean
  backgroundColor: string
  filter: (tooltipItem: TooltipItem<'doughnut'>) => boolean
  titleAlign: TextAlign // Use defined type instead of string
  displayColors: boolean
  bodyAlign: TextAlign // Use defined type instead of string
  callbacks: TooltipCallbacks
}

interface LegendOptions {
  display: boolean
}

// E

interface CustomChartOptions {
  cutout?: string
  responsive?: boolean
  maintainAspectRatio?: boolean
  plugins?: {
    legend?: Partial<LegendOptions> // Use Partial to allow for deep partial types
    tooltip?: Partial<TooltipOptions> // Use Partial to allow for deep partial types
    [key: string]: unknown // Allow for additional plugins that might have different structures
  }
}
const wrapText = (
  context: CanvasRenderingContext2D,
  text: string,
  x: number,
  y: number,
  maxWidthText: number,
  lineHeightText: number,
  countProject: number,
) => {
  const characters = Array.from(text)
  let line = ''
  let newY = y

  while (characters.length > 0) {
    line += characters.shift()
    const metrics = context.measureText(line)
    const testWidth = metrics.width

    if (testWidth > maxWidthText && line.length > 0) {
      characters.unshift(line[line.length - 1])
      line = line.substring(0, line.length - 1)

      context.fillText(line, x, newY)
      line = ''
      newY += lineHeightText
    }
  }

  if (line.length > 0) {
    context.fillText(line, x, newY)
    newY += lineHeightText
  }

  // Draw countProject on a new line
  context.fillText(`${countProject}`, x, newY)
}

const donutLabelPlugin = () => ({
  id: 'donutLabel',
  beforeDatasetsDraw(chart: Chart) {
    const { ctx, data } = chart

    if (!ctx || !data.datasets.length) return

    const dataset = data.datasets[0] as CustomDataset | undefined
    if (!dataset || typeof dataset.countProject !== 'number') return

    const countProject = dataset.countProject
    const datasetMeta = chart.getDatasetMeta(0)
    const element = datasetMeta.data[0]

    if (!element) return

    const xCoor = element.x
    const yCoor = element.y - 8

    ctx.save()
    ctx.font = 'normal 14px Prompt'
    ctx.fillStyle = 'rgba(0, 0, 0, 1)'
    ctx.textAlign = 'center'
    ctx.textBaseline = 'middle'

    const maxWidth = 150
    const lineHeight = 20

    if (dataset.label) {
      wrapText(ctx, dataset.label, xCoor, yCoor, maxWidth, lineHeight, countProject)
    }

    ctx.restore()
  },
})

export const DonutChart: React.FC<DonutChartProps> = (props) => {
  const isInitialChart = props.value.datasets[0].data.reduce((a, b) => a + b, 0) === 0
  if (isInitialChart) {
    props.value.datasets[0].data = [-1]
    props.value.datasets[0].backgroundColor = [colors.line]
    props.value.datasets[0].hoverOffset = 0
  }

  // Set up the chart options
  const options: CustomChartOptions = {
    cutout: '75%',
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: { display: false },
      tooltip: {
        enabled: true,
        filter: function (tooltipItem: TooltipItem<'doughnut'>) {
          return tooltipItem.dataset.data[0] !== -1
        },
        backgroundColor: '#008B6B',
        titleAlign: 'center',
        displayColors: false,
        bodyAlign: 'center',
        callbacks: {
          label: function (context: TooltipItem<'doughnut'>) {
            return context.formattedValue
          },
        },
      },
    },
  }
  return (
    <div
      css={css`
        z-index: 1;
        display: flex;
        justify-content: center;
        align-items: center;
      `}
    >
      <Doughnut data={props.value} options={options} plugins={[donutLabelPlugin()]} width={200} height={200} />
    </div>
  )
}
