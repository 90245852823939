import { IProjectDashboardParam } from '@ptt-eia-web/services'

export const parseSearchParams = (params: URLSearchParams): IProjectDashboardParam => {
  const getIntParam = (paramName: string): number | null => {
    const paramValue = params.get(paramName)
    return paramValue ? parseInt(paramValue, 10) : null
  }

  return {
    MdprojectTypeId: getIntParam('projectTypeId'),
    MdreportTypeId: getIntParam('reportTypeId'),
    Year: getIntParam('year'),
  }
}
