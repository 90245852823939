import createEmotion from '@emotion/css/create-instance'

export const getCSPNonce = () => {
  return (document.querySelector('meta[name="csp-nonce"]') as HTMLMetaElement)?.content
}

export const { flush, hydrate, cx, merge, getRegisteredStyles, injectGlobal, keyframes, css, sheet, cache } =
  createEmotion({
    key: 'css',
    nonce: getCSPNonce(),
  })
