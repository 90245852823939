import { css } from '@ptt-eia-web/helpers'
import { IProjectTeamResponse, useDeleteProjectTeamMTT } from '@ptt-eia-web/services'
import { Button, Flex, Table } from 'antd'
import { ColumnType } from 'antd/es/table'
import { useCallback, useMemo } from 'react'
import { GoPlus, GoTrash } from 'react-icons/go'

import { colors } from '@frontend/app/theme'
import { IconButtonCss } from '@frontend/assets/css/IconButtonCss'

import { Txt } from '../../../components/Txt'

interface IApproveModalPageListProps {
  requestId?: string
  projectTeams: IProjectTeamResponse[]
  onClickAdd: () => void
  disableAddTeam?: boolean
  canDeleteRoleIds?: number[]
}

export const ApproveModalPageList = ({
  onClickAdd,
  projectTeams,
  requestId = 'N/A',
  disableAddTeam = false,
  canDeleteRoleIds = [],
}: IApproveModalPageListProps) => {
  const { mutateAsync: deleteProjectTeam } = useDeleteProjectTeamMTT(requestId)
  const handleClickAdd = useCallback(() => {
    onClickAdd()
  }, [onClickAdd])

  const handleClickRemove = useCallback(
    (record: IProjectTeamResponse) => {
      deleteProjectTeam({ Id: record.id })
    },
    [deleteProjectTeam],
  )

  const columns = useMemo(
    (): ColumnType<IProjectTeamResponse>[] => [
      {
        title: 'No.',
        width: 50,
        render: (value, record, index) => <Txt ag="light16">{index + 1}</Txt>,
      },
      { title: 'ROLE', width: 200, ellipsis: true, dataIndex: 'mdteamRoleDisplay' },
      { title: 'NAME', width: 200, ellipsis: true, dataIndex: 'fullname' },
      { title: 'POSITION', width: 200, ellipsis: true, dataIndex: 'posName' },
      { title: 'MODIFY BY', width: 150, ellipsis: true, dataIndex: 'modifyByDisplay' },
      { title: 'MODIFY DATE', width: 150, ellipsis: true, dataIndex: 'modifyDateDisplay' },
      {
        title: '',
        width: 70,
        render: (value, record) => {
          if (canDeleteRoleIds.includes(record.mdteamRole))
            return (
              <GoTrash
                onClick={() => handleClickRemove(record)}
                size={16}
                css={IconButtonCss({ color: colors.errorRed, hoverColor: colors.errorRedHover })}
              />
            )
        },
      },
    ],
    [canDeleteRoleIds, handleClickRemove],
  )

  return (
    <>
      <Flex
        css={css`
          margin-bottom: 44px;
        `}
        justify="space-between"
      >
        <Flex gap={8}>
          <Txt ag="med18" color="primary">
            Project
          </Txt>
          <Txt ag="med18" color="darkBlue">
            Team
          </Txt>
        </Flex>
        <Button onClick={handleClickAdd} type="primary" icon={<GoPlus />} disabled={disableAddTeam}>
          Add
        </Button>
      </Flex>
      <Table rowKey="id" columns={columns} dataSource={projectTeams} />
    </>
  )
}
