import styled from '@emotion/styled'
import React from 'react'

import BgPieChartDashboard from '../../../assets/project-dashboard/bg-pie-dashboard.jpeg'
import { Header } from '../Header'

import { ChartDashboardCard } from './ChartDashboardCard'
const LayoutStyle = styled.div`
  width: 100%;
  height: 100%;
  padding: 0px 64px;
  background-image: url(${BgPieChartDashboard});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
`
export const ReportDashboardChart = () => {
  return (
    <LayoutStyle data-testid="report-dashboard-chart">
      <Header />
      <ChartDashboardCard />
    </LayoutStyle>
  )
}
