import { css } from '@ptt-eia-web/helpers'
import { ITeamRoleResponse, useGetTeamRoleQRY } from '@ptt-eia-web/services'
import { Card, Col, Flex, Form, FormInstance, Input, Row, Select } from 'antd'

import { Txt } from '../../../components/Txt'

const getTeamRoleOption = (data: ITeamRoleResponse[], acceptedIds: number[]) => {
  const options = data.map((role) => ({
    label: role.name,
    value: role.id,
  }))
  return options.filter((item) => acceptedIds.includes(item.value))
}

interface IMemberDetailFormProps {
  form: FormInstance
  showTitle?: boolean
  acceptedIds?: number[]
  editable?: boolean
  isShowAlert?: boolean
}

export const MemberDetailForm = ({
  form,
  showTitle = true,
  acceptedIds = [],
  editable = true,
  isShowAlert = false,
}: IMemberDetailFormProps) => {
  const { data = [] } = useGetTeamRoleQRY()

  return (
    <Card
      css={
        !showTitle &&
        css`
          padding-top: 24px;
        `
      }
    >
      <Flex
        css={css`
          margin-bottom: 24px;
        `}
        justify="center"
      >
        {showTitle && <Txt ag="med18">Detail</Txt>}
      </Flex>

      <Form layout="vertical" form={form}>
        <Row gutter={8}>
          <Col xs={24} sm={12} md={5}>
            <Form.Item label="Employee Code" name="employeeCode">
              <Input placeholder="Employee Code" disabled />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={3}>
            <Form.Item label="Prefix" name="prefix">
              <Input placeholder="Prefix" disabled />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={8}>
            <Form.Item label="Name" name="fname">
              <Input placeholder="Name" disabled />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={8}>
            <Form.Item label="Last Name" name="lname">
              <Input placeholder="Last Name" disabled />
            </Form.Item>
          </Col>
          {isShowAlert && (
            <Col xs={24}>
              <Row justify="end">
                <Txt ag="med14" color="errorRed">
                  *กรุณาเลือกข้อมูลพนักงาน
                </Txt>
              </Row>
            </Col>
          )}

          <Col xs={24} sm={12} md={12}>
            <Form.Item label="Department" name="department">
              <Input placeholder="Department" disabled />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={12}>
            <Form.Item label="Position" name="position">
              <Input placeholder="Position" disabled />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={12}>
            <Form.Item label="Email" name="email">
              <Input placeholder="Email" disabled />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={8}>
            <Form.Item label="ROLE" name="roleId" rules={[{ required: true, message: 'กรุณาระบุ ROLE' }]}>
              <Select placeholder="ROLE" options={getTeamRoleOption(data, acceptedIds)} disabled={!editable} />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Card>
  )
}
