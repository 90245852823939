import { QueryKey, UndefinedInitialDataOptions, useQuery } from '@tanstack/react-query'

import { pttClient } from '../ptt-client'
import { IResponseWithData } from '../services-types'

import {
  IMDRoleResponse,
  IMDPermissionResponse,
  IMDUserTypeResponse,
  IMDDashboardStatusResponse,
  IMasterReportStatusResponse,
  IMDDocTypeResponse,
  IMDDashboardFormResponse,
} from './master.response'

const MASTER_URL = '/Master'
const MASTER_ROLE_URL = `${MASTER_URL}/MDRole`
const MASTER_PERMISSION_URL = `${MASTER_URL}/MDPermission`
const MASTER_USER_TYPE_URL = `${MASTER_URL}/MDUserType`
const MASTER_DASHBOARD_STATUS_URL = `${MASTER_URL}/MDDashboardStatus`
const MASTER_REPORT_STATUS_URL = `${MASTER_URL}/MDReportStatus`
const MASTER_DOC_TYPE_URL = `${MASTER_URL}/MDDocType`
const MASTER_DASHBOARD_FORM_URL = `${MASTER_URL}/MDDashboardForm`

export const useGetMasterRoleQRY = (
  options?: UndefinedInitialDataOptions<IMDRoleResponse[], Error, IMDRoleResponse[], QueryKey>,
) => {
  return useQuery<IMDRoleResponse[]>({
    queryKey: [MASTER_ROLE_URL],
    queryFn: async (): Promise<IMDRoleResponse[]> => {
      const res = await pttClient.get<IResponseWithData<IMDRoleResponse[]>>(MASTER_ROLE_URL)
      return res.data.data
    },
    ...options,
  })
}

export const useGetMasterPermissionQRY = (
  options?: UndefinedInitialDataOptions<IMDPermissionResponse[], Error, IMDPermissionResponse[], QueryKey>,
) => {
  return useQuery<IMDPermissionResponse[]>({
    queryKey: [MASTER_PERMISSION_URL],
    queryFn: async (): Promise<IMDPermissionResponse[]> => {
      const res = await pttClient.get<IResponseWithData<IMDPermissionResponse[]>>(MASTER_PERMISSION_URL)
      return res.data.data
    },
    ...options,
  })
}

export const useGetMasterUserTypeQRY = (
  options?: UndefinedInitialDataOptions<IMDUserTypeResponse[], Error, IMDUserTypeResponse[], QueryKey>,
) => {
  return useQuery<IMDUserTypeResponse[]>({
    queryKey: [MASTER_USER_TYPE_URL],
    queryFn: async (): Promise<IMDUserTypeResponse[]> => {
      const res = await pttClient.get<IResponseWithData<IMDUserTypeResponse[]>>(MASTER_USER_TYPE_URL)
      return res.data.data
    },
    ...options,
  })
}

export const useGetMasterDashboardStatusQRY = (
  options?: UndefinedInitialDataOptions<IMDDashboardStatusResponse[], Error, IMDDashboardStatusResponse[], QueryKey>,
) => {
  return useQuery<IMDDashboardStatusResponse[]>({
    queryKey: [MASTER_DASHBOARD_STATUS_URL],
    queryFn: async (): Promise<IMDDashboardStatusResponse[]> => {
      const res = await pttClient.get<IResponseWithData<IMDDashboardStatusResponse[]>>(MASTER_DASHBOARD_STATUS_URL)
      return res.data.data
    },
    ...options,
  })
}

export const useGetMasterReportStatusQRY = (
  options?: UndefinedInitialDataOptions<IMasterReportStatusResponse[], Error, IMasterReportStatusResponse[], QueryKey>,
) => {
  return useQuery<IMasterReportStatusResponse[]>({
    queryKey: [MASTER_REPORT_STATUS_URL],
    queryFn: async (): Promise<IMasterReportStatusResponse[]> => {
      const res = await pttClient.get<IResponseWithData<IMasterReportStatusResponse[]>>(MASTER_REPORT_STATUS_URL)
      return res.data.data
    },
    ...options,
  })
}

export const useGetMasterDocTypeQRY = (
  options?: UndefinedInitialDataOptions<IMDDocTypeResponse[], Error, IMDDocTypeResponse[], QueryKey>,
) => {
  return useQuery<IMDDocTypeResponse[]>({
    queryKey: [MASTER_DOC_TYPE_URL],
    queryFn: async (): Promise<IMDDocTypeResponse[]> => {
      const res = await pttClient.get<IResponseWithData<IMDDocTypeResponse[]>>(MASTER_DOC_TYPE_URL)
      return res.data.data
    },
    ...options,
  })
}

export const useGetMasterDashboardFormQRY = () => {
  return useQuery<IMDDashboardFormResponse[]>({
    queryKey: [MASTER_DASHBOARD_FORM_URL],
    queryFn: async (): Promise<IMDDashboardFormResponse[]> => {
      const res = await pttClient.get<IResponseWithData<IMDDashboardFormResponse[]>>(MASTER_DASHBOARD_FORM_URL)
      return res.data.data
    },
  })
}
