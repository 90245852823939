import styled from '@emotion/styled'
import { MenuCodeEnum, PermissionActionEnum } from '@ptt-eia-web/constants'
import { css } from '@ptt-eia-web/helpers'
import { useGetMeSuspenseQRY, usePermission } from '@ptt-eia-web/services'
import React from 'react'
import { Navigate } from 'react-router-dom'

import { permissionConfigs } from '@frontend/app/permission-configs'

import { themeConfig } from '../../app/theme'
import BgPieChartDashboard from '../../assets/project-dashboard/bg-pie-dashboard.jpeg'
import { HeaderTitle } from '../../components/HeaderTitle'

import { NewRequestButton } from './NewRequestButton'
import { RequestContent } from './RequestContent'
import { ServiceRequestContent } from './ServiceRequestContent'

const LayoutStyle = styled.div`
  width: 100%;
  min-height: calc(100vh - ${themeConfig.headerHeight + themeConfig.footerHeight}px);
  background-image: url(${BgPieChartDashboard});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding: 0 64px;
  position: relative;
`

export const PageServiceRequest: React.FC = () => {
  const { data: user } = useGetMeSuspenseQRY()
  const { can } = usePermission(permissionConfigs)
  const canView = can(MenuCodeEnum.ServiceRequestDashboard, PermissionActionEnum.View)
  const canAdd = can(MenuCodeEnum.ServiceRequestDashboard, PermissionActionEnum.Add)
  if (!canView) return <Navigate to="/404" />

  return (
    <LayoutStyle data-testid="page-service-request-dashboard">
      <HeaderTitle titleFirst="Service Request" titleSecond="Dashboard" ag="med30" />
      {canAdd && user && user.userType !== 'BSA' && (
        <NewRequestButton
          css={css`
            width: 150px;
            position: absolute;
            right: 64px;
            top: 18px;
          `}
        />
      )}
      <ServiceRequestContent />
      <RequestContent />
    </LayoutStyle>
  )
}
