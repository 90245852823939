import { useGetMeSuspenseQRY, useLateVisitedURL } from '@ptt-eia-web/services'
import React from 'react'

interface RequireAuthProps {
  children: React.JSX.Element
}

export const RequireAuth = (props: RequireAuthProps) => {
  const { children } = props
  const { setLastVisitedUrl } = useLateVisitedURL()
  const { data: user, isFetched } = useGetMeSuspenseQRY()

  console.log('== user', user)
  console.log('== isFetched', isFetched)

  const isAuthenticated = !!user && isFetched
  if (isAuthenticated) {
    return children
  }
  if (isFetched && !isAuthenticated) {
    setLastVisitedUrl(window.location.href)
    window.location.href = `${window.location.origin}/login`
  }
  return null
}
