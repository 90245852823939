import {
  QueryCache,
  QueryKey,
  UndefinedInitialDataOptions,
  UseMutationOptions,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query'
import { message } from 'antd'

import { pttClient } from '../ptt-client'
import { IResponseWithData } from '../services-types'

import { IAnnualReportParams, IAnnualReportSearchParam } from './annual-report.params'
import { IAnnualReportResponse } from './annual-report.response'

const ANNUAL_REPORT_LIST_URL = '/AnnualReport/AnnualReportList'
const ANNUAL_REPORT_URL = '/AnnualReport/AnnualReport'

export const useGetAnnualReportListQRY = (
  params?: IAnnualReportSearchParam,
  options?: UndefinedInitialDataOptions<IAnnualReportResponse[], Error, IAnnualReportResponse[], QueryKey>,
) => {
  const queryCache = new QueryCache()
  queryCache.find({ queryKey: [ANNUAL_REPORT_LIST_URL, { params }] })
  queryCache.clear()
  return useQuery<IAnnualReportResponse[]>({
    queryKey: [ANNUAL_REPORT_LIST_URL, { params }],
    queryFn: async (): Promise<IAnnualReportResponse[]> => {
      const res = await pttClient.get<IResponseWithData<IAnnualReportResponse[]>>(ANNUAL_REPORT_LIST_URL, {
        params,
      })
      return res.data.data || []
    },
    ...options,
  })
}

export const usePostAnnualReportMTT = (
  options?: UseMutationOptions<IAnnualReportResponse, Error, IAnnualReportParams, unknown>,
) => {
  const queryClient = useQueryClient()
  return useMutation<IAnnualReportResponse, Error, IAnnualReportParams>({
    mutationFn: async (body: IAnnualReportParams) => {
      const res = await pttClient.post<IResponseWithData<IAnnualReportResponse>>(ANNUAL_REPORT_URL, {
        ...body,
      })
      return res.data.data
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [ANNUAL_REPORT_LIST_URL] })
      message.success('เพิ่มข้อมูลไฟล์ Annual Report สำเร็จ')
    },
    ...options,
  })
}

export const useGetAnnualReportQRY = (
  id: number,
  options?: UndefinedInitialDataOptions<IAnnualReportResponse, Error, IAnnualReportResponse, QueryKey>,
) => {
  return useQuery<IAnnualReportResponse>({
    queryKey: [ANNUAL_REPORT_URL, id],
    queryFn: async (): Promise<IAnnualReportResponse> => {
      const res = await pttClient.get<IResponseWithData<IAnnualReportResponse>>(ANNUAL_REPORT_URL, {
        params: { id },
      })
      return res.data.data
    },
    staleTime: 10,
    ...options,
  })
}

export const usePutAnnualReportMTT = (
  id: number,
  options?: UseMutationOptions<IAnnualReportResponse, Error, IAnnualReportParams, unknown>,
) => {
  const queryClient = useQueryClient()
  return useMutation<IAnnualReportResponse, Error, IAnnualReportParams>({
    mutationFn: async (body: IAnnualReportParams) => {
      const res = await pttClient.put<IResponseWithData<IAnnualReportResponse>>(ANNUAL_REPORT_URL, {
        ...body,
      })
      return res.data.data
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [ANNUAL_REPORT_LIST_URL] })
      queryClient.invalidateQueries({ queryKey: [ANNUAL_REPORT_URL, id] })
      message.success('แก้ไขข้อมูลไฟล์ Annual Report สำเร็จ')
    },
    ...options,
  })
}

export const useDeleteAnnualReportMTT = (
  options?: UseMutationOptions<IAnnualReportResponse, Error, number, unknown>,
) => {
  const queryClient = useQueryClient()
  return useMutation<IAnnualReportResponse, Error, number>({
    mutationFn: async (id: number) => {
      const res = await pttClient.delete<IResponseWithData<IAnnualReportResponse>>(ANNUAL_REPORT_URL, {
        params: { id },
      })
      return res.data.data
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [ANNUAL_REPORT_LIST_URL] })
      message.success('ลบข้อมูลไฟล์ Annual Report สำเร็จ')
    },
    ...options,
  })
}
