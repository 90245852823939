import styled from '@emotion/styled'
import { css, getCSPNonce, uuid } from '@ptt-eia-web/helpers'
import { Button, ConfigProvider, Flex, Upload } from 'antd'
import { RcFile, UploadProps } from 'antd/es/upload'
import { useCallback, useMemo } from 'react'

import { Txt } from './Txt'

export interface ICustomUploadFile {
  error?: string
  fileName?: string
  newFile?: RcFile
}

interface IUploadFileInputProps extends Omit<UploadProps, 'onChange' | 'value'> {
  onChange?: (file?: ICustomUploadFile) => void
  value?: ICustomUploadFile
}

const acceptedFileType = '.dwg,.dwf,.dws,.dwt,.dxf,.xlsx,.xls,.doc,.docx,.ppt,.pptx,.pdf,.png,.jpg,.jpeg'

const InputArea = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 150px;
  background-color: white;
  border: 1px solid #dddddd;
`

export const uploadFileRule = {
  validator(_: unknown, value: ICustomUploadFile) {
    if (value && !value.newFile && value.error) {
      return Promise.reject(value.error)
    }
    return Promise.resolve()
  },
}

export const UploadFileInput = (props: IUploadFileInputProps) => {
  const { onChange, value, ...restProps } = props

  const handleBeforeUpload = useCallback(
    (uploadFile: RcFile) => {
      const type = uploadFile.name.split('.').pop() ?? 'noType'
      if (uploadFile.size === 0) {
        onChange?.({ error: 'ไฟล์ไม่มีข้อมูล' })
      } else if (!acceptedFileType?.includes(type)) {
        onChange?.({ error: 'ประเภทไฟล์ไม่ถูกต้อง' })
      } else if (uploadFile.size > 300 * 1024 * 1024) {
        onChange?.({ error: 'ไฟล์มีขนาดเกิน 300 MB' })
      } else {
        onChange?.({ fileName: uploadFile.name, newFile: uploadFile })
      }
      return false
    },
    [onChange],
  )

  const handleRemove = useCallback(() => {
    onChange?.(undefined)
  }, [onChange])

  const fileList = useMemo(() => {
    if (value?.newFile) {
      // มีการกดเลือนไฟล์ใหม่จาก ui
      return [value?.newFile]
    } else if (value?.fileName) {
      // ไม่มีไฟล์ใหม่แต่มีชื่อไฟล์มาจาก api
      return [{ uid: uuid(), name: value.fileName }]
    } else {
      return []
    }
  }, [value?.newFile, value?.fileName])

  return (
    <ConfigProvider
      csp={{ nonce: getCSPNonce() }}
      upload={{
        className: css`
          line-height: 0;
          .ant-upload {
            width: 100%;
          }
          && .ant-upload-list-item {
            margin-top: 0;
          }
          &&&& .ant-upload-list-item-actions {
            button {
              opacity: 1;
            }
          }
          && .ant-upload-list-item:hover {
            background-color: unset;
          }
          .ant-btn-icon {
            svg {
              fill: red;
            }
          }
        `,
      }}
    >
      <Flex vertical>
        <Upload
          multiple={false}
          beforeUpload={handleBeforeUpload}
          onRemove={handleRemove}
          fileList={fileList}
          accept={acceptedFileType}
          {...restProps}
        >
          <InputArea
            css={css`
              display: ${value?.fileName ? 'none' : 'flex'};
            `}
          >
            <Button
              css={css`
                width: 160px;
                margin-bottom: 12px;
              `}
              disabled={restProps.disabled}
            >
              Upload File
            </Button>
            <Txt ag="med14" color="darkGray">
              or Drop File
            </Txt>
          </InputArea>
          <Txt
            ag="med12"
            color="darkGray"
            css={css`
              margin-top: 12px;
              display: ${value?.fileName ? 'none' : 'block'};
            `}
          >
            รองรับไฟล์ PDF, Power point, JPEG, .PNG, .Excel, Word, AutoCAD ขนาดไฟล์ไม่เกิน 300 MB
          </Txt>
        </Upload>
      </Flex>
    </ConfigProvider>
  )
}
