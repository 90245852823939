import { AppModal } from '@ptt-eia-web/components'
import { css } from '@ptt-eia-web/helpers'
import {
  IEmployeeDataNoJobGroupResponse,
  IPTTPersonalInfoResponse,
  IProjectTeamParams,
  usePostProjectTeamMTT,
} from '@ptt-eia-web/services'
import { Form, Space } from 'antd'
import { useCallback, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'

import { Txt } from '../../../components/Txt'

import { MemberDetailForm } from './MemberDetailForm'
import { SearchUser } from './SearchUser'

export interface IAddMemberModalProps {
  title: string
  isOpen: boolean
  onClose: () => void
  projectTeamEmployeeCodes: string[]
  acceptedIds?: number[]
}

export const AddMemberModal = ({
  title,
  isOpen,
  onClose,
  projectTeamEmployeeCodes,
  acceptedIds = [],
}: IAddMemberModalProps) => {
  const [form] = Form.useForm()
  const [searchForm] = Form.useForm()

  const searchFormValues = Form.useWatch([], searchForm)
  const values = Form.useWatch([], form)
  const { id = 'N/A' } = useParams()

  const { mutateAsync: createProjectTeam, isPending } = usePostProjectTeamMTT(id)

  const [selectedData, setSelectedData] = useState<
    | {
        emp?: IEmployeeDataNoJobGroupResponse
        info?: IPTTPersonalInfoResponse
      }
    | undefined
  >(undefined)

  const handleSearch = useCallback(
    (emp?: IEmployeeDataNoJobGroupResponse, info?: IPTTPersonalInfoResponse) => {
      if (emp && info) {
        if (projectTeamEmployeeCodes.includes(emp.employeeId)) {
          searchForm.setFields([{ name: 'searchTxt', errors: ['พนักงานนี้มีอยู่ใน Team แล้ว'] }])
        } else {
          searchForm.setFields([{ name: 'searchTxt', errors: undefined }])
          setSelectedData({ emp, info })
          form.setFieldsValue({
            employeeCode: emp?.employeeId,
            prefix: emp?.iname,
            fname: info?.fname,
            lname: info?.lname,
            department: emp?.departmentNameTH,
            position: emp?.positionNameTH,
            email: emp.emailAddress,
          })
        }
      } else {
        setSelectedData(undefined)
        form.resetFields()
      }
    },
    [form, projectTeamEmployeeCodes, searchForm],
  )

  const handleClearInfoForm = useCallback(() => {
    form.resetFields()
    setSelectedData(undefined)
  }, [form])

  const handleClear = useCallback(() => {
    searchForm.resetFields()
    handleClearInfoForm()
  }, [handleClearInfoForm, searchForm])

  const handleCloseModal = useCallback(() => {
    onClose()
    handleClear()
  }, [handleClear, onClose])

  const handleSubmit = useCallback(async () => {
    await form.validateFields().then(async (formValues) => {
      const params: IProjectTeamParams = {
        tbserviceReqId: Number(id),
        empCode: formValues.employeeCode,
        prefix: formValues.prefix,
        fname: formValues.fname,
        lname: formValues.lname,
        unit: selectedData?.emp?.departmentCode,
        unitname: selectedData?.emp?.departmentNameTH,
        posCode: selectedData?.info?.poscode,
        posName: selectedData?.info?.posname,
        mdteamRole: formValues.roleId,
        email: formValues.email,
      }
      await createProjectTeam(params)
      handleCloseModal()
    })
  }, [
    createProjectTeam,
    form,
    handleCloseModal,
    id,
    selectedData?.emp?.departmentCode,
    selectedData?.emp?.departmentNameTH,
    selectedData?.info?.poscode,
    selectedData?.info?.posname,
  ])

  const isShowAlert = useMemo(() => {
    if (searchFormValues) return !!searchFormValues['searchTxt'] && !values['employeeCode']
    return false
  }, [values, searchFormValues])

  return (
    <AppModal
      width={800}
      css={css`
        .ant-modal-body {
          padding: 12px 36px;
        }
      `}
      title={
        <Txt ag="med18" color="white">
          {title}
        </Txt>
      }
      centered
      open={isOpen}
      okText="Save"
      cancelText="Close"
      okButtonProps={{ loading: isPending }}
      onOk={handleSubmit}
      onCancel={handleCloseModal}
    >
      <Space
        css={css`
          width: 100%;
        `}
        size={16}
        direction="vertical"
      >
        <SearchUser form={searchForm} onSearch={handleSearch} onClear={handleClearInfoForm} />
        <MemberDetailForm form={form} acceptedIds={acceptedIds} isShowAlert={isShowAlert} />
      </Space>
    </AppModal>
  )
}
