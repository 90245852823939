import styled from '@emotion/styled'
import { IServiceRequestParam, useGetServiceRequestRequestList } from '@ptt-eia-web/services'
import dayjs from 'dayjs'
import { isEmpty } from 'lodash'
import { useMemo } from 'react'
import { useSearchParams } from 'react-router-dom'

import { themeConfig } from '@frontend/app/theme'

import { HeaderTitle } from '../../components/HeaderTitle'

import { ExportRequestListButton } from './ExportRequestListButton'
import { RequestList } from './RequestList'
const CURRENT_YEAR = dayjs().year()
const LayoutRequestContentStyle = styled.div`
  width: 100%;
  min-height: calc(100vh - ${themeConfig.headerHeight + themeConfig.footerHeight}px);
  padding-bottom: 64px;
  position: relative;
`

const getParam = (param: string | null) => {
  return isEmpty(param) ? null : Number(param)
}

export const RequestContent = () => {
  const [searchParams] = useSearchParams()
  const filters = useMemo((): IServiceRequestParam => {
    const serviceTypeId = getParam(searchParams.get('serviceTypeId'))
    const projectTypeId = getParam(searchParams.get('projectTypeId'))
    const year = getParam(searchParams.get('year')) ?? CURRENT_YEAR

    return { projectTypeId, serviceTypeId, year }
  }, [searchParams])
  const { data = [], isLoading } = useGetServiceRequestRequestList(filters)
  const dataSource = useMemo(() => data, [data])

  return (
    <LayoutRequestContentStyle>
      <HeaderTitle titleFirst="Service Request" titleSecond="List" ag="med30" />
      <ExportRequestListButton />
      <RequestList dataSource={dataSource} isLoading={isLoading} />
    </LayoutRequestContentStyle>
  )
}
