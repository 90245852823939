import styled from '@emotion/styled'
import { css } from '@ptt-eia-web/helpers'
import { Row } from 'antd'
import React from 'react'
import { useSearchParams } from 'react-router-dom'

import { FilterNav } from './Filter'
import { Title } from './Title'
const HeaderStyle = styled.div`
  background-color: transparent;
  height: 100%;
`

export const Header: React.FC = () => {
  const [searchParams] = useSearchParams()
  return (
    <HeaderStyle>
      <Row
        css={css`
          display: flex;
          flex-direction: column;
          padding: 0px ${searchParams.get('tab') === 'table' ? '64px' : '0px'};
        `}
      >
        <Title data-testid="project-dashboard-title" titleFirst="Project" titleSecond="Dashboard" />
        <FilterNav />
      </Row>
    </HeaderStyle>
  )
}
