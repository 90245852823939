import { AnyObject } from 'antd/es/_util/type'

type IEnvKey =
  | 'DEBUG'
  | 'API_BASE_URL'
  | 'STORAGE_BASE_URL'
  | 'AZURE_AD_TENANT_ID'
  | 'AZURE_AD_CLIENT_ID'
  | 'TINY_KEY'
  | 'IS_MOCK_PTT_API'

export const getEnv = (key: IEnvKey) => {
  return (window as AnyObject).env[key]
}
