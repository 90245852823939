import styled from '@emotion/styled'
import { css } from '@ptt-eia-web/helpers'
import { Col, Flex, Row } from 'antd'
import { Dayjs } from 'dayjs'
import React from 'react'
import { GoCheckCircle } from 'react-icons/go'

import { colors } from '../app/theme'
import AssignSvg from '../assets/svgs/req-assigned.svg?react'
import ClosedSvg from '../assets/svgs/req-closed.svg?react'
import ExecutedSvg from '../assets/svgs/req-executed.svg?react'
import SubmittedSvg from '../assets/svgs/req-submitted.svg?react'

import { TagButton } from './TagButton'
import { Txt } from './Txt'

type IStateValue = { userInfo?: IUserInfoTextProps; status: 'completed' | 'inprogress' | 'inactive' }
type IStepValue = {
  submitted: IStateValue
  assigned: IStateValue & {
    showApprove?: boolean
    showReject?: boolean
    showRevise?: boolean
    onApproveClick?: () => void
    onRejectClick?: () => void
    onReturnForReviseClick?: () => void
  }
  executed: IStateValue & {
    onAssignTeamClick?: () => void
    showAssignTeam?: boolean
  }
  closed: IStateValue
}
type IReqTrackingProgressBarProps = {
  value: IStepValue
}

type IStepProps = {
  isStatusBottom: boolean
  statusLabel: string
  userInfo?: IUserInfoTextProps
  active?: boolean
  roundCap?: ICheckIconProps['roundCap']
  icon: React.ReactNode
}

type ICheckIconProps = {
  active?: boolean
  roundCap?: boolean
}

type IUserInfoTextProps = {
  fullName?: string
  date?: Dayjs
}

const SubmittedIcon = styled(SubmittedSvg)<{ active: boolean }>`
  position: absolute;
  z-index: 2;
  path {
    fill: ${({ active }) => (active ? colors.secondary : colors.disableGray)};
  }
`
const AssignIcon = styled(AssignSvg)<{ active: boolean }>`
  position: absolute;
  z-index: 2;
  path {
    fill: ${({ active }) => (active ? colors.secondary : colors.disableGray)};
  }
`
const ExecutedIcon = styled(ExecutedSvg)<{ active: boolean }>`
  position: absolute;
  z-index: 2;
  path {
    fill: ${({ active }) => (active ? colors.secondary : colors.disableGray)};
  }
`
const ClosedIcon = styled(ClosedSvg)<{ active: boolean }>`
  position: absolute;
  z-index: 2;
  #Path_83026 {
    stroke: ${({ active }) => (active ? colors.secondary : colors.disableGray)};
  }
  #Path_83025 {
    fill: ${({ active }) => (active ? colors.secondary : colors.disableGray)};
  }
`

const dateFormat = 'DD/MM/YYYY HH:mm'

const CircleDisableBgStyled = styled.div<{ active?: boolean }>`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 150px;
  height: 150px;
  border-radius: 50%;
  background-color: white;
  z-index: 0;
`
const CircleBackgroundStyled = styled.div<{ active?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 140px;
  height: 140px;
  border-radius: 50%;
  background-color: ${(props) => (props.active ? colors.secondary : colors.disableGray)};
  z-index: 1;
`
const CircleForegroundStyled = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 110px;
  height: 110px;
  border-radius: 50%;
  background-color: white;
  border: 2px solid #b6b5b5;
  box-shadow: 20px 20px 8px 5px #0000007d;
  z-index: 2;
`
const StatusTextStyled = styled.div<{ active?: boolean }>`
  background: ${(props) => (props.active ? colors.secondary : colors.disableGray)};
  border-radius: 50px;
  width: 100px;
  height: 40px;
  font-size: 14px;
  font-weight: 300;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
`
const VerticalLineStyled = styled.div<{ active?: boolean }>`
  width: 5px;
  height: 30px;
  background-color: ${(props) => (props.active ? colors.secondary : colors.disableGray)};
  z-index: 2;
`
const Space = styled.div`
  height: 30px;
`

const roundCapCss = css`
  border-top-right-radius: 50px;
  border-bottom-right-radius: 50px;
  justify-content: end;
  padding-right: 4px;
  width: 40px;
`

const CheckIconStyled = (props: ICheckIconProps) => {
  const { active, roundCap } = props
  return (
    <Flex
      justify="center"
      align="center"
      css={css`
        background: ${active ? colors.secondary : colors.disableGray};
        color: white;
        height: 30px;
        width: 44px;
        margin: 0 -2px;
        z-index: 0;
        ${roundCap && roundCapCss}
      `}
    >
      <GoCheckCircle size={24} />
    </Flex>
  )
}

const UserInfoText = (props: IUserInfoTextProps) => {
  const { fullName, date } = props
  return (
    <Flex
      css={css`
        min-height: 38px;
      `}
      vertical
      align="center"
      justify="center"
    >
      <Txt ag="med14">{fullName}</Txt>
      <Txt ag="med10">{date?.format(dateFormat)}</Txt>
    </Flex>
  )
}

const Step = (props: IStepProps) => {
  const { active = false, isStatusBottom = true, statusLabel, userInfo, roundCap, icon } = props

  return (
    <Flex justify="center" align="center">
      <Flex vertical justify="center" align="center">
        {!isStatusBottom && <StatusTextStyled active={active}>{statusLabel}</StatusTextStyled>}
        {!isStatusBottom && <VerticalLineStyled />}

        {isStatusBottom && <UserInfoText fullName={userInfo?.fullName} date={userInfo?.date} />}
        {isStatusBottom && <Space />}

        <Flex justify="center" align="center">
          {!active && <CircleDisableBgStyled />}
          <CircleBackgroundStyled active={active} />
          <CircleForegroundStyled />
          {icon}
        </Flex>

        {isStatusBottom && <VerticalLineStyled active={active} />}
        {isStatusBottom && <StatusTextStyled active={active}>{statusLabel}</StatusTextStyled>}

        {!isStatusBottom && <Space />}
        {!isStatusBottom && <UserInfoText fullName={userInfo?.fullName} date={userInfo?.date} />}
      </Flex>
      <CheckIconStyled active={active} roundCap={roundCap} />
    </Flex>
  )
}

export const ReqTrackingProgressBar = (props: IReqTrackingProgressBarProps) => {
  const { value } = props
  const { submitted, assigned, executed, closed } = value
  return (
    <Flex vertical>
      <Row justify="center" align="middle" wrap={false}>
        <Col span={6}>
          <Step
            icon={<SubmittedIcon active={submitted.status === 'completed'} />}
            statusLabel="SUBMITTED"
            active={submitted.status === 'completed'}
            isStatusBottom
            userInfo={submitted.userInfo}
          />
        </Col>
        <Col span={6}>
          <Step
            icon={<AssignIcon active={assigned.status === 'completed'} />}
            statusLabel="ASSIGNED"
            active={assigned.status === 'completed'}
            isStatusBottom={false}
            userInfo={assigned.userInfo}
          />
        </Col>
        <Col span={6}>
          <Step
            icon={<ExecutedIcon active={executed.status === 'completed'} />}
            statusLabel="EXECUTED"
            active={executed.status === 'completed'}
            isStatusBottom
            userInfo={executed.userInfo}
          />
        </Col>
        <Col span={6}>
          <Step
            icon={<ClosedIcon active={closed.status === 'completed'} />}
            statusLabel="CLOSED"
            isStatusBottom={false}
            active={closed.status === 'completed'}
            userInfo={closed.userInfo}
            roundCap
          />
        </Col>
      </Row>
      <Row>
        <Col
          push={6}
          span={6}
          css={css`
            padding-right: 32px;
            .ant-tag {
              margin-top: 10px;
              min-width: 100px;
              cursor: pointer;
              user-select: none;
              :hover {
                opacity: 0.8;
              }
            }
          `}
        >
          {assigned.status === 'inprogress' && (
            <Flex vertical justify="center" align="center">
              {assigned.showApprove && (
                <TagButton onClick={assigned.onApproveClick} color="#00B815">
                  Approve
                </TagButton>
              )}
              {assigned.showReject && (
                <TagButton onClick={assigned.onRejectClick} color="#D52B1E">
                  Reject
                </TagButton>
              )}
              {assigned.showRevise && (
                <TagButton onClick={assigned.onReturnForReviseClick} color="#F0BC2D">
                  Return for Revise
                </TagButton>
              )}
            </Flex>
          )}
        </Col>

        <Col
          push={6}
          span={6}
          css={css`
            padding-right: 32px;
            .ant-tag {
              margin-top: 10px;
              min-width: 100px;
              cursor: pointer;
              user-select: none;
              :hover {
                opacity: 0.8;
              }
            }
          `}
        >
          {executed.showAssignTeam && (
            <Flex vertical justify="center" align="center">
              <TagButton onClick={executed.onAssignTeamClick} color="#00B815">
                Assign Team
              </TagButton>
            </Flex>
          )}
        </Col>
      </Row>
    </Flex>
  )
}
