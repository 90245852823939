import { css } from '@ptt-eia-web/helpers'
import { ProjectDashboardLTAInDtos, ProjectDashboardSHEInDtos } from '@ptt-eia-web/services'
import React from 'react'

import { Txt } from '@frontend/components/Txt'

import { colors } from '../../../../../app/theme'

import { SHETable } from './SHETable'

interface ISHEPerformanceProps {
  onProjectDashboardSHEInDtos: (arg0: ProjectDashboardSHEInDtos[]) => void
  dataSource: ProjectDashboardSHEInDtos[]
  dataSourceLTA: ProjectDashboardLTAInDtos[]
}
export const SHEPerformance = (props: ISHEPerformanceProps) => {
  return (
    <div
      css={css`
        padding: 0px 70px 0px 70px;
      `}
    >
      <span>
        <Txt
          ag="med24"
          css={css`
            color: ${colors.primary};
            margin-right: 8px;
          `}
        >
          SHE
        </Txt>
        <Txt ag="med24">Performance</Txt>
      </span>
      <div
        css={css`
          margin-top: 26px;
        `}
      >
        <SHETable
          dataSourceLTA={props.dataSourceLTA}
          dataSource={props.dataSource}
          onProjectDashboardSHEInDtos={(data) => props.onProjectDashboardSHEInDtos(data)}
        />
      </div>
    </div>
  )
}
