import { css } from '@ptt-eia-web/helpers'

interface ICellEiaHighlightActivities {
  highlightActivity: string | undefined
  wayForward: string | undefined
}

const cssContent = css`
  white-space: pre-wrap;
  font-size: 12px;
`

export const CellEiaHighlightActivities = ({ highlightActivity, wayForward }: ICellEiaHighlightActivities) => (
  <>
    <div css={cssContent}>{highlightActivity}</div>
    <div css={cssContent}>{wayForward}</div>
  </>
)
