import { RightOutlined } from '@ant-design/icons'
import { css } from '@ptt-eia-web/helpers'
import { Button } from 'antd'
import React from 'react'
import { Link } from 'react-router-dom'

import { colors } from '../../app/theme'
export const PrNewsSwiperViewDetail: React.FC = () => {
  return (
    <Link
      to="/pr-news"
      css={css`
        padding-right: 56px;
        height: 8px;
      `}
    >
      <Button
        css={css`
          background-color: ${colors.primary};
          box-shadow: 5px 5px 5px ${colors.line};
        `}
        type="primary"
        shape="round"
        size="small"
      >
        View All{' '}
        <RightOutlined
          css={css`
            font-size: 24px;
            font-weight: 900;
          `}
        />
      </Button>
    </Link>
  )
}
