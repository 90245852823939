export const progressColor = [
  {
    id: 1,
    color: '#00b050',
    description: 'AHEAD PLAN',
  },
  {
    id: 2,
    color: '#92d050',
    description: 'ON PLAN',
  },
  {
    id: 3,
    color: '#ffd966',
    description: 'DELAY 1-5%',
  },
  {
    id: 4,
    color: '#ff5050',
    description: 'DELAY >5%',
  },
]
