import { SearchOutlined } from '@ant-design/icons'
import { css } from '@ptt-eia-web/helpers'
import {
  IMDDashboardStatusResponse,
  IProjectDashboardListParam,
  ISettingResponse,
  useGetMasterDashboardStatusQRY,
  useGetProjectDashboardListQRY,
  useGetProjectTypesQRY,
  useGetReportTypesQRY,
} from '@ptt-eia-web/services'
import { Button, DatePicker, Flex, Form, Input, Select, Typography, DatePickerProps } from 'antd'
import { LabeledValue } from 'antd/es/select'
import dayjs, { Dayjs } from 'dayjs'
import React, { useEffect, useState } from 'react'
import { URLSearchParamsInit, useSearchParams } from 'react-router-dom'

import { checkValue } from '@frontend/components/CheckValue'
import { getPreviousWeek } from '@frontend/components/GetWeek'

import { colors } from '../../../../app/theme'

interface IOption {
  value: number
  label: string
}
interface IProjectChoice {
  value: number
  label: string
}

interface IReportChoice {
  value: number
  label: string
}

const parseToInt = (value: string | null, defaultValue: number | null = null) =>
  value ? parseInt(value, 10) : defaultValue

const parseSearchParams = (params: URLSearchParams): IProjectDashboardListParam => {
  const getIntParam = (paramName: string): number | null => {
    const paramValue = params.get(paramName)
    return paramValue ? parseInt(paramValue, 10) : null
  }

  return {
    StartDate: params.get('startDate') ?? '',
    EndDate: params.get('endDate') ?? '',
    ProjectName: params.get('projectName') ?? '',
    MddashboardStatusId: getIntParam('statusId'),
    MdprojectTypeId: getIntParam('projectTypeId'),
    MdreportTypeId: getIntParam('reportTypeId'),
    Year: getIntParam('year'),
  }
}

const updateTypeOptions = (
  dataList: ISettingResponse[],
  setOptionsFn: React.Dispatch<React.SetStateAction<IOption[]>>,
) => {
  const newOptions = dataList
    .filter((data) => data.active === 1)
    .map((data) => ({
      value: data.id,
      label: data.name,
    }))
  setOptionsFn(newOptions)
}

const updateStatusOptions = (
  dataList: IMDDashboardStatusResponse[],
  setOptionsFn: React.Dispatch<React.SetStateAction<IOption[]>>,
) => {
  const newOptions = dataList.map((data) => ({
    value: data.id,
    label: data.status,
  }))
  setOptionsFn(newOptions)
}

export const Filter = () => {
  const { data: projectTypeList } = useGetProjectTypesQRY()
  const { data: reportTypeList } = useGetReportTypesQRY()
  const { data: dashboardStatus } = useGetMasterDashboardStatusQRY()
  const [searchParams, setSearchParams] = useSearchParams()
  const [projectTypeOptions, setProjectTypeOptions] = useState<IProjectChoice[]>([])
  const [reportTypeOptions, setReportTypeOptions] = useState<IReportChoice[]>([])
  const [dashboardStatusOptions, setDashboardStatusOptions] = useState<IReportChoice[]>([])
  const [chooseReportType, setChooseReportType] = useState<string>()
  const [chooseProjectType, setChooseProjectType] = useState<string>()
  const [chooseYear, setChooseYear] = useState<string>()
  const [chooseStartDate, setChooseStartDate] = useState<string>()
  const [chooseEndDate, setChooseEndDate] = useState<string>()
  const [searchProjectName, setSearchProjectName] = useState<string | undefined>()
  const [form] = Form.useForm<{ name: string; age: number }>()
  const [chooseStatus, setChooseStatus] = useState<string>()

  const [apiSearchParam, setApiSearchParam] = useState<IProjectDashboardListParam>(parseSearchParams(searchParams))

  const handleChangeYearPicker = (year: Dayjs | null) => {
    setChooseYear(year ? year.format('YYYY') : '')
  }

  const handleChangeStartDatePicker: DatePickerProps['onChange'] = (date) => {
    setChooseStartDate(date ? date.format('YYYY-MM-DD') : '')
  }

  const handleChangeEndDatePicker: DatePickerProps['onChange'] = (date) => {
    setChooseEndDate(date ? date.format('YYYY-MM-DD') : '')
  }

  const handleSearchProjectName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchProjectName(event.target.value)
  }

  const handleChangeProjectType = (label: LabeledValue) => {
    setChooseProjectType(label?.toString())
  }

  const handleChangeStatus = (label: LabeledValue) => {
    setChooseStatus(label?.toString())
  }
  const handleChangeReportType = (label: LabeledValue) => {
    setChooseReportType(label?.toString())
  }

  const { data: responseDataAPI } = useGetProjectDashboardListQRY(apiSearchParam)
  useEffect(() => {
    if (projectTypeList) updateTypeOptions(projectTypeList, setProjectTypeOptions)
    if (reportTypeList) updateTypeOptions(reportTypeList, setReportTypeOptions)
    if (dashboardStatus) updateStatusOptions(dashboardStatus, setDashboardStatusOptions)
    setChooseEndDate(checkValue(searchParams.get('endDate')))
    setChooseStartDate(checkValue(searchParams.get('startDate')))
    setChooseReportType(checkValue(searchParams.get('reportTypeId')))
    setChooseProjectType(checkValue(searchParams.get('projectTypeId')))
    setSearchProjectName(checkValue(searchParams.get('projectName')))
    setChooseYear(checkValue(searchParams.get('year')))
    setChooseStatus(checkValue(searchParams.get('statusId')))
  }, [projectTypeList, reportTypeList, apiSearchParam, searchParams, responseDataAPI, dashboardStatus])

  const handleClearFilter = () => {
    const params = new URLSearchParams(searchParams.toString())
    setChooseEndDate('')
    setChooseStartDate('')
    setSearchProjectName('')
    setChooseReportType('')
    setChooseProjectType('')
    setChooseYear('')
    params.delete('startDate')
    params.delete('endDate')
    params.delete('projectName')
    params.delete('reportTypeId')
    params.delete('projectTypeId')
    params.delete('year')
    params.delete('statusId')
    params.delete('currentPage')
    setSearchParams(params)
    setApiSearchParam({
      StartDate: '',
      EndDate: '',
      ProjectName: '',
      MddashboardStatusId: null,
      MdprojectTypeId: null,
      MdreportTypeId: null,
      Year: null,
    })
  }

  const handleSummitSearch = () => {
    const params: URLSearchParamsInit = Object.fromEntries([...searchParams])
    setSearchParams({
      ...params,
      startDate: checkValue(chooseStartDate),
      endDate: checkValue(chooseEndDate),
      projectName: checkValue(searchProjectName),
      statusId: checkValue(chooseStatus),
      reportTypeId: checkValue(chooseReportType),
      projectTypeId: checkValue(chooseProjectType),
      year: checkValue(chooseYear),
      currentPage: '1',
    })
    setApiSearchParam({
      StartDate: checkValue(chooseStartDate),
      EndDate: checkValue(chooseEndDate),
      ProjectName: checkValue(searchProjectName),
      MddashboardStatusId: parseToInt(checkValue(chooseStatus)),
      MdprojectTypeId: parseToInt(checkValue(chooseProjectType)),
      MdreportTypeId: parseToInt(checkValue(chooseReportType)),
      Year: parseToInt(checkValue(chooseYear)),
    })
  }
  return (
    <Form form={form} layout="vertical" autoComplete="off">
      <div
        css={css`
          height: 220px;
          border-radius: 10px;
          background-color: white;
          padding: 12px 30px;
          font-weight: 500;
          border-width: 0.5px;
          border-style: solid;
          border-color: ${colors.line};
          box-shadow: 0.5px 0.5px 0.5px ${colors.line};
        `}
      >
        <Flex justify="center">
          <Typography css={css({ fontWeight: 'bold', fontSize: 18 })}>{`Week ${getPreviousWeek()}`}</Typography>
        </Flex>
        <Typography css={css({ fontWeight: 'bold', marginBottom: 6 })}>FILTER</Typography>
        <Flex gap={16} wrap="wrap">
          <div
            css={css`
              flex: 1;
            `}
          >
            <DatePicker
              placeholder="Start Date"
              css={css`
                width: 100%;
              `}
              value={chooseStartDate ? dayjs(chooseStartDate) : null}
              onChange={handleChangeStartDatePicker}
            />
          </div>
          <div
            css={css`
              flex: 1;
            `}
          >
            <DatePicker
              placeholder="End Date"
              css={css`
                width: 100%;
              `}
              value={chooseEndDate ? dayjs(chooseEndDate) : null}
              onChange={handleChangeEndDatePicker}
            />
          </div>
          <div
            css={css`
              flex: 1;
            `}
          >
            <Input
              css={css`
                width: 100%;
              `}
              value={searchProjectName}
              onChange={handleSearchProjectName}
              placeholder="ชื่อโครงการ"
              suffix={<SearchOutlined />}
            />
          </div>
          <Flex
            gap={16}
            css={css`
              flex: 1;
            `}
          >
            <div
              css={css`
                flex: 0.5;
              `}
            >
              <Button
                onClick={handleSummitSearch}
                css={css`
                  width: 100%;
                  text-align: center;
                `}
                htmlType="submit"
                type="primary"
              >
                Search
              </Button>
            </div>
            <Form.Item
              css={css`
                flex: 0.5;
              `}
            >
              <Button
                onClick={handleClearFilter}
                css={css`
                  width: 100%;
                  background-color: #ccecf8;
                  color: ${colors.primary};
                `}
                type="primary"
              >
                Clear
              </Button>
            </Form.Item>
          </Flex>
        </Flex>
        <Flex gap={16} wrap="wrap">
          <div
            css={css`
              flex: 1;
            `}
          >
            <Select
              placeholder="ประเภทรายงาน"
              value={reportTypeOptions.find((item) => item.value === Number(chooseReportType))}
              css={css`
                width: 100%;
              `}
              onSelect={handleChangeReportType}
              options={reportTypeOptions}
            />
          </div>
          <div
            css={css`
              flex: 1;
            `}
          >
            <Select
              placeholder="ประเภทโครงการ"
              value={projectTypeOptions.find((item) => item.value === Number(chooseProjectType))}
              css={css`
                width: 100%;
              `}
              onSelect={handleChangeProjectType}
              options={projectTypeOptions}
            />
          </div>
          <div
            css={css`
              flex: 1;
            `}
          >
            <DatePicker
              css={css`
                width: 100%;
              `}
              onChange={(value) => handleChangeYearPicker(value)}
              picker="year"
              placeholder="Year"
              value={chooseYear ? dayjs(chooseYear) : null}
              format="YYYY"
            />
          </div>
          <div
            css={css`
              flex: 1;
            `}
          >
            <Select
              placeholder="Status"
              value={dashboardStatusOptions.find((item) => item.value === Number(chooseStatus))}
              css={css`
                width: 100%;
              `}
              onSelect={handleChangeStatus}
              options={dashboardStatusOptions}
            />
          </div>
        </Flex>
      </div>
    </Form>
  )
}
