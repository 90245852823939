import { css } from '@ptt-eia-web/helpers'
import { Flex } from 'antd'

import { Txt } from './Txt'

interface IHeaderTitleProps {
  titleFirst: string
  titleSecond: string
  ag?:
    | 'med30'
    | 'med28'
    | 'med24'
    | 'med20'
    | 'med18'
    | 'med16'
    | 'med14'
    | 'med12'
    | 'med10'
    | 'med8'
    | 'light16'
    | 'light12'
    | 'light10'
    | 'reg18'
    | 'reg14'
    | 'reg12'
    | 'reg10'
}

export const HeaderTitle = (props: IHeaderTitleProps) => {
  const { titleFirst, titleSecond, ag } = props
  return (
    <Flex
      className="header-title"
      justify="center"
      align="center"
      css={css`
        padding: 18px 0;
      `}
    >
      <Flex gap={10}>
        <Txt ag={ag || 'med30'} color="primary">
          {titleFirst}
        </Txt>
        <Txt ag={ag || 'med30'}>{titleSecond}</Txt>
      </Flex>
    </Flex>
  )
}
