import styled from '@emotion/styled'
import { css } from '@ptt-eia-web/helpers'
import {
  IServiceRequestDashboardResponse,
  IServiceRequestParam,
  useGetProjectTypesQRY,
  useGetRequestDashboardChartQRY,
  useGetServiceTypesQRY,
} from '@ptt-eia-web/services'
import { Flex, Row, Col } from 'antd'
import dayjs from 'dayjs'
import { isEmpty } from 'lodash'
import { useEffect, useMemo, useState } from 'react'
import { FaCircle } from 'react-icons/fa'
import { useSearchParams } from 'react-router-dom'

import { colors } from '../../app/theme'
import { Txt } from '../../components/Txt'

import { BarChartServiceType, IBarChartDataSet } from './BarChartServiceType'
import { DonutChartRequestStatus } from './DonutChartRequestStatus'
import { FilterNav } from './Filter'

const LABELS: string[] = ['Submitted', 'Assigned', 'Executed', 'Closed']
const BACKGROUND_COLORS: string[] = ['#2EBCA2', '#009FDA', '#77DB82', '#00338D']
const CURRENT_YEAR = dayjs().year()
export const parseSearchParams = (params: URLSearchParams, currentYear: number): IServiceRequestParam => {
  return {
    projectTypeId: 0,
    serviceTypeId: 0,
    year: currentYear,
  }
}

type MyObject = {
  [key: string]: number
}

type BarChartObject = {
  axis: string
  label: string
  data: number[]
  backgroundColor: string
}

interface IDataSet {
  labels: string[]
  datasets: [
    {
      group: string
      label: string
      data: number[]
      backgroundColor: string[]
      hoverOffset: number
      borderWidth: number
      pointStyle: string
    },
  ]
}

const ChartLayoutStyle = styled.div`
  margin-top: 20px;
  margin-bottom: 40px;
  border-radius: 10px;
  height: 500px;
  background-color: white;
  padding: 28px 30px;
  font-weight: 500;
  border-width: 0.5px;
  border-style: solid;
  border-color: ${colors.line};
  box-shadow: 0.5px 0.5px 0.5px ${colors.line};
`

const getTotalCountsByLabel = (apiData: IServiceRequestDashboardResponse, labels: string[]) => {
  const totalCounts: MyObject = {}
  if (apiData.requestByService.length === 0) {
    return [-1]
  }

  apiData.requestByService.forEach((service) => {
    service.requestByStatus.forEach((status) => {
      const { status: statusLabel, count } = status

      if (!totalCounts[statusLabel]) {
        totalCounts[statusLabel] = 0
      }

      totalCounts[statusLabel] += count
    })
  })

  return labels.map((label) => totalCounts[label] || 0)
}

const getBarChartData = (apiData: IServiceRequestDashboardResponse, labels: string[]) => {
  const barChartData: BarChartObject[] = []
  apiData.requestByService.forEach((service) => {
    const totalCounts: MyObject = {}

    service.requestByStatus.forEach((status) => {
      const { status: statusLabel, count } = status

      if (!totalCounts[statusLabel]) {
        totalCounts[statusLabel] = 0
      }

      totalCounts[statusLabel] += count
    })
    barChartData.push({
      axis: 'y',
      label: service.serviceType,
      data: labels.map((label) => totalCounts[label] || 0),
      backgroundColor: `#${service.color}`,
    })
  })
  return barChartData
}

const getParam = (param: string | null) => {
  return isEmpty(param) ? null : Number(param)
}
export const ServiceRequestContent = () => {
  const [datasetDonut, setDatasetDonut] = useState<number[]>([])
  const [datasetBar, setDatasetBar] = useState<BarChartObject[]>([])
  const [totalRequest, setTotalRequest] = useState<number>(0)
  const [summaryDonut, setSummaryDonut] = useState<string>('TOTAL 0%')
  const [bgColorDonut, setBGColorDonut] = useState<string[]>(BACKGROUND_COLORS)
  const { data: projectTypeList } = useGetProjectTypesQRY()
  const { data: serviceTypeList } = useGetServiceTypesQRY()

  const [searchParams] = useSearchParams()
  const filters = useMemo((): IServiceRequestParam => {
    const serviceTypeId = getParam(searchParams.get('serviceTypeId'))
    const projectTypeId = getParam(searchParams.get('projectTypeId'))
    const year = getParam(searchParams.get('year')) ?? CURRENT_YEAR
    return { projectTypeId, serviceTypeId, year }
  }, [searchParams])

  const { data: responseChartData } = useGetRequestDashboardChartQRY(filters)

  useEffect(() => {
    responseChartData && setTotalRequest(responseChartData.totalRequest)
    if (responseChartData) {
      if (responseChartData.requestByService.length === 0) {
        setSummaryDonut('TOTAL 0%')
        setBGColorDonut(['#DDDDDD'])
      } else {
        setSummaryDonut('TOTAL 100%')
        setBGColorDonut(BACKGROUND_COLORS)
      }

      const dataBar = getBarChartData(responseChartData, LABELS)
      const data = getTotalCountsByLabel(responseChartData, LABELS)
      setDatasetDonut(data)
      setDatasetBar(dataBar)
    }
  }, [projectTypeList, responseChartData, totalRequest, serviceTypeList, setSummaryDonut])

  const dataDonutChart: IDataSet = {
    labels: LABELS.map((l) => l.toUpperCase()),
    datasets: [
      {
        group: '',
        label: summaryDonut,
        data: datasetDonut,
        backgroundColor: bgColorDonut,
        hoverOffset: 4,
        borderWidth: 1,
        pointStyle: 'circle',
      },
    ],
  }

  const dataBarChart: IBarChartDataSet = {
    labels: LABELS.map((l) => l.toUpperCase()),
    datasets: datasetBar,
  }

  return (
    <>
      <FilterNav />
      <Row gutter={10} data-testid="fe-service-request-chart">
        <Col sm={24} md={12}>
          <ChartLayoutStyle>
            <Flex
              align="end"
              css={css`
                margin-bottom: 20px;
              `}
            >
              <Flex vertical>
                <Txt ag={'med20'}>ประเภท Service</Txt>
                <Txt ag={'med30'} color="primary">
                  {totalRequest}
                </Txt>
                <Txt ag={'med14'}>Request</Txt>
              </Flex>
              <Flex>
                <Row
                  justify="center"
                  css={css`
                    align-items: center;
                  `}
                >
                  <Col span={24}>
                    <div
                      css={css`
                        display: grid;
                        grid-template-columns: repeat(3, 1fr);
                        font-size: 10px;
                        line-height: 1.5em;
                      `}
                    >
                      {datasetBar.map((l, i) => {
                        return (
                          <div
                            css={css`
                              display: flex;
                              column-gap: 10px;
                              margin-bottom: 10px;
                            `}
                            key={i}
                          >
                            <div
                              css={css`
                                width: 15px;
                                height: 15px;
                                border-radius: 50%;
                              `}
                            />
                            <FaCircle color={l.backgroundColor} />
                            <Txt ag={'med10'}>{l.label}</Txt>
                          </div>
                        )
                      })}
                    </div>
                  </Col>
                </Row>
              </Flex>
            </Flex>
            <BarChartServiceType value={dataBarChart} />
          </ChartLayoutStyle>
        </Col>
        <Col sm={24} md={12}>
          <ChartLayoutStyle>
            <Flex vertical>
              <Txt ag={'med20'}>จำนวน Request แยกตามสถานะ</Txt>
              <Txt ag={'med30'} color="primary">
                {totalRequest}
              </Txt>
              <Txt ag={'med14'}>Request</Txt>
            </Flex>
            <DonutChartRequestStatus value={dataDonutChart} />
            <Row
              justify="center"
              css={css`
                margin-top: 30px;
              `}
            >
              <Col>
                <div
                  css={css`
                    display: grid;
                    grid-template-columns: repeat(2, 1fr);
                    gap: 16px 150px;
                    padding: 0px 16px;
                  `}
                >
                  {LABELS.map((l, i) => {
                    return (
                      <div
                        css={css`
                          display: flex;
                          column-gap: 10px;
                        `}
                        key={i}
                      >
                        <div
                          css={css`
                            width: 15px;
                            height: 15px;
                            border-radius: 50%;
                          `}
                        />
                        <FaCircle color={BACKGROUND_COLORS[i]} />
                        {l.toUpperCase()}
                      </div>
                    )
                  })}
                </div>
              </Col>
            </Row>
          </ChartLayoutStyle>
        </Col>
      </Row>
    </>
  )
}
