import { AppModal } from '@ptt-eia-web/components'
import { css } from '@ptt-eia-web/helpers'
import {
  IProjectTeamParams,
  IProjectTeamResponse,
  useDeleteProjectTeamMTT,
  usePutProjectTeamMTT,
} from '@ptt-eia-web/services'
import { Button, Flex, Form, Space, notification } from 'antd'
import { isEmpty } from 'lodash'
import { useCallback, useEffect } from 'react'
import { useParams } from 'react-router-dom'

import { ErrorButtonCss } from '@frontend/assets/css/ErrorButtonCss'

import { Txt } from '../../../components/Txt'

import { MemberDetailForm } from './MemberDetailForm'

const showDeleteWithCanSaveChange = (teamRoleId: number, canSaveChange?: boolean) => {
  if (!canSaveChange) return false
  return [3, 4, 5].includes(teamRoleId)
}

interface IEditMemberModalProps {
  isOpen: boolean
  onClose: () => void
  projectTeam?: IProjectTeamResponse
  editable?: boolean
  acceptedIds?: number[]

  canSaveChange?: boolean
}

export const EditMemberModal = ({
  isOpen,
  onClose,
  projectTeam,
  editable,
  acceptedIds,
  canSaveChange,
}: IEditMemberModalProps) => {
  const [form] = Form.useForm()
  const values = Form.useWatch(form)
  const roleId = Form.useWatch('roleId', form)

  const { id = 'N/A' } = useParams()

  const { mutateAsync: editProjectTeam } = usePutProjectTeamMTT(id)
  const { mutateAsync: deleteProjectTeam } = useDeleteProjectTeamMTT(id)

  const handleClear = useCallback(() => {
    form.resetFields()
  }, [form])

  const handleCloseModal = useCallback(() => {
    onClose()
    handleClear()
  }, [handleClear, onClose])

  const handleSubmit = useCallback(async () => {
    await form.validateFields().then(async (formValues) => {
      if (projectTeam) {
        const params: IProjectTeamParams = {
          ...projectTeam,
          tbserviceReqId: Number(id),
          mdteamRole: formValues.roleId,
        }
        await editProjectTeam(params)
        notification.success({ message: 'แก้ไข Member สำเร็จ' })
        handleCloseModal()
      }
    })
  }, [editProjectTeam, form, handleCloseModal, id, projectTeam])

  const handleClickDelete = useCallback(async () => {
    if (projectTeam) {
      await deleteProjectTeam({ Id: projectTeam.id })
      notification.success({ message: 'ลบ Member สำเร็จ' })
      handleCloseModal()
    }
  }, [deleteProjectTeam, handleCloseModal, projectTeam])

  useEffect(() => {
    if (isEmpty(values) && projectTeam) {
      form.setFieldsValue({
        ...projectTeam,
        employeeCode: projectTeam?.empCode,
        roleId: projectTeam?.mdteamRole,
        department: projectTeam?.unitname,
        position: projectTeam?.posName,
      })
    }
  }, [form, projectTeam, values])

  return (
    <AppModal
      width={800}
      css={css`
        .ant-modal-body {
          padding: 12px 36px;
        }
        .ant-modal-footer {
          .ant-btn {
            min-width: 100px;
          }
        }
      `}
      title={
        <Txt ag="med18" color="white">
          Edit Project Team
        </Txt>
      }
      centered
      open={isOpen}
      okText="Save Change"
      cancelText="Close"
      onOk={handleSubmit}
      onCancel={handleCloseModal}
      footer={(_, { OkBtn, CancelBtn }) => (
        <Flex gap={16}>
          {(editable || showDeleteWithCanSaveChange(roleId, canSaveChange)) && (
            <Button css={ErrorButtonCss} type="primary" onClick={handleClickDelete}>
              Delete
            </Button>
          )}
          {editable && <OkBtn />}

          <CancelBtn />
        </Flex>
      )}
    >
      <Space
        css={css`
          width: 100%;
        `}
        size={16}
        direction="vertical"
      >
        <MemberDetailForm form={form} showTitle={false} editable={editable} acceptedIds={acceptedIds} />
      </Space>
    </AppModal>
  )
}
