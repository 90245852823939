import styled from '@emotion/styled'
import { MenuCodeEnum, PermissionActionEnum } from '@ptt-eia-web/constants'
import { css } from '@ptt-eia-web/helpers'
import {
  IAnnualReportResponse,
  IAnnualReportSearchParam,
  useGetAnnualReportListQRY,
  usePermission,
} from '@ptt-eia-web/services'
import { List } from 'antd'
import { isEmpty } from 'lodash'
import { useEffect, useMemo, useState } from 'react'
import { Navigate, useSearchParams } from 'react-router-dom'

import { permissionConfigs } from '@frontend/app/permission-configs'
import { themeConfig } from '@frontend/app/theme'

import BgAnnualReport from '../../assets/annual-report/close-up-green-bg.png'

import { FilterKM } from './FilterKM'
import { HeaderAnnualReport } from './HeaderAnnualReport'
import { KMReportList } from './KMReportList'

const LayoutKMReportStyle = styled.div`
  width: 100%;
  min-height: calc(100vh - ${themeConfig.headerHeight + themeConfig.footerHeight}px);
  background-image: url(${BgAnnualReport});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding: 0 147px;
`

const getParam = (param: string | null) => {
  return isEmpty(param) ? null : param
}

export const PageAnnualReport = () => {
  const [searchParams] = useSearchParams()
  const [reportList, setReportList] = useState<IAnnualReportResponse[]>()
  const { can } = usePermission(permissionConfigs)
  const canView = can(MenuCodeEnum.AnnualReport, PermissionActionEnum.View)

  const filters = useMemo((): IAnnualReportSearchParam => {
    const MDDocTypeID = '2'
    const SearchText = getParam(searchParams.get('SearchText'))
    return { MDDocTypeID, SearchText, IsFront: '1' }
  }, [searchParams])
  const { data: annualReports = [], isFetched } = useGetAnnualReportListQRY(filters)

  useEffect(() => {
    if (isFetched && annualReports !== null) setReportList(annualReports)
  }, [annualReports, isFetched])

  if (!canView) return <Navigate to="/404" />
  return (
    <>
      <HeaderAnnualReport />
      <LayoutKMReportStyle>
        <FilterKM />
        <List
          grid={{
            gutter: 8,
            xs: 1,
            sm: 1,
            md: 5,
            xl: 5,
            xxl: 5,
          }}
          css={css`
            padding-bottom: 24px;
          `}
          dataSource={reportList}
          renderItem={(item: IAnnualReportResponse, index: number) => (
            <List.Item key={index}>
              <KMReportList report={item} />
            </List.Item>
          )}
        />
      </LayoutKMReportStyle>
    </>
  )
}
