import { css } from '@ptt-eia-web/helpers'
import { IRequestTrackingResponse } from '@ptt-eia-web/services'
import { Button, Card, Flex } from 'antd'
import { useMemo } from 'react'

import { colors } from '@frontend/app/theme'
import { FormInfoDetail } from '@frontend/modules/service-request/new/FormInfoDetail'
import { RequestTrackingTopField } from '@frontend/modules/service-request-tracking/RequestTrackingTopField'

import { RequestProgress } from './RequestProgress'

interface ITaskFormProps {
  requestTracking?: IRequestTrackingResponse
  serviceTypeId?: number
  subServiceTypeId?: number
  loading?: boolean
  onBack: () => void
  actions?: {
    approve?: number
    reject?: number
    returnForRevise?: number
    recall?: number
    approveCancel?: number
    rejectCancel?: number
  }
  onOpenActivityLogs: () => void
}

export const TaskForm = ({
  requestTracking,
  serviceTypeId,
  subServiceTypeId,
  loading,
  onBack,
  actions,
  onOpenActivityLogs,
}: ITaskFormProps) => {
  const isCancel = useMemo(
    () => requestTracking?.tbserviceReqOutDto.mdreqStatusId === 9,
    [requestTracking?.tbserviceReqOutDto.mdreqStatusId],
  )

  return (
    <Card loading={loading}>
      <Flex
        css={css`
          margin-bottom: 32px;
        `}
        gap={64}
        vertical
      >
        <Flex justify="center">
          <RequestProgress actions={actions} onBack={onBack} requestTracking={requestTracking} />
        </Flex>
        <Flex>
          <Button
            css={css`
              &:hover {
                background-color: #00ffd0 !important;
              }
              background-color: ${colors.bobbleSecondary};
            `}
            type="primary"
            onClick={onOpenActivityLogs}
          >
            Activity Logs
          </Button>
        </Flex>
      </Flex>
      <RequestTrackingTopField isCancel={isCancel} showButton />
      <FormInfoDetail editable={false} serviceTypeId={serviceTypeId} subServiceTypeId={subServiceTypeId} />
    </Card>
  )
}
