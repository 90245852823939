import { css } from '@ptt-eia-web/helpers'
import {
  IProjectDashboardListParam,
  IProjectDashboardOutDto,
  useGetProjectDashboardListQRY,
} from '@ptt-eia-web/services'
import { Table, Tag, Tooltip } from 'antd'
import React, { useCallback, useEffect } from 'react'
import { FiEye } from 'react-icons/fi'
import { useSearchParams } from 'react-router-dom'

import { colors } from '../../../../app/theme'
import { Txt } from '../../../../components/Txt'

import type { ColumnsType } from 'antd/es/table'
interface DataType {
  id: number
  no: number
  projectName: string
  reportType: string
  projectType: string
  reportTypeId: number
  projectTypeId: number
  formTypeId: number
  formType: string
  status: string
  statusId: number
  statusColor: string
  createDate: string
}
const columnTitleStyles = css`
  font-size: 14px;
  text-align: center;
`

const parseSearchParams = (params: URLSearchParams): IProjectDashboardListParam => {
  const getIntParam = (paramName: string): number | null => {
    const paramValue = params.get(paramName)
    return paramValue ? parseInt(paramValue, 10) : null
  }

  return {
    StartDate: params.get('startDate') ?? '',
    EndDate: params.get('endDate') ?? '',
    ProjectName: params.get('projectName') ?? '',
    MddashboardStatusId: getIntParam('statusId'),
    MdprojectTypeId: getIntParam('projectTypeId'),
    MdreportTypeId: getIntParam('reportTypeId'),
    Year: getIntParam('year'),
  }
}

const generateData = (subItem: IProjectDashboardOutDto, index: number) => {
  return {
    id: subItem.id,
    no: index,
    projectName: subItem.projectName ?? '',
    reportType: subItem.mdreportTypeName ?? '',
    projectType: subItem.mdprojectTypeName ?? '',
    status: subItem.mddashboardStatusName ?? '',
    statusId: subItem.mddashboardStatusId ?? 0,
    statusColor: subItem.mddashboardStatusColor ?? '',
    createDate: subItem.createDate ?? '',
    color: '#E5E5E5',
    formType: subItem.mddashboardFormName ?? '',
    formTypeId: subItem.mddashboardFormId ?? 0,
    reportTypeId: subItem.mdreportTypeId ?? 0,
    projectTypeId: subItem.mdprojectTypeId ?? 0,
  }
}

export const ListTable = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const [dataList, setDataList] = React.useState<DataType[]>([])
  const { data: responseDataAPI, isPending } = useGetProjectDashboardListQRY(parseSearchParams(searchParams))
  const currentPageParam = searchParams.get('currentPage') ?? '1'
  const handlePageChange = useCallback(
    (page: number, pageSize: number) => {
      const params = Object.fromEntries([...searchParams])
      setSearchParams({
        ...params,
        currentPage: page.toString(),
      })
    },
    [searchParams, setSearchParams],
  )

  useEffect(() => {
    const newList: DataType[] =
      responseDataAPI?.map((item, index) => {
        return {
          ...generateData(item, index + 1),
          key: item.id.toString(),
        }
      }) ?? []

    setDataList(newList)
  }, [responseDataAPI, searchParams])
  const handlerClickView = (record: DataType) => {
    const params = Object.fromEntries([...searchParams])
    setSearchParams({
      ...params,
      id: record.id.toString(),
      projectName: record.projectName,
      projectType: record.projectType.toString(),
      reportType: record.reportType.toString(),
      formType: record.formType.toString(),
      projectTypeId: record.projectTypeId.toString(),
      reportTypeId: record.reportTypeId.toString(),
      formTypeId: record.formTypeId.toString(),
      projectStatus: record.status.toString(),
      projectStatusId: record.statusId.toString(),
    })
  }
  const columns: ColumnsType<DataType> = [
    {
      title: 'NO.',
      align: 'center',
      dataIndex: 'no',
      width: 10,
      key: 'no',
    },
    {
      title: (
        <div css={columnTitleStyles}>
          <Txt ag="reg14">ชื่อโครงการ</Txt>
        </div>
      ),
      dataIndex: 'projectName',
      width: 220,
      key: 'projectName',

      render: (text) => (
        <Tooltip title={text} trigger="hover">
          <div
            css={css`
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 1;
              overflow: hidden;
              text-overflow: ellipsis;
            `}
          >
            {text}
          </div>
        </Tooltip>
      ),
    },
    {
      title: 'ประเภทโครงการ',
      align: 'center',
      dataIndex: 'projectType',
      width: 150,
      key: 'projectType',
    },
    {
      title: 'ประเภทรายงาน',
      align: 'center',
      dataIndex: 'reportType',
      width: 100,
      key: 'reportType',
    },
    {
      title: 'ชื่อแบบฟอร์ม',
      align: 'center',
      dataIndex: 'formType',
      width: 100,
      key: 'formType',
    },
    {
      title: <Txt ag="reg14">STATUS</Txt>,
      align: 'center',
      key: 'status',
      width: 10,
      dataIndex: 'status',
      render: (_, recode) => (
        <Tag
          css={css`
            margin: 0px -10px;
          `}
          color={'#' + recode.statusColor}
          key={recode.status}
        >
          {recode.status.toUpperCase()}
        </Tag>
      ),
    },
    {
      title: 'CREATE DATE',
      align: 'center',
      key: 'createDate',
      width: 10,
      dataIndex: 'createDate',
    },
    {
      title: 'VIEW',
      dataIndex: 'view',
      align: 'center',
      width: 10,
      key: 'view',
      render: (_, record) => (
        <div
          css={css`
            align-items: center;
            text-align: center;
          `}
        >
          <button
            onClick={() => handlerClickView(record)}
            css={css`
              background-color: transparent;
              border: 0 solid;
              cursor: pointer;
            `}
          >
            <FiEye
              css={css`
                font-size: 15px;
                color: ${colors.gray};
                &:hover {
                  color: ${colors.primary};
                }
              `}
            />
          </button>
        </div>
      ),
    },
  ]
  return (
    <Table
      bordered
      rowKey={(item) => item.no.toString()}
      loading={isPending}
      pagination={{
        current: Number(currentPageParam),
        defaultCurrent: 1,
        defaultPageSize: 5,
        showSizeChanger: false,
        onChange: handlePageChange,
      }}
      columns={columns}
      dataSource={dataList}
    />
  )
}
