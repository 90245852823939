import { ModalUtil, SafeLink } from '@ptt-eia-web/components'
import { css, getFullUrl } from '@ptt-eia-web/helpers'
import { Button, Card, Flex, Table } from 'antd'
import { ColumnsType } from 'antd/es/table'
import dayjs from 'dayjs'
import { useCallback, useMemo, useState } from 'react'
import { AiOutlineDelete } from 'react-icons/ai'

import { colors } from '@frontend/app/theme'
import { IconButtonCss } from '@frontend/assets/css/IconButtonCss'

import { Txt } from '../../../components/Txt'
import { IUploadFileModalValue, UploadFileModal } from '../../../components/UploadFileModal'
import { IAttachType } from '../helper'

export type ITableValueType = IAttachType

interface AttachmentTableProps {
  title: string
  subTitle: string
  dataSource: ITableValueType[]
  onDeleteClick?: (record: ITableValueType) => void
  onUploadConfirm?: (value: IUploadFileModalValue) => void
  editable?: boolean
  css?: string
}

const customRenderText = (value: string, record: IAttachType) => {
  return record.cancelDate ? (
    <Txt
      ag="reg14"
      color="errorRed"
      css={css`
        text-decoration: line-through ${colors.errorRed};
      `}
    >
      {value}
    </Txt>
  ) : (
    value
  )
}

const getColumns = ({
  onDeleteClick,
  editable = true,
}: {
  onDeleteClick?: (record: ITableValueType) => void
  editable?: boolean
}) => {
  const col: ColumnsType<ITableValueType> = [
    {
      title: 'NO.',
      render: (_, __, index) => index + 1,
    },
    {
      title: 'DESCRIPTION',
      dataIndex: 'description',
      render: customRenderText,
    },
    {
      title: 'FILE NAME',
      dataIndex: 'fileName',
      render: (_, record) => {
        const fileType = record.fileName.split('.')[1]
        const newTabMode = ['pdf', 'jpg', 'png', 'jpeg'].includes(fileType)
        const deletedCss = record.cancelDate
          ? css`
              text-decoration: line-through ${colors.errorRed};
              color: ${colors.errorRed};
            `
          : undefined
        if (record.path) {
          return (
            <SafeLink
              id={`attachment-link-${record.id}`}
              url={getFullUrl(record.path)}
              target="_blank"
              download={!newTabMode ? record.fileName : undefined}
              rel="noopener noreferrer"
              css={deletedCss}
            >
              {record.fileName}
            </SafeLink>
          )
        } else if (record.newFile) {
          const url = URL.createObjectURL(record.newFile)
          return (
            <SafeLink
              id={`attachment-file-${record.id}`}
              css={deletedCss}
              url={url}
              target="_blank"
              download={!newTabMode ? record.fileName : undefined}
              rel="noopener noreferrer"
            >
              {record.fileName}
            </SafeLink>
          )
        }
      },
    },
    {
      title: 'MODIFY BY',
      dataIndex: 'modifyByDisplay',
      render: customRenderText,
    },
    {
      title: 'MODIFY DATE',
      dataIndex: 'modifyDate',
      render: (value, record) => {
        return customRenderText(dayjs(value).format('DD/MM/YYYY HH:mm'), record)
      },
    },
  ]
  if (editable) {
    col.push({
      title: '',
      dataIndex: 'action',
      render: (_, record) => {
        if (record.cancelDate) return null
        return (
          <AiOutlineDelete
            id="delete-icon"
            css={IconButtonCss({ color: colors.errorRed, hoverColor: colors.errorRedHover })}
            size={24}
            onClick={() =>
              ModalUtil.confirm({
                title: 'ยืนยันการลบ',
                onOk: () => onDeleteClick?.(record),
              })
            }
          />
        )
      },
    })
  }
  return col
}

export const AttachmentTable = (props: AttachmentTableProps) => {
  const { title, subTitle, dataSource, onDeleteClick, onUploadConfirm, editable = true, ...restProps } = props
  const [uploadModalOpen, setUploadModalOpen] = useState(false)
  const columns = useMemo(() => getColumns({ onDeleteClick, editable }), [editable, onDeleteClick])

  const handleUploadClick = useCallback(() => {
    setUploadModalOpen(true)
  }, [])

  const handleModalCancelClick = useCallback(() => {
    setUploadModalOpen(false)
  }, [])

  const handleModalOkClick = useCallback(
    (value: IUploadFileModalValue) => {
      onUploadConfirm?.(value)
      setUploadModalOpen(false)
    },
    [onUploadConfirm],
  )

  return (
    <Card {...restProps}>
      <Flex justify="space-between" align="center">
        <Flex wrap="wrap">
          <Txt
            css={css`
              min-width: 120px;
              margin-right: 8px;
            `}
            ag="med20"
            color="primary"
          >
            {title}
          </Txt>
          <Txt ag="med20">{subTitle}</Txt>
        </Flex>
        {editable && (
          <Button
            css={css`
              min-width: 120px;
            `}
            type="primary"
            onClick={handleUploadClick}
          >
            Upload
          </Button>
        )}
      </Flex>
      <Table<ITableValueType>
        css={css`
          margin-top: 50px;
        `}
        columns={columns}
        dataSource={dataSource}
        pagination={false}
      />
      {editable && (
        <UploadFileModal open={uploadModalOpen} onCancel={handleModalCancelClick} onOk={handleModalOkClick} />
      )}
    </Card>
  )
}
