import styled from '@emotion/styled'
import { css } from '@ptt-eia-web/helpers'
import { IAnnualReportSearchParam } from '@ptt-eia-web/services'
import { Button, Col, Flex, Input, Row } from 'antd'
import { useCallback, useState } from 'react'
import { GoSearch } from 'react-icons/go'
import { useSearchParams } from 'react-router-dom'

import { colors } from '@frontend/app/theme'

import { KMReportTitle } from './KMReportTitle'

const FilterLayoutStyle = styled(Row)`
  display: flex;
  justify-content: space-between;
  /* height: 150px;
  border-radius: 10px;
  padding: 28px 30px;
  font-weight: 500; */
  padding-top: 32px;
`
const parseApiParams = (params: URLSearchParams): IAnnualReportSearchParam => {
  const getParam = (paramName: string): string | null => {
    const paramValue = params.get(paramName)
    return paramValue ? paramValue : null
  }

  return {
    SearchText: getParam('SearchText'),
  }
}

const parseToSearchParams = (params: IAnnualReportSearchParam) => {
  return {
    SearchText: params.SearchText?.toString() ?? '',
  }
}

export const FilterKM: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const searchText = searchParams.get('SearchText')
  const [chooseSearchText, setChooseSearchText] = useState<string>(searchText !== null ? searchText : '')
  const [filter, setFilter] = useState<IAnnualReportSearchParam>(parseApiParams(searchParams))

  const handleChangeSearchText = useCallback(
    (value: string) => {
      setChooseSearchText(value)
      setFilter({ ...filter, SearchText: value })
    },
    [filter],
  )

  const handleSummitSearch = useCallback(() => {
    setSearchParams(parseToSearchParams(filter))
  }, [filter, setSearchParams])

  return (
    <FilterLayoutStyle gutter={[16, 16]}>
      <Col xs={24} sm={24} md={6} xl={6} xxl={8}>
        <KMReportTitle />
      </Col>
      <Col xs={24} sm={24} md={18} xl={18} xxl={16}>
        <Flex gap={16} justify="end">
          <Input
            onChange={(e) => handleChangeSearchText(e.target.value)}
            css={css`
              width: 320px;
              .ant-input-group .ant-input-group-addon {
                background-color: ${colors.white};
              }
            `}
            value={chooseSearchText}
            placeholder="Search Text"
            prefix={
              <GoSearch
                css={css`
                  margin-right: 8px;
                `}
              />
            }
          />
          <Button
            onClick={handleSummitSearch}
            type="primary"
            css={css`
              width: 100px;
            `}
          >
            Search
          </Button>
        </Flex>
      </Col>
    </FilterLayoutStyle>
  )
}
