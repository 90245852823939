import { css, getFullUrl, mediaQuery, secureRandomInt } from '@ptt-eia-web/helpers'
import { IStringOrNull } from '@ptt-eia-web/services'

import { Txt } from '@frontend/components/Txt'

interface ISectionServicesProps {
  text: string
  imageUrl: IStringOrNull
}
export const SectionServices = ({ text, imageUrl }: ISectionServicesProps) => {
  return (
    <div
      css={css`
        background-color: white;
        padding: 36px 0 62px 0;
      `}
    >
      <div
        css={css`
          position: relative;
          background-size: contain;
          background-repeat: no-repeat;
          background-image: url(${imageUrl && getFullUrl(imageUrl)});
          background-color: white;
          min-height: calc(50vw);
        `}
      >
        <div
          css={css`
            position: absolute;
            top: 36px;
            left: 50%;
            transform: translate(-50%, -50%);
          `}
        >
          <Txt ag="med30">Services</Txt>
        </div>
        <div
          css={css`
            width: 35%;
            height: auto;
            padding-top: 22%;
            margin-left: 30px;
            padding-bottom: 40px;
            padding-right: 16px;
            @media ${mediaQuery.xlGte} {
              top: 50%;
              width: 30%;
              margin-left: 100px;
            }
          `}
        >
          {text.split('\n').map((item) => (
            <div
              key={secureRandomInt({ min: 100, max: 999 })}
              css={css`
                line-height: 22px;
                font-size: 14px;
                @media ${mediaQuery.xlGte} {
                  font-family: Prompt;
                  font-weight: 400;
                  font-size: calc(14px + 0.390625vw);
                  line-height: calc(26px + 0.390625vw);
                }
              `}
            >
              {item}
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}
