import { css } from '@ptt-eia-web/helpers'
import {
  IProjectDashboardParam,
  ISettingResponse,
  useGetProjectDashboardChartQRY,
  useGetProjectTypesQRY,
  useGetReportTypesQRY,
} from '@ptt-eia-web/services'
import { Typography, DatePicker, Button, Select, Flex } from 'antd'
import { LabeledValue } from 'antd/es/select'
import dayjs, { Dayjs } from 'dayjs'
import { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

import { checkValue } from '@frontend/components/CheckValue'
import { getPreviousWeek } from '@frontend/components/GetWeek'

import { colors } from '../../app/theme'

interface IProjectChoice {
  value: number
  label: string
}

interface IReportChoice {
  value: number
  label: string
}
interface IOption {
  value: number
  label: string
}

const parseSearchParams = (params: URLSearchParams): IProjectDashboardParam => {
  const getIntParam = (paramName: string): number | null => {
    const paramValue = params.get(paramName)
    return paramValue ? parseInt(paramValue, 10) : null
  }

  return {
    MdprojectTypeId: getIntParam('projectTypeId'),
    MdreportTypeId: getIntParam('reportTypeId'),
    Year: getIntParam('year'),
  }
}

export const FilterNav: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const { data: projectTypeList } = useGetProjectTypesQRY()
  const { data: reportTypeList } = useGetReportTypesQRY()
  const [projectTypeOptions, setProjectTypeOptions] = useState<IProjectChoice[]>([])
  const [reportTypeOptions, setReportTypeOptions] = useState<IReportChoice[]>([])
  const [chooseReportType, setChooseReportType] = useState<string>(checkValue(searchParams.get('reportTypeId')))
  const [chooseYear, setChooseYear] = useState<string>(checkValue(searchParams.get('year')))
  const [apiSearchParam, setApiSearchParam] = useState<IProjectDashboardParam>(parseSearchParams(searchParams))
  const projectTypeId = searchParams.get('projectTypeId')
  const [chooseProjectType, setChooseProjectType] = useState<string>(projectTypeId !== null ? projectTypeId : '')
  const handleChangeYearPicker = (year: Dayjs | null) => {
    setChooseYear(year ? year.format('YYYY') : '')
  }

  const handleChangeProjectType = (label: LabeledValue) => {
    setChooseProjectType(label?.toString())
  }

  const handleChangeReportType = (label: LabeledValue) => {
    setChooseReportType(label?.toString())
  }

  const parseToInt = (value: string | null, defaultValue: number | null = null) =>
    value ? parseInt(value, 10) : defaultValue
  const updateOptions = (
    dataList: ISettingResponse[],
    setOptionsFn: React.Dispatch<React.SetStateAction<IOption[]>>,
  ) => {
    const newOptions = dataList
      .filter((data) => data.active === 1)
      .map((data) => ({
        value: data.id,
        label: data.name,
      }))
    setOptionsFn(newOptions)
  }

  const { data: responseDataAPI } = useGetProjectDashboardChartQRY(apiSearchParam)
  useEffect(() => {
    if (projectTypeList) updateOptions(projectTypeList, setProjectTypeOptions)
    if (reportTypeList) updateOptions(reportTypeList, setReportTypeOptions)
  }, [projectTypeList, reportTypeList, apiSearchParam, searchParams, responseDataAPI])

  const handleClearFilter = () => {
    const params = new URLSearchParams(searchParams.toString())
    params.delete('reportTypeId')
    params.delete('projectTypeId')
    params.delete('year')
    setSearchParams(params)
    setApiSearchParam({
      MdprojectTypeId: null,
      MdreportTypeId: null,
      Year: null,
    })
    setChooseReportType('')
    setChooseProjectType('')
    setChooseYear('')
  }

  const handleSummitSearch = () => {
    const params = Object.fromEntries([...searchParams])
    setSearchParams({
      ...params,
      reportTypeId: chooseReportType ?? '',
      projectTypeId: chooseProjectType ?? '',
      year: chooseYear ?? '',
    })
    setApiSearchParam({
      MdprojectTypeId: parseToInt(chooseProjectType ?? ''),
      MdreportTypeId: parseToInt(chooseReportType ?? ''),
      Year: parseToInt(chooseYear ?? ''),
    })
  }

  return (
    <div
      css={css`
        height: 150px;
        border-radius: 10px;
        background-color: white;
        padding: 12px 30px;
        font-weight: 500;
        border-width: 0.5px;
        border-style: solid;
        border-color: ${colors.line};
        box-shadow: 0.5px 0.5px 0.5px ${colors.line};
      `}
    >
      <Flex justify="center">
        <Typography css={css({ fontWeight: 'bold', fontSize: 18 })}>{`Week ${getPreviousWeek()}`}</Typography>
      </Flex>
      <Typography css={css({ fontWeight: 'bold', marginBottom: 6 })}>FILTER</Typography>
      <Flex gap={16} wrap="wrap">
        <div
          css={css`
            flex: 1;
          `}
        >
          <Select
            placeholder="ประเภทรายงาน"
            value={reportTypeOptions.find((item) => item.value === Number(chooseReportType))}
            css={css`
              width: 100%;
            `}
            onSelect={handleChangeReportType}
            options={reportTypeOptions}
          />
        </div>
        <div
          css={css`
            flex: 1;
          `}
        >
          <Select
            placeholder="ประเภทโครงการ"
            value={projectTypeOptions.find((item) => item.value === Number(chooseProjectType))}
            css={css`
              width: 100%;
            `}
            onSelect={handleChangeProjectType}
            options={projectTypeOptions}
          />
        </div>
        <div
          css={css`
            flex: 1;
          `}
        >
          <DatePicker
            css={css`
              width: 100%;
            `}
            onChange={(value) => handleChangeYearPicker(value)}
            picker="year"
            placeholder="Year"
            value={chooseYear ? dayjs(chooseYear) : null}
            format="YYYY"
          />
        </div>
        <Flex
          gap={16}
          css={css`
            flex: 1;
          `}
        >
          <div
            css={css`
              flex: 0.5;
            `}
          >
            <Button
              onClick={handleSummitSearch}
              css={css`
                width: 100%;
                text-align: center;
              `}
              type="primary"
            >
              Search
            </Button>
          </div>
          <div
            css={css`
              flex: 0.5;
            `}
          >
            <Button
              onClick={handleClearFilter}
              css={css`
                width: 100%;
                background-color: #ccecf8;
                color: ${colors.primary};
              `}
              type="primary"
            >
              Clear
            </Button>
          </div>
        </Flex>
      </Flex>
    </div>
  )
}
