import { MenuCodeEnum } from '@ptt-eia-web/constants'
import { IMenuMapping } from '@ptt-eia-web/helpers'

import { paths } from '../route/path-config'

export const MENU_MAPPINGS: IMenuMapping[] = [
  {
    menuCode: MenuCodeEnum.Home,
    menuPath: paths.home(),
  },
  {
    menuCode: MenuCodeEnum.ServiceRequest,
  },
  {
    menuCode: MenuCodeEnum.ServiceRequestDashboard,
    menuPath: paths.serviceRequest(),
    menuNestedPaths: [paths.serviceRequestNew(), paths.serviceRequestTracking()],
  },
  {
    menuCode: MenuCodeEnum.ServiceRequestTask,
    menuPath: paths.serviceRequestTask(),
    menuNestedPaths: [paths.serviceRequestTaskDetail()],
  },
  {
    menuCode: MenuCodeEnum.ProjectDashboardMain,
  },
  {
    menuCode: MenuCodeEnum.ProjectDashboard,
    menuPath: paths.projectDashboard(),
  },
  {
    menuCode: MenuCodeEnum.ProjectStatusUpdate,
    menuPath: paths.projectStatusUpdate(),
    menuNestedPaths: [paths.projectStatusUpdateNewProject()],
  },
  {
    menuCode: MenuCodeEnum.PrNews,
    menuPath: paths.prNews(),
    menuNestedPaths: [paths.prNewsDetail()],
  },
  {
    menuCode: MenuCodeEnum.AnnualReport,
    menuPath: paths.annualReport(),
  },
  {
    menuCode: MenuCodeEnum.AboutUs,
    menuPath: paths.aboutUs(),
  },
]
