import { IProjectTeamResponse, IServiceRequestResponse, useGetRequestStatusUpdateIdQRY } from '@ptt-eia-web/services'
import { Flex } from 'antd'

import { ReqTrackingProgressBar } from '../../components/ReqTrackingProgressBar'

import {
  getAssignedInfoByStatusId,
  getAssignedStatus,
  getExecutedAndClosedStatus,
  getSubmittedStatus,
  getUserInfoByTeamRole,
} from './request-helper'

interface IRequestProgressProps {
  serviceRequest?: IServiceRequestResponse
  teams?: IProjectTeamResponse[]
}

export const RequestProgress = ({ serviceRequest, teams }: IRequestProgressProps) => {
  const { data: reqStatus = [] } = useGetRequestStatusUpdateIdQRY(serviceRequest?.id?.toString())

  return (
    <Flex data-testid="flex-request-progress">
      <ReqTrackingProgressBar
        value={{
          submitted: {
            status: getSubmittedStatus(serviceRequest?.mdreqStatusId),
            userInfo: getUserInfoByTeamRole(teams, 1),
          },
          assigned: {
            status: getAssignedStatus(serviceRequest?.mdreqStatusId),
            userInfo: getAssignedInfoByStatusId(reqStatus, serviceRequest?.mdreqStatusId),
          },
          executed: {
            status: getExecutedAndClosedStatus(serviceRequest?.mdreqStatusId),
          },
          closed: {
            status: getExecutedAndClosedStatus(serviceRequest?.mdreqStatusId),
          },
        }}
      />
    </Flex>
  )
}
