import { MenuCodeEnum, PermissionActionEnum } from '@ptt-eia-web/constants'
import { css } from '@ptt-eia-web/helpers'
import { usePermission } from '@ptt-eia-web/services'
import { Col, Flex, Form, Radio, Row, Switch } from 'antd'
import { useWatch } from 'antd/es/form/Form'
import TextArea from 'antd/es/input/TextArea'
import React, { useEffect, useMemo, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

import { permissionConfigs } from '@frontend/app/permission-configs'
import { Txt } from '@frontend/components/Txt'

import { colors } from '../../../../../../app/theme'
import MonitorTablePicture from '../../../../../../assets/project-dashboard/transmission-monitoring-table.png'

export const EnvComplianceAndMonitoring = () => {
  const [searchParams] = useSearchParams()
  const formEiaSwitch = useWatch('monitorActive')
  const formUpdateBy = useWatch('updateBy')
  const formUpdateDate = useWatch('updateDate')
  const { can } = usePermission(permissionConfigs)
  const canEdit = can(MenuCodeEnum.ProjectStatusUpdate, PermissionActionEnum.Edit)
  const [titleSecondForm, setTitleSecondForm] = useState<string>()
  const [toggleEIA, setToggleEIA] = useState<boolean>(false)
  useEffect(() => {
    if (searchParams.get('flowsType')?.toString() === 'new') {
      setToggleEIA(true)
    }
  }, [searchParams])
  useEffect(() => {
    if (formEiaSwitch !== undefined) {
      setToggleEIA(formEiaSwitch)
    }
  }, [formEiaSwitch, toggleEIA])
  useEffect(() => {
    if (
      (searchParams.get('reportTypeValue')?.toString() === 'ER' ||
        searchParams.get('reportTypeValue')?.toString() === 'Revised ER') &&
      searchParams.get('formTypeId')?.toString() === '2'
    ) {
      setTitleSecondForm('ER')
    } else {
      setTitleSecondForm('EIA')
    }
  }, [searchParams])
  const isDisableToggle = useMemo(() => {
    if (!canEdit) {
      return true
    }
    if (!toggleEIA) {
      return true
    }
  }, [toggleEIA, canEdit])
  const isDisables = useMemo(() => {
    if (!canEdit) {
      return true
    }
  }, [canEdit])
  return (
    <div
      css={css`
        padding: 46px 70px 0px 70px;
      `}
    >
      <span>
        <Txt
          ag="med24"
          css={css`
            color: ${colors.primary};
            margin-right: 8px;
          `}
        >
          Environmental Compliance
        </Txt>
        <Txt ag="med24">and Monitoring</Txt>
      </span>
      <Row
        css={css`
          @media screen and (min-width: 718px) {
            padding: 30px;
          }
          margin: 20px 0px;
        `}
        gutter={[60, 20]}
      >
        <Col lg={8} md={8} xs={24}>
          <Row gutter={[0, 16]}>
            <Col span={8}>
              <Form.Item>
                <Txt ag="reg14">Monitoring</Txt>
              </Form.Item>
            </Col>
            <Col span={14}>
              <Form.Item name="monitorActive">
                <Switch disabled={canEdit ? false : isDisables} defaultChecked />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item
            css={css`
              .ant-form-item-label label {
                height: 20px;
              }
              .ant-input {
                height: auto;
              }
            `}
            rules={[{ required: isDisableToggle, message: 'กรุณากรอกข้อความ' }]}
            name="monitorReason"
            label="ข้อความกรณี Monitoring Inactive"
          >
            <TextArea
              disabled={!isDisableToggle}
              placeholder={isDisableToggle ? 'กรอกข้อความ' : ''}
              rows={6}
              maxLength={1500}
              showCount
            />
          </Form.Item>
          <div
            css={css`
              display: flex;
              flex-direction: column;
              gap: 24px;
            `}
          >
            <Form.Item name="eiaCompliance" label={`${titleSecondForm}  Compliance`}>
              <Radio.Group disabled={isDisableToggle} defaultValue={'0'}>
                <Radio value={'0'}>Yes</Radio>
                <Radio value={'1'}>No</Radio>
              </Radio.Group>
            </Form.Item>
          </div>
        </Col>
        <Col xs={24} md={24} lg={16} xl={16}>
          <div
            css={css`
              height: auto;
              padding: 8px;
              box-shadow: 0px 2px 6px #0000000a;
              border: 1px solid #dddddd;
              border-radius: 10px;
            `}
          >
            <img
              css={css`
                width: 100%;
                height: auto;
              `}
              src={MonitorTablePicture}
              alt="transmission table"
            />
          </div>
        </Col>
      </Row>
      <Row gutter={100}>
        <Col xs={24} md={24} lg={12} xl={12}>
          <Form.Item
            css={css`
              flex: 1;
              .ant-form-item-label label {
                height: 20px;
              }
              .ant-input {
                height: 150px;
              }
            `}
            name="sixMonthReportSubmission"
            label="Six Month Report Submission"
          >
            <TextArea
              disabled={isDisableToggle}
              placeholder={!isDisableToggle ? 'กรอกข้อความ' : ''}
              maxLength={1500}
              showCount
            />
          </Form.Item>
        </Col>
        <Col xs={24} md={24} lg={12} xl={12}>
          <Form.Item
            css={css`
              flex: 1;
              .ant-form-item-label label {
                height: 20px;
              }
              .ant-input {
                height: 150px;
              }
            `}
            name="eiaComplianceHighlightActivities"
            label={`${titleSecondForm} Compliance Highlight Activities`}
          >
            <TextArea
              disabled={isDisableToggle}
              placeholder={!isDisableToggle ? 'กรอกข้อความ' : ''}
              maxLength={1500}
              showCount
            />
          </Form.Item>
        </Col>
      </Row>
      <Flex
        css={css`
          padding: 50px 0px 0px 0px;
        `}
        gap={100}
      >
        <Form.Item name="updateBy" label="Update By">
          <Txt
            ag="med16"
            css={css`
              color: ${colors.primary};
            `}
          >
            {formUpdateBy ? formUpdateBy : '-'}
          </Txt>
        </Form.Item>
        <Form.Item name="updateDate" label="Update Date">
          <Txt ag="med16">{formUpdateDate ? formUpdateDate : '-'}</Txt>
        </Form.Item>
      </Flex>
    </div>
  )
}
