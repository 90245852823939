import styled from '@emotion/styled'
import { MenuCodeEnum, PermissionActionEnum } from '@ptt-eia-web/constants'
import { css } from '@ptt-eia-web/helpers'
import { usePermission } from '@ptt-eia-web/services'
import { Flex, Space } from 'antd'
import React from 'react'
import { Navigate } from 'react-router-dom'

import { permissionConfigs } from '@frontend/app/permission-configs'

import { themeConfig } from '../../app/theme'
import BgPieChartDashboard from '../../assets/project-dashboard/bg-pie-dashboard.jpeg'
import { Txt } from '../../components/Txt'

import { FilterNav } from './Filter'
import { ProjectList } from './ProjectList'

const LayoutStyle = styled.div`
  width: 100%;
  min-height: calc(100vh - ${themeConfig.headerHeight + themeConfig.footerHeight}px);
  background-image: url(${BgPieChartDashboard});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding: 0px 64px 64px 64px;
  position: relative;
`
export const PageTask = () => {
  const { can } = usePermission(permissionConfigs)
  const canView = can(MenuCodeEnum.ServiceRequestTask, PermissionActionEnum.View)
  if (!canView) return <Navigate to="/404" />
  return (
    <LayoutStyle data-testid="page-request-task">
      <Space
        css={css`
          width: 100%;
        `}
        direction="vertical"
        size={16}
      >
        <Flex
          justify="center"
          align="center"
          css={css`
            padding: 18px 0 0 0;
          `}
        >
          <Txt ag="med30">My Approval</Txt>
        </Flex>
        <FilterNav />
        <ProjectList />
      </Space>
    </LayoutStyle>
  )
}
