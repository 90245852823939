import {
  IBaseFormInfoTypeProps,
  IBaseServiceType,
  businessDevelopment,
  eiaErReport,
  eiaMonitoringMr,
  environmentalDueDiligent,
  environmentalModeling,
  environmentalReportScreening,
  preliminaryEia,
  subDevEiaErReport,
  subDevEiaMonitoringMr,
  subDevEnvironmentalModeling,
  subDevPreliminaryEia,
} from '../helper'

import { FormInfoType1 } from './FormInfoType1'
import { FormInfoType2 } from './FormInfoType2'
import { FormInfoType3 } from './FormInfoType3'

type IFormInfoDetailProps = IBaseServiceType & IBaseFormInfoTypeProps

export const FormInfoDetail = (props: IFormInfoDetailProps) => {
  const { serviceTypeId, subServiceTypeId, editable } = props

  if (serviceTypeId && [preliminaryEia, eiaErReport].includes(serviceTypeId)) {
    return <FormInfoType1 editable={editable} />
  }
  if (
    serviceTypeId &&
    subServiceTypeId &&
    [businessDevelopment].includes(serviceTypeId) &&
    [subDevPreliminaryEia, subDevEiaErReport].includes(subServiceTypeId)
  ) {
    return <FormInfoType1 editable={editable} />
  }

  if (
    serviceTypeId &&
    [environmentalReportScreening, environmentalModeling, environmentalDueDiligent].includes(serviceTypeId)
  ) {
    return <FormInfoType2 editable={editable} />
  }
  if (
    serviceTypeId &&
    subServiceTypeId &&
    [businessDevelopment].includes(serviceTypeId) &&
    [subDevEnvironmentalModeling].includes(subServiceTypeId)
  ) {
    return <FormInfoType2 editable={editable} />
  }

  if (serviceTypeId && [eiaMonitoringMr].includes(serviceTypeId)) {
    return <FormInfoType3 editable={editable} />
  }
  if (
    serviceTypeId &&
    subServiceTypeId &&
    [businessDevelopment].includes(serviceTypeId) &&
    [subDevEiaMonitoringMr].includes(subServiceTypeId)
  ) {
    return <FormInfoType3 editable={editable} />
  }

  return <FormInfoType2 editable={editable} />
}
