import { css } from '@ptt-eia-web/helpers'
import { Flex } from 'antd'
import React from 'react'

import { colors } from '../../../../app/theme'
import { Txt } from '../../../../components/Txt'

import { ListTable } from './ListTable'

export const ProjectListTable = () => {
  return (
    <div
      css={css`
        height: 100%;
        background-color: white;
        margin: 30px 0px;
        border-radius: 15px;
        border: 1px solid ${colors.line};
        box-shadow: 0px 2px 6px #0000000a;
      `}
    >
      <Flex
        gap={10}
        css={css`
          padding: 46px 0px 30px 40px;
        `}
      >
        <Txt
          ag="med30"
          css={css`
            color: ${colors.primary};
          `}
        >
          Project
        </Txt>
        <Txt ag="med30">List</Txt>
      </Flex>
      <div
        css={css`
          padding: 0px 24px 54px 24px;
        `}
      >
        <ListTable />
      </div>
    </div>
  )
}
