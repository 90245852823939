import { MenuCodeEnum, PermissionActionEnum } from '@ptt-eia-web/constants'
import { css } from '@ptt-eia-web/helpers'
import { usePermission } from '@ptt-eia-web/services'
import React from 'react'

import { permissionConfigs } from '@frontend/app/permission-configs'

import { colors } from '../../../../app/theme'

import { AddButton } from './AddButton'
import { DataType, EmployeeTable } from './EmployeeTable'

interface IModalEmployeeProps {
  tableData: DataType[]
  isPending: boolean
  onDelete: (id: string) => void
  onClickAddEmail: () => void
}

export const EmployeeCard = ({ tableData, onDelete, isPending, onClickAddEmail }: IModalEmployeeProps) => {
  const { can } = usePermission(permissionConfigs)
  const canEdit = can(MenuCodeEnum.ProjectStatusUpdate, PermissionActionEnum.Edit)
  return (
    <div
      css={css`
        height: 435px;
        background-color: white;
        padding: 18px 20px 16px 20px;
        border-radius: 15px;
        border: 1px solid ${colors.line};
        box-shadow: 0px 2px 6px #0000000a;
      `}
    >
      <div
        css={css`
          display: flex;
          justify-content: flex-end;
          margin-bottom: 20px;
        `}
      >
        {canEdit && <AddButton onAddClick={onClickAddEmail} />}
      </div>
      <EmployeeTable tableData={tableData} onDelete={onDelete} isPending={isPending} />
    </div>
  )
}
