import { css } from '@ptt-eia-web/helpers'
import { ProjectDashboardLTAInDtos, ProjectDashboardSHEInDtos } from '@ptt-eia-web/services'
import React from 'react'

import { Txt } from '@frontend/components/Txt'

import { colors } from '../../../../../app/theme'

import { WithoutLTATable } from './WithoutLTATable'

interface IWithoutLTAProps {
  onProjectDashboardLTAInDtos: (arg0: ProjectDashboardLTAInDtos[]) => void
  onValidateInputPhaseLTASuccess: (arg0: boolean) => void
  dataSourceLTA: ProjectDashboardLTAInDtos[]
  dataSourceSHE: ProjectDashboardSHEInDtos[]
}
export const WithoutLTA = (props: IWithoutLTAProps) => {
  return (
    <div
      css={css`
        padding: 46px 70px 0px 70px;
      `}
    >
      <span>
        <Txt
          ag="med24"
          css={css`
            color: ${colors.primary};
            margin-right: 8px;
            /* padding-left: 32px; */
          `}
        >
          Without
        </Txt>
        <Txt ag="med24">LTA</Txt>
      </span>
      <div
        css={css`
          margin-top: 26px;
        `}
      >
        <WithoutLTATable
          onValidateInputPhaseLTASuccess={(isSuccess: boolean) => props.onValidateInputPhaseLTASuccess(isSuccess)}
          dataSourceLTA={props.dataSourceLTA}
          onProjectDashboardLTAInDtos={(data) => props.onProjectDashboardLTAInDtos(data)}
          dataSourceSHE={props.dataSourceSHE}
        />
      </div>
    </div>
  )
}
