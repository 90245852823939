import { Flex } from 'antd'

import { Txt } from '../../components/Txt'

export const KMReportTitle = () => {
  return (
    <Flex justify="start" align="center">
      <Flex gap={10}>
        <Txt ag="med30" color="primary">
          Reports
        </Txt>
      </Flex>
    </Flex>
  )
}
