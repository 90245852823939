import styled from '@emotion/styled'
import { css } from '@ptt-eia-web/helpers'

import { Txt } from '@frontend/components/Txt'

import { colors } from '../../app/theme'

const BadgeContainer = styled('div')<{ color: string }>`
  background-color: ${(props) => props.color};
  color: ${colors.white};
  text-overflow: ellipsis;
  text-align: center;
  border-radius: 24px;
  font-size: 8px;
  margin-bottom: 8px;
  line-height: 12px;
  padding: 2px 8px;
`

interface ITagBadgeProps {
  color?: string
  status: string
}

export const TagBadge = ({ color = colors.darkGray, status }: ITagBadgeProps) => {
  const backgroundColor = color.startsWith('#') ? color : `#${color}`
  return (
    <BadgeContainer color={backgroundColor}>
      <Txt
        ag="med8"
        css={css`
          line-height: 12px;
          color: ${colors.white};
        `}
      >
        {status}
      </Txt>
    </BadgeContainer>
  )
}
