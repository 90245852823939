import styled from '@emotion/styled'
import { MenuCodeEnum } from '@ptt-eia-web/constants'
import { css } from '@ptt-eia-web/helpers'
import { useGetRoleMenuQRY } from '@ptt-eia-web/services'
import { Col, Flex, Row } from 'antd'
import { useCallback } from 'react'
import { FiPhoneCall } from 'react-icons/fi'
import { IoLocationOutline } from 'react-icons/io5'
import { Link } from 'react-router-dom'

import pttLogoWhite from '../../assets/svgs/ptt-white.svg'
import { Txt } from '../../components/Txt'
import { colors } from '../theme'

const VerticalLine = styled('div')`
  height: 30px;
  margin: 0 10px;
  width: 1px;
  margin-top: auto;
  background: white;
`
const LogoText = styled(Txt)`
  line-height: 10px;
  height: 10px;
  color: ${({ color }) => (color ? color : 'inherit')};
`

const HorizontalLine = styled.div`
  background-color: #dddddd;
  height: 1px;
  width: 100%;
`

export const Footer = () => {
  const { data: roleMenuList = [] } = useGetRoleMenuQRY()
  const mapMenuLabel = useCallback(
    (menuCode: MenuCodeEnum) => {
      return roleMenuList.find((role) => role.menuCode === menuCode)?.menuNameEn || ''
    },
    [roleMenuList],
  )

  return (
    <footer
      css={css`
        background: ${colors.darkBlue};
        color: white;
        padding: 30px 30px 0px;
        ul {
          margin: 0;
          padding-top: 0px;
          padding-left: 25px;
          li {
            font-size: smaller;
            line-height: 22px;
          }
        }
      `}
    >
      <Flex
        vertical
        css={css`
          max-width: 1755px;
          margin: 0 auto;
        `}
      >
        <Row>
          <Col span={24}>
            <Flex>
              <img
                css={css`
                  height: 40px;
                `}
                alt="logo"
                src={pttLogoWhite}
              />
              <VerticalLine />
              <Flex
                vertical
                css={css`
                  margin-top: auto;
                `}
              >
                <LogoText ag="reg10" color="white">
                  Project
                </LogoText>
                <LogoText ag="reg10" color="white">
                  Environmental
                </LogoText>
                <LogoText ag="light10" color="white">
                  Management Services
                </LogoText>
              </Flex>
            </Flex>
          </Col>
        </Row>

        <Row
          gutter={[30, 30]}
          css={css`
            margin-top: 26px;
          `}
        >
          <Col xs={24} xl={7}>
            <Flex>
              <IoLocationOutline
                size={24}
                css={css`
                  width: 20px;
                  min-width: 20px;
                  margin-right: 10px;
                `}
              />
              <Flex vertical>
                <Txt ag="reg14" color="white">
                  บริษัท ปตท. จำกัด (มหาชน)
                </Txt>
                <Txt ag="reg14" color="white">
                  555 ถนนวิภาวดีรังสิต แขวงจตุจักร
                </Txt>
                <Txt ag="reg14" color="white">
                  เขตจตุจักร กรุงเทพมหานคร 10900
                </Txt>
              </Flex>
            </Flex>

            <Flex>
              <FiPhoneCall
                size={18}
                css={css`
                  width: 20px;
                  margin-right: 10px;
                `}
              />
              <Flex vertical>
                <Txt ag="reg14" color="white" ellipsis>
                  โทรศัพท์ : 025373632
                </Txt>
              </Flex>
            </Flex>
          </Col>

          <Col xs={12} sm={9} lg={5} xl={4}>
            <Flex vertical>
              <Txt ag="reg14" color="white" ellipsis>
                {mapMenuLabel(MenuCodeEnum.ServiceRequest)}
              </Txt>
              <ul>
                <li>
                  <Link to="/service-request">
                    <Txt ag="reg14" color="white" ellipsis>
                      {mapMenuLabel(MenuCodeEnum.ServiceRequestDashboard)}
                    </Txt>
                  </Link>
                </li>
                <li>
                  <Link to="/service-request/task">
                    <Txt ag="reg14" color="white" ellipsis>
                      {mapMenuLabel(MenuCodeEnum.ServiceRequestTask)}
                    </Txt>
                  </Link>
                </li>
              </ul>
            </Flex>
          </Col>

          <Col xs={12} sm={9} lg={6} xl={4}>
            <Flex vertical>
              <Txt ag="reg14" color="white" ellipsis>
                {mapMenuLabel(MenuCodeEnum.ProjectDashboardMain)}
              </Txt>
              <ul>
                <li>
                  <Link to="/project-dashboard">
                    <Txt ag="reg14" color="white" ellipsis>
                      {mapMenuLabel(MenuCodeEnum.ProjectDashboard)}
                    </Txt>
                  </Link>
                </li>
                <li>
                  <Link to="/project-dashboard/project-status-update">
                    <Txt ag="reg14" color="white" ellipsis>
                      {mapMenuLabel(MenuCodeEnum.ProjectStatusUpdate)}
                    </Txt>
                  </Link>
                </li>
              </ul>
            </Flex>
          </Col>

          <Col xs={12} sm={6} lg={4} xl={3}>
            <Flex vertical>
              <Txt ag="reg14" color="white" ellipsis>
                {mapMenuLabel(MenuCodeEnum.PrNews)}
              </Txt>
              <ul>
                <li>
                  <Link to="/pr-news">
                    <Txt ag="reg14" color="white" ellipsis>
                      {mapMenuLabel(MenuCodeEnum.PrNews)}
                    </Txt>
                  </Link>
                </li>
              </ul>
            </Flex>
          </Col>

          <Col xs={12} sm={9} lg={5} xl={3}>
            <Flex vertical>
              <Txt ag="reg14" color="white" ellipsis>
                {mapMenuLabel(MenuCodeEnum.AnnualReport)}
              </Txt>
              <ul>
                <li>
                  <Link to="/annual-reports">
                    <Txt ag="reg14" color="white" ellipsis>
                      {mapMenuLabel(MenuCodeEnum.AnnualReport)}
                    </Txt>
                  </Link>
                </li>
              </ul>
            </Flex>
          </Col>

          <Col xs={12} sm={9} lg={3} xl={3}>
            <Flex vertical>
              <Txt ag="reg14" color="white" ellipsis>
                {mapMenuLabel(MenuCodeEnum.AboutUs)}
              </Txt>
              <ul>
                <li>
                  <Link to="/about-us">
                    <Txt ag="reg14" color="white" ellipsis>
                      {mapMenuLabel(MenuCodeEnum.AboutUs)}
                    </Txt>
                  </Link>
                </li>
              </ul>
            </Flex>
          </Col>
        </Row>

        <Row
          css={css`
            margin-top: 45px;
          `}
        >
          <Col span={24}>
            <HorizontalLine />
          </Col>
        </Row>

        <Row
          align="middle"
          justify="center"
          css={css`
            margin: 12px 0;
          `}
        >
          <Col>
            <Txt ag="reg12" color="white">
              Copyright 2024 © PTT Project Environmental Management Services
            </Txt>
          </Col>
        </Row>
      </Flex>
    </footer>
  )
}
