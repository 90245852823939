import { css } from '@ptt-eia-web/helpers'
import { useDebugSignInMTT } from '@ptt-eia-web/services'
import { Button, Flex, Input } from 'antd'
import { useCallback, useState } from 'react'
import { useNavigate } from 'react-router-dom'

export const PageDebugLogin = () => {
  const { mutate: debugSignIn, isPending } = useDebugSignInMTT()
  const navigate = useNavigate()
  const [email, setEmail] = useState('490015@email.com')

  const onDebugSignInClick = useCallback(() => {
    debugSignIn(
      {
        email,
      },
      {
        onSuccess: () => {
          navigate('/')
        },
      },
    )
  }, [debugSignIn, email, navigate])

  const handleChangeEmail = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value)
  }, [])

  return (
    <div
      css={css`
        display: flex;
        width: 100vw;
        height: 100vh;
        justify-content: center;
        align-items: center;
      `}
    >
      <Flex align="center" justify="center" gap="small" vertical>
        <h1>Login</h1>
        <Input placeholder="Email" type="email" value={email} onChange={handleChangeEmail} allowClear />
        <Button type="primary" onClick={onDebugSignInClick} loading={isPending}>
          Debug
        </Button>
      </Flex>
    </div>
  )
}
