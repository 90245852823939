import { LeftOutlined } from '@ant-design/icons'
import styled from '@emotion/styled'
import { SafeImage } from '@ptt-eia-web/components'
import { MenuCodeEnum, PermissionActionEnum } from '@ptt-eia-web/constants'
import { css, getFullUrl } from '@ptt-eia-web/helpers'
import { useGetPRNewsDetailQRY, usePermission } from '@ptt-eia-web/services'
import { Button, Col, Divider, Flex, Row } from 'antd'
import { AnyObject } from 'antd/es/_util/type'
import { useEffect } from 'react'
import { Link, Navigate, useParams } from 'react-router-dom'

import { permissionConfigs } from '@frontend/app/permission-configs'
import BgPrNews from '@frontend/assets/pr-news/pr-news-bg.png'

import { colors, themeConfig } from '../../app/theme'
import FrameAnnualReport from '../../assets/pr-news/pr-news-frame.png'
import { Txt } from '../../components/Txt'

import { dateShortTH } from './PrNewsCard'
import { PrNewsTitle } from './PrNewsTitle'

export const LayoutStyle = styled.div`
  width: 100%;
  min-height: calc(100vh - ${themeConfig.headerHeight + themeConfig.footerHeight}px);
  background-image: url(${BgPrNews});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding: 0 64px;
  position: relative;
`

const FrameImageContent = styled.div`
  display: flex;
  justify-content: center;
  align-self: center;
  background-image: url(${FrameAnnualReport});
  background-position: center;
  background-repeat: no-repeat;
  width: 700px;
  height: 500px;
`

const PrNewsImageContent = styled.div`
  display: flex;
  justify-content: center;
  align-self: center;
  width: 565px;
  height: 400px;
  margin-top: 40px;
  margin-left: 80px;
`

export const PagePrNewsDetail = () => {
  const { prNewsId = 'N/A' } = useParams()
  const { data: prNewsItem, isFetched } = useGetPRNewsDetailQRY({ id: String(prNewsId) })
  const { can } = usePermission(permissionConfigs)
  const canView = can(MenuCodeEnum.PrNews, PermissionActionEnum.View)

  useEffect(() => {
    const contentElement = document.getElementById('pr_news_content') as AnyObject
    if (contentElement && prNewsItem) {
      contentElement.innerHTML = decodeURIComponent(prNewsItem.detail)
    }
  }, [prNewsItem])

  if (!canView) return <Navigate to="/404" />

  return (
    isFetched && (
      <LayoutStyle data-testid="fe-pr-news-detail">
        <PrNewsTitle />
        <Flex vertical>
          <Row justify="center" align="middle">
            <Col xs={24} sm={24} md={2} xl={2}>
              <Txt ag="med14" color="primary">
                {prNewsItem && dateShortTH(prNewsItem.startDate)}
              </Txt>
            </Col>
            <Col xs={24} sm={24} md={22} xl={22}>
              <Divider
                css={css`
                  border-block-start: 1px solid ${colors.primary};
                `}
              />
            </Col>
          </Row>

          <Txt
            ag="med24"
            css={css`
              margin-top: 16px;
            `}
          >
            {prNewsItem?.title}
          </Txt>
          {prNewsItem && (
            <FrameImageContent>
              <PrNewsImageContent>
                <SafeImage
                  id={`pr_news_cover_image_${prNewsItem.id}`}
                  url={getFullUrl(prNewsItem.filePath)}
                  alt={prNewsItem.fileName}
                  width={550}
                  height={400}
                  css={css`
                    object-fit: cover;
                    width: 100%;
                    max-width: 100%;
                    max-height: 100%;
                  `}
                />
              </PrNewsImageContent>
            </FrameImageContent>
          )}
          {prNewsItem && (
            <div
              id="pr_news_content"
              css={css`
                margin-top: 32px;
                margin-bottom: 32px;
                font-size: 16px;
              `}
            ></div>
          )}
        </Flex>
        <Flex vertical align="center">
          <Link
            to="/pr-news"
            css={css`
              margin-bottom: 32px;
              font-size: 16px;
            `}
          >
            <Button type="default" icon={<LeftOutlined />}>
              Back
            </Button>
          </Link>
        </Flex>
      </LayoutStyle>
    )
  )
}
