import {
  IPermissionConfig,
  MenuCodeEnum,
  PermissionActionEnum,
  PermissionIdEnum,
  RoleIdEnum,
} from '@ptt-eia-web/constants'

export const permissionConfigs: IPermissionConfig[] = [
  {
    menuCode: MenuCodeEnum.Home,
    actions: {
      [PermissionActionEnum.View]: [
        { roleId: RoleIdEnum.SystemAdmin },
        { roleId: RoleIdEnum.PowerUser },
        { roleId: RoleIdEnum.GuestUser },
      ],
    },
  },
  {
    menuCode: MenuCodeEnum.ServiceRequestDashboard,
    actions: {
      [PermissionActionEnum.View]: [
        { roleId: RoleIdEnum.SystemAdmin },
        { roleId: RoleIdEnum.PowerUser },
        { roleId: RoleIdEnum.GuestUser },
      ],
      [PermissionActionEnum.Add]: [
        { roleId: RoleIdEnum.SystemAdmin },
        { roleId: RoleIdEnum.PowerUser },
        { roleId: RoleIdEnum.GuestUser },
      ],
      [PermissionActionEnum.EditAll]: [
        { roleId: RoleIdEnum.SystemAdmin, permissionId: PermissionIdEnum.ServiceRequest },
        { roleId: RoleIdEnum.PowerUser, permissionId: PermissionIdEnum.ServiceRequest },
      ],
    },
  },
  {
    menuCode: MenuCodeEnum.ServiceRequestTask,
    actions: {
      [PermissionActionEnum.View]: [
        { roleId: RoleIdEnum.SystemAdmin },
        { roleId: RoleIdEnum.PowerUser },
        { roleId: RoleIdEnum.GuestUser },
      ],
    },
  },
  {
    menuCode: MenuCodeEnum.ProjectDashboard,
    actions: {
      [PermissionActionEnum.View]: [
        { roleId: RoleIdEnum.SystemAdmin },
        { roleId: RoleIdEnum.PowerUser },
        { roleId: RoleIdEnum.GuestUser },
      ],
    },
  },
  {
    menuCode: MenuCodeEnum.ProjectStatusUpdate,
    actions: {
      [PermissionActionEnum.View]: [{ roleId: RoleIdEnum.SystemAdmin }, { roleId: RoleIdEnum.PowerUser }],
      [PermissionActionEnum.Add]: [
        { roleId: RoleIdEnum.SystemAdmin, permissionId: PermissionIdEnum.ProjectData },
        { roleId: RoleIdEnum.PowerUser, permissionId: PermissionIdEnum.ProjectData },
      ],
      [PermissionActionEnum.Edit]: [
        { roleId: RoleIdEnum.SystemAdmin, permissionId: PermissionIdEnum.ProjectData },
        { roleId: RoleIdEnum.PowerUser, permissionId: PermissionIdEnum.ProjectData },
      ],
      [PermissionActionEnum.Delete]: [
        { roleId: RoleIdEnum.SystemAdmin, permissionId: PermissionIdEnum.ProjectData },
        { roleId: RoleIdEnum.PowerUser, permissionId: PermissionIdEnum.ProjectData },
      ],
    },
  },
  {
    menuCode: MenuCodeEnum.PrNews,
    actions: {
      [PermissionActionEnum.View]: [
        { roleId: RoleIdEnum.SystemAdmin },
        { roleId: RoleIdEnum.PowerUser },
        { roleId: RoleIdEnum.GuestUser },
      ],
    },
  },
  {
    menuCode: MenuCodeEnum.AnnualReport,
    actions: {
      [PermissionActionEnum.View]: [
        { roleId: RoleIdEnum.SystemAdmin },
        { roleId: RoleIdEnum.PowerUser },
        { roleId: RoleIdEnum.GuestUser },
      ],
    },
  },
  {
    menuCode: MenuCodeEnum.AboutUs,
    actions: {
      [PermissionActionEnum.View]: [
        { roleId: RoleIdEnum.SystemAdmin },
        { roleId: RoleIdEnum.PowerUser },
        { roleId: RoleIdEnum.GuestUser },
      ],
    },
  },
]
