import { PageDebugLogin, PageLogin, PageLoginCallback, PageAccountNotFound, RequireAuth } from '@ptt-eia-web/auth'
import { getEnv } from '@ptt-eia-web/helpers'
import { useLayoutEffect } from 'react'
import { Navigate, Outlet, Route, Routes, useLocation } from 'react-router-dom'

import { PageAboutUs } from '../../modules/about-us/PageAboutUs'
import { PageAnnualReport } from '../../modules/annual-report/PageAnnualReport'
import { PageDemo } from '../../modules/demo/PageDemo'
import { PageErrorNotFound } from '../../modules/error/PageErrorNotFound'
import { PageHome } from '../../modules/home-content/PageHome'
import { PagePrNews } from '../../modules/pr-news/PagePrNews'
import { PagePrNewsDetail } from '../../modules/pr-news/PagePrNewsDetail'
import { PageProjectDashBoard } from '../../modules/project-dashboard/PageProjectDashboard'
import { PageProjectUpdateStatus } from '../../modules/project-dashboard/project-update-status/PageProjectUpdateStatus'
import { PageNewProject } from '../../modules/project-dashboard/project-update-status/project-list/new-project/PageNewProject'
import { PageServiceRequest } from '../../modules/service-request/PageServiceRequest'
import { PageServiceRequestNew } from '../../modules/service-request/new/PageServiceRequestNew'
import { PageTask } from '../../modules/service-request-task/PageTask'
import { PageTaskDetail } from '../../modules/service-request-task/service-request-task-detail/PageTaskDetail'
import { PageRequestTracking } from '../../modules/service-request-tracking/PageRequestTracking'
import { MainLayout } from '../layout/MainLayout'

import { paths } from './path-config'

export const RootRoute = () => {
  // scroll to top on location change
  const location = useLocation()
  useLayoutEffect(() => {
    document.documentElement.scrollTo({
      top: 0,
      left: 0,
      behavior: 'instant', // skip the scrolling animation
    })
  }, [location.pathname])

  return (
    <Routes>
      <Route path={paths.login()} element={<PageLogin />} />
      <Route path={paths.loginCallback()} element={<PageLoginCallback />} />
      {getEnv('DEBUG') === 'yes' && <Route path={paths.loginDebug()} element={<PageDebugLogin />} />}
      <Route path={paths.accountNotFound()} element={<PageAccountNotFound />} />
      <Route
        element={
          <RequireAuth>
            <MainLayout>
              <Outlet />
            </MainLayout>
          </RequireAuth>
        }
      >
        {/* private route */}
        <Route path="/" element={<Navigate replace to={paths.home()} />} />
        <Route path={paths.home()} element={<PageHome />} />
        <Route path={paths.projectDashboard()} element={<PageProjectDashBoard />} />
        <Route path={paths.serviceRequest()} element={<PageServiceRequest />} />
        <Route path={paths.serviceRequestNew()} element={<PageServiceRequestNew />} />
        <Route path={paths.serviceRequestTracking()} element={<PageRequestTracking />} />
        <Route path={paths.serviceRequestTask()} element={<PageTask />} />
        <Route path={paths.serviceRequestTaskDetail()} element={<PageTaskDetail />} />
        <Route path={paths.projectStatusUpdate()} element={<PageProjectUpdateStatus />} />
        <Route path={paths.projectStatusUpdateNewProject()} element={<PageNewProject />} />
        <Route path={paths.prNews()} element={<PagePrNews />} />
        <Route path={paths.prNewsDetail()} element={<PagePrNewsDetail />} />
        <Route path={paths.annualReport()} element={<PageAnnualReport />} />
        <Route path={paths.aboutUs()} element={<PageAboutUs />} />
      </Route>
      <Route path="demo" element={<PageDemo />} />
      <Route path="*" element={<PageErrorNotFound />} />
    </Routes>
  )
}
