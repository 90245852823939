import { MenuCodeEnum, PermissionActionEnum } from '@ptt-eia-web/constants'
import { css } from '@ptt-eia-web/helpers'
import { IMDDashboardStatusResponse, useGetMasterDashboardStatusQRY, usePermission } from '@ptt-eia-web/services'
import { Col, Form, Input, Row, Select } from 'antd'
import { useWatch } from 'antd/es/form/Form'
import useFormInstance from 'antd/es/form/hooks/useFormInstance'
import TextArea from 'antd/es/input/TextArea'
import React, { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

import { permissionConfigs } from '@frontend/app/permission-configs'
import { checkValue } from '@frontend/components/CheckValue'
interface IReportChoice {
  value: number
  label: string
}
interface IOption {
  value: number
  label: string
}
const updateStatusOptions = (
  dataList: IMDDashboardStatusResponse[],
  setOptionsFn: React.Dispatch<React.SetStateAction<IOption[]>>,
) => {
  const newOptions = dataList.map((data) => ({
    value: data.id,
    label: data.status,
  }))
  setOptionsFn(newOptions)
}

export const FormInput = () => {
  const { data: dashboardStatus } = useGetMasterDashboardStatusQRY()
  const [isDisable, setIsDisable] = useState<boolean>(false)
  const formType = useWatch('formType', useFormInstance())
  const [searchParams] = useSearchParams()
  const [dashboardStatusOptions, setDashboardStatusOptions] = useState<IReportChoice[]>([])
  const { can } = usePermission(permissionConfigs)
  const canEdit = can(MenuCodeEnum.ProjectStatusUpdate, PermissionActionEnum.Edit)
  const flowType = checkValue(searchParams.get('flowsType'))
  useEffect(() => {
    if (dashboardStatus) updateStatusOptions(dashboardStatus, setDashboardStatusOptions)
    setIsDisable(flowType === 'view' || flowType === 'add' || !canEdit)
  }, [dashboardStatus, flowType, canEdit])
  return (
    <div
      css={css`
        padding: 40px 70px 20px 70px;
      `}
    >
      <Row gutter={50}>
        <Col xs={24} md={24} lg={8} xl={8}>
          <Form.Item
            css={css`
              .ant-form-item-label label {
                height: 20px;
              }
              .ant-input {
                height: 150px;
              }
            `}
            rules={[{ required: !isDisable, message: 'กรุณากรอก Project Name' }]}
            name="projectName"
            label="ชื่อโครงการ"
          >
            <TextArea disabled={isDisable} placeholder="ชื่อโครงการ" maxLength={550} showCount />
          </Form.Item>
        </Col>
        <Col xs={24} md={24} lg={8} xl={8}>
          <Form.Item
            css={css`
              width: 100%;
              .ant-form-item-label label {
                height: 20px;
              }
            `}
            name="projectType"
            label="ประเภทโครงการ"
          >
            <Input disabled />
          </Form.Item>
          <Form.Item
            css={css`
              .ant-form-item-label label {
                height: 20px;
              }
            `}
            rules={[{ required: !isDisable, message: 'กรุณาเลือก Project Status' }]}
            name="projectStatus"
            label="Project Status"
          >
            <Select disabled={isDisable} placeholder="สถานะโครงการ" options={dashboardStatusOptions} />
          </Form.Item>
        </Col>
        <Col xs={24} md={24} lg={8} xl={8}>
          <Form.Item
            css={css`
              .ant-form-item-label label {
                height: 20px;
              }
            `}
            name="reportType"
            label="ประเภทรายงาน"
          >
            <Input disabled />
          </Form.Item>
          <Form.Item
            css={css`
              .ant-form-item-label label {
                height: 20px;
              }
              display: ${formType ? 'block' : 'none'};
            `}
            name="formType"
            label="ชื่อแบบฟอร์ม"
          >
            <Input disabled />
          </Form.Item>
        </Col>
      </Row>
    </div>
  )
}
