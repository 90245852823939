import { INewServiceRequestParams, ITbattachInDto } from '@ptt-eia-web/services'
import { set } from 'lodash'

export const requireRule = (label: string) => ({
  required: true,
  message: `โปรดระบุ${label}`,
})

export const createInitFormValue = (...values: [key: string[], value: unknown][]) => {
  const object = {}
  values.forEach(([key, value]) => {
    set(object, key.join('.'), value)
  })
  return object
}

export const environmentalReportScreening = 1
export const preliminaryEia = 2
export const eiaErReport = 3
export const eiaMonitoringMr = 4
export const environmentalModeling = 5
export const environmentalDueDiligent = 6
export const businessDevelopment = 7
export const subDevPreliminaryEia = 1
export const subDevEiaErReport = 2
export const subDevEiaMonitoringMr = 3
export const subDevEnvironmentalModeling = 4

export const NEW_REQ_FORM_KEY = {
  ID: ['tbserviceReqInDto', 'id'],
  STATUS_ID: ['tbserviceReqInDto', 'mdreqStatusId'],
  SERVICE_TYPE_ID: ['tbserviceReqInDto', 'mdserviceTypeId'],
  SUB_SERVICE_ID: ['tbserviceReqInDto', 'mdSubServiceID'],
  REPORT_TYPE_ID: ['tbserviceReqInDto', 'mdreportTypeId'],
  PROJECT_TYPE_ID: ['tbserviceReqInDto', 'mdprojectTypeId'],
  PROJECT_NAME: ['tbserviceReqInDto', 'projectName'],
  PROJECT_ABBR: ['tbserviceReqInDto', 'projectAbbr'],
  PRJ_LOCATION: ['tbserviceReqInDto', 'prjlocation'],
  PRJ_DETAIL: ['tbserviceReqInDto', 'prjdetail'],
  OWNER: ['tbserviceReqInDto', 'owner'],
  TEL: ['tbserviceReqInDto', 'tel'],
  APPROVER: ['tbserviceReqInDto', 'approver'],
  BUDGET: ['tbserviceReqInDto', 'budget'],
  COST_CENTER: ['tbserviceReqInDto', 'costCenter'],
  IO: ['tbserviceReqInDto', 'io'],
  START_POINT: ['tbserviceReqInDto', 'startPoint'],
  END_POINT: ['tbserviceReqInDto', 'endPoint'],
  PIPE_SIZE: ['tbserviceReqInDto', 'pipeSize'],
  PIPE_DISTANCE: ['tbserviceReqInDto', 'pipeDistance'],
  PIPE_DESIGN_PRESSURE: ['tbserviceReqInDto', 'pipeDesignPressure'],
  PIPE_MAX_PRESSURE: ['tbserviceReqInDto', 'pipeMaxPressure'],
  PLAN_START: ['tbserviceReqInDto', 'planStart'],
  PLAN_END: ['tbserviceReqInDto', 'planEnd'],
  LIST_TB_ATTACH_PIPE_LINE: ['listTbattachPipeLineInDto'],
  LIST_TB_ATTACH_CONNECT_POINT: ['listTbattachConnectPointInDto'],
  LIST_TB_ATTACH_CONSTRUCT: ['listTbattachConstructInDto'],
  LIST_TB_ATTACH_OTHER: ['listTbattachOthersInDto'],
  LIST_TB_SERVICE_REQ_TEAM: ['listTbserviceReqTeamInDto'],

  REQ_USER_NAME: ['tempReqUserName'],
  REQ_DATE: ['tempReqDate'],
  REQ_UNIT_CODE: ['tbserviceReqInDto', 'ReqUnitCode'],
  REQ_UNIT_NAME: ['tbserviceReqInDto', 'ReqUnitName'],
  TEMP_OWNER_NAME: ['tempOwnerName'],
}

export interface IBaseFormInfoTypeProps {
  editable?: boolean
}

export type IBaseServiceType = {
  serviceTypeId?: number
  subServiceTypeId?: number
}

export type IAttachType = ITbattachInDto & {
  modifyBy?: number
  modifyByDisplay?: string
  modifyDate?: string
  cancelDate?: string
  newFile?: File
}
export type IFormValueType = INewServiceRequestParams & {
  tempDateRange: unknown
  tempReqUnitCode: string
  tempReqUnitName: string
  tempReqUserName: string
  tempReqDate: string
  tempOwnerName: string
  tempApproveName: string

  listTbattachPipeLineInDto?: IAttachType[]
  listTbattachConnectPointInDto?: IAttachType[]
  listTbattachConstructInDto?: IAttachType[]
  listTbattachOthersInDto?: IAttachType[]
}
