import { AppModal } from '@ptt-eia-web/components'
import { css } from '@ptt-eia-web/helpers'
import {
  useGetMdSubServiceQRY,
  useGetProjectTypesQRY,
  useGetReportTypesQRY,
  useGetServiceTypesQRY,
} from '@ptt-eia-web/services'
import { Button, ButtonProps, Flex, Form, Select } from 'antd'
import { FormInstance, useForm, useWatch } from 'antd/es/form/Form'
import { useState, useCallback, useMemo } from 'react'
import { GoPlus } from 'react-icons/go'
import { useNavigate } from 'react-router-dom'

import { paths } from '../../app/route/path-config'

import {
  preliminaryEia,
  eiaErReport,
  eiaMonitoringMr,
  environmentalReportScreening,
  environmentalModeling,
  environmentalDueDiligent,
  businessDevelopment,
  subDevEiaMonitoringMr,
  subDevEiaErReport,
  subDevPreliminaryEia,
  subDevEnvironmentalModeling,
} from './helper'

export type INewReqModalFormValue = {
  serviceType?: string
  subServiceType?: string
  reportType?: string
  projectType?: string
}

interface IFormDetail {
  form: FormInstance<INewReqModalFormValue>
}

const getFormType = (serviceTypeId?: number, subServiceTypeId?: number) => {
  if (serviceTypeId && [preliminaryEia, eiaErReport, eiaMonitoringMr].includes(serviceTypeId)) {
    return 1
  }

  if (
    serviceTypeId &&
    [environmentalReportScreening, environmentalModeling, environmentalDueDiligent].includes(serviceTypeId)
  ) {
    return 2
  }

  if (serviceTypeId && businessDevelopment === serviceTypeId) {
    if (
      subServiceTypeId &&
      [subDevPreliminaryEia, subDevEiaErReport, subDevEiaMonitoringMr].includes(subServiceTypeId)
    ) {
      return 3
    }
    if (subServiceTypeId && subServiceTypeId === subDevEnvironmentalModeling) {
      return 4
    }
    return 5
  }

  return 6
}

export const NewRequestButton = (props: ButtonProps) => {
  const [open, setOpen] = useState(false)
  const show = useCallback(() => setOpen(true), [])
  const close = useCallback(() => setOpen(false), [])
  const [form] = useForm<INewReqModalFormValue>()
  const navigate = useNavigate()

  const handleAfterClose = useCallback(() => {
    form.resetFields()
  }, [form])

  const handleConfirmClick = useCallback(() => {
    form.submit()
  }, [form])

  const handleFromSubmit = useCallback(
    (value: INewReqModalFormValue) => {
      navigate(paths.serviceRequestNew({ query: value }))
      close()
    },
    [close, navigate],
  )

  return (
    <>
      <Button data-testid="btn-new-request" type="primary" icon={<GoPlus />} onClick={show} {...props}>
        New Request
      </Button>

      <AppModal
        open={open}
        title="New Request"
        onOk={handleConfirmClick}
        onCancel={close}
        width={785}
        afterClose={handleAfterClose}
        okText="Confirm"
      >
        <Flex
          justify="center"
          align="center"
          css={css`
            padding: 32px 32px 0;
          `}
        >
          <Form<INewReqModalFormValue>
            form={form}
            onFinish={handleFromSubmit}
            layout="vertical"
            css={css`
              max-width: 500px;
              width: 100%;
            `}
          >
            <FormDetail form={form} />
          </Form>
        </Flex>
      </AppModal>
    </>
  )
}

const FormDetail = (props: IFormDetail) => {
  const { form } = props
  const { data: serviceTypes, isLoading: isServiceTypesLoading } = useGetServiceTypesQRY({ active: 1 })
  const serviceTypeOptions = useMemo(() => {
    return serviceTypes
      ?.filter((st) => !!st.active)
      .map((st) => ({
        label: st.name,
        value: st.id,
        'data-testid': `st-${st.id}`,
      }))
  }, [serviceTypes])

  const { data: mdSubService, isLoading: isMdSubServiceLoading } = useGetMdSubServiceQRY({ active: 1 })
  const mdSubServiceOptions = useMemo(() => {
    return mdSubService
      ?.filter((md) => !!md.active)
      .map((md) => ({
        label: md.name,
        value: md.id,
        'data-testid': `md-${md.id}`,
      }))
  }, [mdSubService])

  const { data: reportTypes, isLoading: isReportTypesLoading } = useGetReportTypesQRY({ active: 1 })
  const reportTypeOptions = useMemo(() => {
    return reportTypes
      ?.filter((rp) => !!rp.active)
      .map((rp) => ({
        label: rp.name,
        value: rp.id,
        'data-testid': `rp-${rp.id}`,
      }))
  }, [reportTypes])

  const { data: projectTypes, isLoading: isProjectTypesLoading } = useGetProjectTypesQRY({ active: 1 })
  const projectTypeOptions = useMemo(() => {
    return projectTypes
      ?.filter((pj) => !!pj.active)
      .map((pj) => ({
        label: pj.name,
        value: pj.id,
        'data-testid': `pj-${pj.id}`,
      }))
  }, [projectTypes])

  const serviceTypeId = useWatch('serviceType')
  const subServiceTypeId = useWatch('subServiceType')

  const handleOnServiceTypeChange = () => {
    form.setFieldsValue({
      subServiceType: undefined,
      reportType: undefined,
      projectType: undefined,
    })
  }

  const handleOnSubServiceTypeChange = () => {
    form.setFieldsValue({
      reportType: undefined,
      projectType: undefined,
    })
  }

  const handleOnReportTypeChange = () => {
    form.setFieldsValue({
      projectType: undefined,
    })
  }

  return (
    <>
      <Form.Item
        label="ประเภท Service"
        name="serviceType"
        rules={[{ required: true, message: 'กรุณาระบุประเภท Service' }]}
      >
        <Select
          data-testid="select-service-type"
          options={serviceTypeOptions}
          onChange={handleOnServiceTypeChange}
          loading={isServiceTypesLoading}
        />
      </Form.Item>
      {[3, 4, 5].includes(getFormType(serviceTypeId, subServiceTypeId)) && (
        <Form.Item
          label="ประเภท Sub Service"
          name="subServiceType"
          rules={[{ required: true, message: 'กรุณาระบุประเภท Sub Service' }]}
        >
          <Select
            data-testid="select-sub-service-type"
            options={mdSubServiceOptions}
            onChange={handleOnSubServiceTypeChange}
            loading={isMdSubServiceLoading}
          />
        </Form.Item>
      )}
      {[1, 3].includes(getFormType(serviceTypeId, subServiceTypeId)) && (
        <Form.Item
          label="ประเภทรายงาน"
          name="reportType"
          rules={[{ required: true, message: 'กรุณาระบุประเภทรายงาน' }]}
        >
          <Select
            data-testid="select-report-type"
            options={reportTypeOptions}
            onChange={handleOnReportTypeChange}
            loading={isReportTypesLoading}
          />
        </Form.Item>
      )}
      {[1, 2, 3, 4].includes(getFormType(serviceTypeId, subServiceTypeId)) && (
        <Form.Item
          label="ประเภทโครงการ"
          name="projectType"
          data-testid="select-project-type"
          rules={[{ required: true, message: 'กรุณาระบุประเภทโครงการ' }]}
        >
          <Select options={projectTypeOptions} loading={isProjectTypesLoading} />
        </Form.Item>
      )}
    </>
  )
}
