import { css } from '@ptt-eia-web/helpers'
import { IMDDashboardNoteReport } from '@ptt-eia-web/services'
import { Col, Row } from 'antd'
import React from 'react'

import { ColorIndicator } from '../summary-graph-project-dashboard/Indicator'

import { progressColor } from './ReportStatus'

interface INoteReportProps {
  reportData: IMDDashboardNoteReport[] | undefined
  title?: string
}

export const NoteReport = (props: INoteReportProps) => {
  return (
    <Row
      css={css`
        padding: 8px 0px 50px 100px;
        display: flex;
        align-items: center;
      `}
    >
      <Col
        xl={14}
        md={24}
        sm={24}
        css={css`
          font-size: 14px;
          font-weight: 500;
        `}
      >
        {props.title}
        <Row>
          <Col xl={14} md={24} sm={24}>
            {props.reportData?.map((items) => {
              return (
                <p
                  key={items.id}
                  css={css`
                    width: 100%;
                    font-size: 14px;
                    font-weight: 300;
                  `}
                >
                  {items.id} . {items.description}
                </p>
              )
            })}
          </Col>
        </Row>
      </Col>
      <Col xl={8} md={24} sm={24}>
        <div
          css={css`
            display: grid;
            justify-content: center;
            align-items: center;
            grid-template-columns: repeat(2, 1fr);
            gap: 14px;
            @media screen and (max-width: 1199px) {
              margin-top: 14px;
            }
          `}
        >
          {progressColor.map((items) => {
            return (
              <span
                key={items.id}
                css={css`
                  display: flex;
                  gap: 10px;
                  font-size: 14px;
                `}
              >
                <ColorIndicator color={items.color} /> {items.description}
              </span>
            )
          })}
        </div>
      </Col>
    </Row>
  )
}
