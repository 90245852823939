import { css } from '@ptt-eia-web/helpers'
import { IProjectDashboardOutDto, useGetProjectTypeDetailQRY } from '@ptt-eia-web/services'
import { Flex } from 'antd'
import React, { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

import { colors } from '../../../app/theme'

import { ExportButton } from './ExportButton'
import { NoteReport } from './NoteReport'
import { businessReportData } from './NoteReportData'
import { RemarkTableData } from './Remark'
interface IBDTableProps {
  dataSourceTables: IProjectDashboardOutDto[] | undefined
}

export const BDTable = (props: IBDTableProps) => {
  const [reportTypeId, setReportTypeId] = useState<number>()
  const [year, setYear] = useState<number>()
  const { data: projectType } = useGetProjectTypeDetailQRY(5)
  const [searchParams] = useSearchParams()
  useEffect(() => {
    setReportTypeId(parseInt(searchParams.get('reportTypeId') ?? ''))
    setYear(parseInt(searchParams.get('year') ?? ''))
  }, [searchParams])

  return (
    <div
      css={css`
        height: 100%;
        background-color: white;
        margin: 30px 0px;
        border-radius: 15px;
        border: 1px solid ${colors.line};
        box-shadow: 0px 2px 6px #0000000a;
      `}
    >
      <Flex
        css={css`
          padding: 22px 24px;
        `}
        justify="space-between"
        align="center"
      >
        <p
          css={css`
            font-size: 30px;
            color: ${colors.primary};
          `}
        >
          {projectType?.mdprojectTypeOut?.name}
        </p>
        {props.dataSourceTables && props.dataSourceTables?.length !== 0 && (
          <ExportButton projectId={5} reportId={reportTypeId} year={year} />
        )}
      </Flex>
      <div
        css={css`
          padding: 0px 24px 54px 24px;
        `}
      >
        <RemarkTableData typeTable="BD" dataSource={props.dataSourceTables} />
      </div>
      <NoteReport reportData={businessReportData} title="*Note: Report Status" />
    </div>
  )
}
