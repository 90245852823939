import { IUpdateRequestStatusParams, usePostUpdateRequestStatusMTT } from '@ptt-eia-web/services'
import { useCallback } from 'react'
import { useParams } from 'react-router-dom'

import { AddReasonBeforeSubmitModal } from '@frontend/modules/service-request-tracking/AddReasonBeforeSubmitModal'

interface IConfirmReturnReviseModalProps {
  isOpen: boolean
  onClose: () => void
  mdReqStatusId?: number
}

export const ConfirmReturnReviseModal = ({ isOpen, onClose, mdReqStatusId }: IConfirmReturnReviseModalProps) => {
  const { id = 'N/A' } = useParams()
  const { mutateAsync: updateRequestStatus } = usePostUpdateRequestStatusMTT(id)

  const handleCloseModal = useCallback(() => {
    onClose()
  }, [onClose])

  const handleSubmit = useCallback(
    async (reason: string) => {
      if (mdReqStatusId) {
        const params: IUpdateRequestStatusParams = {
          mdreqStatusId: mdReqStatusId,
          tbserviceReqId: Number(id),
          reason,
        }
        await updateRequestStatus(params)
      } else {
        throw new Error('Cannot find next status')
      }
    },
    [id, mdReqStatusId, updateRequestStatus],
  )
  return (
    <AddReasonBeforeSubmitModal
      title="กรุณาระบุเหตุผลการ Return for Revise"
      isOpen={isOpen}
      onClose={handleCloseModal}
      onSubmit={handleSubmit}
    />
  )
}
