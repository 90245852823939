import { css } from '@ptt-eia-web/helpers'
import { IAnnualReportResponse } from '@ptt-eia-web/services'
import { Divider, Flex } from 'antd'
import dayjs from 'dayjs'

import { colors } from '@frontend/app/theme'

import { Txt } from '../../components/Txt'

interface IAnnualReportTitleProps {
  annualReport: IAnnualReportResponse
}

const DateShortEn = (date: string) => {
  return dayjs(date, 'DD/MM/YYYY').format('MMM YYYY')
}

export const AnnualReportTitle = (props: IAnnualReportTitleProps) => {
  return (
    <Flex
      vertical
      justify="center"
      align="start"
      css={css`
        padding: 18px 0;
      `}
    >
      <Flex gap={10}>
        <Txt ag="med24" color="primary" data-testid="fe-title-annual-report">
          {props.annualReport.mdDocTypeName}
        </Txt>
        <Txt ag="med24">{props.annualReport.docYear}</Txt>
      </Flex>
      <Txt
        ag="med14"
        css={css`
          padding: 0px 0 18px 0;
        `}
      >
        {`Post on ${DateShortEn(props.annualReport.modifyDate)}`}
      </Txt>
      <Divider
        css={css`
          border-block-start: 1px solid ${colors.primary};
        `}
      />
    </Flex>
  )
}
