import styled from '@emotion/styled'
import { css, getEnv } from '@ptt-eia-web/helpers'
import { IPRNewsResponse, useGetPRNewsQRY } from '@ptt-eia-web/services'
import { Col, Row } from 'antd'
import dayjs from 'dayjs'
import { useEffect, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import SwiperCore from 'swiper'
import { Pagination, Navigation, A11y, Autoplay } from 'swiper/modules'
import { Swiper, SwiperClass, SwiperSlide } from 'swiper/react'

import BgSwiper from '../../../src/assets/home/bg-swiper-content.jpeg'
import { colors } from '../../app/theme'

import { PrNewsSwiperNav } from './PrNewsSwiperNav'
import 'swiper/css'
import './swiper.css'
import 'swiper/css/pagination'
import { PrNewsSwiperViewDetail } from './PrNewsSwiperViewDetailButton'
import 'swiper/swiper-bundle.css'

SwiperCore.use([Navigation])

const PopupSpan = styled.span`
  display: flex;
  position: absolute;
  width: 80%;
  background-color: ${colors.defaultBg};
  top: 90%;
  left: 10%;
  border-radius: 3px;
  box-shadow: 6px 6px 54px ${colors.darkGray};
  z-index: 100;
`

const content = (list: IPRNewsResponse) => (
  <Row
    data-testid="content-popup"
    css={css`
      padding: 0px;
    `}
  >
    <Col
      css={css`
        width: 5px;
        background-color: ${colors.secondary};
        border-radius: 30px;
      `}
    />
    <Col
      css={css`
        justify-content: center;
        align-items: center;
        cursor: default;
        padding: 14px;
        flex: 1;
      `}
    >
      <Link
        to={`/pr-news/${list.id}`}
        css={css`
          cursor: pointer;
          color: ${colors.primary};
          &:hover {
            color: ${colors.primary};
          }
          font-size: 24px;
          @media screen and (max-width: 718px) {
            font-size: 16px;
          }
        `}
      >
        {list.title}
      </Link>
      <p
        css={css`
          font-size: 14px;
          @media screen and (max-width: 718px) {
            font-size: 12px;
          }
        `}
      >
        {list.shortDesc}
      </p>
    </Col>
  </Row>
)
export const PrNewsSection = () => {
  const [swiperInstance, setSwiperInstance] = useState<SwiperClass>()
  const [hoveredIdCard, setHoveredIdCard] = useState<number | null>(null)

  const { data: prNewsItems } = useGetPRNewsQRY()
  const [dataSwiper, setDataSwiper] = useState<IPRNewsResponse[]>([])
  const isLoop = useMemo(() => dataSwiper.length > 3, [dataSwiper.length])

  const handleMouseEnter = (id: number, event: React.MouseEvent) => {
    const target = event.target as HTMLDivElement
    if (
      target.className.includes('swiper-slide-active') ||
      target.parentElement?.className.includes('swiper-slide-active')
    ) {
      setHoveredIdCard(id)
    }

    isLoop && swiperInstance?.autoplay.stop()
  }
  useEffect(() => {
    if (prNewsItems !== undefined) {
      let publishedItems = prNewsItems.filter(
        (item) =>
          dayjs(item.startDate).startOf('day') <= dayjs().startOf('day') &&
          dayjs(item.endDate).startOf('day') >= dayjs().startOf('day') &&
          item.active === 1,
      )
      if (publishedItems.length > 1) {
        publishedItems = publishedItems.concat(publishedItems)
      }
      setDataSwiper(publishedItems)
    }
  }, [prNewsItems])

  const handleClickCard = (event: React.MouseEvent) => {
    const target = event.target as HTMLDivElement
    if (
      target.className.includes('swiper-slide-prev') ||
      target.parentElement?.className.includes('swiper-slide-prev')
    ) {
      swiperInstance?.slidePrev()
    }
    if (
      target.className.includes('swiper-slide-next') ||
      target.parentElement?.className.includes('swiper-slide-next')
    ) {
      swiperInstance?.slideNext()
    }
  }

  const handleMouseLeave = () => {
    setHoveredIdCard(null)
    isLoop && swiperInstance?.autoplay.start()
  }

  const renderSlideCard = (list: IPRNewsResponse) => {
    const imageUrl = `${getEnv('STORAGE_BASE_URL')}${list.filePath}`
    return (
      <div
        css={css`
          position: relative;
        `}
      >
        <div
          onMouseEnter={(event) => handleMouseEnter(list.id, event)}
          onClick={(event) => handleClickCard(event)}
          css={css`
            cursor: pointer;
            margin: 10px;
            height: 30vw;
            transition: transform 0.3s ease-in-out;
            overflow: hidden;
            background-image: url(${imageUrl});
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            background-color: transparent;
            box-shadow: ${hoveredIdCard === list.id && `rgb(38, 57, 77) 0px 20px 30px -10px`};
            &:hover {
              transform: scale(1.02);
            }
          `}
        />
        <PopupSpan className="pr-news-details">{content(list)}</PopupSpan>
      </div>
    )
  }

  if (dataSwiper.length === 0) return null
  return (
    <div
      css={css`
        .swiper {
          height: 40vw;
        }
        .swiper-slide-next,
        .swiper-slide-prev,
        .swiper-slide-active {
          > div > div {
            transition: all 0.2s linear;
          }
        }

        .swiper-slide-next,
        .swiper-slide-prev {
          > div > div {
            height: 25vw;
            margin-top: 2.5vw;
          }
          .pr-news-details {
            display: none;
          }
        }
      `}
    >
      <PrNewsSwiperNav swiper={swiperInstance} />
      {dataSwiper.length > 0 && (
        <Swiper
          data-testid="fe-item-pr-news"
          onSwiper={(swiper) => setSwiperInstance(swiper)}
          centeredSlides
          initialSlide={dataSwiper.length >= 3 ? 1 : 0}
          loop={isLoop}
          slidesPerView={2}
          grabCursor={true}
          modules={[Autoplay, Pagination, Navigation, A11y]}
        >
          {dataSwiper.length > 0 && <img data-testid="swiper-img-pr-news" src={BgSwiper} alt="bg-swiper-content" />}
          {dataSwiper.map((list: IPRNewsResponse, index: number) => (
            <SwiperSlide key={`${list.id}-${index}`} onMouseLeave={handleMouseLeave}>
              {renderSlideCard(list)}
            </SwiperSlide>
          ))}
          <span
            css={css`
              position: absolute;
              top: 80%;
              z-index: 2;
              right: 0;
            `}
          >
            <PrNewsSwiperViewDetail />
          </span>
        </Swiper>
      )}
    </div>
  )
}
