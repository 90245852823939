import { MenuCodeEnum, PermissionActionEnum } from '@ptt-eia-web/constants'
import { css } from '@ptt-eia-web/helpers'
import {
  IMasterReportStatusResponse,
  useGetMasterReportStatusQRY,
  useGetReportTypeDetailQRY,
  usePermission,
} from '@ptt-eia-web/services'
import { Col, Form, InputNumber, Radio, Row, Space, Switch } from 'antd'
import { useWatch } from 'antd/es/form/Form'
import TextArea from 'antd/es/input/TextArea'
import React, { useEffect, useMemo, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

import { permissionConfigs } from '@frontend/app/permission-configs'
import { Txt } from '@frontend/components/Txt'
import { progressColor } from '@frontend/modules/project-dashboard/project-dashboard-table/ReportStatus'
import { ColorIndicator } from '@frontend/modules/project-dashboard/summary-graph-project-dashboard/Indicator'

import { colors } from '../../../../../app/theme'
import TransmissionTablePicture from '../../../../../assets/project-dashboard/transmission-table.png'

const REQUIRED_MESSAGE = 'กรุณากรอกข้อความ'

export const SecondForm = () => {
  const [searchParams] = useSearchParams()
  const { data: reportStatusData } = useGetMasterReportStatusQRY()
  const { data: reportTypeList } = useGetReportTypeDetailQRY(searchParams.get('reportTypeId') as unknown as number)
  const formEiaSwitch = useWatch('eiaActive')
  const formActual = useWatch('actual')
  const formPlan = useWatch('plan')
  const { can } = usePermission(permissionConfigs)
  const canEdit = can(MenuCodeEnum.ProjectStatusUpdate, PermissionActionEnum.Edit)
  const [toggleEIA, setToggleEIA] = useState<boolean>(false)

  const [reportStatusFrom] = useState(searchParams.get('formTypeId'))
  useEffect(() => {
    if (searchParams.get('flowsType')?.toString() === 'new') {
      setToggleEIA(true)
    }
  }, [searchParams])
  useEffect(() => {
    if (formEiaSwitch !== undefined) {
      setToggleEIA(formEiaSwitch)
    }
  }, [formEiaSwitch, toggleEIA])

  const isDisableToggle = useMemo(() => {
    if (!canEdit) {
      return true
    }
    if (!toggleEIA) {
      return true
    }
  }, [toggleEIA, canEdit])

  const isDisables = useMemo(() => {
    if (!canEdit) {
      return true
    }
  }, [canEdit])

  const calculateColor = (plan: number, actual: number) => {
    let total
    if (plan !== null && actual !== null) {
      total = plan - actual
    } else {
      return 'none'
    }
    if (total > 5) {
      return '#ff5050'
    } else if (total >= 1 && total <= 5) {
      return '#ffd966'
    } else if (total === 0) {
      return '#92d050'
    } else if (total < 0) {
      return '#00b050'
    } else {
      return 'none'
    }
  }
  const colorInputNumberActual = useMemo(() => calculateColor(formPlan, formActual), [formPlan, formActual])

  const getStatusText = (item: IMasterReportStatusResponse) => {
    switch (reportStatusFrom) {
      case '1':
        return item.statusForm1
      case '2':
        return item.statusForm2
      default:
        return item.statusForm3
    }
  }

  return (
    <div
      css={css`
        padding: 60px 70px 20px 70px;
        background-color: ${colors.lightGray};
      `}
    >
      <Txt
        ag="med24"
        css={css`
          color: ${colors.primary};
        `}
      >
        {reportTypeList?.eiaEr}
      </Txt>
      <Row
        gutter={[60, 16]}
        css={css`
          padding: 34px;
        `}
      >
        <Col xs={24} md={24} lg={12} xl={12}>
          <Row>
            <Col xl={12} lg={12} md={24} sm={24} xs={24}>
              <Row>
                <Col xl={12} lg={12} md={12} xs={12}>
                  <Form.Item>
                    <Txt ag="med16">{reportTypeList?.eiaEr}</Txt>
                  </Form.Item>
                </Col>
                <Col xl={12} lg={12} md={12} xs={12}>
                  <Form.Item name="eiaActive">
                    <Switch disabled={canEdit ? false : isDisables} defaultChecked />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <Col xl={12} lg={12} md={24} sm={24} xs={24}>
              <Col xs={24} md={24} lg={24} xl={24}>
                <Form.Item
                  css={css`
                    .ant-form-item-label label {
                      height: 20px;
                    }
                    .ant-input {
                      height: auto;
                    }
                  `}
                  rules={[
                    {
                      required: isDisableToggle,
                      message: REQUIRED_MESSAGE,
                    },
                  ]}
                  name="eiaActiveReason"
                  label={`ข้อความกรณี ${reportTypeList?.eiaEr} Inactive`}
                >
                  <TextArea
                    disabled={!isDisableToggle}
                    placeholder={isDisableToggle ? 'กรอกข้อความ' : ''}
                    rows={6}
                    maxLength={1500}
                    showCount
                  />
                </Form.Item>
              </Col>
            </Col>
          </Row>
        </Col>
        <Col xs={24} md={24} lg={12} xl={12}>
          <Form.Item>
            <div
              css={css`
                height: auto;
                padding: 8px;
                box-shadow: 0px 2px 6px #0000000a;
                border: 1px solid #dddddd;
                border-radius: 10px;
              `}
            >
              <img
                css={css`
                  width: 100%;
                  height: auto;
                `}
                src={TransmissionTablePicture}
                alt="transmission table"
              />
            </div>
          </Form.Item>
        </Col>
      </Row>
      <Row
        gutter={[60, 40]}
        css={css`
          padding: 14px;
        `}
      >
        <Col xs={24} md={24} lg={10} xl={10}>
          <Form.Item
            rules={[{ required: !isDisableToggle, message: 'กรุณาเลือก Report Status' }]}
            name="eiaReportStatus"
            label="Report Status"
          >
            <Radio.Group disabled={isDisableToggle}>
              <Space direction="vertical">
                {reportStatusData?.map((item) => (
                  <Radio key={item.id} value={item.id}>
                    {getStatusText(item)}
                  </Radio>
                ))}
              </Space>
            </Radio.Group>
          </Form.Item>
        </Col>
        <Col xs={24} md={24} lg={14} xl={14}>
          <Txt ag="reg14">Progress (%)</Txt>
          <Row
            css={css`
              margin-top: 10px;
            `}
            gutter={60}
          >
            <Col xs={24} md={24} lg={24} xl={24}>
              <Row gutter={40}>
                <Col xs={24} md={24} lg={24} xl={12}>
                  <Row gutter={40}>
                    <Col xs={24} md={24} lg={12} xl={12}>
                      <Form.Item
                        css={css`
                          .ant-form-item-label label {
                            height: 20px;
                          }
                        `}
                        rules={[{ required: !isDisableToggle, message: 'กรุณาระบุค่า Plan' }]}
                        name="plan"
                        label="Plan"
                      >
                        <InputNumber
                          css={css`
                            width: 100%;
                          `}
                          disabled={isDisableToggle}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} md={24} sm={24} lg={12} xl={12}>
                      <Form.Item
                        css={css`
                          .ant-form-item-label label {
                            height: 20px;
                          }
                        `}
                        rules={[{ required: !isDisableToggle, message: 'กรุณาระบุค่า Actual' }]}
                        name="actual"
                        label="Actual"
                      >
                        <InputNumber
                          css={css`
                            width: 100%;
                            background-color: ${colorInputNumberActual};
                            &:hover {
                              background-color: ${colorInputNumberActual};
                            }
                          `}
                          disabled={isDisableToggle}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
                <Col xs={24} md={24} lg={24} xl={12}>
                  <Row>
                    <Col span={24}>
                      <div
                        css={css`
                          display: grid;
                          justify-content: center;
                          align-items: center;
                          margin-top: 28px;
                          grid-template-columns: repeat(2, 1fr);
                          @media screen and (max-width: 1196px) {
                            margin-top: 0px;
                            margin-bottom: 24px;
                          }
                        `}
                      >
                        {progressColor.map((items) => {
                          return (
                            <span
                              key={items.id}
                              css={css`
                                display: flex;
                                align-items: center;
                                justify-content: flex-start;
                                text-align: center;
                                gap: 10px;
                                font-size: 14px;
                              `}
                            >
                              <ColorIndicator color={items.color} /> {items.description}
                            </span>
                          )
                        })}
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <Form.Item
                css={css`
                  .ant-form-item-label label {
                    height: 20px;
                  }
                  .ant-input {
                    height: 150px;
                  }
                `}
                rules={[{ required: !isDisableToggle, message: 'กรุณาระบุ Highlight Activities' }]}
                name="highlightActivity"
                label="Highlight Activities"
              >
                <TextArea disabled={isDisableToggle} placeholder="กรุณากรอกข้อความ" maxLength={1500} showCount />
              </Form.Item>
              <Form.Item
                css={css`
                  .ant-form-item-label label {
                    height: 20px;
                  }
                  .ant-input {
                    height: 150px;
                  }
                `}
                rules={[{ required: !isDisableToggle, message: 'กรุณาระบุ Way Forward' }]}
                name="wayForward"
                label="Way Forward"
              >
                <TextArea disabled={isDisableToggle} placeholder="กรุณากรอกข้อความ" maxLength={1500} showCount />
              </Form.Item>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  )
}
