import { getEnv } from '@ptt-eia-web/helpers'
import {
  QueryKey,
  UndefinedInitialDataOptions,
  UseMutationOptions,
  UseQueryOptions,
  useMutation,
  useQuery,
} from '@tanstack/react-query'

import { pttClient } from '../ptt-client'
import { IResponseWithData } from '../services-types'

import { IEmployeeDataNoJobGroupParams, IGetPTTAPIPersonalInfoParams, IGetPTTUnitParams } from './pttapi.params'
import {
  IEmployeeDataNoJobGroupResponse,
  IPTTPersonalInfoResponse,
  IPTTRelationHeadResponse,
  IPTTRelationPeopleResponse,
  IPTTUnitResponse,
} from './pttapi.response'

const PTTAPI_EMPLOYEE_DATA_URL = '/PTTAPI/EmployeeDataNoJobGroup'
const PTTAPI_PERSONAL_INFO_URL = '/PTTAPI/PersonelInfo'
const PTTAPI_RELATION_PEOPLE_URL = '/PTTAPI/RelationPeople'
const PTTAPI_UNIT_URL = 'PTTAPI/Unit'
const PTTAPI_RELATION_HEAD = '/PTTAPI/RelationHead'

export const useGetEmployeeDataNoJobGroupQRY = (
  params: IEmployeeDataNoJobGroupParams,
  options?: Omit<
    UndefinedInitialDataOptions<IEmployeeDataNoJobGroupResponse[], Error, IEmployeeDataNoJobGroupResponse[], QueryKey>,
    'queryKey'
  >,
) => {
  return useQuery<IEmployeeDataNoJobGroupResponse[]>({
    queryKey: [PTTAPI_EMPLOYEE_DATA_URL, params],
    queryFn: async (): Promise<IEmployeeDataNoJobGroupResponse[]> => {
      const res = await pttClient.get<IResponseWithData<IEmployeeDataNoJobGroupResponse[]>>(PTTAPI_EMPLOYEE_DATA_URL, {
        params: { ...params },
      })
      return res.data.data
    },
    ...options,
  })
}

export const useGetEmployeeDataNoJobGroupMTT = (
  options?: UseMutationOptions<IEmployeeDataNoJobGroupResponse[], Error, IEmployeeDataNoJobGroupParams>,
) => {
  return useMutation({
    mutationFn: async (params) => {
      const res = await pttClient.get<IResponseWithData<IEmployeeDataNoJobGroupResponse[]>>(PTTAPI_EMPLOYEE_DATA_URL, {
        params: { ...params },
      })
      return res.data.data
    },
    ...options,
  })
}

export const useGetPTTPersonalInfoMTT = () => {
  return useMutation<IPTTPersonalInfoResponse[], Error, IGetPTTAPIPersonalInfoParams>({
    mutationFn: async (params) => {
      const res = await pttClient.get<IResponseWithData<IPTTPersonalInfoResponse[]>>(PTTAPI_PERSONAL_INFO_URL, {
        params,
      })
      return res.data.data
    },
  })
}

export const useGetPTTRelationPeopleQTY = (options?: UndefinedInitialDataOptions<IPTTRelationPeopleResponse[]>) => {
  return useQuery({
    queryKey: [PTTAPI_RELATION_PEOPLE_URL],
    queryFn: async () => {
      const isUseMockApi = getEnv('IS_MOCK_PTT_API') === 'yes'
      if (isUseMockApi) {
        const res = mockRelationPeopleResponse
        return res.data
      } else {
        const res = await pttClient.get<IResponseWithData<IPTTRelationPeopleResponse[]>>(PTTAPI_RELATION_PEOPLE_URL)
        return res.data.data
      }
    },
    ...options,
  })
}

const mockRelationPeopleResponse: IResponseWithData<IPTTRelationPeopleResponse[]> = {
  status: {
    isError: false,
    message: '',
    code: '200',
  },
  data: [
    {
      empCode: '490015',
      prefix: 'นาย',
      fname: 'กลศ',
      lname: 'บุณยะวรรธน',
      unit: '80000599',
      unitname: 'ส่วนวิศวกรรมเทคนิค',
      posCode: '61019278',
      posName: 'ผจ.วก.',
      mdteamRole: 2,
      email: '490015@email.com',
    },
    {
      empCode: '420015',
      prefix: 'นาย',
      fname: 'อธิชย์',
      lname: 'ธีรภัทร',
      unit: '80001404',
      unitname: 'ฝ่ายจัดการสิ่งแวดล้อมโครงการ',
      posCode: '61029872',
      posName: 'ผจ.วลม.',
      mdteamRole: 3,
      email: '420015@email.com',
    },
    {
      empCode: '320040',
      prefix: 'นาย',
      fname: 'สรไนย',
      lname: 'เลิศอัก',
      unit: '80000563',
      unitname: 'ผช.กผญ. แยกก๊าซธรรมชาติ',
      posCode: '61004236',
      posName: 'ผยก.',
      mdteamRole: 6,
      email: '320040@email.com',
    },
    {
      empCode: '360062',
      prefix: 'นาย',
      fname: 'ประกาศ',
      lname: 'ชูศักดิ์',
      unit: '80000416',
      unitname: 'ผช.กผญ. วิศวกรรมและบริหารโครงการ',
      posCode: '61004233',
      posName: 'ผคม.',
      mdteamRole: 7,
      email: '360062@email.com',
    },
  ],
}

export const useGetPTTUnitInfoMTT = (options?: UseMutationOptions<IPTTUnitResponse[], Error, IGetPTTUnitParams>) => {
  return useMutation<IPTTUnitResponse[], Error, IGetPTTUnitParams>({
    mutationFn: async (params) => {
      const res = await pttClient.get<IResponseWithData<IPTTUnitResponse[]>>(PTTAPI_UNIT_URL, {
        params,
      })
      return res.data.data
    },
    ...options,
  })
}

export const useRelationHeadQTY = (options?: UseQueryOptions<IPTTRelationHeadResponse[]>) => {
  return useQuery<IPTTRelationHeadResponse[]>({
    queryKey: [PTTAPI_RELATION_HEAD],
    queryFn: async () => {
      const res = await pttClient.get<IResponseWithData<IPTTRelationHeadResponse[]>>(PTTAPI_RELATION_HEAD)
      return res.data.data
    },
    ...options,
  })
}
