import { css } from '@ptt-eia-web/helpers'
import { Button } from 'antd'
import { useCallback, useEffect, useState } from 'react'
import { BsArrowDown, BsArrowUp } from 'react-icons/bs'

const buttonStyle = css`
  position: fixed;
  right: 10px;
  width: 50px;
  height: 50px;
  z-index: 999;
`

export const GotoTopDown = () => {
  const [showArrowUp, setShowArrowUp] = useState(false)
  const [showArrowDown, setShowArrowDown] = useState(false)

  const handleClickArrowUp = useCallback(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }, [])
  const handleClickArrowDown = useCallback(() => {
    window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' })
  }, [])

  useEffect(() => {
    const listener = () => {
      setShowArrowUp(window.scrollY > 0)
      setShowArrowDown(window.scrollY < document.body.scrollHeight - document.documentElement.clientHeight)
    }
    window.addEventListener('scroll', listener)
    return () => {
      window.removeEventListener('scroll', listener)
    }
  }, [])

  return (
    <>
      {showArrowUp && (
        <Button
          css={css`
            ${buttonStyle}
            bottom: 120px;
          `}
          onClick={handleClickArrowUp}
          icon={<BsArrowUp size={25} color="white" />}
          type="primary"
          shape="circle"
        />
      )}
      {showArrowDown && (
        <Button
          css={css`
            ${buttonStyle}
            bottom: 60px;
          `}
          onClick={handleClickArrowDown}
          icon={<BsArrowDown size={25} color="white" />}
          type="primary"
          shape="circle"
        />
      )}
    </>
  )
}
