import numeral from 'numeral'

export function numberFormat(number: string | number, precision = 2) {
  return numeral(number).format(`0,0[.][${'0'.repeat(precision)}]`)
}

interface IRandomIntArgs {
  min: number
  max: number
}
export function secureRandomInt({ min, max }: IRandomIntArgs) {
  if (min >= max) {
    throw new RangeError('The "max" must be greater than "min".')
  }
  const buffer = new Uint32Array(1)
  window.crypto.getRandomValues(buffer)
  const maxUint32 = 0xffffffff
  // Scale the number to the desired range
  const randomNumber = Math.floor((buffer[0] / (maxUint32 + 1)) * (max - min + 1)) + min
  return randomNumber
}
