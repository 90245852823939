import {
  IRequestTrackingResponse,
  IUpdateRequestStatusParams,
  useGetMeSuspenseQRY,
  useGetRequestStatusUpdateIdQRY,
  usePostUpdateRequestStatusMTT,
} from '@ptt-eia-web/services'
import { Flex, notification } from 'antd'
import { isNumber } from 'lodash'
import { useCallback, useMemo, useState } from 'react'

import {
  getAssignedStatus,
  getSubmittedStatus,
  getAssignedInfoByStatusId,
  getExecutedAndClosedStatus,
  getUserInfoByTeamRole,
} from '@frontend/modules/service-request-tracking/request-helper'

import { ReqTrackingProgressBar } from '../../../components/ReqTrackingProgressBar'

import { ApproveAddTeamModal } from './ApproveAddTeamModal'
import { ApproveModal } from './ApproveModal'
import { AssignTeamAddTeamModal } from './AssignTeamAddTeamModal'
import { AssignTeamModal } from './AssignTeamModal'
import { ConfirmReturnReviseModal } from './ConfirmReturnReviseModal'
import { RejectModal } from './RejectModal'

interface IRequestProgressProps {
  requestTracking?: IRequestTrackingResponse
  onBack: () => void
  actions?: {
    approve?: number
    reject?: number
    returnForRevise?: number
    recall?: number
    approveCancel?: number
    rejectCancel?: number
  }
}

export const RequestProgress = ({ requestTracking, onBack, actions }: IRequestProgressProps) => {
  const { data: reqStatus = [] } = useGetRequestStatusUpdateIdQRY(requestTracking?.tbserviceReqOutDto.id?.toString())
  const { data: currentUser } = useGetMeSuspenseQRY()

  const { mutateAsync: updateRequestStatus } = usePostUpdateRequestStatusMTT(
    requestTracking?.tbserviceReqOutDto.id.toString(),
  )

  const [isApproveModalOpen, setIsApproveModalOpen] = useState(false)
  const [isApproveAddTeamModalOpen, setApproveAddTeamModal] = useState(false)
  const [isRejectModalOpen, setIsRejectModalOpen] = useState(false)
  const [isReturnForReviseOpen, setIsReturnForReviseOpen] = useState(false)
  const [isAssignTeamOpen, setIsAssignTeamOpen] = useState(false)
  const [isAssignTeamAddTeamOpen, setIsAssignTeamAddTeamOpen] = useState(false)

  const serviceRequest = useMemo(() => requestTracking?.tbserviceReqOutDto, [requestTracking?.tbserviceReqOutDto])

  const teams = useMemo(
    () => requestTracking?.listTbserviceReqTeamOutDto || [],
    [requestTracking?.listTbserviceReqTeamOutDto],
  )

  const projectTeamEmployeeCodes = useMemo(() => teams.map((team) => team.empCode), [teams])

  const reqStatusId = useMemo(
    () => (serviceRequest?.mdreqStatusId ? serviceRequest?.mdreqStatusId : undefined),
    [serviceRequest?.mdreqStatusId],
  )

  const handleClickApprove = useCallback(async () => {
    if (reqStatusId) {
      if ([1].includes(reqStatusId)) {
        const params: IUpdateRequestStatusParams = {
          mdreqStatusId: actions?.approve,
          tbserviceReqId: serviceRequest?.id,
        }
        await updateRequestStatus(params)
        notification.success({ message: 'อนุมัติสำเร็จ' })
        onBack()
      } else {
        setIsApproveModalOpen(true)
      }
    }
  }, [actions?.approve, onBack, serviceRequest?.id, reqStatusId, updateRequestStatus])

  const handleCloseApproveModal = useCallback(() => {
    setIsApproveModalOpen(false)
  }, [])

  const showAssignTeam = useMemo(() => {
    return (
      reqStatusId === 4 &&
      getUserInfoByTeamRole(requestTracking?.listTbserviceReqTeamOutDto, 4)?.empCode === currentUser?.code
    )
  }, [currentUser?.code, reqStatusId, requestTracking?.listTbserviceReqTeamOutDto])

  const isShowApprove = useMemo(() => isNumber(actions?.approve), [actions?.approve])

  const handleShowAssignTeam = useCallback(() => {
    setIsAssignTeamOpen(true)
  }, [])

  const handleCloseAssignModal = useCallback(() => {
    setIsAssignTeamOpen(false)
  }, [])

  const handleClickAssignAddModal = useCallback(() => {
    setIsAssignTeamAddTeamOpen(true)
    setIsAssignTeamOpen(false)
  }, [])

  const handleCloseAssignAddModal = useCallback(() => {
    setIsAssignTeamAddTeamOpen(false)
    setIsAssignTeamOpen(true)
  }, [])

  const handleClickApproveAddModal = useCallback(() => {
    setApproveAddTeamModal(true)
    setIsApproveModalOpen(false)
  }, [])

  const handleCloseApproveAddModal = useCallback(() => {
    setApproveAddTeamModal(false)
    setIsApproveModalOpen(true)
  }, [])

  const handleOpenRejectModal = useCallback(() => {
    setIsRejectModalOpen(true)
  }, [])

  const handleCloseRejectModal = useCallback(() => {
    setIsRejectModalOpen(false)
  }, [])

  const handleClickReturnForRevise = useCallback(async () => {
    setIsReturnForReviseOpen(true)
  }, [])

  const handleCloseReturnForReviseModal = useCallback(() => {
    setIsReturnForReviseOpen(false)
  }, [])

  const mdRole4Added = useMemo(() => {
    return teams.filter((team) => team.createBy === currentUser?.id && team.mdteamRole === 4).length === 1
  }, [currentUser?.id, teams])

  const mdRole5Added = useMemo(() => {
    return teams.filter((team) => team.createBy === currentUser?.id && team.mdteamRole === 5).length >= 1
  }, [currentUser?.id, teams])

  const approveAcceptedId = useMemo(() => {
    const ids = [5]
    if (!mdRole4Added) ids.push(4)
    return ids
  }, [mdRole4Added])

  return (
    <Flex data-testid="flex-request-progress">
      <RejectModal
        isOpen={isRejectModalOpen}
        onClose={handleCloseRejectModal}
        requestId={serviceRequest?.id.toString()}
        mdReqStatusId={actions?.reject}
      />
      <ConfirmReturnReviseModal
        isOpen={isReturnForReviseOpen}
        onClose={handleCloseReturnForReviseModal}
        mdReqStatusId={actions?.returnForRevise}
      />
      <ApproveModal
        onClickAdd={handleClickApproveAddModal}
        requestId={serviceRequest?.id.toString()}
        isOpen={isApproveModalOpen}
        onClose={handleCloseApproveModal}
        projectTeams={requestTracking?.listTbserviceReqTeamOutDto}
        mdReqStatusId={actions?.approve}
        mdRole4Added={mdRole4Added}
        mdRole5Added={mdRole5Added}
      />
      <ApproveAddTeamModal
        title="Approve/Assign > Add"
        isOpen={isApproveAddTeamModalOpen}
        onClose={handleCloseApproveAddModal}
        projectTeamEmployeeCodes={projectTeamEmployeeCodes}
        acceptedIds={approveAcceptedId}
      />
      <AssignTeamModal
        onClickAdd={handleClickAssignAddModal}
        requestId={serviceRequest?.id.toString()}
        isOpen={isAssignTeamOpen}
        onClose={handleCloseAssignModal}
        projectTeams={requestTracking?.listTbserviceReqTeamOutDto}
        mdRole5Added={mdRole5Added}
      />
      <AssignTeamAddTeamModal
        title="Approve/Assign > Add"
        isOpen={isAssignTeamAddTeamOpen}
        onClose={handleCloseAssignAddModal}
        projectTeamEmployeeCodes={projectTeamEmployeeCodes}
        acceptedIds={[5]}
      />

      <ReqTrackingProgressBar
        value={{
          submitted: {
            status: getSubmittedStatus(reqStatusId),
            userInfo: getUserInfoByTeamRole(requestTracking?.listTbserviceReqTeamOutDto, 1),
          },
          assigned: {
            status: getAssignedStatus(reqStatusId),
            userInfo: getAssignedInfoByStatusId(reqStatus, reqStatusId),
            showApprove: isShowApprove,
            showReject: isNumber(actions?.reject),
            showRevise: isNumber(actions?.returnForRevise),
            onApproveClick: handleClickApprove,
            onRejectClick: handleOpenRejectModal,
            onReturnForReviseClick: handleClickReturnForRevise,
          },
          executed: {
            status: getExecutedAndClosedStatus(reqStatusId),
            showAssignTeam: showAssignTeam,
            onAssignTeamClick: handleShowAssignTeam,
          },
          closed: {
            status: getExecutedAndClosedStatus(reqStatusId),
          },
        }}
      />
    </Flex>
  )
}
