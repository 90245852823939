import { css, getCSPNonce } from '@ptt-eia-web/helpers'
import { ConfigProvider, ThemeConfig } from 'antd'
import { PropsWithChildren } from 'react'

export const SPAN_HEADER_CLASS = 'span-header'

export const colors = {
  primary: '#009FDA',
  secondary: '#7AB800',

  gray: '#707070',
  darkGray: '#747678',
  lightGray: '#F8F9FA',
  black: '#000000',
  skyBlue: '#CCECF8',
  labelText: '#2E384D',
  subHeaderBg: '#dcdcdc',

  darkBlue: '#002F41',
  defaultBg: '#F5F6FA',
  lightBlueBg: '#ecfefa',

  line: '#DDDDDD',
  white: '#ffffff',
  pageText: '#DCDDEF',

  bobblePrimary: '#47E359',
  bobbleSecondary: '#00E5BB',
  bobbleTertiary: '#009CD6',
  bobbleQuaternary: '#003BDA',
  bobbleQuinary: '#F5A205',
  bobbleSextary: '#702DF0',

  disableGray: '#BCBEC0',

  // error color frontend
  errorRed: '#C23934',
  errorRedHover: '#E94949',
}

export const themeConfig = {
  maxWidth: 1252,
  headerHeight: 125,
  footerHeight: 288,
}

export const theme: ThemeConfig = {
  token: {
    colorPrimary: colors.primary,
    controlHeight: 48,
    colorTextDisabled: colors.darkGray,
  },
  components: {
    Divider: {
      margin: 0,
      marginLG: 0,
      marginXS: 0,
    },
    Menu: {
      colorPrimary: colors.secondary,
      fontSize: 20,
      fontSizeLG: 20,
      colorText: colors.black,
      lineWidth: 0,
      lineWidthBold: 0,
      itemHoverColor: colors.black,
      itemSelectedBg: colors.white,
    },
    Button: {
      borderRadius: 4,
      defaultBorderColor: colors.primary,
      defaultColor: colors.primary,
      defaultBg: colors.defaultBg,
    },
    Input: {
      borderRadius: 2,
      fontFamily: 'Prompt',
    },
    Select: {
      borderRadius: 2,
    },
    DatePicker: {
      borderRadius: 2,
    },
    Checkbox: {
      controlInteractiveSize: 16,
    },
    Pagination: {
      controlHeight: 25,
    },
    Table: {
      headerBorderRadius: 0,
      rowHoverBg: 'transparent',
    },
  },
}

export const AppThemeConfig = (props: PropsWithChildren) => {
  return (
    <ConfigProvider
      {...props}
      csp={{ nonce: getCSPNonce() }}
      theme={theme}
      button={{
        className: css`
          display: inline-flex;
          align-items: center;
          justify-content: center;
          span {
            font-weight: 300;
            line-height: 15px;
          }
          .ant-btn-icon {
            display: inline-flex;
          }
        `,
      }}
      switch={{
        className: css`
          width: 52px;
          height: 26px;
          .ant-switch-handle {
            width: 22px;
            height: 22px;

            ::before {
              border-radius: 22px;
            }
          }
          &.ant-switch-checked {
            .ant-switch-handle {
              inset-inline-start: calc(100% - 24px);
            }
          }
        `,
      }}
      radio={{
        className: css`
          .ant-radio-checked {
            .ant-radio-inner {
              border-width: 2px;
              background-color: white;
              :after {
                background-color: ${colors.primary};
                transform: scale(0.45);
              }
            }
          }
        `,
      }}
      form={{
        className: css`
          .ant-form-item {
            line-height: 0px;
          }
          .ant-form-item-label {
            padding: 0px 0 5px;
            label {
              height: 19px;
            }
            label.ant-form-item-required {
              ::before {
                content: unset !important;
              }
              ::after {
                content: '*' !important;
                color: red;
                visibility: visible;
              }
            }
          }
        `,
      }}
      tag={{
        className: css`
          font-family: Prompt;
          border-radius: 100px;
          height: 30px;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          font-size: 14px;
        `,
      }}
      pagination={{
        className: css`
          .ant-pagination-item-active {
            background-color: ${colors.primary};
            a {
              color: white !important;
            }
          }
          .ant-pagination-item {
            margin-inline-end: 0px;
            a {
              line-height: 25px;
              color: ${colors.darkGray};
            }
          }
          .ant-pagination-item-link {
            .ant-pagination-item-container {
              margin-top: 2px;
            }
            .anticon {
              width: 10px;
              svg {
                fill: ${colors.darkGray};
              }
            }
          }
          .ant-pagination-options {
            .ant-select {
              height: 24px;
            }
            .ant-pagination-options-quick-jumper {
              input {
                border-radius: 2px;
              }
            }
            .ant-pagination-options-size-changer {
              display: none;
            }
          }
          .ant-pagination-next,
          .ant-pagination-prev {
            margin-inline-end: 4px;
            background-color: ${colors.defaultBg};
          }
          .ant-pagination-next {
            margin-inline-start: 4px;
          }
          .ant-pagination-prev {
            margin-inline-end: 4px;
          }
        `,
      }}
      table={{
        className: css`
          .ant-table-content {
            overflow: auto hidden;
          }
          &&&&&& .span-header {
            border-bottom: 2px solid ${colors.primary};
          }
          .ant-table-thead {
            .ant-table-cell {
              background-color: ${colors.defaultBg};
              font-size: 14px;
              font-weight: 400;
              color: ${colors.darkBlue};
              border-bottom: 2px solid ${colors.primary};
              ::before {
                content: unset !important;
              }
            }
          }
          .ant-table-tbody {
            tr {
              color: ${colors.darkGray};
              .ant-table-cell {
                border-bottom: 1px solid ${colors.line};
              }
              .ant-table-cell-row-hover {
                background: transparent;
              }
            }
            tr:nth-of-type(even) {
              background-color: ${colors.defaultBg};
              .ant-table-cell-fix-right {
                background-color: ${colors.defaultBg};
              }
            }
          }
        `,
      }}
      select={{
        className: css`
          .ant-select-arrow {
            .anticon-down {
              transform: scale(1, 0.655);
            }
          }
        `,
      }}
      modal={{
        className: css`
          .ant-modal-content {
            padding: 0;
            background-color: #ecfefa;
            .ant-modal-header {
              height: 77px;
              background: linear-gradient(to right, ${colors.primary}, ${colors.secondary});
              display: flex;
              justify-content: center;
              align-items: center;
              border-bottom: 3px solid ${colors.primary};
              .ant-modal-title {
                font-family: Prompt;
                font-size: 28px;
                font-weight: 500;
                color: white;
              }
            }
            .ant-modal-close {
              width: 36px;
              height: 36px;
              display: none;
            }
            .ant-modal-footer {
              display: flex;
              justify-content: center;
              padding: 20px 0;

              > .ant-btn {
                min-width: 100px;
              }
              > .ant-btn:first-of-type {
                order: 2;
                margin-left: 15px;
              }
              > .ant-btn:last-of-type {
                order: 1;
                margin-inline-start: 0;
              }
            }
          }
        `,
      }}
    />
  )
}
