import { useMsal } from '@azure/msal-react'
import { LoadingPage } from '@ptt-eia-web/components'
import { removeToken } from '@ptt-eia-web/services'
import { useEffect, useRef } from 'react'
import { Navigate } from 'react-router-dom'

import { azureRedirectUri, azureRedirectUriBackend } from './AppMsalProvider'

interface IPageLoginProps {
  backend?: boolean
}
export const PageLogin = ({ backend }: IPageLoginProps) => {
  const isRedirecting = useRef<boolean>(false)
  const { instance, accounts, inProgress } = useMsal()

  const loginCallbackPath = backend ? azureRedirectUriBackend : azureRedirectUri

  useEffect(() => {
    if (accounts.length === 0 && inProgress === 'none' && !isRedirecting.current) {
      removeToken()
      isRedirecting.current = true
      instance.loginRedirect({ redirectStartPage: loginCallbackPath, scopes: [] })
    }
  }, [accounts, instance, inProgress, loginCallbackPath])

  if (accounts.length > 0 && inProgress === 'none') {
    // Unauthorized on /UserInfo but azure account exist.
    return <Navigate replace to={azureRedirectUri} />
  }
  return <LoadingPage />
}
