import { css } from '@ptt-eia-web/helpers'
import { Button, Flex } from 'antd'
import React from 'react'

interface IAddButtonProps {
  onAddClick: () => void
}

export const AddButton = ({ onAddClick }: IAddButtonProps) => {
  return (
    <Button
      onClick={onAddClick}
      type="primary"
      css={css`
        width: 120px;
      `}
    >
      <Flex justify="center" align="center">
        <p
          css={css`
            font-size: 20px;
            margin-right: 6px;
          `}
        >
          +
        </p>
        <p
          css={css`
            font-size: 16px;
          `}
        >
          Add
        </p>
      </Flex>
    </Button>
  )
}
