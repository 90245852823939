import { CacheProvider } from '@emotion/react'
import { AppMsalProvider } from '@ptt-eia-web/auth'
import { LoadingPage } from '@ptt-eia-web/components'
import { cache } from '@ptt-eia-web/helpers'
import { removeToken } from '@ptt-eia-web/services'
import { MutationCache, QueryCache, QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { notification } from 'antd'
import { AxiosError } from 'axios'
import { StrictMode, Suspense } from 'react'
import * as ReactDOM from 'react-dom/client'
import { Helmet, HelmetData } from 'react-helmet-async'
import { BrowserRouter } from 'react-router-dom'

import App from './app/app'
import { AppThemeConfig } from './app/theme'

import './index.css'

const handleError = (error: Error) => {
  if (error instanceof AxiosError) {
    if ([0, 401].includes(error.response?.status as number)) {
      console.log('== FE:handleError 401')
      removeToken()
      window.location.reload()
    } else {
      notification.error({ message: error.status, description: error.message })
      return
    }
  }
  notification.error({ message: error.message, description: error.message })
}

export const queryClientInstance = new QueryClient({
  queryCache: new QueryCache({
    onError: (error, query) => {
      const { globalError = true } = query.options.meta ?? {}
      if (globalError) {
        handleError(error)
      }
    },
  }),
  mutationCache: new MutationCache({
    onError: (error, variables, context, mutation) => {
      const { globalError = true } = mutation.options.meta ?? {}
      if (globalError) {
        handleError(error)
      }
    },
  }),
  defaultOptions: {
    queries: {
      retry: 0,
      gcTime: 1000 * 60 * 5, // 5 minute
      staleTime: 1000 * 60 * 5, // 5 minute,
      refetchOnWindowFocus: false,
    },
    mutations: {
      retry: 0,
    },
  },
})

const helmetData = new HelmetData({})

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <StrictMode>
    <BrowserRouter>
      <CacheProvider value={cache}>
        <Suspense fallback={<LoadingPage />}>
          <QueryClientProvider client={queryClientInstance}>
            <AppThemeConfig>
              <Helmet helmetData={helmetData}>
                <title>Project Environmental Management Services</title>
                <meta property="og:title" content="Project Environmental Management Services" />
                <script src="/env-config.js"></script>
              </Helmet>
              <AppMsalProvider>
                <App />
              </AppMsalProvider>
            </AppThemeConfig>
            <ReactQueryDevtools initialIsOpen={false} />
          </QueryClientProvider>
        </Suspense>
      </CacheProvider>
    </BrowserRouter>
  </StrictMode>,
)
