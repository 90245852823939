import { css } from '@ptt-eia-web/helpers'

import { colors } from '@frontend/app/theme'

export const ErrorButtonCss = css`
  &:hover {
    background-color: ${colors.errorRedHover} !important;
  }
  background-color: ${colors.errorRed};
`
