import styled from '@emotion/styled'
import { MenuCodeEnum, PermissionActionEnum } from '@ptt-eia-web/constants'
import { css } from '@ptt-eia-web/helpers'
import { IPRNewsResponse, useGetPRNewsQRY, usePermission } from '@ptt-eia-web/services'
import { List } from 'antd'
import dayjs from 'dayjs'
import { useEffect, useState } from 'react'
import { Navigate } from 'react-router-dom'

import { permissionConfigs } from '@frontend/app/permission-configs'
import { themeConfig } from '@frontend/app/theme'
import BgPrNews from '@frontend/assets/pr-news/pr-news-bg.png'

import { PrNewsCard } from './PrNewsCard'
import { PrNewsTitle } from './PrNewsTitle'

export const LayoutStyle = styled.div`
  width: 100%;
  min-height: calc(100vh - ${themeConfig.headerHeight + themeConfig.footerHeight}px);
  background-image: url(${BgPrNews});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding: 0 64px;
`

export const PagePrNews = () => {
  const { data: response } = useGetPRNewsQRY()
  const [prNewsList, setPRNewsList] = useState<IPRNewsResponse[]>([])
  const { can } = usePermission(permissionConfigs)
  const canView = can(MenuCodeEnum.PrNews, PermissionActionEnum.View)

  useEffect(() => {
    if (response) {
      const currentDate = dayjs()
      const newResponse = response.filter(
        (item) => item.active === 1 && dayjs(item.endDate) >= currentDate && dayjs(item.startDate) <= currentDate,
      )
      setPRNewsList(newResponse)
    }
  }, [response])

  if (!canView) return <Navigate to="/404" />
  return (
    <LayoutStyle>
      <PrNewsTitle />
      <List
        grid={{
          gutter: 8,
          xs: 1,
          sm: 1,
          md: 3,
          xl: 3,
          xxl: 3,
        }}
        css={css`
          padding-bottom: 24px;
        `}
        pagination={{
          pageSize: 6,
        }}
        dataSource={prNewsList}
        renderItem={(item: IPRNewsResponse, index: number) => (
          <List.Item key={index} data-testid={`fe-pr-news-list-item-${index}`}>
            <PrNewsCard prNews={item} />
          </List.Item>
        )}
      />
    </LayoutStyle>
  )
}
