import { IPTTRelationPeopleResponse, useRelationHeadQTY } from '@ptt-eia-web/services'
import { Select } from 'antd'
import FormItem from 'antd/es/form/FormItem'
import useFormInstance from 'antd/es/form/hooks/useFormInstance'
import { useCallback, useMemo } from 'react'

import { NEW_REQ_FORM_KEY } from '../helper'

interface ISelectApproverInputProps {
  editable?: boolean
}

export const SelectApproverFormItem = (props: ISelectApproverInputProps) => {
  const { editable = true } = props
  const form = useFormInstance()
  const { data: relationHead } = useRelationHeadQTY()

  const options = useMemo(() => {
    return relationHead?.map((v) => ({
      label: `${v.fname} ${v.lname}`,
      value: v.empCode,
    }))
  }, [relationHead])

  const handleOnSelect = useCallback(
    async (empCode: string) => {
      const teamList: IPTTRelationPeopleResponse[] = form.getFieldValue(NEW_REQ_FORM_KEY.LIST_TB_SERVICE_REQ_TEAM)

      const newRole2 = relationHead?.find((v) => v.empCode === empCode)
      let newTeamList
      const hasRole2 = teamList.some((v) => v.mdteamRole === 2)
      if (hasRole2) {
        newTeamList = teamList.map((v) => {
          if (v.mdteamRole === 2) return newRole2
          return v
        })
      } else {
        newTeamList = [...teamList, newRole2]
      }
      form.setFieldValue(NEW_REQ_FORM_KEY.LIST_TB_SERVICE_REQ_TEAM, newTeamList)
    },
    [form, relationHead],
  )

  return (
    <FormItem name={NEW_REQ_FORM_KEY.APPROVER} label="ผู้อนุมัติหน่วยงานผู้ขอ" labelCol={{ span: 24 }}>
      <Select disabled={!editable} placeholder="ผู้อนุมัติหน่วยงานผู้ขอ" options={options} onSelect={handleOnSelect} />
    </FormItem>
  )
}
