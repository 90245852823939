import styled from '@emotion/styled'
import { MenuCodeEnum, PermissionActionEnum } from '@ptt-eia-web/constants'
import { usePermission } from '@ptt-eia-web/services'

import { permissionConfigs } from '@frontend/app/permission-configs'

import { BannerHome } from './BannerHome'
import { OtherLinks } from './OtherLinks'
import { PrNewsSection } from './PrNewsSection'
const SpaceStyle = styled.div`
  width: 100%;
  height: 100%;
  background-color: white;
`
export const PageHome = () => {
  const { can } = usePermission(permissionConfigs)
  return (
    <SpaceStyle>
      <BannerHome />
      {can(MenuCodeEnum.PrNews, PermissionActionEnum.View) && <PrNewsSection />}
      <OtherLinks />
    </SpaceStyle>
  )
}
