import { IPublicClientApplication } from '@azure/msal-browser'
import { IPermissionConfig, MenuCodeEnum, PermissionActionEnum } from '@ptt-eia-web/constants'
import { secureStorage } from '@ptt-eia-web/helpers'
import { UseQueryOptions, useMutation, useQuery, useQueryClient, useSuspenseQuery } from '@tanstack/react-query'
import dayjs from 'dayjs'

import { pttClient } from '../ptt-client'
import { IResponseWithData, IStringOrNull } from '../services-types'

import { IDebugLoginParams, IGetAccessTokenParams } from './authentication-params'
import {
  IDebugLoginResponse,
  IGetAccessTokenResponse,
  IGetMeResponse,
  IGetRoleMenuResponse,
} from './authentication-response'

const PATH_GET_USER_INFO = '/User/UserInfo'
const PATH_GET_ROLE_MENU = '/User/RoleMenu'

export const useGetMeSuspenseQRY = (options?: UseQueryOptions<IGetMeResponse | null | undefined>) => {
  return useSuspenseQuery({
    queryKey: [PATH_GET_USER_INFO],
    queryFn: async () => {
      try {
        if (!getToken()) {
          return null
        }
        const { data } = await pttClient.get<IResponseWithData<IGetMeResponse | null>>(PATH_GET_USER_INFO)
        return data.data
      } catch (error) {
        console.log('useSuspenseQuery error', error)
        removeToken()
        return null
      }
    },
    staleTime: Infinity,
    gcTime: Infinity,
    meta: {
      globalError: false,
    },
    ...options,
  })
}

export const useDebugSignInMTT = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: async (params: IDebugLoginParams) => {
      const { data } = await pttClient.get<IDebugLoginResponse>('/Token/AuthenWithoutAzureADD', {
        params,
      })
      return data
    },
    onSuccess: (response) => {
      setToken(response.accessToken)
      setCSRFToken(response.accessCsrfToken)
      queryClient.removeQueries()
      queryClient.invalidateQueries({ queryKey: [PATH_GET_USER_INFO] })
    },
  })
}

export const useGetAccessTokenMTT = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: async (params: IGetAccessTokenParams) => {
      const { data } = await pttClient.get<IGetAccessTokenResponse>('/Token/AuthenAzureAD', {
        headers: { Authorization: `Bearer ${params.idToken}` },
      })
      return data
    },
    meta: {
      globalError: false,
    },
    onSuccess: (response) => {
      setToken(response.accessToken)
      setCSRFToken(response.accessCsrfToken)
      queryClient.removeQueries()
      queryClient.invalidateQueries({ queryKey: [PATH_GET_USER_INFO] })
    },
  })
}

export const useGetRoleMenuQRY = () => {
  return useQuery({
    queryKey: [PATH_GET_ROLE_MENU],
    queryFn: async () => {
      const { data } = await pttClient.get<IResponseWithData<IGetRoleMenuResponse[]>>(PATH_GET_ROLE_MENU)
      return data.data
    },
  })
}

export const useLateVisitedURL = (): { lastVisitedUrl: string; setLastVisitedUrl: (url: string) => void } => {
  const lastVisitedUrl = (secureStorage.getItem('LATE_VISITED_PATH') as IStringOrNull) ?? '/'
  const setLastVisitedUrl = (path: string) => {
    secureStorage.setItem('LATE_VISITED_PATH', path)
  }
  return { lastVisitedUrl, setLastVisitedUrl }
}

export const azureLogout = (instance: IPublicClientApplication) => {
  instance.logoutRedirect({ postLogoutRedirectUri: '/' })
  removeToken()
  removeCSRFToken()
}

export const setToken = (token: string) => {
  secureStorage.setItem('AUTH_TOKEN', token)
}

export const getToken = (): IStringOrNull => {
  return secureStorage.getItem('AUTH_TOKEN') as IStringOrNull
}

export const removeToken = () => {
  secureStorage.removeItem('AUTH_TOKEN')
}

interface ICsrfTokenInfo {
  token: string
  expiredAt: string
  isExpired?: boolean
}

const CSRF_TOKEN_EXPIRY_IN_MINUTES = 10
const CSRF_TOKEN_INFO_KEY = 'CSRF_TOKEN_INFO'

export const setCSRFToken = (token: string) => {
  const tokenInfoString = JSON.stringify({
    token,
    expiredAt: dayjs().add(CSRF_TOKEN_EXPIRY_IN_MINUTES, 'minutes').toISOString(),
  })
  secureStorage.setItem(CSRF_TOKEN_INFO_KEY, tokenInfoString)
}

export const getCSRFTokenInfo = (): ICsrfTokenInfo | null => {
  const tokenInfoString = secureStorage.getItem(CSRF_TOKEN_INFO_KEY) as IStringOrNull
  if (tokenInfoString) {
    const tokenInfo = JSON.parse(tokenInfoString)
    const isExpired = dayjs(tokenInfo.expiredAt).isBefore(dayjs())
    return { ...tokenInfo, isExpired }
  } else {
    return null
  }
}

export const removeCSRFToken = () => {
  secureStorage.removeItem(CSRF_TOKEN_INFO_KEY)
}

export const usePermission = (permissionConfigs: IPermissionConfig[]) => {
  const { data: user, isFetched } = useGetMeSuspenseQRY()

  const can = (menuCode: MenuCodeEnum, action: PermissionActionEnum) => {
    if (!isFetched || !user) return false

    const permissionConfig = permissionConfigs.find((config) => config.menuCode === menuCode)
    if (!permissionConfig) return false

    const actionConfig = permissionConfig.actions[action]
    if (!actionConfig) return false

    const rolePermission = actionConfig.find((a) => a.roleId === user.mdRoleID)
    if (!rolePermission) return false

    if (rolePermission.permissionId) {
      return user.listAdminPerm.some((permission) => permission.mdPermissionID === rolePermission.permissionId)
    } else {
      return true
    }
  }
  return {
    can,
    currentUser: user,
    roleId: user?.mdRoleID,
    permissionIds: user?.listAdminPerm?.map((p) => p.mdPermissionID),
  }
}
