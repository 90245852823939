import dayjs from 'dayjs'
import isoWeek from 'dayjs/plugin/isoWeek'
export const getThisWeek = () => {
  const today = dayjs()
  const startOfWeek = today.startOf('week').add(1, 'day').format('DD/MM/YYYY')
  const endOfWeek = today.endOf('week').add(1, 'day').format('DD/MM/YYYY')
  return `${startOfWeek} - ${endOfWeek}`
}

dayjs.extend(isoWeek)

export const getPreviousWeek = () => {
  // Define the reference date (you can change this to `dayjs()` to use the current date)
  const today = dayjs()

  // Get the start of the current week (Monday) according to ISO weeks
  const startOfCurrentWeek = today.startOf('isoWeek')

  // Calculate the start of the previous week (Monday)
  const startOfPreviousWeek = startOfCurrentWeek.subtract(1, 'week')

  // Calculate the end of the previous week (Sunday)
  const endOfPreviousWeek = startOfPreviousWeek.endOf('isoWeek')

  // Format the dates as DD/MM/YYYY
  const startFormatted = startOfPreviousWeek.format('DD/MM/YYYY')
  const endFormatted = endOfPreviousWeek.format('DD/MM/YYYY')

  return `${startFormatted} - ${endFormatted}`
}
