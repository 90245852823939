import { css } from '@ptt-eia-web/helpers'
import { useGetRequestStatusUpdateIdQRY, usePostUpdateRequestStatusMTT } from '@ptt-eia-web/services'
import { Col, Flex, Row } from 'antd'
import FormItem from 'antd/es/form/FormItem'
import { orderBy } from 'lodash'
import { useCallback, useMemo } from 'react'
import { useParams } from 'react-router-dom'

import { TxtField } from '@frontend/components/DisplayTxtField'
import { TagButton } from '@frontend/components/TagButton'
import { Txt } from '@frontend/components/Txt'

export const TOP_FIELD_FORM_KEY = {
  REQUEST_NUMBER: ['tbserviceReqInDto', 'reqNo'],
  TEMP_REQUEST_STATUS_NAME: ['tbserviceReqInDto', 'mdreqStatusDisplay'],
}

interface IRequestTrackingTopFieldProps {
  isCancel: boolean
  showButton?: boolean
}

export const RequestTrackingTopField = ({ isCancel, showButton = false }: IRequestTrackingTopFieldProps) => {
  const { id: requestId = 'N/A' } = useParams()
  const { mutateAsync: updateRequestStatus } = usePostUpdateRequestStatusMTT(requestId.toString())
  const { data: reqStatus = [] } = useGetRequestStatusUpdateIdQRY(requestId)

  const handleClickApproveCanceling = useCallback(async () => {
    await updateRequestStatus({ tbserviceReqId: Number(requestId), mdreqStatusId: 11 })
  }, [requestId, updateRequestStatus])

  const sortedReqStatus = useMemo(() => orderBy(reqStatus, ['createDate'], ['desc']) || [], [reqStatus])

  const currentStatus = useMemo(() => sortedReqStatus[0], [sortedReqStatus])

  const canceledReason = useMemo(
    () => (currentStatus?.mdreqStatusId === 9 ? currentStatus?.reason : undefined) || '',
    [currentStatus?.mdreqStatusId, currentStatus?.reason],
  )

  const returnReviseReason = useMemo(
    () => (currentStatus?.mdreqStatusId === 6 ? currentStatus?.reason : undefined),
    [currentStatus?.mdreqStatusId, currentStatus?.reason],
  )
  const rejectReason = useMemo(
    () => ([7, 3].includes(currentStatus?.mdreqStatusId) ? currentStatus?.reason : undefined),
    [currentStatus?.mdreqStatusId, currentStatus?.reason],
  )

  const recallReason = useMemo(
    () => ([13].includes(currentStatus?.mdreqStatusId) ? currentStatus?.reason : undefined),
    [currentStatus?.mdreqStatusId, currentStatus?.reason],
  )

  const displayReason = useMemo(
    () => rejectReason || returnReviseReason || recallReason,
    [recallReason, rejectReason, returnReviseReason],
  )

  return (
    <Row data-testid="request-tracking-top-field" gutter={[50, 0]}>
      <Col sm={24} md={12} lg={8}>
        <FormItem name={TOP_FIELD_FORM_KEY.REQUEST_NUMBER} labelCol={{ span: 24 }} label="Request Number">
          <TxtField ag="med16" />
        </FormItem>
      </Col>

      <Col sm={24} md={12} lg={8}>
        <FormItem name={TOP_FIELD_FORM_KEY.TEMP_REQUEST_STATUS_NAME} labelCol={{ span: 24 }} label="สถานะ">
          <TxtField ag="med16" />
        </FormItem>
      </Col>

      <Col
        sm={24}
        md={12}
        lg={8}
        css={css`
          padding-right: 32px;
          .ant-tag {
            margin-top: 10px;
            min-width: 100px;
            max-width: 150px;
            cursor: pointer;
            user-select: none;
            :hover {
              opacity: 0.8;
            }
          }
          margin-bottom: 24px;
        `}
      >
        {displayReason && (
          <Flex
            css={css`
              max-width: 300px;
            `}
            justify="center"
            vertical
            gap={12}
          >
            <Txt ag="reg14">เหตุผล :</Txt>
            <Txt
              css={css`
                cursor: text;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              `}
              ag="med16"
              title={displayReason}
            >
              {displayReason}
            </Txt>
          </Flex>
        )}

        {isCancel && (
          <Row gutter={24}>
            {showButton && (
              <Col span={8}>
                <Flex vertical>
                  <TagButton onClick={handleClickApproveCanceling} color="#00B815">
                    Acknowledge
                  </TagButton>
                </Flex>
              </Col>
            )}

            <Col
              span={14}
              css={css`
                display: flex;
                align-items: center;
              `}
            >
              <Flex
                css={css`
                  max-width: 300px;
                `}
                justify="center"
                vertical
                gap={12}
              >
                <Txt ag="reg14">เหตุผลในการขอยกเลิก :</Txt>
                <Txt
                  css={css`
                    cursor: text;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                  `}
                  ag="med16"
                  title={canceledReason}
                >
                  {canceledReason}
                </Txt>
              </Flex>
            </Col>
          </Row>
        )}
      </Col>
    </Row>
  )
}
