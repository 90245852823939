import { css, getCSPNonce } from '@ptt-eia-web/helpers'
import { Button, ConfigProvider, Flex } from 'antd'
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io'
import { SwiperClass } from 'swiper/react'

import { colors } from '../../app/theme'
import BannerImg from '../../assets/home/banner-pr-news.jpg'

interface IPrNewsSwiperNavProps {
  swiper?: SwiperClass
}

export const PrNewsSwiperNav: React.FC<IPrNewsSwiperNavProps> = (props) => {
  const { swiper } = props
  if (!swiper) {
    return null
  }
  return (
    <Flex
      css={css`
        background-image: url(${BannerImg});
        background-size: contain;
        background-position: top left;
        background-repeat: no-repeat;
        height: 190px;
      `}
    >
      <Flex
        justify="space-between"
        align="center"
        flex={1}
        css={css`
          position: relative;
        `}
      >
        <Flex gap={8} flex={1} justify="center" align="center">
          <p
            css={css`
              font-size: 30px;
              color: ${colors.primary};
            `}
          >
            PR
          </p>
          <p
            css={css`
              font-size: 30px;
            `}
          >
            News
          </p>
        </Flex>
        <Flex
          gap={8}
          css={css`
            position: absolute;
            right: 24px;
          `}
        >
          <ConfigProvider
            csp={{ nonce: getCSPNonce() }}
            theme={{
              token: {
                colorPrimary: `${colors.secondary}`,
                borderRadius: 0,
              },
            }}
          >
            <Button
              onClick={() => swiper.slidePrev()}
              shape="circle"
              css={css`
                background-color: transparent;
                cursor: pointer;
                height: 50px;
                width: 50px;
                border: 0px;
                box-shadow: 10px 10px 50px #00000027;
              `}
            >
              <div
                css={css`
                  width: 50px;
                  height: 50px;
                  border-radius: 100%;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  border: 2px solid ${colors.secondary};
                  color: ${colors.secondary};
                  &:hover {
                    color: ${colors.white};
                    background-color: ${colors.secondary};
                  }
                `}
              >
                <IoIosArrowBack size={30} />
              </div>
            </Button>
            <Button
              onClick={() => swiper.slideNext()}
              shape="circle"
              css={css`
                background-color: transparent;
                cursor: pointer;
                height: 50px;
                width: 50px;
                border: 0px;
                box-shadow: 10px 10px 50px #00000027;
              `}
            >
              <div
                css={css`
                  width: 50px;
                  height: 50px;
                  border-radius: 100%;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  border: 2px solid ${colors.secondary};
                  color: ${colors.secondary};
                  &:hover {
                    color: ${colors.white};
                    background-color: ${colors.secondary};
                  }
                `}
              >
                <IoIosArrowForward size={30} />
              </div>
            </Button>
          </ConfigProvider>
        </Flex>
      </Flex>
    </Flex>
  )
}
