import { Input } from 'antd'
import { AnyObject } from 'antd/es/_util/type'
import { ComponentType, useCallback } from 'react'
import { NumberFormatValues, NumericFormat, NumericFormatProps } from 'react-number-format'

interface INumberInputProps extends Omit<NumericFormatProps, 'onChange'> {
  onChange?: (value?: number | string) => void
  valueType?: 'float' | 'formatted' | 'string'
}

const CustomInput: ComponentType<AnyObject> = (props) => {
  return <Input {...props} />
}

export const NumberInput = (props: INumberInputProps) => {
  const { onChange, value, valueType = 'floatValue', ...restProps } = props

  const handleChange = useCallback(
    (data: NumberFormatValues) => {
      switch (valueType) {
        case 'formatted':
          onChange?.(data.formattedValue)
          break
        case 'string':
          onChange?.(data.value)
          break
        case 'float':
        default:
          onChange?.(data.floatValue)
      }
    },
    [onChange, valueType],
  )
  return (
    <NumericFormat
      onValueChange={handleChange}
      customInput={CustomInput}
      value={value ? String(value) : undefined}
      thousandSeparator
      {...restProps}
    />
  )
}
