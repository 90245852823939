import { MenuCodeEnum, PermissionActionEnum } from '@ptt-eia-web/constants'
import { css } from '@ptt-eia-web/helpers'
import { ProjectDashboardLTAInDtos, ProjectDashboardSHEInDtos, usePermission } from '@ptt-eia-web/services'
import { Button, Flex, Form, notification } from 'antd'
import useFormInstance from 'antd/es/form/hooks/useFormInstance'
import React, { useCallback, useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

import { permissionConfigs } from '@frontend/app/permission-configs'

import { BackButton } from '../../update-history/BackButton'

interface IWrapButtonProps {
  sourceLTA: ProjectDashboardLTAInDtos[]
  sourceSHE: ProjectDashboardSHEInDtos[]
  isInputPhaseLTASuccess: boolean
}
export const WrapButton = (props: IWrapButtonProps) => {
  const form = useFormInstance()
  const [loadingDraft, setLoadingsDraft] = useState<boolean[]>([])
  const [loadingSubmit, setLoadingSubmit] = useState<boolean[]>([])
  const [isPhaseMatch, setIsPhaseMatch] = useState<string>('')

  const { can } = usePermission(permissionConfigs)
  const canEdit = can(MenuCodeEnum.ProjectStatusUpdate, PermissionActionEnum.Edit)
  const [searchParams] = useSearchParams()
  const [isShowActionButton, setIsShowActionButton] = useState(false)
  const [isShowSubmitButton, setIsShowSubmitButton] = useState(false)
  useEffect(() => {
    const isNewProject = !searchParams.get('id')
    const isSubmit = searchParams.get('isDraft')?.toString() === '0'
    setIsShowSubmitButton(isNewProject || canEdit)
    setIsShowActionButton(isNewProject || (canEdit && !isSubmit))
  }, [canEdit, searchParams])

  useEffect(() => {
    if (props.sourceLTA.length > 0 && props.isInputPhaseLTASuccess) {
      const isMatch = props.sourceLTA.every((item) => {
        return props.sourceSHE.some((itemSHE) => {
          return item.Phase === itemSHE.Phase
        })
      })
      if (isMatch) {
        setIsPhaseMatch('Match')
      } else {
        setIsPhaseMatch('NotMatch')
      }
    } else if (props.sourceSHE.length <= 0) {
      setIsPhaseMatch('Match')
    } else if (props.sourceSHE.length > 0 && props.sourceLTA.length === 0) {
      setIsPhaseMatch('Match')
    } else if (!props.isInputPhaseLTASuccess) {
      setIsPhaseMatch('NotMatch')
    }
  }, [props])

  const enterLoadingDraft = useCallback(
    (index: number) => {
      setLoadingsDraft((prevLoadings) => {
        const newLoadings = [...prevLoadings]
        newLoadings[index] = true
        return newLoadings
      })
      setTimeout(() => {
        setLoadingsDraft((prevLoadings) => {
          const newLoadings = [...prevLoadings]
          newLoadings[index] = false
          return newLoadings
        })
      }, 6000)
    },
    [setLoadingsDraft],
  )
  const enterLoadingSubmit = useCallback(
    (index: number) => {
      setLoadingSubmit((prevLoadings) => {
        const newLoadings = [...prevLoadings]
        newLoadings[index] = true
        return newLoadings
      })
      setTimeout(() => {
        setLoadingSubmit((prevLoadings) => {
          const newLoadings = [...prevLoadings]
          newLoadings[index] = false
          return newLoadings
        })
      }, 6000)
    },
    [setLoadingSubmit],
  )

  const handleSaveDraftClick = useCallback(() => {
    if (isPhaseMatch !== '' && isPhaseMatch === 'Match') {
      form.submit()
    } else {
      notification.error({ message: 'Phase ในตาราง LTA ไม่ถูกต้อง กรุณาตรอจสอบ' })
      return
    }
    enterLoadingDraft(1)
    form.setFieldValue('submitButton', 1)
    form.submit()
  }, [form, enterLoadingDraft, isPhaseMatch])

  const handleSaveClick = useCallback(() => {
    if (isPhaseMatch !== '' && isPhaseMatch === 'Match') {
      form.submit()
    } else {
      notification.error({ message: 'Phase ในตาราง LTA ไม่ถูกต้อง กรุณาตรอจสอบ' })
      return
    }
    enterLoadingSubmit(1)
    form.setFieldValue('submitButton', 0)
    form.submit()
  }, [form, enterLoadingSubmit, isPhaseMatch])

  return (
    <Flex
      css={css`
        padding: 64px;
      `}
      gap={20}
      justify="center"
      align="center"
    >
      <Form.Item name="submitButton" hidden />
      {isShowActionButton && (
        <span>
          {searchParams.get('flowsType') !== 'new' && (
            <Button
              css={css`
                background-color: #006b93;
                width: 100px;
              `}
              loading={loadingDraft[1]}
              onClick={handleSaveDraftClick}
              type="primary"
            >
              Save Draft
            </Button>
          )}
        </span>
      )}
      {isShowSubmitButton && (
        <Button
          css={css`
            width: 100px;
          `}
          onClick={handleSaveClick}
          loading={loadingSubmit[1]}
          type="primary"
        >
          Submit
        </Button>
      )}

      <BackButton paths="" />
    </Flex>
  )
}
