import { useMsal } from '@azure/msal-react'
import { LoadingPage } from '@ptt-eia-web/components'
import { getToken, setToken, useGetAccessTokenMTT, useLateVisitedURL } from '@ptt-eia-web/services'
import { AxiosError } from 'axios'
import { useCallback, useEffect, useRef, useState } from 'react'
import { Navigate } from 'react-router-dom'

export const PageLoginCallback = () => {
  const { instance, accounts, inProgress } = useMsal()
  const { mutateAsync: getAccessToken } = useGetAccessTokenMTT()
  const { lastVisitedUrl } = useLateVisitedURL()
  const isTokenFetching = useRef<boolean>(false)
  const isTokenFetched = useRef<boolean>(false)
  const [isNonAppUser, setIsNonAppUser] = useState(false)

  const fetchAccessToken = useCallback(async () => {
    await instance.initialize()
    const result = await instance.acquireTokenSilent({
      account: accounts[0],
      scopes: [],
    })
    const idToken = result.idToken
    try {
      const { accessToken } = await getAccessToken({ idToken })
      setToken(accessToken)
    } catch (error: unknown) {
      if (error instanceof AxiosError && error?.response?.data.code === '401') {
        setIsNonAppUser(true)
      } else {
        throw error
      }
    }
    isTokenFetched.current = true
  }, [accounts, getAccessToken, instance])

  useEffect(() => {
    if (inProgress === 'none' && accounts.length > 0 && !isTokenFetching.current && !getToken()) {
      isTokenFetching.current = true
      fetchAccessToken()
    }
  }, [accounts, instance, inProgress, fetchAccessToken])

  if (isTokenFetched.current && isNonAppUser) {
    window.location.href = '/account-not-found'
    return
  }
  if (isTokenFetched.current && !isNonAppUser) {
    window.location.href = lastVisitedUrl
    return
  }
  if (getToken()) {
    // handle navigate to /login-callback directly with session.
    return <Navigate replace to="/" />
  }
  return <LoadingPage />
}
