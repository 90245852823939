import styled from '@emotion/styled'
import { useMemo } from 'react'

import { colors } from '@frontend/app/theme'

const Badge = styled('div')<{ color: string }>`
  color: ${colors.white};
  background-color: ${(props) => props.color};
  min-width: 120px;
  max-width: 300px;
  width: 100%;
  padding: 8px;
  text-overflow: ellipsis;
  text-align: center;
  border-radius: 24px;
`

const BadgeContainer = styled('div')`
  width: 100%;
  display: flex;
  justify-content: center;
`

interface IStatusBadgeProps {
  color?: string | null
  status: string
}

export const StatusBadge = ({ color = colors.darkGray, status }: IStatusBadgeProps) => {
  const backgroundColor = useMemo(() => {
    if (color === null) return colors.darkGray
    return color.startsWith('#') ? color : `#${color}`
  }, [color])

  return (
    <BadgeContainer>
      <Badge color={backgroundColor}>{status}</Badge>
    </BadgeContainer>
  )
}
