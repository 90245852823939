import { css } from '@ptt-eia-web/helpers'
import { Button, Flex } from 'antd'
import { Link } from 'react-router-dom'

interface IBackButtonProps {
  paths: string
}

export const BackButton = ({ paths }: IBackButtonProps) => {
  const handlerBack = () => {
    if (paths) {
      return
    }
    return window.history.back()
  }
  return (
    <Flex justify="center" align="center">
      <Link to={paths ? paths : '#'} onClick={handlerBack}>
        <Button
          css={css`
            width: 100px;
            text-align: center;
            align-items: center;
            justify-content: center;
          `}
        >
          <p
            css={css`
              font-size: 20px;
              margin-right: 10px;
            `}
          >{`<`}</p>
          <p
            css={css`
              font-size: 16px;
              margin-right: 10px;
            `}
          >
            Back
          </p>
        </Button>
      </Link>
    </Flex>
  )
}
