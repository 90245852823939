import { BaseHttpClient, getEnv } from '@ptt-eia-web/helpers'
import { AnyObject } from 'antd/es/_util/type'
import { AxiosError, AxiosResponse, InternalAxiosRequestConfig } from 'axios'
import { set } from 'lodash'

import { getCSRFTokenInfo, getToken, setCSRFToken } from './authentication/authentication-service'

class PttClient extends BaseHttpClient {
  constructor() {
    super({
      baseURL: getEnv('API_BASE_URL'),
      timeout: 30 * 1000,
      withCredentials: true,
    })
  }

  async onRequest(request: InternalAxiosRequestConfig<AnyObject>): Promise<InternalAxiosRequestConfig<AnyObject>> {
    const accessToken = getToken()
    if (accessToken && !request.headers['AuthorizationApi']) {
      set(request.headers, 'AuthorizationApi', accessToken)
    }
    let csrfTokenInfo = getCSRFTokenInfo()
    const PATH_CSRF_REFRESHING = '/Login/GenTokenCsrf'
    if (accessToken && csrfTokenInfo?.isExpired && !request.url?.endsWith(PATH_CSRF_REFRESHING)) {
      const csrfRes = await this.get<{ accessCsrfToken: string }>(PATH_CSRF_REFRESHING)
      const csrfToken = csrfRes.data.accessCsrfToken
      setCSRFToken(csrfToken)
      csrfTokenInfo = getCSRFTokenInfo()
    }
    if (csrfTokenInfo) {
      set(request.headers, 'X-CSRF-TOKEN', csrfTokenInfo?.token)
    }
    return request
  }

  async onResponse(response: AxiosResponse<AnyObject>) {
    if (response.data?.status?.isError && response.data?.status?.message !== 'NoData') {
      let msgError = response.data?.status?.message
      if (
        response.data?.status?.message === 'Data is already exists' &&
        response.request.responseURL.endsWith('/ProjectDashboard/ProjectDashboard')
      ) {
        msgError = 'ชื่อโครงการนี้มีอยู่ในระบบแล้ว'
      }
      return Promise.reject(
        new AxiosError(msgError, response.data?.status?.code, response.request.config, response.request, response),
      )
    }
    return response
  }
}

export const pttClient = new PttClient()
