import styled from '@emotion/styled'
import { css } from '@ptt-eia-web/helpers'
import { IProjectDashboardDtOutDto, IProjectDashboardOutDto } from '@ptt-eia-web/services'
import { Table } from 'antd'
import React, { useEffect, useMemo } from 'react'

import { SPAN_HEADER_CLASS, colors } from '../../../app/theme'
import CheckSvg from '../../../assets/svgs/check-icon.svg?react'
import CloseSvg from '../../../assets/svgs/close-icon.svg?react'

import { CellEiaHighlightActivities } from './CellEiaHighlightActivities'
import { renderColSpanMonitoringActive } from './ColSpanTable'

import type { ColumnsType } from 'antd/es/table'
const FixedWidthTitle = (props: { title: string; width: string }) => {
  return (
    <p
      css={css`
        ${columnTitleStyles}
        min-width: ${props.width};
      `}
    >
      {props.title}
    </p>
  )
}

interface DataType {
  key: string | undefined
  mddashboardStatusId: number | undefined
  plan: number | undefined
  actual: number | undefined
  project: string | undefined
  eiaHighlight: string | undefined
  eiaWayForward: string | undefined
  eiaCompliance: string | undefined
  sixMonth: string | undefined
  eiaComplianceHighlight: string | undefined
  planActualColors: string | undefined
  eiaactive?: number | undefined
  eiaactiveReason?: string | undefined
  monitorActive?: number | undefined
  monitorReason?: string | undefined
}

interface GroupedData {
  [key: string]: IProjectDashboardOutDto[]
}

const CheckIcon = styled(CheckSvg)`
  text-align: center;
  align-self: center;
  #Ellipse_1973 {
    fill: ${colors.primary};
  }
  #Ellipse_1974 {
    fill: ${colors.primary};
  }
  #Path_16408 {
    fill: ${colors.primary};
  }
  #Path_16407 {
    fill: ${colors.white};
  }
  #Path_16406 {
    fill: ${colors.primary};
  }
  #Path_16408 {
    fill: ${colors.primary};
  }
`
const CloseIcon = styled(CloseSvg)`
  text-align: center;
  align-self: center;
  width: 22px;
  height: 22px;
`

const columnTitleStyles = css`
  font-weight: 900;
  font-size: 16px;
  text-align: center;
`
const columnTitleWhiteTextStyles = css`
  font-weight: 900;
  font-size: 16px;
  text-align: center;
  align-self: center;
  color: ${colors.white};
`

const generateData = (subItem: IProjectDashboardOutDto | undefined, dtItem: IProjectDashboardDtOutDto) => {
  return {
    key: String(subItem?.id),
    mddashboardStatusId: dtItem?.mdreportStatusId,
    project: subItem?.projectName,
    plan: dtItem?.progressPlan,
    actual: dtItem?.progressActual,
    eiaHighlight: dtItem?.highlightActivity,
    eiaWayForward: dtItem?.wayForward,
    eiaCompliance: dtItem?.eiacompliance,
    sixMonth: dtItem?.sixMonth,
    eiaComplianceHighlight: dtItem?.complianceHighlight,
    planActualColors: dtItem?.progressActualColor,
    eiaactive: dtItem?.eiaactive,
    eiaactiveReason: dtItem?.eiaactiveReason,
    monitorActive: dtItem?.monitorActive,
    monitorReason: dtItem?.monitorReason,
  }
}

const renderSubHeaderRow = (text: string, record: DataType) => (
  <div
    css={css`
      height: 100%;
      align-self: center;
      text-align: ${isNaN(Number(record.key)) ? 'left' : 'center'};
      font-size: ${isNaN(Number(record.key)) ? '16px' : '14px'};
      font-weight: ${isNaN(Number(record.key)) ? 'bold' : 'normal'};
    `}
  >
    {text}
  </div>
)

export interface IDistributionTableDataProps {
  dataSource: IProjectDashboardOutDto[] | undefined
}
const spanCol = (record: DataType) => {
  if (isNaN(Number(record.key))) {
    return 0
  }
  return 1
}

export const renderColSpanEIAActiveSwitch = (
  key: string | undefined,
  colSpan: number,
  isColumnStartSpan: boolean,
  activeSwitch: number | undefined,
) => {
  if (isNaN(Number(key))) {
    return 0
  } else if (activeSwitch === 0 && isColumnStartSpan) {
    return colSpan
  } else if (activeSwitch === 1) {
    return 1
  }
  return 0
}

export const DistributionPipeLineTable = (props: IDistributionTableDataProps) => {
  const [dataList, setDataList] = React.useState<DataType[]>([])

  const generateColumn = (title: string, dataIndex: string, width: number) => {
    return {
      title: () => <p css={columnTitleStyles}>{title}</p>,
      dataIndex,
      width,
      onHeaderCell: () => ({
        className: SPAN_HEADER_CLASS,
      }),
      onCell: (record: DataType) => ({
        colSpan:
          title === '1'
            ? renderColSpanEIAActiveSwitch(record.key, 10, true, record.eiaactive)
            : renderColSpanEIAActiveSwitch(record.key, 0, false, record.eiaactive),
        style: {
          backgroundColor:
            record.mddashboardStatusId === parseInt(title) && record.eiaactive === 1 ? '#315496' : 'transparent',
        },
      }),
      render: (_: string, record: DataType) => {
        if (record.eiaactive !== 1) {
          return (
            <div
              css={css`
                display: flex;
                justify-content: center;
                align-items: center;
                white-space: pre-wrap;
                font-size: 12px;
                min-width: 300px;
                min-height: 60px;
              `}
            >
              {record.eiaactiveReason}
            </div>
          )
        } else {
          return ''
        }
      },
    }
  }

  const distributionColumns = Array.from({ length: 7 }, (_, index) =>
    generateColumn((index + 1).toString(), 'mddashboardStatusId', 5),
  )

  const columns: ColumnsType<DataType> = useMemo(() => {
    return [
      {
        title: () => <p css={columnTitleStyles}>NO.</p>,
        dataIndex: 'key',
        width: 6,
        onCell: (record) => ({ colSpan: isNaN(Number(record.key)) ? 12 : 1 }),
        render: renderSubHeaderRow,
        onHeaderCell: () => ({
          className: SPAN_HEADER_CLASS,
        }),
      },
      {
        title: <FixedWidthTitle width="300px" title="PROJECT" />,
        dataIndex: 'project',
        width: 50,
        onCell: (record) => ({ colSpan: spanCol(record) }),
        render: (text) => (
          <div
            css={css`
              white-space: pre-wrap;
              font-size: 12px;
            `}
          >
            {text}
          </div>
        ),
        onHeaderCell: () => ({
          className: SPAN_HEADER_CLASS,
        }),
      },
      {
        title: () => <p css={columnTitleWhiteTextStyles}>EIA/ER</p>,
        onHeaderCell: () => ({ style: { background: colors.secondary, height: 26, className: SPAN_HEADER_CLASS } }),
        children: [
          {
            title: () => <p css={columnTitleWhiteTextStyles}>EIA/ER REPORT STATUS</p>,
            onHeaderCell: () => ({ style: { background: '#006B93', height: '20px', className: SPAN_HEADER_CLASS } }),
            onCell: (record) => ({ colSpan: spanCol(record) }),
            children: distributionColumns,
          },
          {
            title: () => <p css={columnTitleWhiteTextStyles}>PROGRESS(%)</p>,
            onHeaderCell: () => ({ style: { background: colors.primary, height: 26, className: SPAN_HEADER_CLASS } }),
            onCell: (record) => ({ colSpan: spanCol(record) }),
            children: [
              {
                title: () => <p css={columnTitleStyles}>PLAN</p>,
                onHeaderCell: () => ({
                  style: { height: 26 },
                  className: SPAN_HEADER_CLASS,
                }),
                onCell: (record: DataType) => ({
                  colSpan: renderColSpanEIAActiveSwitch(record.key, 0, false, record.eiaactive),
                }),
                width: 15,
                dataIndex: 'plan',
                render: (value) => (
                  <p
                    css={css`
                      font-size: 12px;
                      text-align: center;
                    `}
                  >
                    {value}
                  </p>
                ),
              },
              {
                title: () => <p css={columnTitleStyles}>ACTUAL</p>,
                onHeaderCell: () => ({
                  style: { height: 26 },
                  className: SPAN_HEADER_CLASS,
                }),
                width: 15,
                dataIndex: 'actual',
                onCell: (record) => ({
                  colSpan: renderColSpanEIAActiveSwitch(record.key, 0, false, record.eiaactive),
                  style: {
                    backgroundColor: record.planActualColors !== '' ? record.planActualColors : 'transparent',
                  },
                }),
                render: (value, record) => (
                  <p
                    css={css`
                      color: ${record.planActualColors !== '' ? '#393a2c' : ''};
                      font-size: 12px;
                      text-align: center;
                    `}
                  >
                    {value}
                  </p>
                ),
              },
            ],
          },
          {
            title: () => <FixedWidthTitle width="400px" title="EIA/ER HIGHLIGHT ACTIVITIES" />,
            dataIndex: 'eiaHighlight',
            onHeaderCell: () => ({
              className: SPAN_HEADER_CLASS,
            }),
            onCell: (record) => ({ colSpan: renderColSpanEIAActiveSwitch(record.key, 0, false, record.eiaactive) }),
            render: (_, record) => (
              <CellEiaHighlightActivities highlightActivity={record.eiaHighlight} wayForward={record.eiaWayForward} />
            ),
          },
        ],
      },
      {
        title: () => <p css={columnTitleWhiteTextStyles}>ENVIRONMENT COMPLIANCE AND MONITORING</p>,
        onHeaderCell: () => ({ style: { background: '#002F41', height: 26, className: SPAN_HEADER_CLASS } }),

        children: [
          {
            title: () => <p css={columnTitleStyles}>EIA/ER COMPLIANCE STATUS (DURING CONSTRUCTION)</p>,
            onHeaderCell: () => ({
              style: { height: '20px', className: SPAN_HEADER_CLASS },
            }),
            width: 50,
            children: [
              {
                title: () => <p css={columnTitleStyles}>EIA PERFORMANCE</p>,
                dataIndex: 'eiaHighlight',
                width: 50,

                onHeaderCell: () => ({
                  style: { background: '#00338D', color: 'white', height: '20px' },
                }),
                children: [
                  {
                    title: () => <p css={columnTitleStyles}>EIA COMPLIANCE</p>,
                    onHeaderCell: () => ({
                      style: { height: 26 },
                    }),
                    dataIndex: 'eiaCompliance',
                    width: 50,
                    onCell: (record) => ({
                      colSpan: record.monitorActive === 0 ? 3 : 1,
                    }),
                    render: (_, record) =>
                      record.monitorActive === 0 ? (
                        <p
                          css={css`
                            text-align: center;
                            white-space: pre-wrap;
                            font-size: 12px;
                          `}
                        >
                          {record.monitorReason}
                        </p>
                      ) : (
                        !isNaN(Number(record.key)) && (
                          <span>
                            {record.eiaCompliance === '0' ? (
                              <span
                                css={css`
                                  display: flex;
                                  align-items: center;
                                  justify-content: center;
                                  height: 60%;
                                `}
                              >
                                <CheckIcon alt="check" />
                              </span>
                            ) : (
                              <span
                                css={css`
                                  display: flex;
                                  align-items: center;
                                  justify-content: center;
                                  height: 60%;
                                `}
                              >
                                <CloseIcon alt="close" />
                              </span>
                            )}
                          </span>
                        )
                      ),
                  },
                  {
                    title: () => {
                      return <FixedWidthTitle width="200px" title="SIX-MONTH REPORT SUBMISSION" />
                    },
                    onHeaderCell: () => ({
                      style: { height: 26 },
                      className: SPAN_HEADER_CLASS,
                    }),
                    onCell: (record) => ({
                      colSpan: renderColSpanMonitoringActive(record.monitorActive),
                    }),
                    render: (text) => {
                      return (
                        <div
                          css={css`
                            white-space: pre-wrap;
                            font-size: 12px;
                          `}
                        >
                          {text}
                        </div>
                      )
                    },
                    width: 50,
                    dataIndex: 'sixMonth',
                  },
                ],
              },
              {
                title: () => <FixedWidthTitle width="400px" title="EIA COMPLIANCE HIGHLIGHT ACTIVITIES" />,
                onHeaderCell: () => ({
                  style: { height: 26 },
                  className: SPAN_HEADER_CLASS,
                }),
                onCell: (record) => ({
                  colSpan: renderColSpanMonitoringActive(record.monitorActive),
                }),
                render: (text) => {
                  return (
                    <div
                      css={css`
                        white-space: pre-wrap;
                        font-size: 12px;
                      `}
                    >
                      {text}
                    </div>
                  )
                },
                dataIndex: 'eiaComplianceHighlight',
              },
            ],
          },
        ],
      },
    ]
  }, [distributionColumns])

  useEffect(() => {
    const newList: DataType[] = []
    let reportType = ''
    let rowNum = 1

    const sortedDataSourceWithTypeEIA_ER: GroupedData = {}
    props.dataSource?.forEach((item) => {
      const key = item.mdreportTypeEIA_ER

      if (!sortedDataSourceWithTypeEIA_ER[key]) {
        sortedDataSourceWithTypeEIA_ER[key] = []
      }

      sortedDataSourceWithTypeEIA_ER[key].push(item)
    })

    const sortedAndGroupedData: IProjectDashboardOutDto[] = Object.keys(sortedDataSourceWithTypeEIA_ER)
      .sort()
      .reduce((acc: IProjectDashboardOutDto[], key) => {
        const sortedGroup = sortedDataSourceWithTypeEIA_ER[key].sort(
          (a, b) => a.mdreportTypeEIA_ER_Sort - b.mdreportTypeEIA_ER_Sort,
        )
        acc.push(...sortedGroup)
        return acc
      }, [])

    sortedAndGroupedData?.forEach((item) => {
      if (reportType !== item.mdreportTypeEIA_ER) {
        reportType = ''
      }

      item.projectDashboardDtOutDtos?.forEach((dtItem) => {
        rowNum = rowNum + 1

        if (reportType === '') {
          rowNum = 1
          reportType = item.mdreportTypeEIA_ER
          newList.push({
            key: item.mdreportTypeEIA_ER,
            mddashboardStatusId: undefined,
            plan: undefined,
            actual: undefined,
            project: undefined,
            eiaHighlight: undefined,
            eiaWayForward: undefined,
            eiaCompliance: undefined,
            sixMonth: undefined,
            eiaComplianceHighlight: undefined,
            planActualColors: undefined,
            eiaactive: undefined,
            eiaactiveReason: undefined,
            monitorActive: undefined,
            monitorReason: undefined,
          })
          newList.push({ ...generateData(item, dtItem), key: rowNum.toString() })
        } else if (reportType === item.mdreportTypeEIA_ER) {
          newList.push({ ...generateData(item, dtItem), key: rowNum.toString() })
        }
      })
    })

    setDataList(newList)
  }, [props.dataSource])

  return (
    <Table
      css={css`
        .ant-table-tbody tr.sub-header-row {
          color: ${colors.black};
          background-color: ${colors.subHeaderBg} !important;
        }
        .ant-table-tbody {
          tr:nth-of-type(even) {
            background-color: white;
            .ant-table-cell-fix-right {
              background-color: white;
            }
          }
        }
      `}
      columns={columns}
      pagination={false}
      virtual
      bordered
      dataSource={dataList}
      rowKey="key"
      size="small"
      rowClassName={(record) => (isNaN(Number(record.key)) ? 'sub-header-row' : '')}
    />
  )
}
