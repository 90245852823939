import { css } from '@ptt-eia-web/helpers'
import { Button } from 'antd'
import { PropsWithChildren, useCallback, useState } from 'react'

import { colors } from '@frontend/app/theme'

interface FunctionClick {
  (): Promise<void> | void
}

interface ITagButtonProps {
  color: string
  onClick?: FunctionClick
}

export const TagButton = ({ color, onClick, children }: PropsWithChildren<ITagButtonProps>) => {
  const [loading, setLoading] = useState(false)

  const handleClick = useCallback(async () => {
    if (onClick) {
      setLoading(true)
      await onClick()
      setLoading(false)
    }
  }, [onClick])

  return (
    <Button
      css={css`
        height: 28px;
        border-radius: 100px;
        background-color: ${color};
        color: ${colors.white};
        border: none;
        padding: 4px 8px;
        margin-top: 10px;
        min-width: 100px;
        max-width: 150px;
        user-select: none;
        span {
          font-weight: 400;
        }
        &:hover {
          opacity: 0.65;
          cursor: pointer;
          background-color: ${color} !important;
          color: ${colors.white} !important;
        }
      `}
      onClick={handleClick}
      loading={loading}
    >
      {children}
    </Button>
  )
}
