import { Configuration, PublicClientApplication } from '@azure/msal-browser'
import { MsalProvider } from '@azure/msal-react'
import { getEnv } from '@ptt-eia-web/helpers'
import { PropsWithChildren, useMemo } from 'react'

interface IAppMsalProviderProps extends PropsWithChildren {
  backend?: boolean
}

export const azureRedirectUri = '/login-callback'
export const azureRedirectUriBackend = `/backend${azureRedirectUri}`

export const AppMsalProvider = ({ children, backend }: IAppMsalProviderProps) => {
  const msalInstance = useMemo(() => {
    const authConfig: Configuration = {
      auth: {
        clientId: getEnv('AZURE_AD_CLIENT_ID'),
        authority: `https://login.microsoftonline.com/${getEnv('AZURE_AD_TENANT_ID')}`,
        redirectUri: azureRedirectUri,
      },
    }
    if (backend) {
      authConfig.auth.redirectUri = azureRedirectUriBackend
    }
    return new PublicClientApplication(authConfig)
  }, [backend])
  return <MsalProvider instance={msalInstance}>{children}</MsalProvider>
}
