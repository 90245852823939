import { PlusOutlined } from '@ant-design/icons'
import { css } from '@ptt-eia-web/helpers'
import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Flex,
  Form,
  Input,
  Modal,
  Pagination,
  Radio,
  Row,
  Segmented,
  Select,
  Switch,
  Table,
  Tag,
} from 'antd'
import { AnyObject } from 'antd/es/_util/type'
import { FaFilter } from 'react-icons/fa'
import { IoFilterOutline } from 'react-icons/io5'
import { MdOutlineFilterVintage } from 'react-icons/md'

import { colors } from '../../app/theme'
import { Txt } from '../../components/Txt'
import { useState } from 'react'
import { ReqTrackingProgressBar } from '../../components/ReqTrackingProgressBar'
import dayjs from 'dayjs'

export const PageDemo = () => {
  const [modalVisible, setModalVisible] = useState<boolean>(false)
  return (
    <Row
      css={css`
        padding: 32px;
      `}
    >
      <Col span={24}>
        <Flex>
          <ReqTrackingProgressBar
            value={{
              submitted: {
                status: 'completed',
                userInfo: {
                  fullName: 'John Doe',
                  date: dayjs(),
                },
              },
              assigned: {
                status: 'inprogress',
                showApprove: true,
                showReject: true,
                showRevise: true,
                userInfo: {
                  fullName: 'John Doe',
                  date: dayjs(),
                },
                onApproveClick: () => {
                  console.log('approve')
                },
                onRejectClick: () => {
                  console.log('reject')
                },
                onReturnForReviseClick: () => {
                  console.log('return for revise')
                },
              },
              executed: {
                status: 'inactive',
                userInfo: {
                  fullName: 'John Doe',
                  date: dayjs(),
                },
              },
              closed: {
                status: 'inactive',
                // userInfo: {
                //   fullName: 'John Doe',
                //   date: dayjs(),
                // },
              },
            }}
          />
        </Flex>

        <Flex>
          <Button
            onClick={() => {
              setModalVisible(true)
            }}
          >
            Modal
          </Button>
          <Modal
            maskClosable={false}
            open={modalVisible}
            onOk={() => {
              setModalVisible(false)
            }}
            onCancel={() => {
              setModalVisible(false)
            }}
            title="Upload File"
          >
            asdfaf23423
          </Modal>
        </Flex>

        <Flex vertical>
          <Txt ag="med30">Medium</Txt>
          <Txt ag="med30">Prompt - 30pt เบอร์โทรติดต่อผู้กรอกข้อมูล</Txt>
          <Txt ag="med28">Prompt - 28pt เบอร์โทรติดต่อผู้กรอกข้อมูล</Txt>
          <Txt ag="med24">Prompt - 24pt เบอร์โทรติดต่อผู้กรอกข้อมูล</Txt>
          <Txt ag="med20">Prompt - 20pt เบอร์โทรติดต่อผู้กรอกข้อมูล</Txt>
          <Txt ag="med18">Prompt - 18pt เบอร์โทรติดต่อผู้กรอกข้อมูล</Txt>
          <Txt ag="med14">Prompt - 14pt เบอร์โทรติดต่อผู้กรอกข้อมูล</Txt>
          <Txt ag="med10">Prompt - 10pt เบอร์โทรติดต่อผู้กรอกข้อมูล</Txt>
          <Txt ag="med8">Prompt - 8pt เบอร์โทรติดต่อผู้กรอกข้อมูล</Txt>

          <Txt ag="reg18">Regular</Txt>
          <Txt ag="reg18">Prompt - 18pt เบอร์โทรติดต่อผู้กรอกข้อมูล Default</Txt>
          <Txt ag="reg14">Prompt - 14pt เบอร์โทรติดต่อผู้กรอกข้อมูล</Txt>

          <Txt ag="light16">Light</Txt>
          <Txt ag="light16">Prompt - 16pt เบอร์โทรติดต่อผู้กรอกข้อมูล</Txt>
          <Txt ag="light12">Prompt - 12pt เบอร์โทรติดต่อผู้กรอกข้อมูล</Txt>
        </Flex>
        <Row>
          <Col>
            <Button
              css={css`
                width: 200px;
              `}
              type="primary"
            >
              Primary
            </Button>
            <Button type="primary" disabled>
              Primary Disabled
            </Button>
            <Button type="default">Default</Button>
            <Button type="default" disabled>
              Default Disabled
            </Button>
          </Col>
        </Row>

        <Row>
          <Col>
            <Button icon={<PlusOutlined />} type="primary">
              Primary
            </Button>
            <Button
              icon={<FaFilter />}
              css={css`
                width: 200px;
                justify-content: center;
              `}
              type="primary"
              disabled
            >
              Primary Disabled
            </Button>
            <Button icon={<IoFilterOutline />} type="default">
              Default
            </Button>
            <Button icon={<MdOutlineFilterVintage />} type="default" disabled>
              Default Disabled
            </Button>
          </Col>
        </Row>

        <Row>
          <Col>
            <Button
              icon={<IoFilterOutline />}
              type="primary"
              css={css`
                background: ${colors.darkBlue};
              `}
            >
              Default
            </Button>
            <Button icon={<MdOutlineFilterVintage />} type="default" disabled>
              Default Disabled
            </Button>
          </Col>
        </Row>

        <Row
          css={css`
            margin-top: 32;
          `}
        >
          <Col>
            <Switch />
          </Col>
          <Col>
            <Switch checked />
          </Col>
          <Col>
            <Switch disabled />
          </Col>
          <Col>
            <Switch checked disabled />
          </Col>
        </Row>

        <Row
          css={css`
            margin-top: 32;
          `}
        >
          <Col>
            <Checkbox>label</Checkbox>
          </Col>
          <Col>
            <Checkbox checked>label</Checkbox>
          </Col>

          <Col>
            <Checkbox disabled>ราเบล</Checkbox>
          </Col>
          <Col>
            <Checkbox checked disabled>
              ราเบล
            </Checkbox>
          </Col>
        </Row>

        <Row
          css={css`
            margin-top: 32;
          `}
        >
          <Col>
            <Radio.Group defaultValue={'2'}>
              <Radio value="1">aaaaa</Radio>
              <Radio value="2">bbbbb</Radio>
              <Radio value="3" disabled>
                bbbbb
              </Radio>
            </Radio.Group>
          </Col>
        </Row>

        <Row
          css={css`
            margin-top: 32;
          `}
        >
          <Col>
            <Select placeholder="placeholder">
              <Select.Option value="1">item 1</Select.Option>
              <Select.Option value="2">item 2</Select.Option>
            </Select>
          </Col>
          <Col>
            <Select placeholder="placeholder" disabled>
              <Select.Option value="1">item 1</Select.Option>
              <Select.Option value="2">item 2</Select.Option>
            </Select>
          </Col>
          <Col>
            <Select defaultValue="1">
              <Select.Option value="1">item 1</Select.Option>
              <Select.Option value="2">item 2</Select.Option>
            </Select>
          </Col>
          <Col>
            <Select defaultValue="1" disabled>
              <Select.Option value="1">item 1</Select.Option>
              <Select.Option value="2">item 2</Select.Option>
            </Select>
          </Col>
          <Form>
            <Form.Item label="ค้นหา" rules={[{ required: true }]} name="select-field" labelCol={{ span: 24 }}>
              <Select placeholder="placeholder" allowClear>
                <Select.Option value="1">item 1</Select.Option>
                <Select.Option value="2">item 2</Select.Option>
              </Select>
            </Form.Item>
            <Button htmlType="submit">submit</Button>
          </Form>
        </Row>

        <Row
          css={css`
            margin-top: 32;
          `}
        >
          <Col>
            <DatePicker placeholder="date picker" />
          </Col>
          <Col>
            <DatePicker placeholder="date picker" disabled />
          </Col>

          <Form>
            <Form.Item label="วันที่" rules={[{ required: true }]} name="select-field" labelCol={{ span: 24 }}>
              <DatePicker placeholder="date picker" />
            </Form.Item>
            <Button htmlType="submit">submit</Button>
          </Form>
        </Row>

        <Row
          css={css`
            margin-top: 32;
          `}
        >
          <Col>
            <Input />
          </Col>
          <Col>
            <Input disabled />
          </Col>
          <Col>
            <Input disabled value="disable value" />
          </Col>
          <Col>
            <Input placeholder="placeholder" />
          </Col>
          <Col>
            <Input placeholder="placeholder" disabled />
          </Col>
          <Form>
            <Form.Item label="ค้นหา" rules={[{ required: true }]} name="input-field" labelCol={{ span: 24 }}>
              <Input />
            </Form.Item>
            <Button htmlType="submit">submit</Button>
          </Form>
        </Row>

        <Row>
          <Col>
            <Input.TextArea autoSize={{ minRows: 4, maxRows: 4 }} />
          </Col>
        </Row>

        <Row
          css={css`
            margin-top: 32;
          `}
        >
          <Col>
            <Tag closable color="blue">
              info
            </Tag>
            <Tag closable color="green">
              info
            </Tag>
            <Tag closable color="orange">
              info
            </Tag>
            <Tag closable color="red">
              info asdfasdfs
            </Tag>
          </Col>
        </Row>

        <Row
          css={css`
            margin-top: 32;
          `}
        >
          <Col>
            <Segmented
              options={[
                'Daily',
                { label: 'Weekly', value: 'Weekly', disabled: true },
                'Monthly',
                { label: 'Quarterly', value: 'Quarterly', disabled: true },
                'Yearly',
              ]}
            />
          </Col>
        </Row>

        <Row
          css={css`
            margin-top: 32;
          `}
        >
          <Col span={24}>
            <Table
              columns={[
                { title: 'AAAAAA', dataIndex: 'a' },
                { title: 'BBBBBB', dataIndex: 'b' },
                { title: 'CCCCC', dataIndex: 'c' },
              ]}
              dataSource={Array.from({ length: 3 }).fill({ a: '111', b: '222', c: '3333' }) as AnyObject[]}
              pagination={{
                total: 100,
              }}
            />
          </Col>
        </Row>

        <Row>
          <Col>
            <Pagination total={85} showPrevNextJumpers showQuickJumper />
          </Col>
        </Row>
      </Col>
    </Row>
  )
}
