import { css } from '@ptt-eia-web/helpers'
import {
  useGetServiceTypesQRY,
  useGetReportTypesQRY,
  useGetProjectTypesQRY,
  useGetMdSubServiceQRY,
} from '@ptt-eia-web/services'
import { Col, DatePicker, Input, Row, Select } from 'antd'
import { useWatch } from 'antd/es/form/Form'
import FormItem from 'antd/es/form/FormItem'
import useFormInstance from 'antd/es/form/hooks/useFormInstance'
import dayjs, { Dayjs } from 'dayjs'
import { useMemo } from 'react'

import { NumberInput } from '../../../components/NumberInput'
import { Txt, TxtProps } from '../../../components/Txt'
import { IBaseFormInfoTypeProps, NEW_REQ_FORM_KEY, requireRule } from '../helper'

import { SelectApproverFormItem } from './SelectApproverFormItem'
import { SelectOwnerInput } from './SelectOwnerInput'

type IFormInfoType3Props = IBaseFormInfoTypeProps

const TxtField = (props: TxtProps & { value?: string }) => {
  const { value, ...restProps } = props
  return <Txt {...restProps}>{value}</Txt>
}

export type RangeValue = [Dayjs, Dayjs] | null
export const FormInfoType3 = (props: IFormInfoType3Props) => {
  const { editable = true } = props
  const form = useFormInstance()

  const planStart = useWatch(NEW_REQ_FORM_KEY.PLAN_START, {
    preserve: true,
  })
  const planEnd = useWatch(NEW_REQ_FORM_KEY.PLAN_END, {
    preserve: true,
  })
  const subServiceId = useWatch(NEW_REQ_FORM_KEY.SUB_SERVICE_ID, {
    preserve: true,
  })

  const { data: serviceTypes } = useGetServiceTypesQRY()
  const serviceTypeOptions = useMemo(() => {
    return serviceTypes
      ?.filter((st) => !!st.active)
      .map((st) => ({
        label: st.name,
        value: st.id,
      }))
  }, [serviceTypes])

  const { data: mdSubService } = useGetMdSubServiceQRY()
  const subServiceOptions = useMemo(() => {
    return mdSubService
      ?.filter((md) => !!md.active)
      .map((md) => ({
        label: md.name,
        value: md.id,
      }))
  }, [mdSubService])

  const { data: reportTypes } = useGetReportTypesQRY()
  const reportTypeOptions = useMemo(() => {
    return reportTypes
      ?.filter((rp) => !!rp.active)
      .map((rp) => ({
        label: rp.name,
        value: rp.id,
      }))
  }, [reportTypes])

  const { data: projectTypes } = useGetProjectTypesQRY()
  const projectTypeOptions = useMemo(() => {
    return projectTypes
      ?.filter((pj) => !!pj.active)
      .map((pj) => ({
        label: pj.name,
        value: pj.id,
      }))
  }, [projectTypes])

  return (
    <>
      <Row gutter={[50, 0]}>
        <FormItem name={NEW_REQ_FORM_KEY.REQ_UNIT_CODE} hidden />
        <FormItem name={NEW_REQ_FORM_KEY.LIST_TB_SERVICE_REQ_TEAM} hidden />
        <FormItem name={NEW_REQ_FORM_KEY.STATUS_ID} hidden />

        <Col sm={24} md={12} lg={8}>
          <FormItem name={NEW_REQ_FORM_KEY.REQ_USER_NAME} labelCol={{ span: 24 }} label="ผู้ส่งคำขอ">
            <TxtField ag="med16" />
          </FormItem>
        </Col>
        <Col sm={24} md={12} lg={8}>
          <FormItem name={NEW_REQ_FORM_KEY.REQ_DATE} labelCol={{ span: 24 }} label="วันเวลาที่ส่งคำขอ">
            <TxtField ag="med16" />
          </FormItem>
        </Col>
        <Col sm={24} md={12} lg={8}>
          <FormItem name={NEW_REQ_FORM_KEY.REQ_UNIT_NAME} labelCol={{ span: 24 }} label="หน่วยงานที่ขอ">
            <TxtField ag="med16" />
          </FormItem>
        </Col>

        <Col sm={24} md={12} lg={8}>
          <FormItem name={NEW_REQ_FORM_KEY.SERVICE_TYPE_ID} labelCol={{ span: 24 }} label="ประเภท Service">
            <Select disabled options={serviceTypeOptions} placeholder="ประเภท Service" />
          </FormItem>
        </Col>
        {subServiceId && (
          <Col sm={24} md={12} lg={8}>
            <FormItem name={NEW_REQ_FORM_KEY.SUB_SERVICE_ID} labelCol={{ span: 24 }} label="ประเภท Sub Service">
              <Select disabled options={subServiceOptions} placeholder="ประเภท Sub Service" />
            </FormItem>
          </Col>
        )}
        <Col sm={24} md={12} lg={8}>
          <FormItem name={NEW_REQ_FORM_KEY.REPORT_TYPE_ID} labelCol={{ span: 24 }} label="ประเภทรายงาน">
            <Select disabled options={reportTypeOptions} placeholder="ประเภทรายงาน" />
          </FormItem>
        </Col>
        <Col sm={24} md={12} lg={8}>
          <FormItem name={NEW_REQ_FORM_KEY.PROJECT_TYPE_ID} labelCol={{ span: 24 }} label="ประเภทโครงการ">
            <Select disabled options={projectTypeOptions} placeholder="ประเภทโครงการ" />
          </FormItem>
        </Col>

        <Col sm={24} md={12} lg={8}>
          <FormItem
            name={NEW_REQ_FORM_KEY.PROJECT_NAME}
            labelCol={{ span: 24 }}
            label="ชื่อโครงการ"
            required
            rules={[requireRule('ชื่อโครงการ')]}
          >
            <Input disabled={!editable} maxLength={250} placeholder="ชื่อโครงการ" />
          </FormItem>
        </Col>
        <Col sm={24} md={12} lg={8}>
          <FormItem name={NEW_REQ_FORM_KEY.PROJECT_ABBR} labelCol={{ span: 24 }} label="ชื่อย่อโครงการ">
            <Input disabled={!editable} maxLength={50} placeholder="ชื่อย่อโครงการ" />
          </FormItem>
        </Col>
      </Row>

      <Row gutter={[50, 0]}>
        <Col sm={24} md={24} lg={8}>
          <FormItem
            name={NEW_REQ_FORM_KEY.PRJ_LOCATION}
            labelCol={{ span: 24 }}
            label="ที่ตั้งโครงการ"
            required
            rules={[requireRule('ที่ตั้งโครงการ')]}
          >
            <Input.TextArea
              css={css`
                min-height: 240px !important;
                max-height: 240px !important;
              `}
              autoSize={{ minRows: 9, maxRows: 9 }}
              disabled={!editable}
              placeholder="ที่ตั้งโครงการ"
              maxLength={1500}
              showCount
            />
          </FormItem>
        </Col>

        <Col md={24} lg={16}>
          <Row gutter={[50, 0]}>
            <Col sm={24} md={12}>
              <FormItem
                name={NEW_REQ_FORM_KEY.OWNER}
                labelCol={{ span: 24 }}
                label="ผู้รับผิดชอบโครงการ"
                required
                rules={[requireRule('ผู้รับผิดชอบโครงการ')]}
              >
                <SelectOwnerInput editable={editable} placeholder="ผู้รับผิดชอบโครงการ" />
              </FormItem>
            </Col>
            <Col sm={24} md={12}>
              <FormItem
                name={NEW_REQ_FORM_KEY.TEL}
                labelCol={{ span: 24 }}
                label="เบอร์โทรติดต่อผู้กรอกข้อมูล"
                required
                rules={[requireRule('เบอร์โทรติดต่อผู้กรอกข้อมูล')]}
              >
                <Input disabled={!editable} placeholder="เบอร์โทรติดต่อผู้กรอกข้อมูล" />
              </FormItem>
            </Col>

            <Col sm={24} md={12}>
              <SelectApproverFormItem editable={editable} />
            </Col>

            <Col span={24}>
              <Row gutter={[50, 0]}>
                <Col sm={24} md={12} lg={12} xl={8}>
                  <FormItem
                    name={NEW_REQ_FORM_KEY.BUDGET}
                    labelCol={{ span: 24 }}
                    label="งบประมาณ (บาท)"
                    required
                    rules={[requireRule('งบประมาณ')]}
                  >
                    <NumberInput disabled={!editable} placeholder="งบประมาณ (บาท)" />
                  </FormItem>
                </Col>
                <Col sm={24} md={12} lg={12} xl={8}>
                  <FormItem
                    name={NEW_REQ_FORM_KEY.COST_CENTER}
                    labelCol={{ span: 24 }}
                    label="Cost center"
                    required
                    rules={[requireRule('Cost center')]}
                  >
                    <Input disabled={!editable} placeholder="Cost center" />
                  </FormItem>
                </Col>
                <Col sm={24} md={12} lg={12} xl={8}>
                  <FormItem
                    name={NEW_REQ_FORM_KEY.IO}
                    labelCol={{ span: 24 }}
                    label="IO"
                    required
                    rules={[requireRule('IO')]}
                  >
                    <Input disabled={!editable} placeholder="IO" />
                  </FormItem>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>

      <Row gutter={[50, 0]}>
        <Col sm={24} lg={8}>
          <FormItem
            name={NEW_REQ_FORM_KEY.PRJ_DETAIL}
            labelCol={{ span: 24 }}
            label="รายละเอียดโครงการเบื้องต้น"
            required
            rules={[requireRule('รายละเอียดโครงการเบื้องต้น')]}
          >
            <Input.TextArea
              css={css`
                min-height: 240px !important;
                max-height: 240px !important;
              `}
              autoSize={{ minRows: 9, maxRows: 9 }}
              disabled={!editable}
              placeholder="รายละเอียดโครงการเบื้องต้น"
              maxLength={1500}
              showCount
            />
          </FormItem>
        </Col>

        <Col sm={24} lg={16}>
          <Row gutter={[50, 0]}>
            <Col sm={12} lg={12}>
              <FormItem name={NEW_REQ_FORM_KEY.PLAN_START} hidden />
              <FormItem name={NEW_REQ_FORM_KEY.PLAN_END} hidden />
              <FormItem
                labelCol={{ span: 24 }}
                label="แผนงานก่อสร้าง (เริ่มต้น/สิ้นสุด)"
                required
                rules={[
                  {
                    validator() {
                      if (!planStart || !planEnd) {
                        return Promise.reject('โปรดระบุแผนงานก่อสร้าง')
                      }
                      return Promise.resolve()
                    },
                  },
                ]}
              >
                <DatePicker.RangePicker
                  css={css`
                    width: 100%;
                  `}
                  value={planStart && planEnd ? [dayjs(planStart), dayjs(planEnd)] : undefined}
                  onChange={(values) => {
                    const [start, end] = values ?? []
                    form.setFieldValue(NEW_REQ_FORM_KEY.PLAN_START, start?.format())
                    form.setFieldValue(NEW_REQ_FORM_KEY.PLAN_END, end?.format())
                  }}
                  disabled={!editable}
                  format="DD/MM/YYYY"
                  placeholder={['เริ่มต้น', 'สิ้นสุด']}
                />
              </FormItem>
            </Col>
          </Row>
          <Row gutter={[50, 0]}>
            <Col sm={24} md={12}>
              <FormItem
                name={NEW_REQ_FORM_KEY.PIPE_SIZE}
                labelCol={{ span: 24 }}
                label="ขนาดท่อ (นิ้ว)"
                required
                rules={[requireRule('ขนาดท่อ (นิ้ว)')]}
              >
                <NumberInput disabled={!editable} placeholder="ขนาดท่อ (นิ้ว)" />
              </FormItem>
            </Col>
            <Col sm={24} md={12}>
              <FormItem
                name={NEW_REQ_FORM_KEY.PIPE_DISTANCE}
                labelCol={{ span: 24 }}
                label="ระยะท่อ (เมตร)"
                required
                rules={[requireRule('ระยะท่อ (เมตร)')]}
              >
                <NumberInput disabled={!editable} placeholder="ระยะท่อ (เมตร)" />
              </FormItem>
            </Col>
            <Col sm={24} md={12}>
              <FormItem
                name={NEW_REQ_FORM_KEY.PIPE_DESIGN_PRESSURE}
                labelCol={{ span: 24 }}
                label="ความดันออกแบบ (psig)"
                required
                rules={[requireRule('ความดันออกแบบ')]}
              >
                <NumberInput disabled={!editable} placeholder="ความดันออกแบบ (psig)" />
              </FormItem>
            </Col>
            <Col sm={24} md={12}>
              <FormItem
                name={NEW_REQ_FORM_KEY.PIPE_MAX_PRESSURE}
                labelCol={{ span: 24 }}
                label="ความดันใช้งานสูงสุด (psig)"
                required
                rules={[requireRule('ความดันใช้งานสูงสุด')]}
              >
                <NumberInput disabled={!editable} placeholder="ความดันใช้งานสูงสุด (psig)" />
              </FormItem>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  )
}
