import { AppModal } from '@ptt-eia-web/components'
import { css } from '@ptt-eia-web/helpers'
import { Flex, Form, Input, ModalProps } from 'antd'
import { useForm } from 'antd/es/form/Form'
import FormItem from 'antd/es/form/FormItem'
import { useCallback } from 'react'

import { Txt } from './Txt'
import { ICustomUploadFile, UploadFileInput, uploadFileRule } from './UploadFileInput'

interface IUploadFileModalProps extends Omit<ModalProps, 'onOk'> {
  onOk: (value: IUploadFileModalValue) => void
}

export interface IUploadFileModalValue {
  description: string
  file: ICustomUploadFile
}

export const UploadFileModal = (props: IUploadFileModalProps) => {
  const { onOk, ...restProps } = props
  const [form] = useForm()

  const handleOkClick = useCallback(() => {
    form.submit()
  }, [form])

  const handleSubmit = useCallback(
    (value: IUploadFileModalValue) => {
      onOk?.(value)
    },
    [onOk],
  )

  return (
    <AppModal
      title="Upload File"
      okText="Save"
      cancelText="Close"
      width={785}
      afterClose={form.resetFields}
      onOk={handleOkClick}
      {...restProps}
    >
      <Flex
        vertical
        css={css`
          margin: 50px 100px 0;
        `}
      >
        <Flex>
          <Txt
            ag="med20"
            color="primary"
            css={css`
              margin-right: 4px;
            `}
          >
            Upload
          </Txt>
          <Txt ag="med20">File</Txt>
        </Flex>
        <Form
          form={form}
          layout="vertical"
          onFinish={handleSubmit}
          css={css`
            margin-top: 40px;
          `}
        >
          <FormItem name="description" label="Description" rules={[{ required: true, message: 'กรุณาระบุรายละเอียด' }]}>
            <Input.TextArea
              maxLength={50}
              showCount
              autoSize={{ minRows: 1, maxRows: 1 }}
              onKeyDown={(e) => {
                if (e.code === 'Enter') {
                  e.preventDefault()
                }
              }}
            />
          </FormItem>
          <FormItem name="file" label="File" rules={[{ required: true, message: 'กรุณาเลือกไฟล์' }, uploadFileRule]}>
            <UploadFileInput />
          </FormItem>
        </Form>
      </Flex>
    </AppModal>
  )
}
