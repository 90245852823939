import styled from '@emotion/styled'
import React from 'react'

import BgProjectDashboard from '../../../assets/project-dashboard/bg-project-dashboard.jpeg'
import { Header } from '../Header'

import { GraphDashboard } from './GraphDashboard'
import { ReportStatus } from './ReportStatus'

const LayoutLineChartStyle = styled.div`
  width: 100%;
  height: 100%;
  background-image: url(${BgProjectDashboard});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
`
export const PageSummaryGraphProjectDashboard = () => {
  setTimeout(() => {
    const bubbleElements = document.getElementsByClassName('bobble') as HTMLCollectionOf<HTMLElement>
    for (const element of Array.from(bubbleElements)) {
      if (element?.style) {
        element.style.animationPlayState = 'paused'
      }
    }
  }, 5000)
  return (
    <LayoutLineChartStyle data-testid="page-summary-graph-project-dashboard">
      <Header />
      <GraphDashboard />
      <ReportStatus />
    </LayoutLineChartStyle>
  )
}
