import { css } from '@ptt-eia-web/helpers'
import { Flex } from 'antd'

import { Txt } from '../../components/Txt'

export const PrNewsTitle = () => {
  return (
    <Flex
      justify="center"
      align="center"
      css={css`
        padding: 18px 0;
      `}
    >
      <Flex
        gap={10}
        css={css`
          z-index: 1;
        `}
      >
        <Txt ag="med30" color="primary" data-testid="fe-title-pr-news">
          PR
        </Txt>
        <Txt ag="med30">News</Txt>
      </Flex>
    </Flex>
  )
}
