import styled from '@emotion/styled'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartOptions,
} from 'chart.js'
import { Bar } from 'react-chartjs-2'

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend)

export const options: ChartOptions<'bar'> = {
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    x: {
      stacked: true,
    },
    y: {
      stacked: true,
    },
  },
  plugins: {
    title: {
      display: false,
      text: 'ประเภท Service',
    },
    legend: {
      display: false,
    },
    tooltip: {
      xAlign: 'left',
      yAlign: 'center',
    },
  },
}

type BarChartObject = {
  axis: string
  label: string
  data: number[]
  backgroundColor: string
}

export interface IBarChartDataSet {
  labels: string[]
  datasets: BarChartObject[]
}

interface IBarChartServiceTypeProps {
  value: IBarChartDataSet
}

const BarChartLayoutStyle = styled.div`
  height: 300px;
`

export const BarChartServiceType = (props: IBarChartServiceTypeProps) => {
  return (
    <BarChartLayoutStyle>
      <Bar options={options} data={props.value} />
    </BarChartLayoutStyle>
  )
}
