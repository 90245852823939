import styled from '@emotion/styled'
import { MenuCodeEnum, PermissionActionEnum } from '@ptt-eia-web/constants'
import { css } from '@ptt-eia-web/helpers'
import {
  IGetMeResponse,
  IRequestTrackingResponse,
  useGetMeSuspenseQRY,
  useGetNewRequestByIdQRY,
  usePermission,
  useGetRequestStatusUpdateIdQRY,
} from '@ptt-eia-web/services'
import { Button, Flex, Form, Space, Spin } from 'antd'
import dayjs from 'dayjs'
import { isEmpty } from 'lodash'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { GoChevronLeft } from 'react-icons/go'
import { Navigate, useNavigate, useParams } from 'react-router-dom'

import { permissionConfigs } from '@frontend/app/permission-configs'
import { paths } from '@frontend/app/route/path-config'
import { ErrorButtonCss } from '@frontend/assets/css/ErrorButtonCss'
import { ActivityLogsModal } from '@frontend/modules/service-request-tracking/ActivityLogsModal'
import { TOP_FIELD_FORM_KEY } from '@frontend/modules/service-request-tracking/RequestTrackingTopField'
import { ProjectTeamTable } from '@frontend/modules/service-request-tracking/project-teams/ProjectTeamTable'
import { getUserInfoByTeamRole } from '@frontend/modules/service-request-tracking/request-helper'

import { themeConfig } from '../../../app/theme'
import BgPieChartDashboard from '../../../assets/project-dashboard/bg-pie-dashboard.jpeg'
import { Txt } from '../../../components/Txt'
import { NEW_REQ_FORM_KEY, createInitFormValue } from '../../service-request/helper'
import { Attachment } from '../../service-request/new/Attachment'

import { ConfirmRecallModal } from './ConfirmRecallModal'
import { TaskForm } from './TaskForm'

const getNumberValue = (id: number | string | undefined) => {
  return id && Number(id)
}

const LayoutStyle = styled.div`
  width: 100%;
  min-height: calc(100vh - ${themeConfig.headerHeight + themeConfig.footerHeight}px);
  background-image: url(${BgPieChartDashboard});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding: 0px 64px 64px 64px;
  position: relative;
`
const attachmentValues = (requestTracking?: IRequestTrackingResponse) => {
  const values: [key: string[], value: unknown][] = []
  if (!isEmpty(requestTracking?.listTbattachConnectPointOutDto))
    values.push([NEW_REQ_FORM_KEY.LIST_TB_ATTACH_CONNECT_POINT, requestTracking?.listTbattachConnectPointOutDto])
  if (!isEmpty(requestTracking?.listTbattachConstructOutDto))
    values.push([NEW_REQ_FORM_KEY.LIST_TB_ATTACH_CONSTRUCT, requestTracking?.listTbattachConstructOutDto])
  if (!isEmpty(requestTracking?.listTbattachOthersOutDto))
    values.push([NEW_REQ_FORM_KEY.LIST_TB_ATTACH_OTHER, requestTracking?.listTbattachOthersOutDto])
  if (!isEmpty(requestTracking?.listTbattachPipeLineOutDto))
    values.push([NEW_REQ_FORM_KEY.LIST_TB_ATTACH_PIPE_LINE, requestTracking?.listTbattachPipeLineOutDto])
  return values
}

const getActions = (requestTracking?: IRequestTrackingResponse, currentUser?: IGetMeResponse | null) => {
  const mdReqStatus = requestTracking?.tbserviceReqOutDto.mdreqStatusId || -1
  const mappingMdTeamRole = {
    md1: 2,
    md2: 3,
    md13: 3,
  }
  const mdTeamRole = `md${mdReqStatus}` as keyof typeof mappingMdTeamRole

  const meInTeam = requestTracking?.listTbserviceReqTeamOutDto.find(
    (team) => team.empCode === currentUser?.code && team.mdteamRole === mappingMdTeamRole[mdTeamRole],
  )

  if (meInTeam) {
    const actionsRequestStatusId: {
      approve?: number
      reject?: number
      returnForRevise?: number
      recall?: number
      approveCancel?: number
      rejectCancel?: number
    } = {
      approve: undefined,
      reject: undefined,
      returnForRevise: undefined,
      recall: undefined,
      approveCancel: undefined,
      rejectCancel: undefined,
    }
    if (meInTeam.mdteamRole === 2 && [1].includes(mdReqStatus)) {
      actionsRequestStatusId.approve = 2
      actionsRequestStatusId.reject = 3
    } else if (meInTeam.mdteamRole === 3 && [2, 13].includes(mdReqStatus)) {
      actionsRequestStatusId.approve = 4
      actionsRequestStatusId.reject = 7
      actionsRequestStatusId.returnForRevise = 6
      actionsRequestStatusId.recall = 13
      actionsRequestStatusId.approveCancel = 11
      actionsRequestStatusId.rejectCancel = 10
    }
    return actionsRequestStatusId
  }

  return undefined
}

export const PageTaskDetail = () => {
  const { id = 'N/A' } = useParams()

  const navigate = useNavigate()
  const [form] = Form.useForm()

  const { data: newRequest, isLoading: isRequestLoading } = useGetNewRequestByIdQRY(id)
  const { data: currentUser, isLoading: isGetMeLoading } = useGetMeSuspenseQRY()
  const { data: reqStatus = [] } = useGetRequestStatusUpdateIdQRY(newRequest?.tbserviceReqOutDto?.id?.toString())

  const [isRecallModalOpen, setIsRecallModalOpen] = useState(false)
  const [isActivityLogModalOpen, setIsActivityLogModalOpen] = useState(false)

  const { can } = usePermission(permissionConfigs)
  const canView = can(MenuCodeEnum.ServiceRequestTask, PermissionActionEnum.View)

  const serviceRequest = useMemo(() => newRequest?.tbserviceReqOutDto, [newRequest?.tbserviceReqOutDto])

  const serviceType = useMemo(
    () => ({
      id: serviceRequest?.mdserviceTypeId,
      name: serviceRequest?.mdserviceTypeDisplay,
    }),
    [serviceRequest?.mdserviceTypeDisplay, serviceRequest?.mdserviceTypeId],
  )

  const subServiceType = useMemo(
    () => ({
      id: serviceRequest?.mdsubServiceId,
      name: serviceRequest?.mdsubServiceIdDisplay,
    }),
    [serviceRequest?.mdsubServiceId, serviceRequest?.mdsubServiceIdDisplay],
  )

  const isPageLoading = useMemo(() => isGetMeLoading || isRequestLoading, [isGetMeLoading, isRequestLoading])

  const approverActions = useMemo(() => getActions(newRequest, currentUser), [currentUser, newRequest])

  const teams = useMemo(() => newRequest?.listTbserviceReqTeamOutDto || [], [newRequest])

  const handleClickBack = useCallback(() => {
    navigate(paths.serviceRequestTask())
  }, [navigate])

  const handleClickRecall = useCallback(async () => {
    setIsRecallModalOpen(true)
  }, [])

  const handleCloseRecall = useCallback(() => {
    setIsRecallModalOpen(false)
  }, [])

  const handleClickOpenActivityLogs = useCallback(() => {
    setIsActivityLogModalOpen(true)
  }, [])

  const handleCloseActivityLogs = useCallback(() => {
    setIsActivityLogModalOpen(false)
  }, [])

  useEffect(() => {
    const approver = newRequest?.listTbserviceReqTeamOutDto.find((team) => team.mdteamRole === 2)
    form.setFieldsValue(
      createInitFormValue(
        [NEW_REQ_FORM_KEY.REQ_USER_NAME, getUserInfoByTeamRole(newRequest?.listTbserviceReqTeamOutDto, 1)?.fullName],
        [NEW_REQ_FORM_KEY.REQ_DATE, dayjs(serviceRequest?.createDate).format('DD/MM/YYYY HH:mm')],
        [NEW_REQ_FORM_KEY.REQ_UNIT_NAME, serviceRequest?.reqUnitName],
        [NEW_REQ_FORM_KEY.REQ_UNIT_CODE, serviceRequest?.reqUnitCode],
        [NEW_REQ_FORM_KEY.PROJECT_NAME, serviceRequest?.projectName],
        [NEW_REQ_FORM_KEY.PROJECT_ABBR, serviceRequest?.projectAbbr],
        [NEW_REQ_FORM_KEY.PRJ_LOCATION, serviceRequest?.prjlocation],
        [NEW_REQ_FORM_KEY.PRJ_DETAIL, serviceRequest?.prjdetail],
        [NEW_REQ_FORM_KEY.OWNER, serviceRequest?.owner],
        [NEW_REQ_FORM_KEY.TEL, serviceRequest?.tel],
        [NEW_REQ_FORM_KEY.BUDGET, serviceRequest?.budget],
        [NEW_REQ_FORM_KEY.COST_CENTER, serviceRequest?.costCenter],
        [NEW_REQ_FORM_KEY.IO, serviceRequest?.io],

        [NEW_REQ_FORM_KEY.PLAN_START, serviceRequest?.planStart ? dayjs(serviceRequest?.planStart) : undefined],
        [NEW_REQ_FORM_KEY.PLAN_END, serviceRequest?.planEnd ? dayjs(serviceRequest?.planEnd) : undefined],

        [NEW_REQ_FORM_KEY.START_POINT, serviceRequest?.startPoint],
        [NEW_REQ_FORM_KEY.END_POINT, serviceRequest?.endPoint],
        [NEW_REQ_FORM_KEY.PIPE_SIZE, serviceRequest?.pipeSize],
        [NEW_REQ_FORM_KEY.PIPE_DISTANCE, serviceRequest?.pipeDistance],
        [NEW_REQ_FORM_KEY.PIPE_DESIGN_PRESSURE, serviceRequest?.pipeDesignPressure],
        [NEW_REQ_FORM_KEY.PIPE_MAX_PRESSURE, serviceRequest?.pipeMaxPressure],
        [NEW_REQ_FORM_KEY.REQ_UNIT_CODE, serviceRequest?.reqUnitCode],
        [NEW_REQ_FORM_KEY.REQ_UNIT_NAME, serviceRequest?.reqUnitName],

        ...attachmentValues(newRequest),

        [NEW_REQ_FORM_KEY.APPROVER, approver?.empCode],
        [NEW_REQ_FORM_KEY.LIST_TB_SERVICE_REQ_TEAM, newRequest?.listTbserviceReqTeamOutDto],
        [NEW_REQ_FORM_KEY.SERVICE_TYPE_ID, getNumberValue(serviceType.id)],
        [NEW_REQ_FORM_KEY.SUB_SERVICE_ID, getNumberValue(subServiceType.id)],
        [NEW_REQ_FORM_KEY.REPORT_TYPE_ID, getNumberValue(newRequest?.tbserviceReqOutDto.mdreportTypeId)],
        [NEW_REQ_FORM_KEY.PROJECT_TYPE_ID, getNumberValue(newRequest?.tbserviceReqOutDto.mdprojectTypeId)],

        [TOP_FIELD_FORM_KEY.REQUEST_NUMBER, serviceRequest?.reqNo],
        [TOP_FIELD_FORM_KEY.TEMP_REQUEST_STATUS_NAME, serviceRequest?.mdreqStatusDisplay],
      ),
    )
  }, [currentUser, form, newRequest, serviceRequest, serviceType.id, subServiceType.id, teams, reqStatus])

  if (!canView) return <Navigate to="/404" />
  return (
    <LayoutStyle>
      {isPageLoading ? (
        <Spin />
      ) : (
        <Space
          data-testid="inner-page-request-task-detail"
          css={css`
            width: 100%;
          `}
          direction="vertical"
          size={16}
        >
          <ActivityLogsModal isOpen={isActivityLogModalOpen} onClose={handleCloseActivityLogs} />
          <ConfirmRecallModal isOpen={isRecallModalOpen} onClose={handleCloseRecall} />
          <Flex
            justify="center"
            align="center"
            css={css`
              padding: 18px 0 0 0;
            `}
          >
            <Txt ag="med30">My Approval</Txt>
          </Flex>
          <Form form={form}>
            <TaskForm
              requestTracking={newRequest}
              serviceTypeId={serviceType.id}
              subServiceTypeId={subServiceType.id}
              loading={isPageLoading}
              onBack={handleClickBack}
              actions={approverActions}
              onOpenActivityLogs={handleClickOpenActivityLogs}
            />
            <Attachment editable={false} serviceTypeId={serviceType.id} subServiceTypeId={subServiceType.id} />
          </Form>
          <ProjectTeamTable data={newRequest?.listTbserviceReqTeamOutDto || []} canManageTeams={false} />
        </Space>
      )}
      <Flex
        justify="center"
        gap={16}
        css={css`
          padding: 64px;
          width: 100%;
        `}
      >
        {serviceRequest?.recallBtn && (
          <Button css={ErrorButtonCss} type="primary" onClick={handleClickRecall}>
            Recall
          </Button>
        )}

        <Button
          css={css`
            width: 92px;
          `}
          type="primary"
          onClick={handleClickBack}
          icon={<GoChevronLeft />}
        >
          Back
        </Button>
      </Flex>
    </LayoutStyle>
  )
}
