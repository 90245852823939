import { AppModal } from '@ptt-eia-web/components'
import { css } from '@ptt-eia-web/helpers'
import { useGetEmployeeDataNoJobGroupMTT } from '@ptt-eia-web/services'
import { Card, Form, Row, Col, Button, ModalProps, Select, Flex, Input } from 'antd'
import { useForm, useWatch } from 'antd/es/form/Form'
import FormItem from 'antd/es/form/FormItem'
import { useCallback, useEffect, useMemo } from 'react'
import { FaSearch } from 'react-icons/fa'

import { colors } from '../app/theme'

import { AsyncSelect } from './AsyncSelect'
import { Txt } from './Txt'

interface ISearchUserModalProps extends Omit<ModalProps, 'onOk'> {
  title: string
  onOk: (value: ISearchUserFormValue) => void
}

interface ISearchFormValue {
  searchBy: string
  search: string
}

export interface ISearchUserFormValue {
  employeeId: string
  iname: string
  employeeNameTH: string
  departmentNameTH: string
  positionNameTH: string
  emailAddress: string
}

export const SearchUserModal = (props: ISearchUserModalProps) => {
  const { title, onOk, ...restProps } = props
  const [searchForm] = useForm()
  const [detailForm] = useForm()
  const searchByValue = useWatch('searchBy', searchForm)

  const { mutateAsync: getEmployeeData } = useGetEmployeeDataNoJobGroupMTT()

  const searchByOptions = useMemo(() => {
    return [
      {
        label: 'รหัสพนักงาน',
        value: 'id',
      },
      {
        label: 'ชื่อพนักงาน',
        value: 'name',
      },
    ]
  }, [])

  const searchFetchFn = useCallback(
    async (v: string) => {
      if (!searchByValue) return []

      const isSearchFromEmployeeId = searchByValue === 'id'
      const data = await getEmployeeData({
        EmployeeID: isSearchFromEmployeeId ? v : undefined,
        EmployeeName: !isSearchFromEmployeeId ? v : undefined,
        Limit: 10,
      })

      return data.map((d) => ({
        label: isSearchFromEmployeeId ? d.employeeId : d.employeeNameTH,
        value: d.employeeId,
      }))
    },
    [getEmployeeData, searchByValue],
  )

  const handleClearClick = useCallback(() => {
    searchForm.resetFields()
  }, [searchForm])

  const handleSearchFinish = useCallback(
    async (value: ISearchFormValue) => {
      if (!value.search || !value.searchBy) return
      try {
        const res = await getEmployeeData({
          Limit: 10,
          EmployeeID: value.search,
        })

        if (res.length) {
          const data = res[0]
          detailForm.setFieldsValue({
            employeeId: data.employeeId,
            iname: data.iname,
            employeeNameTH: data.employeeNameTH,
            departmentNameTH: data.departmentNameTH,
            positionNameTH: data.positionNameTH,
            emailAddress: data.emailAddress,
          })
        }
      } catch {
        // no action
      }
    },
    [detailForm, getEmployeeData],
  )

  const handleOk = useCallback(() => {
    detailForm.submit()
  }, [detailForm])

  const handleDetailSubmit = useCallback(
    (value: ISearchUserFormValue) => {
      onOk?.(value)
    },
    [onOk],
  )

  useEffect(() => {
    searchForm.setFieldValue('search', undefined)
  }, [searchForm, searchByValue])

  return (
    <AppModal
      title={title}
      width={800}
      okText="Save"
      onOk={handleOk}
      afterClose={() => {
        searchForm.resetFields()
        detailForm.resetFields()
      }}
      {...restProps}
    >
      <Card
        css={css`
          margin: 20px 30px;
        `}
      >
        <Form layout="vertical" form={searchForm} onFinish={handleSearchFinish}>
          <Row gutter={[20, 0]}>
            <Col xs={24} md={8}>
              <FormItem label="Search By" name="searchBy" rules={[{ required: true }]}>
                <Select options={searchByOptions} />
              </FormItem>
            </Col>
            <Col xs={24} md={8}>
              <FormItem label="Search" name="search" rules={[{ required: true }]}>
                <AsyncSelect fetchFn={searchFetchFn} disabled={!searchByValue} suffixIcon={<FaSearch />} />
              </FormItem>
            </Col>

            <Col xs={24} md={4}>
              <Button
                css={css`
                  width: 100%;
                  margin-top: 24px;
                `}
                type="primary"
                htmlType="submit"
              >
                Search
              </Button>
            </Col>
            <Col xs={24} md={4}>
              <Button
                css={css`
                  background-color: ${colors.skyBlue};
                  color: ${colors.primary};
                  width: 100%;
                  margin-top: 24px;
                `}
                onClick={handleClearClick}
              >
                Clear
              </Button>
            </Col>
          </Row>
        </Form>
      </Card>

      <Card
        css={css`
          margin: 20px 30px;
        `}
      >
        <Flex justify="center" align="center">
          <Txt ag="med20">Detail</Txt>
        </Flex>
        <Form
          layout="vertical"
          form={detailForm}
          onFinish={handleDetailSubmit}
          css={css`
            margin-top: 20px;
          `}
        >
          <Row gutter={[20, 0]}>
            <Col span={6}>
              <FormItem label="Employee Code" name="employeeId">
                <Input disabled />
              </FormItem>
            </Col>
            <Col span={6}>
              <FormItem label="Prefix" name="iname">
                <Input disabled />
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem label="Name" name="employeeNameTH">
                <Input disabled />
              </FormItem>
            </Col>
          </Row>
          <Row gutter={[20, 0]}>
            <Col span={12}>
              <FormItem label="Department" name="departmentNameTH">
                <Input disabled />
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem label="Position" name="positionNameTH">
                <Input disabled />
              </FormItem>
            </Col>
            <FormItem
              css={css`
                display: none;
              `}
              name="emailAddress"
            />
          </Row>
        </Form>
      </Card>
    </AppModal>
  )
}
