import { IActivityLogResponse, IProjectTeamResponse } from '@ptt-eia-web/services'
import dayjs from 'dayjs'
import { isNumber } from 'lodash'

export const getSubmittedStatus = (currentStatus = -1) => {
  if (currentStatus === 13) return 'completed'
  if ([6, 9, 99].includes(currentStatus)) return 'inprogress'
  if (currentStatus >= 1) return 'completed'
  return 'inprogress'
}

export const getUserInfoByTeamRole = (projectTeams: IProjectTeamResponse[] = [], teamRoleId?: number) => {
  if (teamRoleId) {
    const info = projectTeams.find((team) => team.mdteamRole === teamRoleId)
    if (info)
      return {
        fullName: info.fullname,
        date: dayjs(info.createDate),
        ...info,
      }
  }
  return undefined
}

export const getUserInfoByStatusId = (activities: IActivityLogResponse[] = [], mdreqStatusId?: number) => {
  if (mdreqStatusId) {
    const info = activities.find((activity) => activity.mdreqStatusId === mdreqStatusId)
    if (info)
      return {
        fullName: info.createByDisplay,
        date: dayjs(info.createDate),
      }
  }
  return undefined
}

export const getAssignedInfoByStatusId = (activities: IActivityLogResponse[] = [], mdreqStatusId?: number) => {
  if (mdreqStatusId) {
    if (mdreqStatusId >= 5) return getUserInfoByStatusId(activities, 5)
    if (mdreqStatusId === 4) return getUserInfoByStatusId(activities, 4)
    return undefined
  }
  return undefined
}

export const getAssignedStatus = (statusId?: number | null) => {
  if (!isNumber(statusId) || [6, 9].includes(statusId)) return 'inprogress'
  if (statusId >= 4 && statusId < 13) return 'completed'
  return 'inprogress'
}

export const getExecutedAndClosedStatus = (statusId?: number | null) => {
  if (!isNumber(statusId) || [6, 9].includes(statusId)) return 'inprogress'
  if (statusId > 4 && statusId < 13) return 'completed'
  return 'inprogress'
}
