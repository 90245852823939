import { PropsWithChildren } from 'react'
import { Link } from 'react-router-dom'

interface ISafeLinkToProps extends PropsWithChildren {
  id: string
  path: string
}
export const SafeLinkTo = ({ id, path, children }: ISafeLinkToProps) => {
  return (
    <Link id={id} to={encodeURI(path)}>
      {children}
    </Link>
  )
}
