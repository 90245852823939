import { css } from '@ptt-eia-web/helpers'
import {
  IMDDashboardStatusResponse,
  IProjectDashboardGroupByDashboardStatus,
  IProjectDashboardGroupByReportType,
  IProjectDashboardParam,
  IProjectDashboardResponse,
  IReportTypeDetailResponse,
  useGetMasterDashboardStatusQRY,
  useGetProjectDashboardChartQRY,
  useGetReportTypesQRY,
} from '@ptt-eia-web/services'
import React, { useMemo } from 'react'
import { useSearchParams } from 'react-router-dom'

import { colors } from '../../../app/theme'
import CheckIcon from '../../../assets/svgs/check-icon.svg'

import { DonutChart } from './DonutChart'

interface IProjectDashboardGroupByReportTypeOutDtos {
  mdReportTypeId: number
  mdReportTypeName: string
  mdDashboardStatusProjectCount: number
  projectCount: number
  projectPercent: number
  color: string | null
  mdDashboardStatusName: string
}

interface IDataSet {
  labels: string[]
  datasets: [
    {
      group: string
      label: string
      countProject: number
      data: number[]
      backgroundColor: string[]
      hoverOffset: number
      borderWidth: number
    },
  ]
}

export const ChartReport: React.FC = () => {
  const { data: respDonutData = [] } = useGetMasterDashboardStatusQRY()
  const { data: respReportType = [] } = useGetReportTypesQRY()
  const [param] = useSearchParams()

  const parseSearchParams = (params: URLSearchParams): IProjectDashboardParam => {
    const getIntParam = (paramName: string): number | null => {
      const paramValue = params.get(paramName)
      return paramValue ? parseInt(paramValue, 10) : null
    }

    return {
      MdprojectTypeId: getIntParam('projectTypeId'),
      MdreportTypeId: getIntParam('reportTypeId'),
      Year: getIntParam('year'),
    }
  }

  const { data: apiData } = useGetProjectDashboardChartQRY(parseSearchParams(param))

  const groupedData = useMemo(() => {
    if (!apiData) return []
    return processData(respDonutData, apiData, respReportType)
  }, [apiData, respDonutData, respReportType])

  const dataSets = useMemo(() => {
    return processDatasets(groupedData)
  }, [groupedData])

  const renderDonutChart = (datasets: IDataSet, index: number) => {
    return (
      <div
        key={index}
        css={css`
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 54px 0px;
          .ant-progress {
            margin: 0 -1px;
          }
        `}
      >
        {index !== 0 && (
          <span
            css={css`
              z-index: 0;
              position: relative;
              background-color: ${colors.line};
              height: 26px;
              display: flex;
              justify-content: center;
              align-items: center;
              width: 82px;
              margin: 0px -1px;
              svg {
                font-size: 26px;
              }
            `}
          >
            <img src={CheckIcon} alt="" />
          </span>
        )}
        <DonutChart value={datasets} />
      </div>
    )
  }

  return (
    <div
      css={css`
        display: flex;
        justify-content: center;
      `}
    >
      <div
        css={css`
          display: flex;
          flex-direction: row;
          overflow-x: auto;
        `}
      >
        {dataSets.map((value, index) => renderDonutChart(value, index))}
      </div>
    </div>
  )
}

const processData = (
  respDonutData: IMDDashboardStatusResponse[],
  apiData: IProjectDashboardResponse,
  respReportType: IReportTypeDetailResponse[],
): IProjectDashboardGroupByReportTypeOutDtos[] => {
  if (!apiData.projectDashboardGroupByDashboardStatusOutDtos || !respReportType) {
    return []
  }
  const dashboardData = apiData.projectDashboardGroupByDashboardStatusOutDtos
  return respDonutData
    .flatMap((donutData) => {
      // Ensure donutData.id exists and is correctly referenced
      if (!donutData || typeof donutData.id === 'undefined') {
        return []
      }
      const filteredDashboardData = dashboardData.filter((pdData) => {
        // Ensure pdData.mdDashboardStatusId exists and is correctly referenced
        return typeof pdData.mdDashboardStatusId !== 'undefined' && pdData.mdDashboardStatusId === donutData.id
      })
      return processDashboardData(filteredDashboardData, respReportType)
    })
    .filter((item) => item !== undefined)
}

const processDashboardData = (
  dashboardData: IProjectDashboardGroupByDashboardStatus[],
  reportTypeData: IReportTypeDetailResponse[],
): IProjectDashboardGroupByReportTypeOutDtos[] => {
  return dashboardData.flatMap((pdData) =>
    pdData.projectDashboardGroupByReportTypeOutDtos.flatMap((pdsData) =>
      processReportTypeData(reportTypeData, pdData, pdsData),
    ),
  )
}

const processReportTypeData = (
  reportTypeData: IReportTypeDetailResponse[],
  pdData: IProjectDashboardGroupByDashboardStatus,
  pdsData: IProjectDashboardGroupByReportType,
): IProjectDashboardGroupByReportTypeOutDtos[] => {
  return reportTypeData
    .filter((reportData) => pdsData.mdReportTypeId === reportData.id)
    .map((reportData) => ({
      mdDashboardStatusName: pdData.mdDashboardStatusName,
      mdReportTypeId: pdsData.mdReportTypeId,
      mdReportTypeName: pdsData.mdReportTypeName,
      mdDashboardStatusProjectCount: pdData.projectCount,
      projectCount: pdsData.projectCount,
      projectPercent: pdsData.projectPercent,
      color: reportData.color,
    }))
}

const processDatasets = (groupedData: IProjectDashboardGroupByReportTypeOutDtos[]): IDataSet[] => {
  const newDataSet: IDataSet[] = []
  let currentGroup = ''
  let currentCountProject = 0
  const createDataSet = (
    groupParam: string,
    countProjectParam: number,
    items: IProjectDashboardGroupByReportTypeOutDtos[],
  ): IDataSet => ({
    labels: items.map((item) => item.mdReportTypeName),
    datasets: [
      {
        group: groupParam,
        label: groupParam,
        countProject: countProjectParam,
        data: items.map((item) => item.projectCount),
        backgroundColor: items.map((item) => item.color ?? ''),
        hoverOffset: 4,
        borderWidth: 1,
      },
    ],
  })
  groupedData?.reduce(
    (
      accumulator: IProjectDashboardGroupByReportTypeOutDtos[],
      currentItem: IProjectDashboardGroupByReportTypeOutDtos,
    ) => {
      if (!currentGroup) {
        currentGroup = currentItem.mdDashboardStatusName
        currentCountProject = currentItem.mdDashboardStatusProjectCount
      }
      if (currentGroup !== currentItem.mdDashboardStatusName) {
        newDataSet.push(createDataSet(currentGroup, currentCountProject, accumulator))
        accumulator = []
        currentGroup = currentItem.mdDashboardStatusName
        currentCountProject = currentItem.mdDashboardStatusProjectCount
      }
      accumulator.push(currentItem)
      return accumulator
    },
    [] as IProjectDashboardGroupByReportTypeOutDtos[],
  )
  if (currentGroup && groupedData) {
    newDataSet.push(
      createDataSet(
        currentGroup,
        currentCountProject,
        groupedData.filter((data) => data.mdDashboardStatusName === currentGroup),
      ),
    )
  }
  return newDataSet
}
