import { css } from '@ptt-eia-web/helpers'
import { useGetMeSuspenseQRY } from '@ptt-eia-web/services'
import { FormInstance, useWatch } from 'antd/es/form/Form'
import FormItem from 'antd/es/form/FormItem'
import useFormInstance from 'antd/es/form/hooks/useFormInstance'
import dayjs from 'dayjs'
import { useCallback } from 'react'

import { IUploadFileModalValue } from '../../../components/UploadFileModal'
import {
  IBaseServiceType,
  NEW_REQ_FORM_KEY,
  businessDevelopment,
  eiaErReport,
  eiaMonitoringMr,
  environmentalDueDiligent,
  environmentalModeling,
  environmentalReportScreening,
  preliminaryEia,
  subDevEiaErReport,
  subDevEiaMonitoringMr,
  subDevEnvironmentalModeling,
  subDevPreliminaryEia,
} from '../helper'

import { AttachmentTable, ITableValueType } from './AttachmentTable'

type IAttachmentProps = IBaseServiceType & {
  editable?: boolean
}

const useAttach = (fieldName: string[], form: FormInstance) => {
  const { data: currentUser } = useGetMeSuspenseQRY()
  const data = useWatch<ITableValueType[]>(fieldName, form)
  const add = useCallback(
    (value: IUploadFileModalValue) => {
      const newData: ITableValueType = {
        id: 0,
        tbserviceReqId: 0,
        description: value.description,
        fileName: value.file.fileName ?? '',
        modifyBy: currentUser?.id,
        modifyByDisplay: currentUser?.fullName,
        modifyDate: dayjs().format(),
        newFile: value.file.newFile,
      }
      form.setFieldValue(fieldName, [...(data ?? []), newData])
    },
    [currentUser?.fullName, currentUser?.id, data, fieldName, form],
  )
  const remove = useCallback(
    (recode: ITableValueType) => {
      if (recode.id === 0) {
        const removeIndex = data.indexOf(recode)
        data.splice(removeIndex, 1)
      } else {
        recode.cancelDate = dayjs().format()
      }
      form.setFieldValue(fieldName, [...data])
    },
    [data, fieldName, form],
  )

  return {
    data,
    action: {
      add,
      remove,
    },
  }
}

const getAttachmentFormType = (serviceTypeId?: number, subServiceTypeId?: number) => {
  if (serviceTypeId && [preliminaryEia, eiaErReport].includes(serviceTypeId)) {
    return 1
  }
  if (
    serviceTypeId &&
    subServiceTypeId &&
    [businessDevelopment].includes(serviceTypeId) &&
    [subDevPreliminaryEia, subDevEiaErReport].includes(subServiceTypeId)
  ) {
    return 1
  }

  if (
    serviceTypeId &&
    [environmentalReportScreening, environmentalModeling, environmentalDueDiligent].includes(serviceTypeId)
  ) {
    return 2
  }
  if (
    serviceTypeId &&
    subServiceTypeId &&
    [businessDevelopment].includes(serviceTypeId) &&
    [subDevEnvironmentalModeling].includes(subServiceTypeId)
  ) {
    return 2
  }

  if (serviceTypeId && [eiaMonitoringMr].includes(serviceTypeId)) {
    return 3
  }
  if (
    serviceTypeId &&
    subServiceTypeId &&
    [businessDevelopment].includes(serviceTypeId) &&
    [subDevEiaMonitoringMr].includes(subServiceTypeId)
  ) {
    return 3
  }
  return 0
}

export const Attachment = (props: IAttachmentProps) => {
  const { serviceTypeId: serviceTypeName, subServiceTypeId: subServiceTypeName, editable = true } = props
  const form = useFormInstance()
  const attachPipeLine = useAttach(NEW_REQ_FORM_KEY.LIST_TB_ATTACH_PIPE_LINE, form)
  const attachConnectPoint = useAttach(NEW_REQ_FORM_KEY.LIST_TB_ATTACH_CONNECT_POINT, form)
  const attachConstruct = useAttach(NEW_REQ_FORM_KEY.LIST_TB_ATTACH_CONSTRUCT, form)
  const attachOther = useAttach(NEW_REQ_FORM_KEY.LIST_TB_ATTACH_OTHER, form)

  return (
    <>
      {[1].includes(getAttachmentFormType(serviceTypeName, subServiceTypeName)) && (
        <>
          <FormItem name={NEW_REQ_FORM_KEY.LIST_TB_ATTACH_PIPE_LINE} hidden />
          <AttachmentTable
            css={css`
              margin-top: 20px;
            `}
            title="Attachment รูปภาพแนวท่อ / โครงการ"
            subTitle="(แสดงจุดเริ่มต้น / ขนาดท่อ / จุดสิ้นสุด)"
            dataSource={attachPipeLine.data}
            onUploadConfirm={attachPipeLine.action.add}
            onDeleteClick={attachPipeLine.action.remove}
            editable={editable}
          />
        </>
      )}

      {[1].includes(getAttachmentFormType(serviceTypeName, subServiceTypeName)) && (
        <>
          <FormItem name={NEW_REQ_FORM_KEY.LIST_TB_ATTACH_CONNECT_POINT} hidden />
          <AttachmentTable
            css={css`
              margin-top: 20px;
            `}
            title="Attachment ตำแหน่งจุดเชื่อมต่อ"
            subTitle="(เช่นบริเวณถนน หรือท่อส่งก๊าซเส้นที่...)"
            dataSource={attachConnectPoint.data}
            onUploadConfirm={attachConnectPoint.action.add}
            onDeleteClick={attachConnectPoint.action.remove}
            editable={editable}
          />
        </>
      )}

      {[1, 2, 3].includes(getAttachmentFormType(serviceTypeName, subServiceTypeName)) && (
        <>
          <FormItem name={NEW_REQ_FORM_KEY.LIST_TB_ATTACH_CONSTRUCT} hidden />
          <AttachmentTable
            css={css`
              margin-top: 20px;
            `}
            title="Attachment"
            subTitle="เช่น แผนงานโครงการ แผนงานก่อสร้าง ข้อมูลโครงการ ข้อมูลวิศวกรรม"
            dataSource={attachConstruct.data}
            onUploadConfirm={attachConstruct.action.add}
            onDeleteClick={attachConstruct.action.remove}
            editable={editable}
          />
        </>
      )}

      {[1, 2, 3].includes(getAttachmentFormType(serviceTypeName, subServiceTypeName)) && (
        <>
          <FormItem name={NEW_REQ_FORM_KEY.LIST_TB_ATTACH_OTHER} hidden />
          <AttachmentTable
            css={css`
              margin-top: 20px;
            `}
            title="Attachment"
            subTitle="เอกสารอื่น ๆ"
            dataSource={attachOther.data}
            onUploadConfirm={attachOther.action.add}
            onDeleteClick={attachOther.action.remove}
            editable={editable}
          />
        </>
      )}
    </>
  )
}
