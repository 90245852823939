import React, { useCallback, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { paths } from '../../../../app/route/path-config'
import { Header } from '../Header'

import { Filter } from './Filter'
import { INewReqModalProject, ModalAddNewProject } from './ModalAddNewProject'
import { ProjectListTable } from './ProjectListTable'

export const ProjectListCard = () => {
  const navigate = useNavigate()
  const [isModalNewProjectOpen, setIsModalNewProjectOpen] = useState(false)

  const handleNewProjectModal = useCallback(() => {
    setIsModalNewProjectOpen(true)
  }, [])
  const handleCloseNewProjectModal = useCallback(() => {
    setIsModalNewProjectOpen(false)
  }, [])
  const handleClickOkNewProjectModal = useCallback(
    (value: INewReqModalProject) => {
      setIsModalNewProjectOpen(false)
      navigate(paths.projectStatusUpdateNewProject({ query: value }))
    },
    [navigate],
  )

  return (
    <div>
      <Header onClickNewProject={handleNewProjectModal} />
      <Filter />
      <ProjectListTable />
      {isModalNewProjectOpen && (
        <ModalAddNewProject
          isModalOpen={isModalNewProjectOpen}
          onClickOk={(value: INewReqModalProject) => handleClickOkNewProjectModal(value)}
          onClose={handleCloseNewProjectModal}
        />
      )}
    </div>
  )
}
