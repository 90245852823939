import styled from '@emotion/styled'
import { ModalUtil } from '@ptt-eia-web/components'
import { MenuCodeEnum, PermissionActionEnum } from '@ptt-eia-web/constants'
import { downloadExcelBase64, css } from '@ptt-eia-web/helpers'
import {
  IAttachmentUploadFilePath,
  IProjectTeamParams,
  IUpdateRequestStatusParams,
  IUploadAttachmentParam,
  IUploadAttachmentResponse,
  useDeleteRequestTrackingMTT,
  useGetExportRequestTrackingMTT,
  useGetRequestTrackingDetailQRY,
  usePermission,
  usePostUpdateRequestStatusMTT,
  usePutNewRequestMTT,
  usePutProjectTeamMTT,
  useUploadAttachmentMTT,
} from '@ptt-eia-web/services'
import { Button, Flex, Form, Space, notification } from 'antd'
import { AnyObject } from 'antd/es/_util/type'
import { useCallback, useMemo, useState } from 'react'
import { GoChevronLeft } from 'react-icons/go'
import { Navigate, useNavigate, useParams } from 'react-router-dom'

import { permissionConfigs } from '@frontend/app/permission-configs'
import { paths } from '@frontend/app/route/path-config'
import { ErrorButtonCss } from '@frontend/assets/css/ErrorButtonCss'

import { colors, themeConfig } from '../../app/theme'
import BgPieChartDashboard from '../../assets/project-dashboard/bg-pie-dashboard.jpeg'
import { Txt } from '../../components/Txt'
import { IAttachType, NEW_REQ_FORM_KEY } from '../service-request/helper'

import { ActivityLogsModal } from './ActivityLogsModal'
import { AddReasonBeforeSubmitModal } from './AddReasonBeforeSubmitModal'
import { RequestTrackingForm } from './RequestTrackingForm'
import { ProjectTeamTable } from './project-teams/ProjectTeamTable'

const LayoutStyle = styled.div`
  width: 100%;
  min-height: calc(100vh - ${themeConfig.headerHeight + themeConfig.footerHeight}px);
  background-image: url(${BgPieChartDashboard});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding: 0px 64px 64px 64px;
  position: relative;
`

const uploadAndNormalizeAttachData = async (
  uploadApi: (variables: IUploadAttachmentParam) => Promise<IUploadAttachmentResponse>,
  filePath: IAttachmentUploadFilePath,
  attachFiles?: IAttachType[],
) => {
  const data: IAttachType[] = []

  for (const v of attachFiles ?? []) {
    const { newFile, ...rest } = v
    const fileData = { ...rest }
    if (newFile) {
      const { filePath: newPath } = await uploadApi({
        filePath,
        fileUpload: newFile,
      })
      fileData.path = newPath
      v.path = newPath
      v.newFile = undefined
      data.push(fileData)
    } else if (v.cancelDate) {
      data.push(fileData)
    }
  }

  return data
}

export const PageRequestTracking = () => {
  const { id = 'N/A' } = useParams()
  const [form] = Form.useForm()
  const navigate = useNavigate()
  const { can, currentUser } = usePermission(permissionConfigs)
  const canView = can(MenuCodeEnum.ServiceRequestDashboard, PermissionActionEnum.View)
  const canEdit = can(MenuCodeEnum.ServiceRequestDashboard, PermissionActionEnum.EditAll)

  const { data: requestTracking, isLoading: isRequestLoading } = useGetRequestTrackingDetailQRY(id)

  const { mutateAsync: updateRequestStatus } = usePostUpdateRequestStatusMTT(id)
  const { mutateAsync: deleteRequestTracking, isPending: isDeletePending } = useDeleteRequestTrackingMTT()
  const { mutateAsync: uploadFile } = useUploadAttachmentMTT()
  const { mutateAsync: updateRequest } = usePutNewRequestMTT(id)
  const { mutateAsync: exportExcel } = useGetExportRequestTrackingMTT({
    onSuccess: (response) => {
      downloadExcelBase64(response.base64String, response.fileName)
    },
  })
  const { mutateAsync: editProjectTeam } = usePutProjectTeamMTT(id)

  const [isActivityLogModalOpen, setIsActivityLogModalOpen] = useState(false)
  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false)
  const [isSaveChangeLoading, setIsSaveChangeLoading] = useState(false)
  const [isSaveDraftLoading, setIsSaveDraftLoading] = useState(false)
  const [isSubmitLoading, setIsSubmitLoading] = useState(false)

  const serviceRequest = useMemo(() => requestTracking?.tbserviceReqOutDto, [requestTracking?.tbserviceReqOutDto])

  const isNotSubmitted = useMemo(
    () =>
      serviceRequest?.mdreqStatusId === null ||
      serviceRequest?.mdreqStatusId === 99 ||
      serviceRequest?.mdreqStatusId === 6,
    [serviceRequest?.mdreqStatusId],
  )

  const isRequester = useMemo(
    () => currentUser?.id === serviceRequest?.createBy,
    [currentUser?.id, serviceRequest?.createBy],
  )

  const isShowCancel = useMemo(() => serviceRequest?.cancelBtn && isRequester, [isRequester, serviceRequest?.cancelBtn])
  const isShowDelete = useMemo(() => serviceRequest?.deleteBtn, [serviceRequest?.deleteBtn])

  const editable = useMemo(() => (canEdit || isRequester) && isNotSubmitted, [canEdit, isNotSubmitted, isRequester])

  const handleClickBack = useCallback(() => {
    navigate(paths.serviceRequest())
  }, [navigate])

  const updateTeamRole = useCallback(
    async (newApprover: IProjectTeamParams) => {
      await editProjectTeam(newApprover)
    },
    [editProjectTeam],
  )

  const updateRequestTracking = useCallback(async () => {
    const values = { ...form.getFieldsValue() }
    const {
      tempDateRange,
      tempReqDate,
      tempReqUnitCode,
      tempReqUnitName,
      tempReqUserName,
      tempApproveName,
      tempOwnerName,
      tempRequestNumber,
      ...submitValue
    } = values

    try {
      submitValue.listTbattachPipeLineInDto = await uploadAndNormalizeAttachData(
        uploadFile,
        'PipeLine',
        values.listTbattachPipeLineInDto,
      )
      submitValue.listTbattachConnectPointInDto = await uploadAndNormalizeAttachData(
        uploadFile,
        'ConnectPoint',
        values.listTbattachConnectPointInDto,
      )
      submitValue.listTbattachConstructInDto = await uploadAndNormalizeAttachData(
        uploadFile,
        'Construct',
        values.listTbattachConstructInDto,
      )
      submitValue.listTbattachOthersInDto = await uploadAndNormalizeAttachData(
        uploadFile,
        'Others',
        values.listTbattachOthersInDto,
      )

      submitValue.tbserviceReqInDto = {
        ...submitValue.tbserviceReqInDto,
        id,
      }

      await updateRequest(submitValue)

      const newApprover = submitValue.listTbserviceReqTeamInDto.find((team: AnyObject) => team.mdteamRole === 2)
      const oldApprover = requestTracking?.listTbserviceReqTeamOutDto.find(
        (team) => team.empCode === serviceRequest?.approver,
      )

      if (newApprover && oldApprover) await updateTeamRole({ ...newApprover, Id: oldApprover?.id })
      notification.success({ message: 'Success' })
    } catch {
      // Do not thing
    }
  }, [
    form,
    uploadFile,
    requestTracking?.listTbserviceReqTeamOutDto,
    id,
    updateRequest,
    updateTeamRole,
    serviceRequest?.approver,
  ])

  const handleClickSaveDraft = useCallback(async () => {
    setIsSaveDraftLoading(true)
    form.setFieldValue(NEW_REQ_FORM_KEY.STATUS_ID, 99)
    await updateRequestTracking()
    setIsSaveDraftLoading(false)
  }, [form, updateRequestTracking])

  const hasOneMdTeamRole2 = useMemo(
    () => requestTracking?.listTbserviceReqTeamOutDto.filter((team) => team.mdteamRole === 2).length === 1,
    [requestTracking?.listTbserviceReqTeamOutDto],
  )
  const hasOneMdTeamRole3 = useMemo(
    () => requestTracking?.listTbserviceReqTeamOutDto.filter((team) => team.mdteamRole === 3).length === 1,
    [requestTracking?.listTbserviceReqTeamOutDto],
  )

  const canSubmit = useMemo(() => hasOneMdTeamRole2 && hasOneMdTeamRole3, [hasOneMdTeamRole2, hasOneMdTeamRole3])

  const handleClickSubmit = useCallback(async () => {
    form.setFieldValue(NEW_REQ_FORM_KEY.STATUS_ID, 1)
    if (canSubmit) {
      setIsSubmitLoading(true)
      await updateRequestTracking()
      setIsSubmitLoading(false)
    } else
      ModalUtil.error({
        className: 'danger',
        title: 'ไม่สามารถ submit ได้',
        content: 'รายชื่อ project team ไม่ครบกรุณาตรวจสอบ',
        okText: 'ตกลง',
      })
  }, [form, updateRequestTracking, canSubmit])

  const handleClickOpenActivityLogs = useCallback(() => {
    setIsActivityLogModalOpen(true)
  }, [])

  const handleCloseActivityLogs = useCallback(() => {
    setIsActivityLogModalOpen(false)
  }, [])

  const handleClickExport = useCallback(async () => {
    await exportExcel({ id: Number(id) })
    notification.success({ message: 'ออกรายงานสำเร็จ' })
  }, [exportExcel, id])

  const handleConfirmDelete = useCallback(async () => {
    await deleteRequestTracking({ id: Number(id) }, { onSuccess: () => navigate(paths.serviceRequest()) })
    notification.success({ message: 'ลบรายการสำเร็จ' })
  }, [deleteRequestTracking, id, navigate])

  const handleClickDeleteRequest = useCallback(() => {
    ModalUtil.confirm({
      title: 'ยืนยันการลบ',
      okButtonProps: { loading: isDeletePending },
      onOk: async () => {
        await handleConfirmDelete()
      },
    })
  }, [handleConfirmDelete, isDeletePending])

  const handleClickCancelRequest = useCallback(async () => {
    setIsCancelModalOpen(true)
  }, [])

  const handleCloseCancelModal = useCallback(() => {
    setIsCancelModalOpen(false)
  }, [])

  const handleConfirmCancel = useCallback(
    async (reason: string) => {
      const params: IUpdateRequestStatusParams = {
        mdreqStatusId: 9,
        tbserviceReqId: Number(id),
        reason,
      }
      await updateRequestStatus(params)
    },
    [id, updateRequestStatus],
  )

  const isShowSaveChange = useMemo(() => serviceRequest?.saveChangesBtn, [serviceRequest?.saveChangesBtn])

  const handleClickSaveChange = useCallback(async () => {
    setIsSaveChangeLoading(true)
    await updateRequestTracking()
    setIsSaveChangeLoading(false)
  }, [updateRequestTracking])

  if (!canView) return <Navigate to="/404" />
  return (
    <LayoutStyle data-testid="page-request-tracking">
      <ActivityLogsModal isOpen={isActivityLogModalOpen} onClose={handleCloseActivityLogs} />
      <Button
        css={css`
          width: 150px;
          position: absolute;
          right: 64px;
          top: 18px;
        `}
        type="primary"
        onClick={handleClickExport}
      >
        Export
      </Button>
      <Space
        css={css`
          width: 100%;
        `}
        direction="vertical"
        size={16}
      >
        <Flex
          justify="center"
          align="center"
          css={css`
            padding: 18px 0 0 0;
          `}
          gap={8}
        >
          <Txt ag="med30" color="primary">
            Request
          </Txt>
          <Txt ag="med30" color="darkBlue">
            Tracking
          </Txt>
        </Flex>
        <Form form={form}>
          <RequestTrackingForm
            isNotSubmitted={isNotSubmitted}
            isRequestLoading={isRequestLoading}
            onOpenActivityLogs={handleClickOpenActivityLogs}
            requestTracking={requestTracking}
          />
        </Form>

        <ProjectTeamTable
          data={requestTracking?.listTbserviceReqTeamOutDto || []}
          canManageTeams={!isShowCancel && editable}
          canSaveChange={isShowSaveChange}
          isRequester={isRequester}
        />
      </Space>
      <Flex
        justify="center"
        gap={16}
        css={css`
          padding: 64px;
          width: 100%;
        `}
      >
        {isShowSaveChange && (
          <Button onClick={handleClickSaveChange} loading={isSaveChangeLoading}>
            Save Change
          </Button>
        )}
        {isShowDelete && (
          <Button css={ErrorButtonCss} type="primary" onClick={handleClickDeleteRequest}>
            Delete Request
          </Button>
        )}
        {isShowCancel && (
          <Button css={ErrorButtonCss} type="primary" onClick={handleClickCancelRequest}>
            Cancel Request
          </Button>
        )}

        {editable && (
          <Button
            css={css`
              text-align: center;
              background-color: #006b93;
              color: ${colors.white};
            `}
            type="primary"
            onClick={handleClickSaveDraft}
            loading={isSaveDraftLoading}
          >
            Save Draft
          </Button>
        )}
        {editable && (
          <Button type="primary" onClick={handleClickSubmit} loading={isSubmitLoading}>
            Submit
          </Button>
        )}

        <Button onClick={handleClickBack} icon={<GoChevronLeft />}>
          Back
        </Button>
      </Flex>
      <AddReasonBeforeSubmitModal
        title="ยืนยันการยกเลิกคำขอ"
        onClose={handleCloseCancelModal}
        isOpen={isCancelModalOpen}
        onSubmit={handleConfirmCancel}
      />
    </LayoutStyle>
  )
}
