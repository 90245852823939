import styled from '@emotion/styled'
import { SafeImage } from '@ptt-eia-web/components'
import { css, downloadFile, getFullUrl } from '@ptt-eia-web/helpers'
import { IAnnualReportResponse } from '@ptt-eia-web/services'
import { Button, Flex, Tooltip } from 'antd'
import { useCallback } from 'react'
import { FaRegFilePdf } from 'react-icons/fa'

import { colors } from '@frontend/app/theme'
import { Txt } from '@frontend/components/Txt'

import { TagBadge } from './TagBadge'

interface IKMReportListProps {
  report: IAnnualReportResponse
}

const PDFImageContent = styled.div`
  display: flex;
  justify-content: center;
  align-self: center;
  margin: 70px 0 0;
  width: 170px;
  height: 250px;
  transition: all 0.3s;
  :hover {
    scale: 1.15;
  }
`

const PDFImageFooter = styled.div`
  display: flex;
  justify-content: center;
  align-self: center;
  background-color: ${colors.lightGray};
  width: 170px;
  height: 80px;
`

const LayoutWrapTextStyle = styled.div`
  white-space: nowrap;
  width: 138px;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
`

export const KMReportList = (props: IKMReportListProps) => {
  const { report } = props

  const handleClickDownload = useCallback((pdfPath: string, fileName: string) => {
    const pdfUrl = getFullUrl(pdfPath)
    downloadFile(pdfUrl, fileName)
  }, [])

  return (
    <Flex vertical justify="center" align="center">
      <PDFImageContent>
        <SafeImage
          id={`km_report_cover_${report.id}`}
          url={getFullUrl(report.picturePath)}
          alt={report.pdfFile}
          width={170}
          height={250}
          css={css`
            object-fit: cover;
            width: 100%;
            max-width: 100%;
            max-height: 100%;
          `}
        />
      </PDFImageContent>
      <PDFImageFooter>
        <Flex
          vertical
          justify="center"
          align="start"
          css={css`
            padding: 0 16px;
            width: 100%;
          `}
        >
          <Flex
            gap={10}
            css={css`
              padding-bottom: 8px;
            `}
          >
            <Tooltip title={report.docName} trigger="hover">
              <LayoutWrapTextStyle>{report.docName}</LayoutWrapTextStyle>
            </Tooltip>
            <Txt ag="med14">{report.docYear}</Txt>
          </Flex>
          <TagBadge color={colors.primary} status={report.tag} />
        </Flex>
      </PDFImageFooter>
      <Button
        onClick={() => {
          handleClickDownload(report.pdfPath, report.pdfFile)
        }}
        icon={<FaRegFilePdf size="21px" />}
        type="default"
        css={css`
          width: 170px;
          height: 35px;
          background-color: transparent;
          border-radius: 0;
        `}
      >
        PDF
      </Button>
    </Flex>
  )
}
