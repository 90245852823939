import styled from '@emotion/styled'
import { css } from '@ptt-eia-web/helpers'
import {
  IProjectDashboardGroupByProjectType,
  IProjectDashboardParam,
  useGetDashboardSettingQRY,
  useGetProjectDashboardChartQRY,
} from '@ptt-eia-web/services'
import { Card, Flex } from 'antd'
import React, { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

import { colors } from '../../../app/theme'
import BgGraphDashboard from '../../../assets/project-dashboard/graph-dashboard.png'

import { BobbleButton } from './BobbleButton'
import { ColorIndicator } from './Indicator'

const GRAPH_COLORS = [
  colors.bobblePrimary,
  colors.bobbleSecondary,
  colors.bobbleTertiary,
  colors.bobbleQuaternary,
  colors.bobbleQuinary,
  colors.bobbleSextary,
]

const DashboardContainer = styled.div`
  overflow-x: scroll;
  background: url(${BgGraphDashboard}) no-repeat bottom / contain;
  @media screen and (max-width: 1000px) {
    height: calc(80vw * 1.22);
  }
  @media screen and (min-width: 1001px) and (max-width: 1200px) {
    height: calc(80vw * 0.85);
  }
  @media screen and (min-width: 1201px) and (max-width: 1229px) {
    height: calc(80vw / 1000 * 700);
  }

  @media screen and (min-width: 1230px) and (max-width: 1535px) {
    height: calc(80vw / 1000 * 650);
  }

  @media screen and (min-width: 1536px) and (max-width: 2515px) {
    height: calc(80vw / 1000 * 630);
  }
  @media screen and (min-width: 2516px) {
    height: calc(80vw / 1000 * 510);
  }
`

const DashboardCard = styled(Card)`
  height: 125px;
  width: 100%;
  margin-bottom: 24px;
  background-color: ${colors.white};
  border-radius: 20px;
  border: 0.8px solid ${colors.line};
  box-shadow: 0.5px 0.5px 0.5px ${colors.line};
`

const CardTitle = styled.h1<{ color: string }>`
  font-weight: 400;
  color: ${({ color }) => color};
`

const GraphWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-wrap: wrap;
  row-gap: 60px;
`
const parseSearchParams = (params: URLSearchParams): IProjectDashboardParam => {
  const getIntParam = (paramName: string): number | null => {
    const paramValue = params.get(paramName)
    return paramValue ? parseInt(paramValue, 10) : null
  }

  return {
    MdprojectTypeId: getIntParam('projectTypeId'),
    MdreportTypeId: getIntParam('reportTypeId'),
    Year: getIntParam('year'),
  }
}

export const GraphDashboard: React.FC = () => {
  const getColorOfGraph = (idx: number) => GRAPH_COLORS[idx - 1] || GRAPH_COLORS[GRAPH_COLORS.length - 1]
  const [param] = useSearchParams()
  const [trirTitleValue, setTrirTitleValue] = useState('0')
  const { data: apiData } = useGetProjectDashboardChartQRY(parseSearchParams(param))
  const { data: maserSetting } = useGetDashboardSettingQRY()
  useEffect(() => {
    if (maserSetting) {
      setTrirTitleValue(String(maserSetting.trir))
    }
  }, [maserSetting])
  const renderCol = (item: IProjectDashboardGroupByProjectType) => (
    <div
      css={css`
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
      `}
    >
      <ColorIndicator color={getColorOfGraph(item.mdProjectTypeId)} />
      <span
        css={css`
          padding-top: 2px;
        `}
      >
        {item.mdProjectTypeName}
      </span>
    </div>
  )

  const renderSummaryCard = (valueColor: string, value: number | undefined, label: string) => (
    <DashboardCard>
      {label}
      <CardTitle color={valueColor}>{value}</CardTitle>
    </DashboardCard>
  )

  return (
    <DashboardContainer>
      <div
        css={css`
          display: flex;
          flex-wrap: wrap;
          column-gap: 40px;
        `}
      >
        <div
          css={css`
            width: 210px;
            margin-top: 30px;
            margin-left: 60px;
            @media screen and (max-width: 1090px) {
              width: 100%;
              display: flex;
              column-gap: 20px;
              margin: 0px 30px;
              margin-top: 30px;
            }
          `}
        >
          {renderSummaryCard('#009FDA', apiData?.ltaSummary, 'LTA SUMMARY')}
          {renderSummaryCard(colors.secondary, apiData?.trir, `TRIR < ${trirTitleValue}`)}
          {renderSummaryCard('#002F41', apiData?.eiaFindingIssue, 'EIA FINDING ISSUE')}
        </div>
        <GraphWrapper>
          <Flex
            wrap="wrap"
            css={css`
              column-gap: 84px;
              row-gap: 12px;
              margin-top: 30px;
              @media screen and (max-width: 1090px) {
                margin-top: 0px;
                margin-left: 30px;
              }
            `}
          >
            {apiData?.projectDashboardGroupByProjectTypeOutDtos?.map(
              (item: IProjectDashboardGroupByProjectType, index: number) =>
                index < 6 ? <div key={item.mdProjectTypeId}>{renderCol(item)}</div> : null,
            )}
          </Flex>
          <Flex
            data-testid="project-dashboard-bobble-button"
            justify="space-between"
            flex={1}
            css={css`
              margin: 0px 30px;
            `}
          >
            {apiData?.projectDashboardGroupByProjectTypeOutDtos?.map(
              (item: IProjectDashboardGroupByProjectType, index: number) =>
                index < 6 ? (
                  <BobbleButton
                    animationDuration={[5, 4, 6, 2, 3, 5][index % 5]}
                    key={item.mdProjectTypeId}
                    value={item}
                  />
                ) : null,
            )}
          </Flex>
        </GraphWrapper>
      </div>
    </DashboardContainer>
  )
}
