import {
  QueryKey,
  UndefinedInitialDataOptions,
  UseMutationOptions,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query'
import { message } from 'antd'

import { pttClient } from '../ptt-client'
import { IResponseWithData } from '../services-types'

import { IOtherLinkFormSaveParams } from './other-link-param'
import { IOtherLinkResponse } from './other-link.response'

const OTHER_LINK_BASE_URL_PATH = '/HomeContent/OtherLink'
export const useGetOtherLink = (
  options?: UndefinedInitialDataOptions<IOtherLinkResponse[], Error, IOtherLinkResponse[], QueryKey>,
) => {
  return useQuery<IOtherLinkResponse[]>({
    queryKey: [OTHER_LINK_BASE_URL_PATH],
    queryFn: async (): Promise<IOtherLinkResponse[]> => {
      const res = await pttClient.get<IResponseWithData<IOtherLinkResponse[]>>(OTHER_LINK_BASE_URL_PATH)
      return res.data.data
    },
    ...options,
  })
}

const OTHER_LINK_GET_DETAIL_PATH = '/HomeContent/OtherLink/Id'
export const useGetOtherLinkDetailQRY = (
  id?: number,
  options?: UndefinedInitialDataOptions<IOtherLinkResponse[], Error, IOtherLinkResponse[], QueryKey>,
) => {
  return useQuery<IOtherLinkResponse[]>({
    queryKey: [OTHER_LINK_GET_DETAIL_PATH, { params: { id } }],
    queryFn: async (): Promise<IOtherLinkResponse[]> => {
      const res = await pttClient.get<IResponseWithData<IOtherLinkResponse[]>>(OTHER_LINK_GET_DETAIL_PATH, {
        params: { id },
      })
      return res.data.data
    },
    ...options,
    enabled: !!id,
  })
}

export const usePostOtherLinkMTT = (
  options?: UseMutationOptions<IOtherLinkResponse, Error, Omit<IOtherLinkFormSaveParams, 'id'>, unknown>,
) => {
  const queryClient = useQueryClient()
  return useMutation<IOtherLinkResponse, Error, Omit<IOtherLinkFormSaveParams, 'id'>>({
    mutationFn: async (body: Omit<IOtherLinkFormSaveParams, 'id'>) => {
      const res = await pttClient.post<IResponseWithData<IOtherLinkResponse>>(OTHER_LINK_BASE_URL_PATH, {
        ...body,
      })

      return res.data.data
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [OTHER_LINK_BASE_URL_PATH] })
      message.success('เพิ่มข้อมูลลิงก์อื่นๆสำเร็จ')
    },
    ...options,
  })
}

export const usePutOtherLinkMTT = (
  options?: UseMutationOptions<IOtherLinkResponse, Error, IOtherLinkFormSaveParams, unknown>,
) => {
  const queryClient = useQueryClient()
  return useMutation<IOtherLinkResponse, Error, IOtherLinkFormSaveParams>({
    mutationFn: async (body: IOtherLinkFormSaveParams) => {
      const res = await pttClient.put<IResponseWithData<IOtherLinkResponse>>(OTHER_LINK_BASE_URL_PATH, {
        ...body,
      })

      return res.data.data
    },
    onSuccess: (values, body) => {
      queryClient.invalidateQueries({ queryKey: [OTHER_LINK_BASE_URL_PATH] })
      queryClient.removeQueries({ queryKey: [OTHER_LINK_GET_DETAIL_PATH, { params: { id: body.id } }] })
      message.success('แก้ไขข้อมูลลิงก์อื่นๆสำเร็จ')
    },
    ...options,
  })
}

export const useDeleteOtherLinkMTT = (options?: UseMutationOptions<IOtherLinkResponse, Error, number, unknown>) => {
  const queryClient = useQueryClient()
  return useMutation<IOtherLinkResponse, Error, number>({
    mutationFn: async (id: number) => {
      const res = await pttClient.delete<IResponseWithData<IOtherLinkResponse>>(OTHER_LINK_BASE_URL_PATH, {
        data: { id },
      })

      return res.data.data
    },
    onSuccess: (values) => {
      queryClient.invalidateQueries({ queryKey: [OTHER_LINK_BASE_URL_PATH] })
      message.success('ลบข้อมูลลิงก์อื่นๆสำเร็จ')
    },
    ...options,
  })
}
