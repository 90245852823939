import { useMediaQuery } from 'react-responsive'

export const breakpoint = {
  smMin: '576px',
  mdMin: '768px',
  lgMin: '992px',
  xlMin: '1200px',
  xxlMin: '1600px',

  xsMax: '575.98px',
  smMax: '767.98px',
  mdMax: '991.98px',
  lgMax: '1199.98px',
  xlMax: '1599.98px',
}

export const mediaQuery = {
  xs: `screen and (max-width: ${breakpoint.xsMax})`,
  sm: `screen and (min-width: ${breakpoint.smMin})`,
  md: `screen and (min-width: ${breakpoint.mdMin})`,
  lg: `screen and (min-width: ${breakpoint.lgMin})`,
  xl: `screen and (min-width: ${breakpoint.xlMin})`,
  xxl: `screen and (min-width: ${breakpoint.xxlMin})`,

  xsOnly: `screen and (max-width: ${breakpoint.xsMax})`,
  smOnly: `screen and (min-width: ${breakpoint.smMin}) and (max-width: ${breakpoint.smMax})`,
  mdOnly: `screen and (min-width: ${breakpoint.mdMin}) and (max-width: ${breakpoint.mdMax})`,
  lgOnly: `screen and (min-width: ${breakpoint.lgMin}) and (max-width: ${breakpoint.lgMax})`,
  xlOnly: `screen and (min-width: ${breakpoint.xlMin}) and (max-width: ${breakpoint.xlMax})`,
  xxlOnly: `screen and (min-width: ${breakpoint.xxlMin})`,

  smGte: `screen and (min-width: ${breakpoint.smMin})`,
  mdGte: `screen and (min-width: ${breakpoint.mdMin})`,
  lgGte: `screen and (min-width: ${breakpoint.lgMin})`,
  xlGte: `screen and (min-width: ${breakpoint.xlMin})`,
  xxlGte: `screen and (min-width: ${breakpoint.xxlMin})`,

  smLte: `screen and (max-width: ${breakpoint.smMax})`,
  mdLte: `screen and (max-width: ${breakpoint.mdMax})`,
  lgLte: `screen and (max-width: ${breakpoint.lgMax})`,
  xlLte: `screen and (max-width: ${breakpoint.xlMax})`,
}

export const useScreen = () => {
  const xs = useMediaQuery({ minWidth: 0 })
  const sm = useMediaQuery({ minWidth: 576 })
  const md = useMediaQuery({ minWidth: 768 })
  const lg = useMediaQuery({ minWidth: 992 })
  const xl = useMediaQuery({ minWidth: 1200 })
  const xxl = useMediaQuery({ minWidth: 1600 })

  const isMobile = useMediaQuery({ maxWidth: 575 })
  const isTablet = useMediaQuery({ minWidth: 576, maxWidth: 768 })
  const isDesktop = useMediaQuery({ minWidth: 992 })

  return {
    xs,
    sm,
    md,
    lg,
    xl,
    xxl,
    isMobile,
    isTablet,
    isDesktop,
  }
}
